export const wordList: string[] = [
    "AAIUN",
    "AARAO",
    "ABABA",
    "ABABE",
    "ABACA",
    "ABACE",
    "ABACI",
    "ABACO",
    "ABADA",
    "ABADE",
    "ABADO",
    "ABAFA",
    "ABAFE",
    "ABAFO",
    "ABAIO",
    "ABAIS",
    "ABAJA",
    "ABAJU",
    "ABALA",
    "ABALE",
    "ABALO",
    "ABAMA",
    "ABAMO",
    "ABANA",
    "ABANE",
    "ABANO",
    "ABAPO",
    "ABARA",
    "ABARE",
    "ABARO",
    "ABASO",
    "ABATA",
    "ABATE",
    "ABATI",
    "ABATO",
    "ABATU",
    "ABAVA",
    "ABAVI",
    "ABAVO",
    "ABDAL",
    "ABDAO",
    "ABDAR",
    "ABDAS",
    "ABDON",
    "ABDUZ",
    "ABEAU",
    "ABEBA",
    "ABEBE",
    "ABECA",
    "ABECE",
    "ABECI",
    "ABECO",
    "ABEDA",
    "ABEDE",
    "ABEDO",
    "ABEIS",
    "ABELA",
    "ABEMO",
    "ABESI",
    "ABETA",
    "ABETE",
    "ABETO",
    "ABEXI",
    "ABGLT",
    "ABIAO",
    "ABIAS",
    "ABIBE",
    "ABICO",
    "ABIEL",
    "ABIES",
    "ABIGA",
    "ABIGI",
    "ABILO",
    "ABIOS",
    "ABIRA",
    "ABISA",
    "ABITA",
    "ABITO",
    "ABIUD",
    "ABIUS",
    "ABLES",
    "ABLEU",
    "ABLUA",
    "ABLUI",
    "ABLUO",
    "ABNER",
    "ABNUA",
    "ABNUE",
    "ABNUI",
    "ABNUO",
    "ABOAR",
    "ABOBO",
    "ABOCA",
    "ABOCE",
    "ABOCO",
    "ABOFE",
    "ABOHM",
    "ABOIO",
    "ABOIZ",
    "ABOLA",
    "ABOLE",
    "ABOLI",
    "ABOMA",
    "ABONA",
    "ABONE",
    "ABONO",
    "ABORA",
    "ABRAM",
    "ABRAO",
    "ABRAR",
    "ABRAS",
    "ABREM",
    "ABRES",
    "ABREU",
    "ABRIA",
    "ABRIL",
    "ABRIR",
    "ABRIS",
    "ABRIU",
    "ABSIS",
    "ABSTE",
    "ABSUS",
    "ABUAS",
    "ABUBE",
    "ABUCA",
    "ABUCE",
    "ABUCO",
    "ABUDA",
    "ABUDO",
    "ABUIZ",
    "ABUJA",
    "ABULO",
    "ABUNA",
    "ABUSA",
    "ABUSE",
    "ABUSO",
    "ABUTA",
    "ABUXO",
    "ACABA",
    "ACABE",
    "ACABO",
    "ACACA",
    "ACACU",
    "ACADA",
    "ACADE",
    "ACADO",
    "ACAEM",
    "ACAIA",
    "ACAIO",
    "ACAIS",
    "ACAJA",
    "ACAJU",
    "ACALA",
    "ACALO",
    "ACAMA",
    "ACAME",
    "ACAMO",
    "ACANA",
    "ACANE",
    "ACANO",
    "ACAPA",
    "ACAPE",
    "ACAPO",
    "ACAPU",
    "ACARA",
    "ACARE",
    "ACARI",
    "ACARO",
    "ACARU",
    "ACASA",
    "ACASO",
    "ACATA",
    "ACATE",
    "ACATO",
    "ACAUA",
    "ACAVE",
    "ACAVO",
    "ACAXA",
    "ACCIO",
    "ACEBA",
    "ACEBE",
    "ACEBO",
    "ACECA",
    "ACEFE",
    "ACEJA",
    "ACEJE",
    "ACEJO",
    "ACELO",
    "ACENA",
    "ACENE",
    "ACENO",
    "ACENS",
    "ACERA",
    "ACERE",
    "ACERO",
    "ACESA",
    "ACESO",
    "ACETA",
    "ACETE",
    "ACETO",
    "ACHAI",
    "ACHAM",
    "ACHAR",
    "ACHAS",
    "ACHEI",
    "ACHEM",
    "ACHES",
    "ACHOA",
    "ACHOE",
    "ACHOO",
    "ACHOU",
    "ACIAS",
    "ACIBE",
    "ACICA",
    "ACIDA",
    "ACIDE",
    "ACIDO",
    "ACIES",
    "ACILA",
    "ACILE",
    "ACILO",
    "ACIMA",
    "ACIME",
    "ACIMO",
    "ACINA",
    "ACINO",
    "ACIOA",
    "ACIOS",
    "ACIPE",
    "ACISA",
    "ACISO",
    "ACLES",
    "ACLIA",
    "ACLIO",
    "ACLIS",
    "ACMAO",
    "ACMES",
    "ACMOS",
    "ACNAS",
    "ACNEA",
    "ACNES",
    "ACNUA",
    "ACOAR",
    "ACOBA",
    "ACOBE",
    "ACOBO",
    "ACOCA",
    "ACODA",
    "ACODE",
    "ACODO",
    "ACOES",
    "ACOLA",
    "ACOLE",
    "ACOLI",
    "ACOME",
    "ACONA",
    "ACOPE",
    "ACOPO",
    "ACORA",
    "ACORE",
    "ACORI",
    "ACORO",
    "ACOVA",
    "ACOVE",
    "ACOVO",
    "ACRAL",
    "ACRAS",
    "ACRES",
    "ACREU",
    "ACROA",
    "ACROL",
    "ACRON",
    "ACROS",
    "ACTEL",
    "ACTIA",
    "ACTIO",
    "ACTOL",
    "ACUAI",
    "ACUAM",
    "ACUAR",
    "ACUAS",
    "ACUBA",
    "ACUBE",
    "ACUDA",
    "ACUDE",
    "ACUDI",
    "ACUDO",
    "ACUEI",
    "ACUEM",
    "ACUES",
    "ACULA",
    "ACULE",
    "ACULO",
    "ACUMA",
    "ACUME",
    "ACUMI",
    "ACUNA",
    "ACUNS",
    "ACUOS",
    "ACUOU",
    "ACURA",
    "ACURE",
    "ACURI",
    "ACURO",
    "ACUSA",
    "ACUSE",
    "ACUSO",
    "ACUTA",
    "ACUTI",
    "ADABA",
    "ADACA",
    "ADADA",
    "ADAGA",
    "ADAGE",
    "ADAIL",
    "ADAIR",
    "ADAIS",
    "ADALI",
    "ADAMA",
    "ADAME",
    "ADAMI",
    "ADAMO",
    "ADARA",
    "ADARI",
    "ADAUS",
    "ADEBA",
    "ADEGA",
    "ADEGE",
    "ADEGO",
    "ADEIS",
    "ADEJA",
    "ADEJE",
    "ADEJO",
    "ADELA",
    "ADELE",
    "ADELO",
    "ADEMA",
    "ADENA",
    "ADENO",
    "ADERA",
    "ADERE",
    "ADERI",
    "ADERO",
    "ADESA",
    "ADESO",
    "ADEUS",
    "ADEVA",
    "ADIAI",
    "ADIAM",
    "ADIAO",
    "ADIAR",
    "ADIAS",
    "ADIBA",
    "ADIBE",
    "ADIBO",
    "ADICA",
    "ADIDA",
    "ADIDO",
    "ADIEI",
    "ADIEL",
    "ADIEM",
    "ADIES",
    "ADILA",
    "ADILE",
    "ADILO",
    "ADIMO",
    "ADINA",
    "ADINE",
    "ADINO",
    "ADINS",
    "ADIOE",
    "ADION",
    "ADIOU",
    "ADIPE",
    "ADIPO",
    "ADIRA",
    "ADIRO",
    "ADIT.",
    "ADITA",
    "ADITE",
    "ADITO",
    "ADIVE",
    "ADIXA",
    "ADJAZ",
    "ADJER",
    "ADLAI",
    "ADLER",
    "ADOBA",
    "ADOBE",
    "ADOBO",
    "ADOCA",
    "ADOCE",
    "ADOCO",
    "ADOGA",
    "ADOLF",
    "ADOLI",
    "ADOLO",
    "ADONA",
    "ADONE",
    "ADONI",
    "ADONO",
    "ADORA",
    "ADORE",
    "ADORO",
    "ADOTA",
    "ADOTE",
    "ADOTO",
    "ADOVA",
    "ADOXA",
    "ADOXO",
    "ADQUI",
    "ADROS",
    "ADSIR",
    "ADSON",
    "ADUAI",
    "ADUAM",
    "ADUAR",
    "ADUAS",
    "ADUBA",
    "ADUBE",
    "ADUBO",
    "ADUCA",
    "ADUCE",
    "ADUCI",
    "ADUCO",
    "ADUEI",
    "ADUEM",
    "ADUES",
    "ADUFA",
    "ADUFE",
    "ADUFO",
    "ADULA",
    "ADULE",
    "ADULO",
    "ADUMA",
    "ADUME",
    "ADUMO",
    "ADUNA",
    "ADUNE",
    "ADUNO",
    "ADUNS",
    "ADUOU",
    "ADURA",
    "ADURE",
    "ADURI",
    "ADURO",
    "ADV.º",
    "ADVEM",
    "ADVIM",
    "ADVIR",
    "AECIO",
    "AEDES",
    "AEDOS",
    "AEEAD",
    "AEGLA",
    "AELIA",
    "AENEO",
    "AEREA",
    "AEREO",
    "AFACA",
    "AFACO",
    "AFAGA",
    "AFAGO",
    "AFALA",
    "AFAMA",
    "AFAME",
    "AFAMO",
    "AFANA",
    "AFANE",
    "AFANO",
    "AFARA",
    "AFAZE",
    "AFEAI",
    "AFEAR",
    "AFECA",
    "AFEEI",
    "AFEFE",
    "AFEGA",
    "AFEGO",
    "AFEIA",
    "AFEIE",
    "AFEIO",
    "AFEOU",
    "AFERA",
    "AFERE",
    "AFERI",
    "AFERO",
    "AFETA",
    "AFETE",
    "AFETO",
    "AFIAI",
    "AFIAL",
    "AFIAM",
    "AFIAR",
    "AFIAS",
    "AFIDO",
    "AFIEI",
    "AFIEM",
    "AFIES",
    "AFIFA",
    "AFIFE",
    "AFIFO",
    "AFILA",
    "AFILO",
    "AFINA",
    "AFINE",
    "AFINO",
    "AFINS",
    "AFIOS",
    "AFIOU",
    "AFIRA",
    "AFIRO",
    "AFITO",
    "AFIXA",
    "AFIXE",
    "AFIXO",
    "AFLAI",
    "AFLAM",
    "AFLAR",
    "AFLAS",
    "AFLEI",
    "AFLEM",
    "AFLES",
    "AFLOU",
    "AFLUA",
    "AFLUI",
    "AFLUO",
    "AFOBA",
    "AFOBE",
    "AFOBO",
    "AFODO",
    "AFOES",
    "AFOFA",
    "AFOFE",
    "AFOFO",
    "AFOGA",
    "AFOGO",
    "AFOLA",
    "AFOLE",
    "AFOLO",
    "AFONA",
    "AFONO",
    "AFORA",
    "AFORE",
    "AFORO",
    "AFOSE",
    "AFOXE",
    "AFRAS",
    "AFROS",
    "AFTAS",
    "AFUFO",
    "AFUMA",
    "AFUME",
    "AFUMO",
    "AFURA",
    "AGABE",
    "AGABO",
    "AGACE",
    "AGADA",
    "AGADO",
    "AGAIS",
    "AGALA",
    "AGAMA",
    "AGAME",
    "AGAMI",
    "AGAMO",
    "AGANA",
    "AGANE",
    "AGANO",
    "AGAPA",
    "AGAPE",
    "AGARA",
    "AGARE",
    "AGARO",
    "AGATA",
    "AGATE",
    "AGATI",
    "AGAVA",
    "AGAVE",
    "AGAVO",
    "AGAZA",
    "AGAZE",
    "AGAZO",
    "AGEIA",
    "AGEIS",
    "AGEMA",
    "AGENA",
    "AGENO",
    "AGIAM",
    "AGIAR",
    "AGIAS",
    "AGIDA",
    "AGIDO",
    "AGIMO",
    "AGINA",
    "AGINO",
    "AGIOS",
    "AGIRA",
    "AGITA",
    "AGITE",
    "AGITO",
    "AGLAE",
    "AGLAS",
    "AGLEA",
    "AGLIA",
    "AGMAR",
    "AGMAS",
    "AGMEN",
    "AGMIS",
    "AGNAL",
    "AGNAS",
    "AGNES",
    "AGNIS",
    "AGNOS",
    "AGNUS",
    "AGOGA",
    "AGOGE",
    "AGOGO",
    "AGOLO",
    "AGOMA",
    "AGONA",
    "AGONO",
    "AGONS",
    "AGORA",
    "AGORI",
    "AGOTA",
    "AGOTE",
    "AGOTO",
    "AGRAI",
    "AGRAM",
    "AGRAO",
    "AGRAR",
    "AGRAS",
    "AGRAZ",
    "AGREA",
    "AGREI",
    "AGREM",
    "AGRES",
    "AGREU",
    "AGRIA",
    "AGRIO",
    "AGROR",
    "AGROS",
    "AGROU",
    "AGUAI",
    "AGUAM",
    "AGUAR",
    "AGUAS",
    "AGUCA",
    "AGUCE",
    "AGUCO",
    "AGUDA",
    "AGUDE",
    "AGUDO",
    "AGUEI",
    "AGUEM",
    "AGUES",
    "AGUIA",
    "AGUOU",
    "AGUTI",
    "AIACA",
    "AIADA",
    "AIADO",
    "AIAIA",
    "AIAIS",
    "AIALA",
    "AIAMO",
    "AIANE",
    "AIAPA",
    "AIAPE",
    "AIARA",
    "AIAVA",
    "AICAR",
    "AIDJE",
    "AIDOS",
    "AIDRO",
    "AIEIS",
    "AIEMO",
    "AIGUE",
    "AIMAO",
    "AIMAS",
    "AIMEE",
    "AIMOL",
    "AINAS",
    "AINDA",
    "AINES",
    "AINHO",
    "AINOA",
    "AINOS",
    "AINSA",
    "AINUS",
    "AIOCA",
    "AIOLA",
    "AIORO",
    "AIPIM",
    "AIPOS",
    "AIRAI",
    "AIRAM",
    "AIRAO",
    "AIRAR",
    "AIRAS",
    "AIREI",
    "AIREM",
    "AIRES",
    "AIROL",
    "AIROU",
    "AIRUA",
    "AISLA",
    "AISLE",
    "AISLO",
    "AITAO",
    "AIUBA",
    "AIUCA",
    "AIUIU",
    "AIUNA",
    "AIUNE",
    "AIUNO",
    "AIVAL",
    "AIVAO",
    "AIVAS",
    "AIXES",
    "AIZOA",
    "AJABO",
    "AJACU",
    "AJAEZ",
    "AJAIS",
    "AJAJA",
    "AJAME",
    "AJAMO",
    "AJANA",
    "AJAPA",
    "AJARA",
    "AJARE",
    "AJAUA",
    "AJERU",
    "AJIPA",
    "AJOBO",
    "AJOIA",
    "AJOIE",
    "AJOIO",
    "AJOIS",
    "AJOLE",
    "AJUBA",
    "AJUDA",
    "AJUDE",
    "AJUDO",
    "AJUFE",
    "AJUGA",
    "AJULA",
    "AJULE",
    "AJULO",
    "AJUPA",
    "AJUPE",
    "AJURI",
    "AJURU",
    "ALABA",
    "ALABE",
    "ALABO",
    "ALACR",
    "ALADA",
    "ALADO",
    "ALAFE",
    "ALAGA",
    "ALAGO",
    "ALAIM",
    "ALAIN",
    "ALAIR",
    "ALALA",
    "ALALI",
    "ALALO",
    "ALAMA",
    "ALAMO",
    "ALANA",
    "ALANE",
    "ALANO",
    "ALAOR",
    "ALAPA",
    "ALAPE",
    "ALAPI",
    "ALAPO",
    "ALARA",
    "ALARE",
    "ALARO",
    "ALASA",
    "ALASO",
    "ALAUR",
    "ALAUS",
    "ALAVA",
    "ALAVO",
    "ALAZA",
    "ALAZE",
    "ALAZO",
    "ALBAS",
    "ALBIA",
    "ALBIN",
    "ALBIS",
    "ALBOI",
    "ALBOM",
    "ALBOR",
    "ALBOS",
    "ALBUM",
    "ALBUR",
    "ALCAI",
    "ALCAM",
    "ALCAO",
    "ALCAR",
    "ALCAS",
    "ALCAZ",
    "ALCEA",
    "ALCEI",
    "ALCEM",
    "ALCES",
    "ALCEU",
    "ALCIR",
    "ALCIS",
    "ALCOS",
    "ALCOU",
    "ALDEA",
    "ALDEL",
    "ALDER",
    "ALDIR",
    "ALDOL",
    "ALEAI",
    "ALEAR",
    "ALEAS",
    "ALEDA",
    "ALEDE",
    "ALEDO",
    "ALEEI",
    "ALEFE",
    "ALEGA",
    "ALEGO",
    "ALEIA",
    "ALEIE",
    "ALEIO",
    "ALEJA",
    "ALELI",
    "ALELO",
    "ALEMA",
    "ALEMO",
    "ALENA",
    "ALEOS",
    "ALEOU",
    "ALEPO",
    "ALERJ",
    "ALETA",
    "ALETE",
    "ALETO",
    "ALEUS",
    "ALEVA",
    "ALEXA",
    "ALFAI",
    "ALFAM",
    "ALFAR",
    "ALFAS",
    "ALFEI",
    "ALFEM",
    "ALFES",
    "ALFEU",
    "ALFIL",
    "ALFIM",
    "ALFIO",
    "ALFIR",
    "ALFIS",
    "ALFOL",
    "ALFOS",
    "ALFOU",
    "ALFOZ",
    "ALFRE",
    "ALFUR",
    "ALGAL",
    "ALGAR",
    "ALGAS",
    "ALGAZ",
    "ALGER",
    "ALGES",
    "ALGIA",
    "ALGOL",
    "ALGOR",
    "ALGOS",
    "ALGOZ",
    "ALGUM",
    "ALGUR",
    "ALHAL",
    "ALHAR",
    "ALHAS",
    "ALHEA",
    "ALHEO",
    "ALHIA",
    "ALHOS",
    "ALIAI",
    "ALIAM",
    "ALIAR",
    "ALIAS",
    "ALIBA",
    "ALIBI",
    "ALICA",
    "ALICE",
    "ALICO",
    "ALIDA",
    "ALIEI",
    "ALIEM",
    "ALIES",
    "ALIFE",
    "ALIJA",
    "ALIJE",
    "ALIJO",
    "ALILA",
    "ALILO",
    "ALIMA",
    "ALIME",
    "ALINA",
    "ALINE",
    "ALIOS",
    "ALIOU",
    "ALIPA",
    "ALIPO",
    "ALISA",
    "ALISE",
    "ALISO",
    "ALITA",
    "ALJUS",
    "ALJUZ",
    "ALMAO",
    "ALMAS",
    "ALMEZ",
    "ALMIR",
    "ALMOM",
    "ALMOS",
    "ALMUZ",
    "ALNAS",
    "ALNOS",
    "ALOCA",
    "ALOCO",
    "ALODE",
    "ALOES",
    "ALOFE",
    "ALOFO",
    "ALOGO",
    "ALOJA",
    "ALOJE",
    "ALOJO",
    "ALONA",
    "ALONO",
    "ALONS",
    "ALOSE",
    "ALOTE",
    "ALPAO",
    "ALPEA",
    "ALPES",
    "ALPOS",
    "ALQUA",
    "ALSOL",
    "ALTAR",
    "ALTAS",
    "ALTEA",
    "ALTER",
    "ALTIM",
    "ALTOR",
    "ALTOS",
    "ALUAI",
    "ALUAM",
    "ALUAN",
    "ALUAR",
    "ALUAS",
    "ALUCO",
    "ALUDA",
    "ALUDE",
    "ALUDI",
    "ALUDO",
    "ALUEI",
    "ALUEM",
    "ALUES",
    "ALUFA",
    "ALUGA",
    "ALUGO",
    "ALUIA",
    "ALUIO",
    "ALUIR",
    "ALUIS",
    "ALUIU",
    "ALUJA",
    "ALULA",
    "ALUMA",
    "ALUME",
    "ALUMO",
    "ALUNA",
    "ALUNE",
    "ALUNO",
    "ALUNS",
    "ALUOU",
    "ALURA",
    "ALVAO",
    "ALVAR",
    "ALVAS",
    "ALVEA",
    "ALVEO",
    "ALVES",
    "ALVIM",
    "ALVOR",
    "ALVOS",
    "AMACA",
    "AMACI",
    "AMADA",
    "AMADE",
    "AMADO",
    "AMADU",
    "AMAGA",
    "AMAGO",
    "AMAIS",
    "AMALA",
    "AMALE",
    "AMAME",
    "AMAMO",
    "AMAMU",
    "AMANA",
    "AMANI",
    "AMAPA",
    "AMARA",
    "AMARI",
    "AMARO",
    "AMASA",
    "AMATO",
    "AMAVA",
    "AMBAO",
    "AMBAR",
    "AMBAS",
    "AMBEL",
    "AMBER",
    "AMBIA",
    "AMBIE",
    "AMBIL",
    "AMBIO",
    "AMBIR",
    "AMBIS",
    "AMBOA",
    "AMBOM",
    "AMBOS",
    "AMBRA",
    "AMBRE",
    "AMBRO",
    "AMBUA",
    "AMBUI",
    "AMBUS",
    "AMEAI",
    "AMEAR",
    "AMEBA",
    "AMECA",
    "AMEEI",
    "AMEIA",
    "AMEIE",
    "AMEIO",
    "AMEIS",
    "AMEJU",
    "AMELA",
    "AMELE",
    "AMELO",
    "AMEMO",
    "AMENA",
    "AMENO",
    "AMENS",
    "AMEOU",
    "AMETE",
    "AMIAL",
    "AMIBA",
    "AMICO",
    "AMIDA",
    "AMIDE",
    "AMIDO",
    "AMIEL",
    "AMIGA",
    "AMIGO",
    "AMILA",
    "AMILO",
    "AMIMA",
    "AMIME",
    "AMIMO",
    "AMINA",
    "AMINE",
    "AMINO",
    "AMINS",
    "AMIOS",
    "AMITO",
    "AMNIO",
    "AMOES",
    "AMOIA",
    "AMOIE",
    "AMOIO",
    "AMOJO",
    "AMOLA",
    "AMOLE",
    "AMOLO",
    "AMOMO",
    "AMONA",
    "AMONE",
    "AMONO",
    "AMORA",
    "AMORE",
    "AMOTA",
    "AMOTE",
    "AMOTO",
    "AMOVO",
    "AMPAS",
    "AMPLA",
    "AMPLO",
    "AMRAO",
    "AMUAI",
    "AMUAM",
    "AMUAR",
    "AMUAS",
    "AMUCA",
    "AMUEI",
    "AMUEM",
    "AMUES",
    "AMUOS",
    "AMUOU",
    "AMURA",
    "AMURE",
    "AMURI",
    "AMURO",
    "AMUSA",
    "AMUSO",
    "AMUXA",
    "ANABE",
    "ANABI",
    "ANABO",
    "ANACA",
    "ANACE",
    "ANACO",
    "ANAEL",
    "ANAFA",
    "ANAFE",
    "ANAFO",
    "ANAGE",
    "ANAHY",
    "ANAIA",
    "ANAIS",
    "ANAJA",
    "ANAJE",
    "ANAMA",
    "ANAME",
    "ANAMI",
    "ANAMO",
    "ANANA",
    "ANANI",
    "ANANO",
    "ANAOS",
    "ANAPU",
    "ANARI",
    "ANATA",
    "ANATE",
    "ANATI",
    "ANATO",
    "ANAUA",
    "ANAUE",
    "ANAVI",
    "ANAZA",
    "ANAZE",
    "ANAZO",
    "ANBAR",
    "ANCAS",
    "ANCEL",
    "ANCEU",
    "ANCHA",
    "ANCHE",
    "ANCHO",
    "ANCIA",
    "ANCIL",
    "ANCIS",
    "ANCOL",
    "ANCOS",
    "ANDAI",
    "ANDAM",
    "ANDAR",
    "ANDAS",
    "ANDEI",
    "ANDEM",
    "ANDES",
    "ANDIM",
    "ANDIR",
    "ANDOA",
    "ANDOE",
    "ANDOO",
    "ANDOR",
    "ANDOU",
    "ANDRE",
    "ANDUA",
    "ANDUS",
    "ANEAI",
    "ANEAR",
    "ANECI",
    "ANEDO",
    "ANEEI",
    "ANEEL",
    "ANEIA",
    "ANEIE",
    "ANEIO",
    "ANEIS",
    "ANEJO",
    "ANELA",
    "ANELE",
    "ANELO",
    "ANEMA",
    "ANEOU",
    "ANESA",
    "ANETE",
    "ANETO",
    "ANEXA",
    "ANEXE",
    "ANEXO",
    "ANEZA",
    "ANGAA",
    "ANGAS",
    "ANGAU",
    "ANGEL",
    "ANGLA",
    "ANGLO",
    "ANGOR",
    "ANGRA",
    "ANGUL",
    "ANGUS",
    "ANHOS",
    "ANIAO",
    "ANIAS",
    "ANIBA",
    "ANIEL",
    "ANILA",
    "ANILE",
    "ANILO",
    "ANIMA",
    "ANIME",
    "ANIMI",
    "ANIMO",
    "ANINA",
    "ANION",
    "ANIRA",
    "ANISA",
    "ANISE",
    "ANISO",
    "ANITA",
    "ANITE",
    "ANITO",
    "ANIXI",
    "ANIXO",
    "ANJAO",
    "ANJAS",
    "ANJOS",
    "ANJUM",
    "ANODO",
    "ANOES",
    "ANOIS",
    "ANOJA",
    "ANOJE",
    "ANOJO",
    "ANONA",
    "ANORA",
    "ANORI",
    "ANOSA",
    "ANOSO",
    "ANOTA",
    "ANOTE",
    "ANOTO",
    "ANRAO",
    "ANSAR",
    "ANSAS",
    "ANSEL",
    "ANSIA",
    "ANTAI",
    "ANTAL",
    "ANTAM",
    "ANTAO",
    "ANTAR",
    "ANTAS",
    "ANTEI",
    "ANTEM",
    "ANTES",
    "ANTEU",
    "ANTIA",
    "ANTOS",
    "ANTOU",
    "ANTR.",
    "ANTRO",
    "ANUAI",
    "ANUAL",
    "ANUAM",
    "ANUAR",
    "ANUAS",
    "ANUBE",
    "ANUEM",
    "ANUIA",
    "ANUIR",
    "ANUIS",
    "ANUIU",
    "ANUJA",
    "ANULA",
    "ANULE",
    "ANULO",
    "ANUNS",
    "ANUOS",
    "ANURI",
    "ANURO",
    "ANUUS",
    "ANZOL",
    "AOITA",
    "AONDE",
    "AONIA",
    "AONIO",
    "AOQUI",
    "AORTA",
    "AOTOS",
    "APAGA",
    "APAGE",
    "APAGO",
    "APAIM",
    "APAJA",
    "APAJE",
    "APAJO",
    "APAMA",
    "APANA",
    "APAPA",
    "APARA",
    "APARE",
    "APARO",
    "APART",
    "APEAI",
    "APEAR",
    "APECU",
    "APEDA",
    "APEDE",
    "APEDO",
    "APEEI",
    "APEGA",
    "APEGO",
    "APEIA",
    "APEIE",
    "APEIO",
    "APEJA",
    "APELA",
    "APELE",
    "APELO",
    "APENA",
    "APENE",
    "APENO",
    "APEOU",
    "APERA",
    "APERE",
    "APERO",
    "APERU",
    "APETA",
    "APETO",
    "APIAA",
    "APIAI",
    "APIAO",
    "APICE",
    "APICU",
    "APIDA",
    "APIOL",
    "APIOS",
    "APIRA",
    "APIRO",
    "APITA",
    "APITE",
    "APITO",
    "APITU",
    "APLAO",
    "APOAS",
    "APOCA",
    "APODA",
    "APODE",
    "APODI",
    "APODO",
    "APOEM",
    "APOES",
    "APOFE",
    "APOIA",
    "APOIE",
    "APOIO",
    "APOJO",
    "APOLO",
    "APONA",
    "APONE",
    "APONO",
    "APORA",
    "APORE",
    "APORO",
    "APOTA",
    "APOTO",
    "APRAZ",
    "APREA",
    "APRES",
    "APTAI",
    "APTAM",
    "APTAR",
    "APTAS",
    "APTEI",
    "APTEM",
    "APTES",
    "APTOS",
    "APTOU",
    "APUAR",
    "APUIE",
    "APUIM",
    "APULA",
    "APULO",
    "APUPO",
    "APURA",
    "APURE",
    "APURO",
    "AQUEA",
    "AQUEM",
    "AQUEO",
    "AQUEU",
    "AQUIM",
    "AQUIS",
    "ARAAO",
    "ARABA",
    "ARABE",
    "ARABI",
    "ARABO",
    "ARABU",
    "ARACA",
    "ARACE",
    "ARACI",
    "ARACU",
    "ARADA",
    "ARADE",
    "ARADO",
    "ARAIA",
    "ARAIO",
    "ARAIS",
    "ARAIU",
    "ARALO",
    "ARAMA",
    "ARAME",
    "ARAMO",
    "ARANA",
    "ARANI",
    "ARAPU",
    "ARARA",
    "ARARE",
    "ARARI",
    "ARATA",
    "ARATO",
    "ARATU",
    "ARAUA",
    "ARAUE",
    "ARAVA",
    "ARAVO",
    "ARAXA",
    "ARAZA",
    "ARBEL",
    "ARBIA",
    "ARBIM",
    "ARBIO",
    "ARCAI",
    "ARCAM",
    "ARCAO",
    "ARCAR",
    "ARCAS",
    "ARCAZ",
    "ARCEU",
    "ARCHA",
    "ARCIO",
    "ARCOS",
    "ARCOU",
    "ARCTA",
    "ARCTE",
    "ARCTO",
    "ARCUA",
    "ARCUE",
    "ARCUO",
    "ARDAM",
    "ARDAS",
    "ARDEA",
    "ARDEI",
    "ARDEM",
    "ARDER",
    "ARDES",
    "ARDEU",
    "ARDIA",
    "ARDIL",
    "ARDIS",
    "ARDOM",
    "ARDOR",
    "ARDRA",
    "ARDUA",
    "ARDUO",
    "AREAI",
    "AREAL",
    "AREAO",
    "AREAR",
    "AREAS",
    "ARECA",
    "AREDE",
    "AREEI",
    "AREIA",
    "AREIE",
    "AREIO",
    "AREIS",
    "AREJA",
    "AREJE",
    "AREJO",
    "ARELI",
    "ARELO",
    "AREMO",
    "ARENA",
    "ARENE",
    "ARENO",
    "AREOL",
    "AREOU",
    "ARERE",
    "ARETU",
    "ARFAI",
    "ARFAM",
    "ARFAR",
    "ARFAS",
    "ARFEI",
    "ARFEM",
    "ARFES",
    "ARFIL",
    "ARFIR",
    "ARFIS",
    "ARFOU",
    "ARFUR",
    "ARGAL",
    "ARGAO",
    "ARGAS",
    "ARGAU",
    "ARGEL",
    "ARGEM",
    "ARGES",
    "ARGEU",
    "ARGIO",
    "ARGOL",
    "ARGON",
    "ARGOS",
    "ARGUA",
    "ARGUE",
    "ARGUI",
    "ARGUO",
    "ARIAI",
    "ARIAM",
    "ARIAO",
    "ARIAR",
    "ARIAS",
    "ARIBE",
    "ARICA",
    "ARICO",
    "ARICU",
    "ARIDA",
    "ARIDO",
    "ARIEI",
    "ARIEL",
    "ARIEM",
    "ARIES",
    "ARIFE",
    "ARIGO",
    "ARILA",
    "ARILO",
    "ARIMA",
    "ARIMO",
    "ARINA",
    "ARIOU",
    "ARIPO",
    "ARIRI",
    "ARITA",
    "ARITE",
    "ARITI",
    "ARITO",
    "ARITU",
    "ARJAO",
    "ARJOA",
    "ARJOE",
    "ARJOO",
    "ARJOZ",
    "ARLEI",
    "ARLES",
    "ARMAI",
    "ARMAM",
    "ARMAO",
    "ARMAR",
    "ARMAS",
    "ARMEI",
    "ARMEM",
    "ARMES",
    "ARMEU",
    "ARMIA",
    "ARMIM",
    "ARMIO",
    "ARMOU",
    "ARMUM",
    "ARMUR",
    "ARNAL",
    "ARNAZ",
    "ARNES",
    "ARNIS",
    "ARODE",
    "ARODI",
    "AROER",
    "AROES",
    "AROLA",
    "AROLO",
    "AROM.",
    "AROMA",
    "AROME",
    "AROMO",
    "AROTO",
    "ARPAI",
    "ARPAM",
    "ARPAO",
    "ARPAR",
    "ARPAS",
    "ARPEA",
    "ARPEI",
    "ARPEM",
    "ARPES",
    "ARPEU",
    "ARPOA",
    "ARPOE",
    "ARPOO",
    "ARPOU",
    "ARPUA",
    "ARPUE",
    "ARPUO",
    "ARQUE",
    "ARQUI",
    "ARRAI",
    "ARRAL",
    "ARRAS",
    "ARREA",
    "ARRES",
    "ARRIA",
    "ARRIE",
    "ARRIO",
    "ARROS",
    "ARROZ",
    "ARRUA",
    "ARRUE",
    "ARRUO",
    "ARSES",
    "ARSIO",
    "ARSIS",
    "ARSOS",
    "ART.º",
    "ARTES",
    "ARTRO",
    "ARTS.",
    "ARTUR",
    "ARUAI",
    "ARUAS",
    "ARUAU",
    "ARUBA",
    "ARUBE",
    "ARUCA",
    "ARUGA",
    "ARUJA",
    "ARUJO",
    "ARULA",
    "ARUMA",
    "ARUNS",
    "ARUPA",
    "ARURA",
    "ARURU",
    "ARUTO",
    "ARVAL",
    "ARVAS",
    "ARVOA",
    "ARVOE",
    "ARVOO",
    "ARXAI",
    "ARXAM",
    "ARXAR",
    "ARXAS",
    "ARXEI",
    "ARXEM",
    "ARXES",
    "ARXOU",
    "ARZEL",
    "ASADA",
    "ASADO",
    "ASAEL",
    "ASAFE",
    "ASAIS",
    "ASAMO",
    "ASANA",
    "ASARA",
    "ASARO",
    "ASASE",
    "ASAVA",
    "ASBEL",
    "ASCAL",
    "ASCAS",
    "ASCIA",
    "ASCII",
    "ASCIO",
    "ASCON",
    "ASCOS",
    "ASCRA",
    "ASCUA",
    "ASCUE",
    "ASCUO",
    "ASEIA",
    "ASEIS",
    "ASELO",
    "ASEMO",
    "ASENO",
    "ASIAM",
    "ASIAS",
    "ASIDA",
    "ASIDO",
    "ASIEL",
    "ASILA",
    "ASILE",
    "ASILO",
    "ASIMA",
    "ASIMO",
    "ASIOS",
    "ASIRA",
    "ASMAS",
    "ASMOS",
    "ASNAI",
    "ASNAL",
    "ASNAM",
    "ASNAR",
    "ASNAS",
    "ASNEA",
    "ASNEI",
    "ASNEM",
    "ASNES",
    "ASNIL",
    "ASNIS",
    "ASNOS",
    "ASNOU",
    "ASOAS",
    "ASOOS",
    "ASPAI",
    "ASPAM",
    "ASPAR",
    "ASPAS",
    "ASPEA",
    "ASPEI",
    "ASPEM",
    "ASPES",
    "ASPIS",
    "ASPOU",
    "ASPRA",
    "ASPRE",
    "ASSAI",
    "ASSAM",
    "ASSAR",
    "ASSAS",
    "ASSAZ",
    "ASSEA",
    "ASSEI",
    "ASSEM",
    "ASSES",
    "ASSIM",
    "ASSIR",
    "ASSIS",
    "ASSOA",
    "ASSOE",
    "ASSOO",
    "ASSOS",
    "ASSOU",
    "ASSUA",
    "ASSUR",
    "ASTER",
    "ASTIL",
    "ASTIM",
    "ASTIS",
    "ASTOR",
    "ASTRA",
    "ASTRE",
    "ASTRO",
    "ASTUR",
    "ASURA",
    "ATABI",
    "ATACA",
    "ATACO",
    "ATADA",
    "ATADO",
    "ATAGA",
    "ATAIS",
    "ATALA",
    "ATALO",
    "ATAMA",
    "ATAME",
    "ATAMO",
    "ATANA",
    "ATAPU",
    "ATARA",
    "ATARE",
    "ATAVA",
    "ATAVO",
    "ATEAI",
    "ATEAR",
    "ATECO",
    "ATEEI",
    "ATEIA",
    "ATEIE",
    "ATEIO",
    "ATEIS",
    "ATELA",
    "ATELE",
    "ATEMO",
    "ATENA",
    "ATENS",
    "ATEOU",
    "ATERA",
    "ATEUS",
    "ATEVE",
    "ATICA",
    "ATICE",
    "ATICO",
    "ATICU",
    "ATIDA",
    "ATIDO",
    "ATILA",
    "ATIMA",
    "ATIMO",
    "ATINA",
    "ATINE",
    "ATINO",
    "ATINS",
    "ATIPO",
    "ATIRA",
    "ATIRE",
    "ATIRO",
    "ATITA",
    "ATITE",
    "ATITO",
    "ATIVA",
    "ATIVE",
    "ATIVO",
    "ATLAI",
    "ATLAS",
    "ATOAR",
    "ATOBA",
    "ATOFA",
    "ATOIS",
    "ATOLA",
    "ATOLE",
    "ATOLO",
    "ATOM.",
    "ATOMO",
    "ATONA",
    "ATONO",
    "ATOPE",
    "ATORA",
    "ATOTO",
    "ATRAI",
    "ATRAS",
    "ATREA",
    "ATREO",
    "ATREU",
    "ATRIL",
    "ATRIO",
    "ATRIS",
    "ATRIZ",
    "ATROO",
    "ATROS",
    "ATROZ",
    "ATUAI",
    "ATUAL",
    "ATUAM",
    "ATUAR",
    "ATUAS",
    "ATUBO",
    "ATUEI",
    "ATUEM",
    "ATUES",
    "ATUIA",
    "ATUIR",
    "ATUIS",
    "ATUIU",
    "ATUMA",
    "ATUNS",
    "ATUOU",
    "ATUPA",
    "ATUPI",
    "ATUPO",
    "ATURA",
    "ATURE",
    "ATURO",
    "ATUSO",
    "AUACU",
    "AUAIS",
    "AUAIU",
    "AUARI",
    "AUATI",
    "AUDAZ",
    "AUDIO",
    "AUETE",
    "AUETI",
    "AUETO",
    "AUFUE",
    "AUGAI",
    "AUGAM",
    "AUGAR",
    "AUGAS",
    "AUGES",
    "AUGIR",
    "AUGIU",
    "AUGOU",
    "AUGUE",
    "AUIBA",
    "AUINA",
    "AUITI",
    "AUJOS",
    "AULAS",
    "AULAX",
    "AULEU",
    "AULIR",
    "AUMAI",
    "AUNAR",
    "AUNOS",
    "AURAI",
    "AURAL",
    "AURAM",
    "AURAR",
    "AURAS",
    "AUREA",
    "AUREI",
    "AUREM",
    "AUREO",
    "AURES",
    "AURIA",
    "AURIR",
    "AURIS",
    "AURIU",
    "AUROU",
    "AUSAO",
    "AUSCO",
    "AUSIO",
    "AUSOS",
    "AUSTA",
    "AUSTE",
    "AUSTO",
    "AUTOR",
    "AUTOS",
    "AUTUA",
    "AUTUE",
    "AUTUO",
    "AUUVA",
    "AUXIA",
    "AUZAO",
    "AVACA",
    "AVAIS",
    "AVANA",
    "AVANO",
    "AVANT",
    "AVARA",
    "AVARE",
    "AVARI",
    "AVARO",
    "AVATA",
    "AVATE",
    "AVATI",
    "AVATO",
    "AVEAL",
    "AVEAO",
    "AVEIA",
    "AVEIO",
    "AVEIS",
    "AVELA",
    "AVENA",
    "AVENS",
    "AVEUS",
    "AVEXA",
    "AVEXE",
    "AVEXO",
    "AVEZA",
    "AVEZE",
    "AVEZO",
    "AVIAI",
    "AVIAM",
    "AVIAO",
    "AVIAR",
    "AVIAS",
    "AVIDA",
    "AVIDO",
    "AVIEI",
    "AVIEM",
    "AVIER",
    "AVIES",
    "AVILA",
    "AVILE",
    "AVILO",
    "AVIMO",
    "AVIOS",
    "AVIOU",
    "AVIRA",
    "AVISA",
    "AVISE",
    "AVISO",
    "AVITA",
    "AVITE",
    "AVITO",
    "AVIUM",
    "AVIVA",
    "AVIVE",
    "AVIVO",
    "AVOAO",
    "AVOAR",
    "AVOCA",
    "AVOCO",
    "AVOES",
    "AVOSA",
    "AVOSE",
    "AVOSO",
    "AWUNA",
    "AXADA",
    "AXEIS",
    "AXEXE",
    "AXIAL",
    "AXICA",
    "AXICO",
    "AXILA",
    "AXILO",
    "AXINA",
    "AXINO",
    "AXIXA",
    "AXONA",
    "AXONE",
    "AXOXO",
    "AYRES",
    "AZABE",
    "AZACA",
    "AZADA",
    "AZADO",
    "AZAEL",
    "AZAIA",
    "AZAIR",
    "AZAIS",
    "AZAMO",
    "AZAPA",
    "AZARA",
    "AZARE",
    "AZARO",
    "AZAVA",
    "AZEAS",
    "AZECA",
    "AZEDA",
    "AZEDE",
    "AZEDO",
    "AZEGI",
    "AZEIS",
    "AZEMO",
    "AZEOS",
    "AZERI",
    "AZEVA",
    "AZIAR",
    "AZIAS",
    "AZIBO",
    "AZIDA",
    "AZIEL",
    "AZIGA",
    "AZIGO",
    "AZIMA",
    "AZIMO",
    "AZINA",
    "AZIZA",
    "AZIZE",
    "AZMOM",
    "AZOAR",
    "AZOIA",
    "AZOIS",
    "AZOLA",
    "AZOMA",
    "AZONO",
    "AZOTA",
    "AZOTE",
    "AZOTO",
    "AZUBA",
    "AZUBO",
    "AZUIS",
    "AZULA",
    "AZULE",
    "AZULO",
    "AZURA",
    "AZURE",
    "AZURO",
    "AZURU",
    "BAAIS",
    "BAALI",
    "BAAUS",
    "BABAI",
    "BABAL",
    "BABAM",
    "BABAO",
    "BABAR",
    "BABAS",
    "BABAU",
    "BABEA",
    "BABEI",
    "BABEL",
    "BABEM",
    "BABER",
    "BABES",
    "BABIA",
    "BABIS",
    "BABLA",
    "BABLE",
    "BABOU",
    "BABUA",
    "BABUI",
    "BABUL",
    "BABUS",
    "BACAA",
    "BACAL",
    "BACAO",
    "BACAR",
    "BACAS",
    "BACEN",
    "BACES",
    "BACHI",
    "BACIA",
    "BACIE",
    "BACIO",
    "BACIS",
    "BACON",
    "BACOS",
    "BACT.",
    "BACTA",
    "BACUS",
    "BADAL",
    "BADAS",
    "BADEM",
    "BADIA",
    "BADIL",
    "BADIO",
    "BADIS",
    "BADRI",
    "BADUA",
    "BADUI",
    "BADUR",
    "BAECO",
    "BAELE",
    "BAENA",
    "BAETA",
    "BAFAI",
    "BAFAM",
    "BAFAR",
    "BAFAS",
    "BAFEI",
    "BAFEM",
    "BAFER",
    "BAFES",
    "BAFIA",
    "BAFIO",
    "BAFOS",
    "BAFOU",
    "BAFUM",
    "BAGAI",
    "BAGAM",
    "BAGAR",
    "BAGAS",
    "BAGDA",
    "BAGEA",
    "BAGEM",
    "BAGES",
    "BAGIS",
    "BAGOA",
    "BAGOE",
    "BAGOO",
    "BAGOS",
    "BAGOU",
    "BAGRE",
    "BAGUA",
    "BAGUE",
    "BAGUS",
    "BAHIA",
    "BAIAI",
    "BAIAM",
    "BAIAO",
    "BAIAR",
    "BAIAS",
    "BAIAU",
    "BAIBE",
    "BAICA",
    "BAICO",
    "BAIEI",
    "BAIEM",
    "BAIES",
    "BAILA",
    "BAILE",
    "BAILO",
    "BAIOS",
    "BAIOU",
    "BAIRA",
    "BAIRE",
    "BAIRI",
    "BAIRU",
    "BAITA",
    "BAITE",
    "BAIXA",
    "BAIXE",
    "BAIXO",
    "BAJAI",
    "BAJAM",
    "BAJAR",
    "BAJAS",
    "BAJEA",
    "BAJEI",
    "BAJEM",
    "BAJES",
    "BAJIA",
    "BAJOU",
    "BAJUS",
    "BALAA",
    "BALAM",
    "BALAO",
    "BALAR",
    "BALAS",
    "BALAU",
    "BALBA",
    "BALBO",
    "BALCA",
    "BALCO",
    "BALDA",
    "BALDE",
    "BALDO",
    "BALEA",
    "BALEM",
    "BALES",
    "BALHA",
    "BALHE",
    "BALHO",
    "BALIA",
    "BALIM",
    "BALIO",
    "BALIR",
    "BALIS",
    "BALIU",
    "BALOA",
    "BALOE",
    "BALOO",
    "BALSA",
    "BALSE",
    "BALSO",
    "BALT.",
    "BALTA",
    "BALTO",
    "BAMBA",
    "BAMBE",
    "BAMBI",
    "BAMBO",
    "BAMBU",
    "BAMES",
    "BAMIA",
    "BANAL",
    "BANAM",
    "BANAR",
    "BANAS",
    "BANCA",
    "BANCO",
    "BANDA",
    "BANDE",
    "BANDO",
    "BANEM",
    "BANES",
    "BANGA",
    "BANGO",
    "BANGS",
    "BANGU",
    "BANHA",
    "BANHE",
    "BANHO",
    "BANIA",
    "BANIR",
    "BANIS",
    "BANIU",
    "BANJA",
    "BANJO",
    "BANSA",
    "BANTA",
    "BANTE",
    "BANTI",
    "BANTO",
    "BANTU",
    "BANUA",
    "BANZA",
    "BANZE",
    "BANZO",
    "BAOBA",
    "BAPOS",
    "BAPTA",
    "BAPTE",
    "BAQUE",
    "BARAO",
    "BARAS",
    "BARAU",
    "BARB.",
    "BARBA",
    "BARBE",
    "BARBI",
    "BARBO",
    "BARCA",
    "BARCO",
    "BARDA",
    "BARDE",
    "BARDO",
    "BAREA",
    "BAREM",
    "BARES",
    "BARGA",
    "BARGU",
    "BARIA",
    "BARIL",
    "BARIM",
    "BARIO",
    "BARIS",
    "BARMA",
    "BAROA",
    "BAROS",
    "BARRA",
    "BARRE",
    "BARRI",
    "BARRO",
    "BARSA",
    "BARSI",
    "BARSO",
    "BARUS",
    "BASAI",
    "BASAL",
    "BASAM",
    "BASAR",
    "BASAS",
    "BASCA",
    "BASCO",
    "BASEA",
    "BASEI",
    "BASEM",
    "BASES",
    "BASIC",
    "BASIM",
    "BASIO",
    "BASIS",
    "BASOU",
    "BASQ.",
    "BASRA",
    "BASSE",
    "BASSO",
    "BASTA",
    "BASTE",
    "BASTI",
    "BASTO",
    "BASUA",
    "BATAM",
    "BATAO",
    "BATAS",
    "BATCH",
    "BATEA",
    "BATEI",
    "BATEL",
    "BATEM",
    "BATER",
    "BATES",
    "BATEU",
    "BATIA",
    "BATIM",
    "BATIS",
    "BATMO",
    "BATOM",
    "BATOR",
    "BATOS",
    "BATUL",
    "BAUAS",
    "BAUIS",
    "BAULE",
    "BAUMA",
    "BAUME",
    "BAUNA",
    "BAURU",
    "BAVIA",
    "BAVIO",
    "BAXIM",
    "BAXIS",
    "BAYES",
    "BAZAR",
    "BAZAS",
    "BAZOS",
    "BEABA",
    "BEACH",
    "BEATA",
    "BEATO",
    "BEBAI",
    "BEBAM",
    "BEBAS",
    "BEBEI",
    "BEBEL",
    "BEBEM",
    "BEBER",
    "BEBES",
    "BEBEU",
    "BEBIA",
    "BEBRA",
    "BEBUM",
    "BECAS",
    "BECHE",
    "BECOS",
    "BECUA",
    "BEDAO",
    "BEDEA",
    "BEDEL",
    "BEDEM",
    "BEDRO",
    "BEDUI",
    "BEDUM",
    "BEENS",
    "BEFAS",
    "BEFES",
    "BEGBE",
    "BEGES",
    "BEGUA",
    "BEGUE",
    "BEGUM",
    "BEIAS",
    "BEICA",
    "BEICE",
    "BEICO",
    "BEIJA",
    "BEIJE",
    "BEIJI",
    "BEIJO",
    "BEIJU",
    "BEIR.",
    "BEIRA",
    "BEIRE",
    "BEIRO",
    "BEIRU",
    "BEISA",
    "BEJAS",
    "BEJEL",
    "BEJES",
    "BEJIS",
    "BEL.ª",
    "BELAO",
    "BELAS",
    "BELAU",
    "BELAZ",
    "BELDA",
    "BELDE",
    "BELDO",
    "BELEM",
    "BELFA",
    "BELFO",
    "BELGA",
    "BELGO",
    "BELHO",
    "BELIA",
    "BELIO",
    "BELIS",
    "BELIZ",
    "BELLO",
    "BELOS",
    "BELRO",
    "BELUA",
    "BELUS",
    "BEMAS",
    "BEMBA",
    "BEMBE",
    "BEMOL",
    "BENAS",
    "BENCA",
    "BENCO",
    "BENCU",
    "BENDA",
    "BENDE",
    "BENDI",
    "BENDO",
    "BENG.",
    "BENGA",
    "BENGE",
    "BENGO",
    "BENHA",
    "BENIA",
    "BENIM",
    "BENIN",
    "BENOS",
    "BENTA",
    "BENTO",
    "BENZA",
    "BENZE",
    "BENZI",
    "BENZO",
    "BEOLA",
    "BEQUE",
    "BERAS",
    "BERB.",
    "BERBA",
    "BERBE",
    "BERCA",
    "BERCE",
    "BERCO",
    "BERDA",
    "BERES",
    "BEREU",
    "BERGO",
    "BERIL",
    "BERIS",
    "BERIX",
    "BERMA",
    "BERNA",
    "BERNE",
    "BERNO",
    "BEROE",
    "BEROL",
    "BEROS",
    "BERRA",
    "BERRE",
    "BERRO",
    "BERTA",
    "BERTO",
    "BERUS",
    "BESAI",
    "BESPA",
    "BESPE",
    "BESSI",
    "BESSO",
    "BESTA",
    "BESTE",
    "BESTO",
    "BETAI",
    "BETAL",
    "BETAM",
    "BETAO",
    "BETAR",
    "BETAS",
    "BETEI",
    "BETEL",
    "BETEM",
    "BETES",
    "BETIM",
    "BETIS",
    "BETLE",
    "BETOI",
    "BETOL",
    "BETOM",
    "BETOU",
    "BETRE",
    "BETSI",
    "BETUM",
    "BETUS",
    "BEVOS",
    "BEZAR",
    "BEZAU",
    "BEZOO",
    "BIABA",
    "BIACO",
    "BIAIS",
    "BIAMI",
    "BIANA",
    "BIARA",
    "BIARI",
    "BIARO",
    "BIATA",
    "BIBAS",
    "BIBES",
    "BIBIA",
    "BIBIO",
    "BIBIS",
    "BIBL.",
    "BIBLA",
    "BIBOI",
    "BIBOS",
    "BIBRA",
    "BICAI",
    "BICAL",
    "BICAM",
    "BICAO",
    "BICAR",
    "BICAS",
    "BICHA",
    "BICHE",
    "BICHO",
    "BICIA",
    "BICOS",
    "BICOU",
    "BICUS",
    "BIDAO",
    "BIDAS",
    "BIDUM",
    "BIDUO",
    "BIDUS",
    "BIELA",
    "BIELO",
    "BIEN.",
    "BIENE",
    "BIENO",
    "BIFAI",
    "BIFAM",
    "BIFAR",
    "BIFAS",
    "BIFEI",
    "BIFEM",
    "BIFES",
    "BIFOU",
    "BIFRE",
    "BIFUS",
    "BIGAS",
    "BIGLA",
    "BIGLE",
    "BIGUA",
    "BIGUE",
    "BIGUO",
    "BIGUS",
    "BIJOU",
    "BIJUI",
    "BIJUS",
    "BILAC",
    "BILAI",
    "BILAM",
    "BILAR",
    "BILAS",
    "BILEI",
    "BILEM",
    "BILES",
    "BILHA",
    "BILHO",
    "BILIA",
    "BILIS",
    "BILMO",
    "BILOU",
    "BILRA",
    "BILRE",
    "BILRO",
    "BILSA",
    "BILVA",
    "BIMAO",
    "BIMAR",
    "BIMBA",
    "BIMBE",
    "BIMBO",
    "BIMES",
    "BIMOS",
    "BINAI",
    "BINAM",
    "BINAR",
    "BINAS",
    "BINDA",
    "BINDE",
    "BINEA",
    "BINEI",
    "BINEM",
    "BINES",
    "BINGA",
    "BINGO",
    "BINHA",
    "BINHO",
    "BINIU",
    "BINOU",
    "BINUI",
    "BIOBA",
    "BIOCO",
    "BIOL.",
    "BIOM.",
    "BIOMA",
    "BIOQ.",
    "BIOSE",
    "BIOT.",
    "BIOTA",
    "BIPAI",
    "BIPAM",
    "BIPAR",
    "BIPAS",
    "BIPEI",
    "BIPEM",
    "BIPES",
    "BIPOU",
    "BIQUE",
    "BIRAS",
    "BIRBA",
    "BIRCO",
    "BIRGO",
    "BIRIS",
    "BIRM.",
    "BIRMA",
    "BIRRA",
    "BIRRE",
    "BIRRO",
    "BIRSA",
    "BIRUS",
    "BISAI",
    "BISAM",
    "BISAO",
    "BISAR",
    "BISAS",
    "BISBI",
    "BISCA",
    "BISCO",
    "BISEI",
    "BISEL",
    "BISEM",
    "BISES",
    "BISGA",
    "BISMA",
    "BISOL",
    "BISOU",
    "BISP.",
    "BISPA",
    "BISPE",
    "BISPO",
    "BISSO",
    "BITAI",
    "BITAM",
    "BITAR",
    "BITAS",
    "BITEI",
    "BITEM",
    "BITER",
    "BITES",
    "BITIA",
    "BITIO",
    "BITIS",
    "BITOU",
    "BITR.",
    "BITRA",
    "BITRE",
    "BITRO",
    "BIURA",
    "BIVAS",
    "BIVIO",
    "BIXAS",
    "BIZAS",
    "BIZER",
    "BIZUS",
    "BLASE",
    "BLAUS",
    "BLEFA",
    "BLEFE",
    "BLEFO",
    "BLENO",
    "BLESA",
    "BLESO",
    "BLITZ",
    "BLOCA",
    "BLOCO",
    "BLOGA",
    "BLOGO",
    "BLOGS",
    "BLUCA",
    "BLUCO",
    "BLUES",
    "BLUFO",
    "BLUSA",
    "BLUSH",
    "BNDES",
    "BOABA",
    "BOACO",
    "BOADA",
    "BOADO",
    "BOAIS",
    "BOAMO",
    "BOANA",
    "BOANE",
    "BOARA",
    "BOARI",
    "BOATA",
    "BOATE",
    "BOATO",
    "BOAVA",
    "BOBAI",
    "BOBAL",
    "BOBAM",
    "BOBAO",
    "BOBAR",
    "BOBAS",
    "BOBEA",
    "BOBEI",
    "BOBEM",
    "BOBES",
    "BOBIS",
    "BOBOS",
    "BOBOU",
    "BOCAI",
    "BOCAL",
    "BOCAM",
    "BOCAO",
    "BOCAR",
    "BOCAS",
    "BOCEI",
    "BOCEL",
    "BOCEM",
    "BOCES",
    "BOCHA",
    "BOCHE",
    "BOCHO",
    "BOCIM",
    "BOCIO",
    "BOCOA",
    "BOCOU",
    "BOCUE",
    "BOCUS",
    "BODAI",
    "BODAM",
    "BODAR",
    "BODAS",
    "BODEI",
    "BODEM",
    "BODES",
    "BODIS",
    "BODOU",
    "BODUM",
    "BOEIO",
    "BOEIS",
    "BOELA",
    "BOELO",
    "BOEM.",
    "BOEMO",
    "BOERS",
    "BOETA",
    "BOFAI",
    "BOFAM",
    "BOFAR",
    "BOFAS",
    "BOFEI",
    "BOFEM",
    "BOFES",
    "BOFIA",
    "BOFOU",
    "BOGAI",
    "BOGAM",
    "BOGAR",
    "BOGAS",
    "BOGOU",
    "BOGUE",
    "BOGUM",
    "BOIAI",
    "BOIAM",
    "BOIAO",
    "BOIAR",
    "BOIAS",
    "BOIBI",
    "BOICA",
    "BOICE",
    "BOICO",
    "BOICU",
    "BOIEI",
    "BOIEM",
    "BOIES",
    "BOIGA",
    "BOIIL",
    "BOIIS",
    "BOIME",
    "BOINA",
    "BOIOS",
    "BOIOU",
    "BOIRA",
    "BOIRE",
    "BOIRO",
    "BOIRU",
    "BOITA",
    "BOITE",
    "BOJAI",
    "BOJAM",
    "BOJAO",
    "BOJAR",
    "BOJAS",
    "BOJEI",
    "BOJEM",
    "BOJES",
    "BOJOS",
    "BOJOU",
    "BOJUI",
    "BOLAI",
    "BOLAM",
    "BOLAO",
    "BOLAR",
    "BOLAS",
    "BOLBO",
    "BOLCA",
    "BOLCE",
    "BOLCO",
    "BOLDO",
    "BOLEA",
    "BOLEI",
    "BOLEM",
    "BOLES",
    "BOLEU",
    "BOLGO",
    "BOLHA",
    "BOLHE",
    "BOLHO",
    "BOLIM",
    "BOLIS",
    "BOLOR",
    "BOLOS",
    "BOLOU",
    "BOLSA",
    "BOLSE",
    "BOLSO",
    "BOMAS",
    "BOMB.",
    "BOMBA",
    "BOMBE",
    "BOMBO",
    "BONAR",
    "BONCA",
    "BONCE",
    "BONCO",
    "BONDA",
    "BONDE",
    "BONDI",
    "BONDO",
    "BONES",
    "BONGA",
    "BONGE",
    "BONGO",
    "BONHA",
    "BONHE",
    "BONHO",
    "BONJO",
    "BONUS",
    "BONZA",
    "BONZE",
    "BONZO",
    "BOONS",
    "BOOPE",
    "BOORA",
    "BOQUE",
    "BORAL",
    "BORAS",
    "BORAX",
    "BORBA",
    "BORCA",
    "BORCO",
    "BORD.",
    "BORDA",
    "BORDE",
    "BORDO",
    "BOREL",
    "BORES",
    "BORG.",
    "BORGA",
    "BORIS",
    "BORJA",
    "BORLA",
    "BORNA",
    "BORNE",
    "BORNO",
    "BOROA",
    "BOROS",
    "BORR.",
    "BORRA",
    "BORRE",
    "BORRO",
    "BOSAO",
    "BOSAS",
    "BOSCA",
    "BOSCO",
    "BOSEA",
    "BOSIS",
    "BOSON",
    "BOSSA",
    "BOSSE",
    "BOSSO",
    "BOSTA",
    "BOSTE",
    "BOSTO",
    "BOTAI",
    "BOTAL",
    "BOTAM",
    "BOTAO",
    "BOTAR",
    "BOTAS",
    "BOTEI",
    "BOTEM",
    "BOTES",
    "BOTIM",
    "BOTIO",
    "BOTOA",
    "BOTOE",
    "BOTOO",
    "BOTOS",
    "BOTOU",
    "BOTOX",
    "BOUBA",
    "BOUCA",
    "BOUCE",
    "BOUCO",
    "BOUDA",
    "BOUGA",
    "BOURA",
    "BOURE",
    "BOURI",
    "BOURO",
    "BOVIS",
    "BOXAI",
    "BOXAM",
    "BOXAR",
    "BOXAS",
    "BOXEA",
    "BOXEI",
    "BOXEM",
    "BOXER",
    "BOXES",
    "BOXOU",
    "BOZRA",
    "BOZUM",
    "BRABA",
    "BRABE",
    "BRABO",
    "BRACA",
    "BRACO",
    "BRADA",
    "BRADE",
    "BRADI",
    "BRADO",
    "BRAGA",
    "BRAIA",
    "BRAIS",
    "BRAJA",
    "BRALA",
    "BRAMA",
    "BRAME",
    "BRAMI",
    "BRAMO",
    "BRAS.",
    "BRASA",
    "BRASE",
    "BRASO",
    "BRAUS",
    "BRAVA",
    "BRAVE",
    "BRAVO",
    "BRCAS",
    "BREAI",
    "BREAK",
    "BREAL",
    "BREAR",
    "BREBA",
    "BRECA",
    "BRECO",
    "BREDA",
    "BREDO",
    "BREEI",
    "BREFA",
    "BREFO",
    "BREGA",
    "BREIA",
    "BREIE",
    "BREIO",
    "BREJA",
    "BREJO",
    "BREMA",
    "BRENO",
    "BREOU",
    "BRET.",
    "BRETA",
    "BRETE",
    "BREUS",
    "BREVE",
    "BREZA",
    "BREZO",
    "BRIAL",
    "BRIAS",
    "BRIBA",
    "BRICA",
    "BRICO",
    "BRICS",
    "BRIDA",
    "BRIDE",
    "BRIDO",
    "BRIEA",
    "BRIES",
    "BRIG.",
    "BRIGA",
    "BRIGO",
    "BRINS",
    "BRIOL",
    "BRION",
    "BRIOS",
    "BRISA",
    "BRISE",
    "BRISO",
    "BRIT.",
    "BRITA",
    "BRITE",
    "BRITO",
    "BRIVE",
    "BRIZA",
    "BROAS",
    "BROCA",
    "BROCO",
    "BRODA",
    "BROLA",
    "BROLE",
    "BROLO",
    "BROM.",
    "BROMA",
    "BROME",
    "BROMO",
    "BRONA",
    "BROTA",
    "BROTE",
    "BROTO",
    "BROXA",
    "BROXE",
    "BROXO",
    "BRUAA",
    "BRUCE",
    "BRUCO",
    "BRUEU",
    "BRUGO",
    "BRUMA",
    "BRUME",
    "BRUMO",
    "BRUNA",
    "BRUNE",
    "BRUNI",
    "BRUNO",
    "BRUTA",
    "BRUTO",
    "BRUXA",
    "BRUXE",
    "BRUXO",
    "BUACU",
    "BUADA",
    "BUAIS",
    "BUAIZ",
    "BUALA",
    "BUAMA",
    "BUANA",
    "BUANO",
    "BUARA",
    "BUASE",
    "BUAVA",
    "BUBAL",
    "BUBAO",
    "BUBAS",
    "BUBIS",
    "BUBOS",
    "BUBUS",
    "BUCAL",
    "BUCHA",
    "BUCHO",
    "BUCHU",
    "BUCIL",
    "BUCIM",
    "BUCIS",
    "BUCLE",
    "BUCOS",
    "BUCRE",
    "BUCUE",
    "BUCUI",
    "BUCUS",
    "BUDAS",
    "BUDIN",
    "BUDOA",
    "BUDOE",
    "BUDOO",
    "BUDUR",
    "BUDUS",
    "BUENA",
    "BUENO",
    "BUENS",
    "BUETA",
    "BUETE",
    "BUFAI",
    "BUFAM",
    "BUFAO",
    "BUFAR",
    "BUFAS",
    "BUFEI",
    "BUFEM",
    "BUFES",
    "BUFIR",
    "BUFOS",
    "BUFOU",
    "BUFRA",
    "BUFRI",
    "BUGIA",
    "BUGIE",
    "BUGIO",
    "BUGLE",
    "BUGRA",
    "BUGRE",
    "BUIAM",
    "BUIAS",
    "BUIDA",
    "BUIDO",
    "BUIGE",
    "BUILO",
    "BUIRA",
    "BUJAO",
    "BUJAS",
    "BUJIS",
    "BULAI",
    "BULAM",
    "BULAR",
    "BULAS",
    "BULBO",
    "BULDO",
    "BULEI",
    "BULEM",
    "BULES",
    "BULG.",
    "BULHA",
    "BULHE",
    "BULHO",
    "BULIA",
    "BULIO",
    "BULIR",
    "BULIS",
    "BULIU",
    "BULOU",
    "BUMBA",
    "BUMBE",
    "BUMBO",
    "BUMES",
    "BUNAS",
    "BUNCE",
    "BUNDA",
    "BUNDE",
    "BUNDO",
    "BUNGA",
    "BUNGO",
    "BUNHA",
    "BUNHE",
    "BUNHO",
    "BUQUE",
    "BUQUI",
    "BURAO",
    "BURCA",
    "BURCO",
    "BURDO",
    "BUREL",
    "BURGA",
    "BURGO",
    "BURIL",
    "BURIS",
    "BURL.",
    "BURLA",
    "BURLE",
    "BURLO",
    "BURNA",
    "BURNE",
    "BURNI",
    "BURNO",
    "BURNU",
    "BUROS",
    "BURRA",
    "BURRE",
    "BURRO",
    "BURSA",
    "BURUE",
    "BURUM",
    "BURUS",
    "BUSCA",
    "BUSCO",
    "BUSIL",
    "BUSIS",
    "BUSOS",
    "BUSSO",
    "BUSTO",
    "BUTAI",
    "BUTAM",
    "BUTAO",
    "BUTAR",
    "BUTAS",
    "BUTAU",
    "BUTEA",
    "BUTEI",
    "BUTEM",
    "BUTEO",
    "BUTES",
    "BUTIA",
    "BUTIL",
    "BUTIM",
    "BUTIO",
    "BUTIR",
    "BUTIS",
    "BUTOS",
    "BUTOU",
    "BUTRE",
    "BUTUA",
    "BUUNO",
    "BUVAR",
    "BUVAS",
    "BUXAL",
    "BUXOS",
    "BUZAS",
    "BUZIA",
    "BUZIE",
    "BUZIO",
    "BUZIS",
    "BUZOS",
    "BUZUS",
    "BYRON",
    "BYTES",
    "C.AG.",
    "CAABA",
    "CAACO",
    "CAACU",
    "CAAEE",
    "CAAIA",
    "CAAMA",
    "CAAMI",
    "CAAPI",
    "CAAXI",
    "CABAL",
    "CABAS",
    "CABAU",
    "CABAZ",
    "CABEA",
    "CABEI",
    "CABEM",
    "CABER",
    "CABES",
    "CABIA",
    "CABIU",
    "CABLA",
    "CABLE",
    "CABLO",
    "CABOM",
    "CABOS",
    "CABOZ",
    "CABRA",
    "CABRE",
    "CABRO",
    "CABUI",
    "CABUL",
    "CABUR",
    "CABUS",
    "CACAI",
    "CACAM",
    "CACAO",
    "CACAR",
    "CACAS",
    "CACAU",
    "CACEA",
    "CACEI",
    "CACEM",
    "CACES",
    "CACHA",
    "CACHE",
    "CACHO",
    "CACHU",
    "CACIM",
    "CACIZ",
    "CACOA",
    "CACOE",
    "CACOO",
    "CACOS",
    "CACOU",
    "CACRE",
    "CACTO",
    "CACUA",
    "CACUS",
    "CADAI",
    "CADAS",
    "CADEA",
    "CADES",
    "CADIS",
    "CADOS",
    "CADOZ",
    "CADUE",
    "CAETE",
    "CAFAL",
    "CAFES",
    "CAFIZ",
    "CAFRA",
    "CAFRE",
    "CAFTA",
    "CAFUA",
    "CAFUS",
    "CAFUZ",
    "CAGAI",
    "CAGAM",
    "CAGAO",
    "CAGAR",
    "CAGAS",
    "CAGOA",
    "CAGOM",
    "CAGOU",
    "CAGUE",
    "CAIAI",
    "CAIAM",
    "CAIAR",
    "CAIAS",
    "CAIBA",
    "CAIBI",
    "CAIBO",
    "CAICA",
    "CAICO",
    "CAICU",
    "CAIDA",
    "CAIDE",
    "CAIDO",
    "CAIEI",
    "CAIEM",
    "CAIES",
    "CAIMO",
    "CAINA",
    "CAIOU",
    "CAIPA",
    "CAIRA",
    "CAIRE",
    "CAIRI",
    "CAIRO",
    "CAIRU",
    "CAITE",
    "CAITO",
    "CAIUA",
    "CAIUE",
    "CAIUS",
    "CAIVA",
    "CAIXA",
    "CAIXE",
    "CAJAS",
    "CAJUA",
    "CAJUI",
    "CAJUS",
    "CALAI",
    "CALAM",
    "CALAO",
    "CALAR",
    "CALAS",
    "CALAU",
    "CALC.",
    "CALCA",
    "CALCE",
    "CALCO",
    "CALD.",
    "CALDA",
    "CALDO",
    "CALEA",
    "CALEI",
    "CALEM",
    "CALES",
    "CALFE",
    "CALHA",
    "CALHE",
    "CALHO",
    "CALIL",
    "CALIM",
    "CALIS",
    "CALIX",
    "CALIZ",
    "CALMA",
    "CALME",
    "CALMO",
    "CALOI",
    "CALOM",
    "CALOR",
    "CALOS",
    "CALOU",
    "CALPA",
    "CALPO",
    "CALTA",
    "CALUA",
    "CALUM",
    "CALUS",
    "CALV.",
    "CALVA",
    "CALVE",
    "CALVO",
    "CAMAA",
    "CAMAL",
    "CAMAO",
    "CAMAS",
    "CAMBA",
    "CAMBE",
    "CAMBO",
    "CAMEA",
    "CAMIM",
    "CAMOL",
    "CAMOM",
    "CAMPA",
    "CAMPE",
    "CAMPI",
    "CAMPO",
    "CAMUA",
    "CANAA",
    "CANAL",
    "CANAS",
    "CANAZ",
    "CANCA",
    "CANDA",
    "CANDE",
    "CANDI",
    "CANDO",
    "CANEA",
    "CANER",
    "CANEZ",
    "CANGA",
    "CANGO",
    "CANGU",
    "CANHA",
    "CANHO",
    "CANIL",
    "CANIS",
    "CANJA",
    "CANJE",
    "CANJO",
    "CANOA",
    "CANOE",
    "CANON",
    "CANOS",
    "CANSA",
    "CANSE",
    "CANSO",
    "CANT.",
    "CANTA",
    "CANTE",
    "CANTO",
    "CANTU",
    "CANVI",
    "CANVO",
    "CANZA",
    "CANZO",
    "CAOBA",
    "CAOBI",
    "CAOCO",
    "CAOIS",
    "CAORI",
    "CAPAI",
    "CAPAM",
    "CAPAO",
    "CAPAR",
    "CAPAS",
    "CAPAZ",
    "CAPEA",
    "CAPEI",
    "CAPEM",
    "CAPES",
    "CAPIA",
    "CAPIM",
    "CAPOM",
    "CAPOS",
    "CAPOU",
    "CAPRA",
    "CAPRO",
    "CAPS.",
    "CAPSA",
    "CAPSO",
    "CAPT.",
    "CAPTA",
    "CAPTE",
    "CAPTO",
    "CAPUA",
    "CAPUT",
    "CAPUZ",
    "CAQUI",
    "CARAA",
    "CARAI",
    "CARAL",
    "CARAO",
    "CARAS",
    "CARBO",
    "CARCA",
    "CARD.",
    "CARDA",
    "CARDE",
    "CARDI",
    "CARDO",
    "CAREA",
    "CAREL",
    "CAREN",
    "CAREU",
    "CARGA",
    "CARGO",
    "CARIA",
    "CARIE",
    "CARII",
    "CARIL",
    "CARIO",
    "CARIS",
    "CARIZ",
    "CARLA",
    "CARLO",
    "CARMA",
    "CARME",
    "CARMI",
    "CARMO",
    "CARN.",
    "CARNA",
    "CARNE",
    "CARNO",
    "CAROA",
    "CAROE",
    "CAROL",
    "CAROS",
    "CARP.",
    "CARPA",
    "CARPE",
    "CARPI",
    "CARPO",
    "CARR.",
    "CARRA",
    "CARRE",
    "CARRI",
    "CARRO",
    "CART.",
    "CARTA",
    "CARTE",
    "CARTO",
    "CARUA",
    "CARUS",
    "CARVA",
    "CARVI",
    "CASAI",
    "CASAL",
    "CASAM",
    "CASAO",
    "CASAR",
    "CASAS",
    "CASBA",
    "CASCA",
    "CASCO",
    "CASEA",
    "CASEI",
    "CASEM",
    "CASES",
    "CASOS",
    "CASOU",
    "CASPA",
    "CASSA",
    "CASSE",
    "CASSO",
    "CAST.",
    "CASTA",
    "CASTI",
    "CASTO",
    "CATAI",
    "CATAM",
    "CATAO",
    "CATAR",
    "CATAS",
    "CATAU",
    "CATEA",
    "CATEI",
    "CATEM",
    "CATES",
    "CATIA",
    "CATIM",
    "CATIS",
    "CATOS",
    "CATOU",
    "CATRE",
    "CATUA",
    "CATUR",
    "CAUAS",
    "CAUBA",
    "CAUBI",
    "CAUDA",
    "CAUIM",
    "CAULE",
    "CAULO",
    "CAUMA",
    "CAUNA",
    "CAUNO",
    "CAUPI",
    "CAURE",
    "CAURI",
    "CAUS.",
    "CAUSA",
    "CAUSE",
    "CAUSO",
    "CAUTA",
    "CAUTO",
    "CAUXI",
    "CAVAI",
    "CAVAM",
    "CAVAO",
    "CAVAR",
    "CAVAS",
    "CAVEA",
    "CAVEI",
    "CAVEM",
    "CAVES",
    "CAVIA",
    "CAVIS",
    "CAVOU",
    "CAVUS",
    "CAXAO",
    "CAXAS",
    "CAXES",
    "CAXIM",
    "CAXOS",
    "CAXUA",
    "CAZIM",
    "CAZOL",
    "CCCII",
    "CCCIV",
    "CCCIX",
    "CCCLI",
    "CCCLV",
    "CCCLX",
    "CCCVI",
    "CCCXC",
    "CCCXI",
    "CCCXL",
    "CCCXV",
    "CCCXX",
    "CCEAD",
    "CCIII",
    "CCLII",
    "CCLIV",
    "CCLIX",
    "CCLVI",
    "CCLXI",
    "CCLXV",
    "CCLXX",
    "CCVII",
    "CCXCI",
    "CCXCV",
    "CCXII",
    "CCXIV",
    "CCXIX",
    "CCXLI",
    "CCXLV",
    "CCXVI",
    "CCXXI",
    "CCXXV",
    "CCXXX",
    "CDIII",
    "CDLII",
    "CDLIV",
    "CDLIX",
    "CDLVI",
    "CDLXI",
    "CDLXV",
    "CDLXX",
    "CDVII",
    "CDXCI",
    "CDXCV",
    "CDXII",
    "CDXIV",
    "CDXIX",
    "CDXLI",
    "CDXLV",
    "CDXVI",
    "CDXXI",
    "CDXXV",
    "CDXXX",
    "CEADA",
    "CEADO",
    "CEAIS",
    "CEAMO",
    "CEARA",
    "CEASA",
    "CEATA",
    "CEAVA",
    "CEBAR",
    "CEBAS",
    "CEBIO",
    "CEBOS",
    "CEBUS",
    "CECAL",
    "CECAO",
    "CECAS",
    "CECEA",
    "CECEM",
    "CECIL",
    "CECOS",
    "CECUM",
    "CEDAM",
    "CEDAR",
    "CEDAS",
    "CEDEI",
    "CEDEM",
    "CEDER",
    "CEDES",
    "CEDEU",
    "CEDIA",
    "CEDRO",
    "CEEIS",
    "CEEMO",
    "CEFAS",
    "CEFET",
    "CEFEU",
    "CEFOS",
    "CEGAI",
    "CEGAM",
    "CEGAR",
    "CEGAS",
    "CEGOS",
    "CEGOU",
    "CEGUE",
    "CEIAM",
    "CEIAS",
    "CEIBA",
    "CEIBO",
    "CEIEM",
    "CEIES",
    "CEIFA",
    "CEIFE",
    "CEIFO",
    "CEIRA",
    "CEITA",
    "CEIVA",
    "CEIVE",
    "CEIVO",
    "CELAO",
    "CELAS",
    "CELEA",
    "CELEO",
    "CELGA",
    "CELHA",
    "CELIA",
    "CELIE",
    "CELIO",
    "CELOS",
    "CELSA",
    "CELSO",
    "CELT.",
    "CELTA",
    "CELTE",
    "CELTO",
    "CELVA",
    "CELVE",
    "CELVO",
    "CEMAN",
    "CENAS",
    "CENDA",
    "CENDI",
    "CENES",
    "CENHO",
    "CENIO",
    "CENIS",
    "CENIZ",
    "CENOS",
    "CENSO",
    "CENT.",
    "CENTI",
    "CENTO",
    "CEOMA",
    "CEOMO",
    "CEOTA",
    "CEOTE",
    "CEOTO",
    "CEPAS",
    "CEPOS",
    "CERAI",
    "CERAM",
    "CERAR",
    "CERAS",
    "CERCA",
    "CERCE",
    "CERCO",
    "CERDA",
    "CERDO",
    "CEREA",
    "CEREI",
    "CEREM",
    "CEREO",
    "CERES",
    "CERGE",
    "CERGI",
    "CERIA",
    "CERIO",
    "CERIS",
    "CERJA",
    "CERJO",
    "CERNA",
    "CERNE",
    "CERNI",
    "CERNO",
    "CEROL",
    "CEROU",
    "CERRA",
    "CERRE",
    "CERRO",
    "CERTA",
    "CERTE",
    "CERTO",
    "CERUS",
    "CERV.",
    "CERVA",
    "CERVO",
    "CERZE",
    "CERZI",
    "CESAR",
    "CESCA",
    "CESIO",
    "CESOS",
    "CESSA",
    "CESSE",
    "CESSO",
    "CEST.",
    "CESTA",
    "CESTO",
    "CETAL",
    "CETAS",
    "CETEF",
    "CETES",
    "CETIL",
    "CETIM",
    "CETIS",
    "CETOL",
    "CETOS",
    "CETRA",
    "CETRO",
    "CEUCI",
    "CEUTA",
    "CEVAI",
    "CEVAM",
    "CEVAO",
    "CEVAR",
    "CEVAS",
    "CEVEI",
    "CEVEM",
    "CEVES",
    "CEVOU",
    "CH.B.",
    "CH.M.",
    "CHABO",
    "CHABU",
    "CHACA",
    "CHACE",
    "CHACO",
    "CHADA",
    "CHADE",
    "CHAEM",
    "CHAGA",
    "CHAGO",
    "CHAIA",
    "CHAIM",
    "CHAIS",
    "CHAJA",
    "CHALA",
    "CHALE",
    "CHALO",
    "CHAMA",
    "CHAME",
    "CHAMO",
    "CHANA",
    "CHANG",
    "CHANS",
    "CHAOS",
    "CHAP.",
    "CHAPA",
    "CHAPE",
    "CHAPO",
    "CHARA",
    "CHARI",
    "CHARO",
    "CHARU",
    "CHATA",
    "CHATE",
    "CHATO",
    "CHATS",
    "CHAUA",
    "CHAUI",
    "CHAUS",
    "CHAVE",
    "CHAVO",
    "CHEBE",
    "CHECA",
    "CHECO",
    "CHEDA",
    "CHEDE",
    "CHEFA",
    "CHEFE",
    "CHEGA",
    "CHEGO",
    "CHEIA",
    "CHEIO",
    "CHELA",
    "CHENA",
    "CHEPE",
    "CHESF",
    "CHETA",
    "CHETO",
    "CHIAI",
    "CHIAM",
    "CHIAO",
    "CHIAR",
    "CHIAS",
    "CHIBA",
    "CHIBE",
    "CHIBO",
    "CHICA",
    "CHICE",
    "CHICO",
    "CHICU",
    "CHIEI",
    "CHIEM",
    "CHIES",
    "CHIFU",
    "CHIL.",
    "CHILA",
    "CHILE",
    "CHILI",
    "CHILO",
    "CHIMA",
    "CHIMU",
    "CHIN.",
    "CHINA",
    "CHINE",
    "CHING",
    "CHINO",
    "CHINS",
    "CHIOU",
    "CHIPO",
    "CHIPS",
    "CHIRA",
    "CHIRU",
    "CHITA",
    "CHITE",
    "CHIUO",
    "CHIUS",
    "CHOCA",
    "CHOCO",
    "CHOES",
    "CHOGO",
    "CHOIS",
    "CHOLA",
    "CHOLE",
    "CHOLO",
    "CHONA",
    "CHONE",
    "CHONO",
    "CHOPA",
    "CHOPE",
    "CHOPI",
    "CHOPP",
    "CHORA",
    "CHORE",
    "CHORO",
    "CHOTA",
    "CHOTE",
    "CHOTI",
    "CHOTO",
    "CHOVA",
    "CHOVE",
    "CHOVI",
    "CHOVO",
    "CHUCA",
    "CHUCE",
    "CHUCO",
    "CHUES",
    "CHUFA",
    "CHUFE",
    "CHUFO",
    "CHUIS",
    "CHUL.",
    "CHULA",
    "CHULE",
    "CHULO",
    "CHUNA",
    "CHUPA",
    "CHUPE",
    "CHUPO",
    "CHURI",
    "CHUTA",
    "CHUTE",
    "CHUTO",
    "CHUVA",
    "CHUXO",
    "CIADA",
    "CIADO",
    "CIAIS",
    "CIAMO",
    "CIANA",
    "CIANO",
    "CIARA",
    "CIATO",
    "CIAVA",
    "CIBAI",
    "CIBAM",
    "CIBAR",
    "CIBAS",
    "CIBEI",
    "CIBEM",
    "CIBER",
    "CIBES",
    "CIBOA",
    "CIBOU",
    "CIBUM",
    "CICAS",
    "CICCO",
    "CICIA",
    "CICIE",
    "CICIO",
    "CICL.",
    "CICLA",
    "CICLO",
    "CICRO",
    "CIDES",
    "CIDNO",
    "CIDRA",
    "CIDRO",
    "CIEIS",
    "CIEMO",
    "CIEN.",
    "CIENA",
    "CIESE",
    "CIFAI",
    "CIFAM",
    "CIFAR",
    "CIFAS",
    "CIFEI",
    "CIFEM",
    "CIFES",
    "CIFOS",
    "CIFOU",
    "CIFRA",
    "CIFRE",
    "CIFRO",
    "CIGAS",
    "CIGNO",
    "CILAS",
    "CILEA",
    "CILEU",
    "CILHA",
    "CILHE",
    "CILHO",
    "CILIO",
    "CILOS",
    "CIMAS",
    "CIMBA",
    "CIMBI",
    "CIMBO",
    "CIMNO",
    "CIMOL",
    "CIMOS",
    "CIMUE",
    "CINAS",
    "CINCA",
    "CINCO",
    "CINDA",
    "CINDE",
    "CINDI",
    "CINDO",
    "CINES",
    "CING.",
    "CINGE",
    "CINGI",
    "CINJA",
    "CINJO",
    "CINTA",
    "CINTE",
    "CINTO",
    "CINZ.",
    "CINZA",
    "CINZE",
    "CINZO",
    "CIOBA",
    "CIOCO",
    "CIOES",
    "CIONA",
    "CIOSA",
    "CIOSO",
    "CIOTO",
    "CIPOA",
    "CIPOE",
    "CIPOO",
    "CIPOS",
    "CIRAO",
    "CIRAS",
    "CIRBA",
    "CIRC.",
    "CIRCO",
    "CIRIO",
    "CIRNA",
    "CIRNE",
    "CIRNO",
    "CIRPO",
    "CIRRO",
    "CIRSO",
    "CIRTO",
    "CIRZA",
    "CIRZO",
    "CISAI",
    "CISAM",
    "CISAO",
    "CISAR",
    "CISAS",
    "CISCA",
    "CISCO",
    "CISEI",
    "CISEL",
    "CISEM",
    "CISES",
    "CISMA",
    "CISME",
    "CISMO",
    "CISNE",
    "CISOU",
    "CISPA",
    "CISPE",
    "CISPO",
    "CISTA",
    "CISTE",
    "CISTO",
    "CITAI",
    "CITAM",
    "CITAR",
    "CITAS",
    "CITEI",
    "CITEM",
    "CITES",
    "CITEU",
    "CITNA",
    "CITOU",
    "CITRO",
    "CITT.",
    "CIUMA",
    "CIUME",
    "CIUMO",
    "CIURO",
    "CIVEL",
    "CIVIL",
    "CIVIS",
    "CIZEL",
    "CLADA",
    "CLADE",
    "CLADO",
    "CLAMA",
    "CLAME",
    "CLAMO",
    "CLAPE",
    "CLARA",
    "CLARO",
    "CLAS.",
    "CLAUS",
    "CLAVA",
    "CLAVE",
    "CLAVO",
    "CLEAR",
    "CLEIA",
    "CLENA",
    "CLERO",
    "CLETE",
    "CLETO",
    "CLICA",
    "CLICO",
    "CLIDA",
    "CLIDE",
    "CLIII",
    "CLIM.",
    "CLIMA",
    "CLIN.",
    "CLINA",
    "CLINE",
    "CLINO",
    "CLIPA",
    "CLIPE",
    "CLIPO",
    "CLIPS",
    "CLISA",
    "CLISE",
    "CLISO",
    "CLITA",
    "CLITO",
    "CLIVA",
    "CLIVE",
    "CLIVO",
    "CLOCA",
    "CLOCO",
    "CLONA",
    "CLONE",
    "CLONO",
    "CLORA",
    "CLORE",
    "CLORI",
    "CLORO",
    "CLOSE",
    "CLOTO",
    "CLOWN",
    "CLUBE",
    "CLUSA",
    "CLUSO",
    "CLUVA",
    "CLVII",
    "CLXII",
    "CLXIV",
    "CLXIX",
    "CLXVI",
    "CLXXI",
    "CLXXV",
    "CLXXX",
    "CMIII",
    "CMLII",
    "CMLIV",
    "CMLIX",
    "CMLVI",
    "CMLXI",
    "CMLXV",
    "CMLXX",
    "CMVII",
    "CMXCI",
    "CMXCV",
    "CMXII",
    "CMXIV",
    "CMXIX",
    "CMXLI",
    "CMXLV",
    "CMXVI",
    "CMXXI",
    "CMXXV",
    "CMXXX",
    "CNESF",
    "CNTSS",
    "CNUTE",
    "COADA",
    "COADO",
    "COAGE",
    "COAGI",
    "COAIS",
    "COAJA",
    "COAJO",
    "COALA",
    "COALE",
    "COAMO",
    "COANA",
    "COANO",
    "COARA",
    "COARI",
    "COATA",
    "COATI",
    "COATO",
    "COAVA",
    "COAXA",
    "COAXE",
    "COAXI",
    "COAXO",
    "COBAS",
    "COBIO",
    "COBOI",
    "COBOL",
    "COBOS",
    "COBRA",
    "COBRE",
    "COBRI",
    "COBRO",
    "COBUA",
    "COBUS",
    "COCAI",
    "COCAL",
    "COCAM",
    "COCAO",
    "COCAR",
    "COCAS",
    "COCEI",
    "COCEM",
    "COCES",
    "COCHA",
    "COCHE",
    "COCHI",
    "COCHO",
    "COCOA",
    "COCOS",
    "COCOU",
    "COCRE",
    "COCTA",
    "COCTO",
    "CODAL",
    "CODAO",
    "CODEA",
    "CODEO",
    "CODEX",
    "CODIO",
    "CODON",
    "CODRA",
    "CODS.",
    "COEIS",
    "COEMO",
    "COERA",
    "COERE",
    "COERI",
    "COERO",
    "COESA",
    "COESO",
    "COEVA",
    "COEVO",
    "COFAP",
    "COFEA",
    "COFIA",
    "COFIE",
    "COFIO",
    "COFOS",
    "COFRE",
    "COGEI",
    "COGEM",
    "COGER",
    "COGES",
    "COGEU",
    "COGIA",
    "COGIE",
    "COGIO",
    "COGN.",
    "COHAB",
    "COIAS",
    "COIBA",
    "COIBE",
    "COIBI",
    "COIBO",
    "COICE",
    "COIFA",
    "COIFE",
    "COIFO",
    "COIMA",
    "COIME",
    "COIMO",
    "COINA",
    "COINE",
    "COINO",
    "COINS",
    "COIOL",
    "COIOS",
    "COIPU",
    "COIRA",
    "COIRO",
    "COISA",
    "COISE",
    "COISO",
    "COITA",
    "COITE",
    "COITO",
    "COJAM",
    "COJAS",
    "COLAI",
    "COLAM",
    "COLAR",
    "COLAS",
    "COLAU",
    "COLCO",
    "COLEA",
    "COLEI",
    "COLEM",
    "COLEO",
    "COLES",
    "COLGA",
    "COLGO",
    "COLHA",
    "COLHE",
    "COLHI",
    "COLHO",
    "COLIA",
    "COLIM",
    "COLIR",
    "COLIS",
    "COLMA",
    "COLME",
    "COLMO",
    "COLON",
    "COLOR",
    "COLOS",
    "COLOU",
    "COLPO",
    "COLS.",
    "COLTA",
    "COLTE",
    "COLTO",
    "COLUA",
    "COLVA",
    "COLZA",
    "COMAM",
    "COMAO",
    "COMAS",
    "COMB.",
    "COMBA",
    "COMBE",
    "COMBI",
    "COMBO",
    "COMEA",
    "COMEI",
    "COMEM",
    "COMER",
    "COMES",
    "COMEU",
    "COMIA",
    "COMIL",
    "COMIO",
    "COMIS",
    "COMP.",
    "COMPO",
    "COMUA",
    "COMUM",
    "CONAB",
    "CONAN",
    "CONAP",
    "CONAS",
    "CONC.",
    "CONCA",
    "CONCE",
    "CONCO",
    "COND.",
    "CONDA",
    "CONDE",
    "CONDI",
    "CONDO",
    "CONES",
    "CONF.",
    "CONG.",
    "CONGA",
    "CONGO",
    "CONHA",
    "CONHO",
    "CONIA",
    "CONIN",
    "CONIO",
    "CONJ.",
    "CONJA",
    "CONOS",
    "CONQ.",
    "CONS.",
    "CONT.",
    "CONTA",
    "CONTE",
    "CONTO",
    "COOBA",
    "COOBE",
    "COOBO",
    "COOGE",
    "COPAI",
    "COPAL",
    "COPAM",
    "COPAR",
    "COPAS",
    "COPEI",
    "COPEL",
    "COPEM",
    "COPES",
    "COPIA",
    "COPIE",
    "COPIO",
    "COPLA",
    "COPOS",
    "COPOU",
    "COPPE",
    "COPRA",
    "COPRO",
    "COPTA",
    "COPTE",
    "COPTO",
    "COPUS",
    "COQUE",
    "CORAI",
    "CORAL",
    "CORAM",
    "CORAO",
    "CORAR",
    "CORAS",
    "CORBA",
    "CORCA",
    "CORCO",
    "CORDA",
    "CORDO",
    "COREA",
    "COREI",
    "COREM",
    "CORES",
    "COREU",
    "CORGA",
    "CORGO",
    "CORIA",
    "CORIL",
    "CORIO",
    "CORIS",
    "CORJA",
    "CORLA",
    "CORMO",
    "CORNA",
    "CORNE",
    "CORNO",
    "COROA",
    "COROE",
    "COROO",
    "COROS",
    "COROU",
    "CORP.",
    "CORPO",
    "CORR.",
    "CORRA",
    "CORRE",
    "CORRI",
    "CORRO",
    "CORSA",
    "CORSI",
    "CORSO",
    "CORTA",
    "CORTE",
    "CORTI",
    "CORTO",
    "CORUS",
    "CORV.",
    "CORVA",
    "CORVO",
    "COSAM",
    "COSAS",
    "COSCA",
    "COSCO",
    "COSEI",
    "COSEM",
    "COSER",
    "COSES",
    "COSEU",
    "COSIA",
    "COSM.",
    "COSME",
    "COSMO",
    "COSPE",
    "COSSA",
    "COSSI",
    "COSSO",
    "COST.",
    "COSTA",
    "COSTO",
    "COTAI",
    "COTAM",
    "COTAO",
    "COTAR",
    "COTAS",
    "COTEI",
    "COTEM",
    "COTES",
    "COTIA",
    "COTIE",
    "COTIL",
    "COTIM",
    "COTIO",
    "COTIS",
    "COTOM",
    "COTOS",
    "COTOU",
    "COTRA",
    "COUBE",
    "COUCA",
    "COUCE",
    "COUCO",
    "COULO",
    "COUMA",
    "COUPE",
    "COURA",
    "COURO",
    "COUSA",
    "COUSO",
    "COUTA",
    "COUTE",
    "COUTO",
    "COUVA",
    "COUVE",
    "COUVO",
    "COUZO",
    "COVAL",
    "COVAO",
    "COVAS",
    "COVEA",
    "COVIL",
    "COVIS",
    "COVOA",
    "COVOS",
    "COXAL",
    "COXAO",
    "COXAS",
    "COXEA",
    "COXES",
    "COXIA",
    "COXIM",
    "COXIS",
    "COXOS",
    "COZAM",
    "COZAS",
    "COZEI",
    "COZEM",
    "COZER",
    "COZES",
    "COZEU",
    "COZIA",
    "CRACA",
    "CRACK",
    "CRADA",
    "CRAPO",
    "CRASE",
    "CRATO",
    "CRAUA",
    "CRAVA",
    "CRAVE",
    "CRAVO",
    "CRAWL",
    "CREAM",
    "CREAS",
    "CRED.",
    "CREDA",
    "CREDE",
    "CREDO",
    "CREEM",
    "CREGA",
    "CREIA",
    "CREIE",
    "CREIO",
    "CRELA",
    "CRELE",
    "CRELO",
    "CREMA",
    "CREME",
    "CREMO",
    "CRENA",
    "CRENE",
    "CRENO",
    "CREPE",
    "CRERA",
    "CRESO",
    "CRETA",
    "CRETE",
    "CRETO",
    "CREVE",
    "CRIAI",
    "CRIAM",
    "CRIAR",
    "CRIAS",
    "CRICA",
    "CRICO",
    "CRIDA",
    "CRIDO",
    "CRIEI",
    "CRIEM",
    "CRIES",
    "CRILA",
    "CRIM.",
    "CRIME",
    "CRINA",
    "CRINO",
    "CRIOU",
    "CRISA",
    "CRISE",
    "CRISO",
    "CRIVA",
    "CRIVE",
    "CRIVO",
    "CRMMG",
    "CROAS",
    "CROCA",
    "CROCE",
    "CROCO",
    "CROIA",
    "CROIO",
    "CROMA",
    "CROME",
    "CROMO",
    "CRON.",
    "CRONO",
    "CRONS",
    "CROTA",
    "CRUAS",
    "CRUCA",
    "CRUDA",
    "CRUDE",
    "CRUDO",
    "CRUEL",
    "CRUGA",
    "CRUME",
    "CRUOR",
    "CRUPE",
    "CRURO",
    "CRUTA",
    "CRUTO",
    "CRUZA",
    "CRUZE",
    "CRUZI",
    "CRUZO",
    "CTASP",
    "CTENA",
    "CUACA",
    "CUACO",
    "CUADA",
    "CUAGA",
    "CUAIS",
    "CUAJE",
    "CUALE",
    "CUALO",
    "CUANE",
    "CUAPA",
    "CUARA",
    "CUARI",
    "CUARO",
    "CUATA",
    "CUATI",
    "CUAXI",
    "CUBAI",
    "CUBAL",
    "CUBAM",
    "CUBAR",
    "CUBAS",
    "CUBEI",
    "CUBEM",
    "CUBES",
    "CUBIE",
    "CUBIO",
    "CUBIS",
    "CUBIU",
    "CUBLA",
    "CUBOS",
    "CUBOU",
    "CUBRA",
    "CUBRE",
    "CUBRO",
    "CUBUS",
    "CUCAI",
    "CUCAL",
    "CUCAM",
    "CUCAR",
    "CUCAS",
    "CUCHE",
    "CUCHI",
    "CUCHO",
    "CUCIO",
    "CUCIS",
    "CUCOS",
    "CUCOU",
    "CUCRI",
    "CUCUS",
    "CUDAS",
    "CUDES",
    "CUDIA",
    "CUDOS",
    "CUDUS",
    "CUDZU",
    "CUEBA",
    "CUECA",
    "CUELE",
    "CUENA",
    "CUERA",
    "CUFAI",
    "CUFAM",
    "CUFAR",
    "CUFAS",
    "CUFEA",
    "CUFEI",
    "CUFEM",
    "CUFES",
    "CUFIA",
    "CUFOU",
    "CUGAR",
    "CUGIA",
    "CUGIE",
    "CUGIO",
    "CUIAO",
    "CUIAS",
    "CUIBA",
    "CUICA",
    "CUICE",
    "CUIDA",
    "CUIDE",
    "CUIDO",
    "CUILA",
    "CUILE",
    "CUILO",
    "CUINE",
    "CUINI",
    "CUINS",
    "CUIPE",
    "CUIRA",
    "CUITA",
    "CUITE",
    "CUITO",
    "CUJAS",
    "CUJES",
    "CUJIA",
    "CUJIE",
    "CUJIO",
    "CUJOS",
    "CUJUS",
    "CULAS",
    "CULCA",
    "CULCO",
    "CULEA",
    "CULEO",
    "CULES",
    "CULEX",
    "CULIS",
    "CULNA",
    "CULPA",
    "CULPE",
    "CULPO",
    "CULT.",
    "CULTA",
    "CULTO",
    "CUMAI",
    "CUMAS",
    "CUMBA",
    "CUMBE",
    "CUMBO",
    "CUMBU",
    "CUMEL",
    "CUMES",
    "CUMIM",
    "CUMIS",
    "CUMPO",
    "CUNAS",
    "CUNAU",
    "CUNCA",
    "CUNCO",
    "CUNEA",
    "CUNEO",
    "CUNHA",
    "CUNHE",
    "CUNHO",
    "CUNIA",
    "CUNOS",
    "CUNTO",
    "CUOBE",
    "CUOZA",
    "CUOZE",
    "CUOZO",
    "CUPAI",
    "CUPAO",
    "CUPAS",
    "CUPEZ",
    "CUPIM",
    "CUPIO",
    "CUPIS",
    "CUPOM",
    "CUPUS",
    "CUQUE",
    "CURAI",
    "CURAM",
    "CURAO",
    "CURAR",
    "CURAS",
    "CURAU",
    "CURCA",
    "CURDA",
    "CURDO",
    "CUREI",
    "CUREM",
    "CURES",
    "CURIA",
    "CURIE",
    "CURII",
    "CURIO",
    "CURIS",
    "CUROS",
    "CUROU",
    "CURRA",
    "CURRE",
    "CURRO",
    "CURRY",
    "CURSA",
    "CURSE",
    "CURSO",
    "CURT.",
    "CURTA",
    "CURTE",
    "CURTI",
    "CURTO",
    "CURUA",
    "CURUL",
    "CURVA",
    "CURVE",
    "CURVO",
    "CUSCO",
    "CUSCU",
    "CUSMA",
    "CUSPA",
    "CUSPE",
    "CUSPI",
    "CUSPO",
    "CUSSO",
    "CUSTA",
    "CUSTE",
    "CUSTO",
    "CUTAO",
    "CUTAS",
    "CUTER",
    "CUTES",
    "CUTIA",
    "CUTIM",
    "CUTIS",
    "CUTIU",
    "CUTRA",
    "CUVAS",
    "CUVUS",
    "CUXIU",
    "CUXUS",
    "CVIII",
    "CXCII",
    "CXCIV",
    "CXCIX",
    "CXCVI",
    "CXIII",
    "CXLII",
    "CXLIV",
    "CXLIX",
    "CXLVI",
    "CXVII",
    "CXXII",
    "CXXIV",
    "CXXIX",
    "CXXVI",
    "CXXXI",
    "CXXXV",
    "CYAT.",
    "D'ACO",
    "D'ARC",
    "D'IFA",
    "D'OVO",
    "DABIO",
    "DABOM",
    "DABOS",
    "DABUA",
    "DABUS",
    "DACAO",
    "DACAR",
    "DACAS",
    "DACES",
    "DACIA",
    "DACIO",
    "DACMA",
    "DACOA",
    "DACOS",
    "DACPO",
    "DADAS",
    "DADOR",
    "DADOS",
    "DADRA",
    "DAFNE",
    "DAGAS",
    "DAGOM",
    "DAIMA",
    "DAIME",
    "DAIRO",
    "DAIZA",
    "DAKAR",
    "DALAI",
    "DALAN",
    "DALAS",
    "DALEM",
    "DALGO",
    "DALIA",
    "DALMO",
    "DALVA",
    "DALVO",
    "DAMAO",
    "DAMAR",
    "DAMAS",
    "DAMBA",
    "DAMBE",
    "DAMOS",
    "DAMPA",
    "DANAI",
    "DANAM",
    "DANAR",
    "DANAS",
    "DANCA",
    "DANCE",
    "DANCO",
    "DANDE",
    "DANDI",
    "DANDO",
    "DANEI",
    "DANEM",
    "DANES",
    "DANOS",
    "DANOU",
    "DANTA",
    "DANTE",
    "DAOME",
    "DAQUI",
    "DARAO",
    "DARAS",
    "DARCI",
    "DARDA",
    "DARDE",
    "DARDO",
    "DAREI",
    "DAREM",
    "DARES",
    "DARIA",
    "DARIO",
    "DARMA",
    "DAROS",
    "DARTO",
    "DASSA",
    "DATAI",
    "DATAL",
    "DATAM",
    "DATAR",
    "DATAS",
    "DATEI",
    "DATEM",
    "DATES",
    "DATIL",
    "DATOU",
    "DAVAM",
    "DAVAS",
    "DAVID",
    "DAVIS",
    "DAYSE",
    "DCCCI",
    "DCCCL",
    "DCCCV",
    "DCCCX",
    "DCCII",
    "DCCIV",
    "DCCIX",
    "DCCLI",
    "DCCLV",
    "DCCLX",
    "DCCVI",
    "DCCXC",
    "DCCXI",
    "DCCXL",
    "DCCXV",
    "DCCXX",
    "DCIII",
    "DCLII",
    "DCLIV",
    "DCLIX",
    "DCLVI",
    "DCLXI",
    "DCLXV",
    "DCLXX",
    "DCVII",
    "DCXCI",
    "DCXCV",
    "DCXII",
    "DCXIV",
    "DCXIX",
    "DCXLI",
    "DCXLV",
    "DCXVI",
    "DCXXI",
    "DCXXV",
    "DCXXX",
    "DEADA",
    "DEADO",
    "DEAES",
    "DEAOS",
    "DEBAI",
    "DEBAM",
    "DEBAR",
    "DEBAS",
    "DEBEI",
    "DEBEM",
    "DEBES",
    "DEBIL",
    "DEBOU",
    "DECAI",
    "DECAS",
    "DECIA",
    "DECIL",
    "DECIO",
    "DECIS",
    "DECL.",
    "DECOA",
    "DECOE",
    "DECOO",
    "DECOR",
    "DECR.",
    "DEDAI",
    "DEDAL",
    "DEDAM",
    "DEDAO",
    "DEDAR",
    "DEDAS",
    "DEDEI",
    "DEDEM",
    "DEDES",
    "DEDOS",
    "DEDOU",
    "DEDUZ",
    "DEIAS",
    "DEISE",
    "DEITA",
    "DEITE",
    "DEITO",
    "DEIXA",
    "DEIXE",
    "DEIXO",
    "DEJUA",
    "DELAS",
    "DELEM",
    "DELES",
    "DELIA",
    "DELIO",
    "DELIR",
    "DELIS",
    "DELIU",
    "DELMA",
    "DELON",
    "DELTA",
    "DELZA",
    "DEMAO",
    "DEMAR",
    "DEMBA",
    "DEMBE",
    "DEMBO",
    "DEMEA",
    "DEMIA",
    "DEMOS",
    "DENDE",
    "DENDO",
    "DENGO",
    "DENIS",
    "DENS.",
    "DENSA",
    "DENSE",
    "DENSO",
    "DENTA",
    "DENTE",
    "DENTO",
    "DEOES",
    "DEPOE",
    "DEPOR",
    "DEPOS",
    "DEPR.",
    "DEPS.",
    "DEPT.",
    "DEPUS",
    "DEQUE",
    "DERAM",
    "DERAS",
    "DERBI",
    "DEREM",
    "DERES",
    "DERM.",
    "DERMA",
    "DERME",
    "DERMO",
    "DEROS",
    "DERRE",
    "DESAI",
    "DESAM",
    "DESAR",
    "DESAS",
    "DESC.",
    "DESCA",
    "DESCE",
    "DESCI",
    "DESCO",
    "DESDA",
    "DESDE",
    "DESDI",
    "DESDO",
    "DESEI",
    "DESEM",
    "DESES",
    "DESMA",
    "DESMO",
    "DESOU",
    "DESP.",
    "DESPE",
    "DESPI",
    "DESSA",
    "DESSE",
    "DESSO",
    "DESTA",
    "DESTE",
    "DETEM",
    "DETER",
    "DETEU",
    "DETRE",
    "DEUSA",
    "DEVAM",
    "DEVAS",
    "DEVEI",
    "DEVEM",
    "DEVER",
    "DEVES",
    "DEVEU",
    "DEVIA",
    "DEVIM",
    "DEVIO",
    "DEVIR",
    "DEWAR",
    "DEXIA",
    "DHABI",
    "DIABA",
    "DIABO",
    "DIAC.",
    "DIACO",
    "DIADA",
    "DIADE",
    "DIAFA",
    "DIAL.",
    "DIALE",
    "DIANA",
    "DIANE",
    "DIARA",
    "DIATE",
    "DIAUS",
    "DIAZO",
    "DIBOS",
    "DICAI",
    "DICAM",
    "DICAO",
    "DICAR",
    "DICAS",
    "DICAZ",
    "DICHA",
    "DICOU",
    "DIDEA",
    "DIDIO",
    "DIDIS",
    "DIECO",
    "DIEGO",
    "DIENO",
    "DIESA",
    "DIESE",
    "DIESO",
    "DIETA",
    "DIFAS",
    "DIFIA",
    "DIFOS",
    "DIGAM",
    "DIGAR",
    "DIGAS",
    "DIGNA",
    "DIGNE",
    "DIGNO",
    "DILAM",
    "DILAS",
    "DILMA",
    "DILOS",
    "DILUA",
    "DILUI",
    "DILUO",
    "DILZA",
    "DIMAL",
    "DIMAS",
    "DIMBA",
    "DIMEU",
    "DINAR",
    "DINAS",
    "DINCA",
    "DINDA",
    "DINDE",
    "DINDO",
    "DINES",
    "DINGA",
    "DINGO",
    "DINIE",
    "DINIO",
    "DINIS",
    "DINIZ",
    "DINOS",
    "DIOC.",
    "DIODO",
    "DIOGA",
    "DIOGO",
    "DIOIS",
    "DIOLA",
    "DIOMA",
    "DIONA",
    "DIONE",
    "DIOPA",
    "DIOPE",
    "DIORE",
    "DIOSA",
    "DIOSE",
    "DIOSO",
    "DIOTE",
    "DIPAS",
    "DIPL.",
    "DIPLE",
    "DIPLO",
    "DIPSO",
    "DIQUE",
    "DIRAO",
    "DIRAS",
    "DIRCA",
    "DIRCE",
    "DIREI",
    "DIREM",
    "DIRFO",
    "DIRIA",
    "DIROS",
    "DIRRA",
    "DIRUA",
    "DIRUI",
    "DIRUO",
    "DISAS",
    "DISC.",
    "DISCA",
    "DISCO",
    "DISEL",
    "DISGA",
    "DISNA",
    "DISPA",
    "DISPO",
    "DISS.",
    "DISSE",
    "DISSO",
    "DISTA",
    "DISTE",
    "DISTO",
    "DISUE",
    "DITAI",
    "DITAM",
    "DITAR",
    "DITAS",
    "DITEI",
    "DITEM",
    "DITES",
    "DITOS",
    "DITOU",
    "DITUA",
    "DIULA",
    "DIULI",
    "DIUSO",
    "DIUTA",
    "DIUTI",
    "DIVAO",
    "DIVAS",
    "DIVOS",
    "DIXES",
    "DIXIS",
    "DIZEI",
    "DIZEM",
    "DIZER",
    "DIZES",
    "DIZIA",
    "DJAPA",
    "DJICA",
    "DJICO",
    "DJINS",
    "DJOLA",
    "DLIII",
    "DLINS",
    "DLVII",
    "DLXII",
    "DLXIV",
    "DLXIX",
    "DLXVI",
    "DLXXI",
    "DLXXV",
    "DLXXX",
    "DOADA",
    "DOADO",
    "DOAIS",
    "DOAMO",
    "DOARA",
    "DOAVA",
    "DOBAI",
    "DOBAM",
    "DOBAR",
    "DOBAS",
    "DOBEI",
    "DOBEM",
    "DOBES",
    "DOBLA",
    "DOBLE",
    "DOBOU",
    "DOBRA",
    "DOBRE",
    "DOBRO",
    "DOCAI",
    "DOCAL",
    "DOCAM",
    "DOCAR",
    "DOCAS",
    "DOCEI",
    "DOCEM",
    "DOCES",
    "DOCIL",
    "DOCOU",
    "DOCS.",
    "DOCTO",
    "DOCUS",
    "DODOI",
    "DODOL",
    "DODOS",
    "DOEIS",
    "DOEMO",
    "DOERA",
    "DOGAL",
    "DOGAS",
    "DOGES",
    "DOGM.",
    "DOGMA",
    "DOGON",
    "DOGRE",
    "DOGUE",
    "DOHRN",
    "DOIAM",
    "DOIAS",
    "DOIDA",
    "DOIDO",
    "DOILO",
    "DOIRA",
    "DOIRE",
    "DOIRO",
    "DOJOS",
    "DOLAR",
    "DOLCE",
    "DOLIM",
    "DOLIO",
    "DOLIS",
    "DOLMA",
    "DOLOR",
    "DOLOS",
    "DOMAI",
    "DOMAM",
    "DOMAR",
    "DOMAS",
    "DOMBA",
    "DOMBE",
    "DOMBO",
    "DOMEI",
    "DOMEM",
    "DOMES",
    "DOMNO",
    "DOMOS",
    "DOMOU",
    "DONAS",
    "DONAX",
    "DONDA",
    "DONDE",
    "DONDO",
    "DONEA",
    "DONEZ",
    "DONGA",
    "DONGO",
    "DONIA",
    "DONOS",
    "DONTE",
    "DOPAI",
    "DOPAM",
    "DOPAR",
    "DOPAS",
    "DOPEI",
    "DOPEM",
    "DOPES",
    "DOPOU",
    "DORAS",
    "DORCA",
    "DORCO",
    "DOREA",
    "DORES",
    "DORIA",
    "DORIO",
    "DORIR",
    "DORIS",
    "DORME",
    "DORMI",
    "DORMO",
    "DORNA",
    "DORSO",
    "DOSAI",
    "DOSAM",
    "DOSAR",
    "DOSAS",
    "DOSEA",
    "DOSEI",
    "DOSEM",
    "DOSES",
    "DOSOU",
    "DOTAI",
    "DOTAL",
    "DOTAM",
    "DOTAR",
    "DOTAS",
    "DOTEI",
    "DOTEM",
    "DOTES",
    "DOTIM",
    "DOTOU",
    "DOUDA",
    "DOUDO",
    "DOULA",
    "DOULO",
    "DOUNS",
    "DOURA",
    "DOURE",
    "DOURO",
    "DOUTA",
    "DOUTO",
    "DOXAS",
    "DOXUS",
    "DOZAO",
    "DOZEM",
    "DPTO.",
    "DRAGA",
    "DRAGO",
    "DRAM.",
    "DRAMA",
    "DRAV.",
    "DRENA",
    "DRENE",
    "DRENO",
    "DRICA",
    "DRIVE",
    "DROGA",
    "DROGO",
    "DROMO",
    "DROPE",
    "DRUPA",
    "DRUSA",
    "DRUSO",
    "DUAIS",
    "DUALA",
    "DUALO",
    "DUANA",
    "DUBAI",
    "DUBIA",
    "DUBIO",
    "DUBLA",
    "DUBLE",
    "DUBLO",
    "DUBOS",
    "DUBUS",
    "DUCAL",
    "DUCHA",
    "DUCHE",
    "DUCHO",
    "DUCOS",
    "DUCRA",
    "DUCTO",
    "DUDAS",
    "DUECA",
    "DUELA",
    "DUELE",
    "DUELO",
    "DUERE",
    "DUETA",
    "DUETE",
    "DUETO",
    "DUGAO",
    "DUGAS",
    "DUGNI",
    "DUILA",
    "DUINA",
    "DUINS",
    "DUIPA",
    "DUITA",
    "DULCE",
    "DULES",
    "DULIA",
    "DUMAS",
    "DUMBA",
    "DUMBO",
    "DUMOS",
    "DUNAM",
    "DUNAR",
    "DUNAS",
    "DUNDU",
    "DUNFA",
    "DUNGA",
    "DUNGO",
    "DUNHA",
    "DUNIA",
    "DUPLA",
    "DUPLE",
    "DUPLO",
    "DUQUE",
    "DURAI",
    "DURAL",
    "DURAM",
    "DURAO",
    "DURAR",
    "DURAS",
    "DURAZ",
    "DURBA",
    "DURBE",
    "DURBO",
    "DUREI",
    "DUREM",
    "DURES",
    "DUREX",
    "DUREZ",
    "DURGA",
    "DURIM",
    "DURIO",
    "DURMA",
    "DURMO",
    "DURNE",
    "DUROL",
    "DUROS",
    "DUROU",
    "DURRA",
    "DURUS",
    "DUTIS",
    "DUTOS",
    "DUTRA",
    "DUTRO",
    "DUVOL",
    "DUZAM",
    "DUZAS",
    "DUZEM",
    "DUZES",
    "DUZIA",
    "DUZIL",
    "DUZIR",
    "DUZIS",
    "DUZIU",
    "DUZUS",
    "DVIII",
    "DXCII",
    "DXCIV",
    "DXCIX",
    "DXCVI",
    "DXIII",
    "DXLII",
    "DXLIV",
    "DXLIX",
    "DXLVI",
    "DXVII",
    "DXXII",
    "DXXIV",
    "DXXIX",
    "DXXVI",
    "DXXXI",
    "DXXXV",
    "DZETA",
    "EALMA",
    "EALME",
    "EALMO",
    "EANES",
    "EANTO",
    "EARCA",
    "EARCO",
    "EARIA",
    "EBAME",
    "EBAMI",
    "EBANI",
    "EBANO",
    "EBENO",
    "EBIAS",
    "EBIRI",
    "EBLIS",
    "EBOLA",
    "EBOLE",
    "EBOME",
    "EBOMI",
    "EBRIA",
    "EBRIO",
    "EBULA",
    "EBULI",
    "EBULO",
    "ECADA",
    "ECADO",
    "ECAIS",
    "ECAMO",
    "ECANA",
    "ECANO",
    "ECAPA",
    "ECARA",
    "ECATO",
    "ECAVA",
    "ECESE",
    "ECFMA",
    "ECICA",
    "ECICO",
    "ECLER",
    "ECMAS",
    "ECOAI",
    "ECOAM",
    "ECOAR",
    "ECOAS",
    "ECOEI",
    "ECOEM",
    "ECOES",
    "ECOL.",
    "ECON.",
    "ECOOU",
    "ECOPE",
    "ECOTO",
    "ECRAN",
    "ECROM",
    "ECTAL",
    "ECTOL",
    "ECTOS",
    "ECUBO",
    "ECULA",
    "ECULO",
    "ECURU",
    "EDACE",
    "EDALO",
    "EDEIA",
    "EDEMA",
    "EDENS",
    "EDERE",
    "EDGAR",
    "EDIAS",
    "EDICA",
    "EDICO",
    "EDIF.",
    "EDILA",
    "EDIME",
    "EDINA",
    "EDIPO",
    "EDITA",
    "EDITE",
    "EDITO",
    "EDJAN",
    "EDMAR",
    "EDNEI",
    "EDNIE",
    "EDOMA",
    "EDONO",
    "EDRAS",
    "EDREI",
    "EDROS",
    "EDSON",
    "EDTAS",
    "EDUC.",
    "EDUCA",
    "EDUCO",
    "EDULE",
    "EDULO",
    "EDUOS",
    "EDUTO",
    "EDUZA",
    "EDUZE",
    "EDUZI",
    "EDUZO",
    "EDVAN",
    "EFATA",
    "EFEBO",
    "EFEM.",
    "EFESO",
    "EFETA",
    "EFIPO",
    "EFIRA",
    "EFIRO",
    "EFLUA",
    "EFLUI",
    "EFLUO",
    "EFODE",
    "EFODO",
    "EFORO",
    "EFREM",
    "EFUCO",
    "EFUES",
    "EFUNS",
    "EFUSO",
    "EGEAO",
    "EGEIA",
    "EGESA",
    "EGEUS",
    "EGIDE",
    "EGIP.",
    "EGIPA",
    "EGITO",
    "EGLES",
    "EGRIO",
    "EGROS",
    "EGUAI",
    "EGUAR",
    "EGUAS",
    "EGUCI",
    "EGUEI",
    "EGUEM",
    "EGUES",
    "EGUNS",
    "EGUOU",
    "EIBAS",
    "EICAI",
    "EICAM",
    "EICAR",
    "EICAS",
    "EICEI",
    "EICEM",
    "EICES",
    "EICHA",
    "EICHE",
    "EICHO",
    "EICOS",
    "EICOU",
    "EIDAS",
    "EIDER",
    "EIDOS",
    "EIMAI",
    "EIMAM",
    "EIMAR",
    "EIMAS",
    "EIMEI",
    "EIMEM",
    "EIMES",
    "EIMOU",
    "EIRAS",
    "EIROS",
    "EIRUS",
    "EITAS",
    "EITOS",
    "EIVAI",
    "EIVAM",
    "EIVAO",
    "EIVAR",
    "EIVAS",
    "EIVEI",
    "EIVEM",
    "EIVES",
    "EIVOU",
    "EIXAI",
    "EIXAM",
    "EIXAR",
    "EIXAS",
    "EIXEI",
    "EIXEM",
    "EIXES",
    "EIXOS",
    "EIXOU",
    "EIXUS",
    "EJETA",
    "EJETE",
    "EJETO",
    "EJUOS",
    "ELADA",
    "ELADO",
    "ELAFA",
    "ELAFO",
    "ELAIS",
    "ELAMI",
    "ELAMO",
    "ELANA",
    "ELANE",
    "ELANO",
    "ELARA",
    "ELATE",
    "ELAUS",
    "ELAVA",
    "ELBIA",
    "ELBIO",
    "ELCHE",
    "ELCIO",
    "ELDER",
    "ELEAI",
    "ELEAR",
    "ELEBO",
    "ELEDA",
    "ELEDE",
    "ELEEI",
    "ELEGE",
    "ELEGI",
    "ELEIA",
    "ELEIE",
    "ELEIO",
    "ELEIS",
    "ELEJA",
    "ELEJO",
    "ELEMI",
    "ELEMO",
    "ELENA",
    "ELENI",
    "ELEOU",
    "ELEPE",
    "ELETO",
    "ELEUS",
    "ELEVA",
    "ELEVE",
    "ELEVO",
    "ELFAS",
    "ELFOS",
    "ELIAS",
    "ELICA",
    "ELICE",
    "ELICO",
    "ELIDA",
    "ELIDE",
    "ELIDI",
    "ELIDO",
    "ELIEL",
    "ELIGE",
    "ELIGI",
    "ELIJA",
    "ELIJO",
    "ELIME",
    "ELIMO",
    "ELINA",
    "ELIRI",
    "ELISA",
    "ELISE",
    "ELITE",
    "ELIUD",
    "ELIXA",
    "ELIXO",
    "ELMAR",
    "ELMAS",
    "ELMER",
    "ELMOS",
    "ELOAR",
    "ELOIM",
    "ELONA",
    "ELOPE",
    "ELSON",
    "ELTON",
    "ELUAM",
    "ELUAS",
    "ELUDA",
    "ELUDE",
    "ELUDI",
    "ELUDO",
    "ELUEM",
    "ELUEU",
    "ELUIA",
    "ELUIR",
    "ELUIS",
    "ELUIU",
    "ELURO",
    "ELVIA",
    "ELVIS",
    "EMACA",
    "EMACE",
    "EMACO",
    "EMALA",
    "EMALE",
    "EMALO",
    "EMAMO",
    "EMANA",
    "EMANE",
    "EMANO",
    "EMAPU",
    "EMARA",
    "EMARE",
    "EMARO",
    "EMAUS",
    "EMBAI",
    "EMBEU",
    "EMBIA",
    "EMBIU",
    "EMBOA",
    "EMBR.",
    "EMBUA",
    "EMBUI",
    "EMBUU",
    "EMEBE",
    "EMEDA",
    "EMEDE",
    "EMEDO",
    "EMEIO",
    "EMELA",
    "EMELE",
    "EMELO",
    "EMENS",
    "EMERJ",
    "EMERO",
    "EMESE",
    "EMEUS",
    "EMEXO",
    "EMFIM",
    "EMIAS",
    "EMICA",
    "EMICO",
    "EMIDA",
    "EMIDE",
    "EMILE",
    "EMINS",
    "EMITA",
    "EMITE",
    "EMITI",
    "EMITO",
    "EMOCO",
    "EMOL.",
    "EMOLE",
    "EMOLI",
    "EMONA",
    "EMONE",
    "EMONO",
    "EMOTA",
    "EMOTO",
    "EMPAI",
    "EMPAM",
    "EMPAR",
    "EMPAS",
    "EMPEA",
    "EMPEI",
    "EMPEM",
    "EMPES",
    "EMPIS",
    "EMPOA",
    "EMPOE",
    "EMPOO",
    "EMPOS",
    "EMPOU",
    "EMPR.",
    "EMULA",
    "EMULE",
    "EMULO",
    "ENALO",
    "ENANA",
    "ENANO",
    "ENASE",
    "ENATA",
    "ENATE",
    "ENATO",
    "ENCHA",
    "ENCHE",
    "ENCHI",
    "ENCHO",
    "ENCHU",
    "ENDES",
    "ENDEZ",
    "ENDOS",
    "ENDRO",
    "ENDUA",
    "ENEAS",
    "ENEIS",
    "ENEMA",
    "ENEOS",
    "ENEUS",
    "ENEVA",
    "ENEVE",
    "ENEVO",
    "ENFEA",
    "ENFIA",
    "ENFIE",
    "ENFIM",
    "ENFIO",
    "ENFUA",
    "ENFUE",
    "ENFUO",
    "ENG.ª",
    "ENG.º",
    "ENGAI",
    "ENGAM",
    "ENGAR",
    "ENGAS",
    "ENGEA",
    "ENGOS",
    "ENGOU",
    "ENGRA",
    "ENGUE",
    "ENHOS",
    "ENIBU",
    "ENICA",
    "ENICO",
    "ENINO",
    "ENITO",
    "ENJOA",
    "ENJOE",
    "ENJOO",
    "ENLEA",
    "ENODA",
    "ENODE",
    "ENODO",
    "ENOGA",
    "ENOGO",
    "ENOIS",
    "ENOJA",
    "ENOJE",
    "ENOJO",
    "ENOMO",
    "ENONE",
    "ENORA",
    "ENOVA",
    "ENOVE",
    "ENOVO",
    "ENQUE",
    "ENRIA",
    "ENRIE",
    "ENRIO",
    "ENSEA",
    "ENSIS",
    "ENSOA",
    "ENSOE",
    "ENSOO",
    "ENTAO",
    "ENTAS",
    "ENTEA",
    "ENTER",
    "ENTES",
    "ENTEU",
    "ENTOA",
    "ENTOE",
    "ENTOO",
    "ENTRA",
    "ENTRE",
    "ENTRO",
    "ENULA",
    "ENVES",
    "ENVIA",
    "ENVIE",
    "ENVIO",
    "ENXIA",
    "ENXIE",
    "ENXIO",
    "ENXOS",
    "ENXUI",
    "ENXUS",
    "EOIPO",
    "EOLIA",
    "EOLIO",
    "EONES",
    "EPATA",
    "EPEIA",
    "EPENA",
    "EPICA",
    "EPICO",
    "EPIG.",
    "EPILA",
    "EPILE",
    "EPILO",
    "EPIRO",
    "EPOCA",
    "EPODO",
    "EPOXI",
    "EPULA",
    "EPURA",
    "EPUXA",
    "EQUEI",
    "EQUEM",
    "EQUES",
    "EQUEU",
    "EQUIO",
    "EQUIS",
    "EQUOS",
    "ERADA",
    "ERADO",
    "ERAIS",
    "ERAMA",
    "ERAMO",
    "ERANA",
    "ERANO",
    "ERARA",
    "ERATI",
    "ERATO",
    "ERAVA",
    "ERBIA",
    "ERBIO",
    "EREAS",
    "EREBO",
    "EREIS",
    "EREMO",
    "EREOS",
    "ERERE",
    "ERESO",
    "ERETA",
    "ERETO",
    "ERGAM",
    "ERGAS",
    "ERGIA",
    "ERGIO",
    "ERGOL",
    "ERGUE",
    "ERGUI",
    "ERIAS",
    "ERICA",
    "ERICE",
    "ERICO",
    "ERIGE",
    "ERIGI",
    "ERIJA",
    "ERIJO",
    "ERINA",
    "ERINO",
    "ERION",
    "ERMAI",
    "ERMAL",
    "ERMAM",
    "ERMAR",
    "ERMAS",
    "ERMEI",
    "ERMEM",
    "ERMES",
    "ERMOS",
    "ERMOU",
    "EROCA",
    "ERODA",
    "ERODE",
    "ERODI",
    "ERODO",
    "EROGE",
    "ERONE",
    "EROPE",
    "EROSA",
    "EROSE",
    "EROSO",
    "ERPES",
    "ERRAI",
    "ERRAM",
    "ERRAR",
    "ERRAS",
    "ERREI",
    "ERREM",
    "ERRES",
    "ERROR",
    "ERROS",
    "ERROU",
    "ERSAS",
    "ERSES",
    "ERUCA",
    "ERUD.",
    "ERUDA",
    "ERUDO",
    "ERUGA",
    "ERUIR",
    "ERURU",
    "ERVAI",
    "ERVAL",
    "ERVAM",
    "ERVAO",
    "ERVAR",
    "ERVAS",
    "ERVEI",
    "ERVEM",
    "ERVES",
    "ERVOA",
    "ERVOE",
    "ERVOO",
    "ERVOU",
    "ESCOA",
    "ESCOE",
    "ESCOL",
    "ESCOO",
    "ESCR.",
    "ESDRA",
    "ESERE",
    "ESFAZ",
    "ESFEX",
    "ESFEZ",
    "ESFIA",
    "ESFIE",
    "ESFIO",
    "ESFIZ",
    "ESGAI",
    "ESGAM",
    "ESGAR",
    "ESGAS",
    "ESGOU",
    "ESGR.",
    "ESGUE",
    "ESIMA",
    "ESIMO",
    "ESIPO",
    "ESMAI",
    "ESMAM",
    "ESMAR",
    "ESMAS",
    "ESMEA",
    "ESMEI",
    "ESMEM",
    "ESMES",
    "ESMOA",
    "ESMOE",
    "ESMOI",
    "ESMOO",
    "ESMOU",
    "ESNUA",
    "ESNUE",
    "ESNUO",
    "ESOCE",
    "ESOPO",
    "ESOT.",
    "ESPIA",
    "ESPIE",
    "ESPIM",
    "ESPIO",
    "ESPOA",
    "ESPOE",
    "ESPOO",
    "ESPRU",
    "ESPUA",
    "ESPUI",
    "ESPUO",
    "ESQUI",
    "ESRON",
    "ESSAS",
    "ESSES",
    "ESSUO",
    "ESTAI",
    "ESTAO",
    "ESTAR",
    "ESTAS",
    "ESTAU",
    "ESTEA",
    "ESTER",
    "ESTES",
    "ESTIA",
    "ESTIE",
    "ESTIO",
    "ESTOL",
    "ESTOS",
    "ESTOU",
    "ESTR.",
    "ESTRO",
    "ESTUA",
    "ESTUE",
    "ESTUO",
    "ESULA",
    "ESVAI",
    "ESVAO",
    "ETAIS",
    "ETANA",
    "ETANO",
    "ETAPA",
    "ETEGO",
    "ETEIA",
    "ETEMO",
    "ETENO",
    "ETERA",
    "ETEUS",
    "ETHOS",
    "ETICA",
    "ETICO",
    "ETIGO",
    "ETILA",
    "ETILE",
    "ETILO",
    "ETIM.",
    "ETIMO",
    "ETINO",
    "ETIOS",
    "ETIPE",
    "ETITA",
    "ETITE",
    "ETITO",
    "ETMOS",
    "ETNIA",
    "ETNOS",
    "ETOES",
    "ETOL.",
    "ETUSA",
    "ETUTU",
    "EUBEU",
    "EUBIO",
    "EUDES",
    "EUDIA",
    "EUDOR",
    "EULER",
    "EUMBO",
    "EURIA",
    "EURIO",
    "EUROS",
    "EVADA",
    "EVADE",
    "EVADI",
    "EVADO",
    "EVAIR",
    "EVAIS",
    "EVALE",
    "EVAMO",
    "EVANA",
    "EVANO",
    "EVARA",
    "EVASE",
    "EVAVA",
    "EVAZA",
    "EVAZE",
    "EVAZO",
    "EVEIA",
    "EVEIS",
    "EVEMO",
    "EVEUS",
    "EVIGO",
    "EVIPA",
    "EVIRA",
    "EVIRE",
    "EVIRO",
    "EVITA",
    "EVITE",
    "EVITO",
    "EVOCA",
    "EVOCO",
    "EVODO",
    "EVOLA",
    "EVOLE",
    "EVOLO",
    "EVORA",
    "EXACO",
    "EXAG.",
    "EXALA",
    "EXALE",
    "EXALO",
    "EXAME",
    "EXARA",
    "EXARE",
    "EXARO",
    "EXATA",
    "EXATO",
    "EXCL.",
    "EXIBA",
    "EXIBE",
    "EXIBI",
    "EXIBO",
    "EXIDO",
    "EXIGE",
    "EXIGI",
    "EXIJA",
    "EXIJO",
    "EXILA",
    "EXILE",
    "EXILO",
    "EXIMA",
    "EXIME",
    "EXIMI",
    "EXIMO",
    "EXINA",
    "EXITA",
    "EXITE",
    "EXITO",
    "EXMAS",
    "EXMOS",
    "EXMª.",
    "EXMº.",
    "EXODO",
    "EXOES",
    "EXORA",
    "EXORE",
    "EXORO",
    "EXPIA",
    "EXPIE",
    "EXPIO",
    "EXPL.",
    "EXPOE",
    "EXPOR",
    "EXPOS",
    "EXPR.",
    "EXPUS",
    "EXSTA",
    "EXSTE",
    "EXSTO",
    "EXSUA",
    "EXSUE",
    "EXSUO",
    "EXTRA",
    "EXUIS",
    "EXULA",
    "EXULE",
    "EXULO",
    "EXUMA",
    "EXUME",
    "EXUMO",
    "EZIOM",
    "FABAL",
    "FABAS",
    "FABER",
    "FABIA",
    "FABIO",
    "FABRO",
    "FACAM",
    "FACAO",
    "FACAS",
    "FACEA",
    "FACES",
    "FACHA",
    "FACHE",
    "FACHI",
    "FACHO",
    "FACIE",
    "FACIL",
    "FACOS",
    "FACTO",
    "FADAI",
    "FADAM",
    "FADAR",
    "FADAS",
    "FADEI",
    "FADEM",
    "FADES",
    "FADIA",
    "FADIM",
    "FADOS",
    "FADOU",
    "FAECE",
    "FAGAI",
    "FAGAL",
    "FAGAM",
    "FAGAR",
    "FAGAS",
    "FAGEA",
    "FAGIA",
    "FAGOS",
    "FAGOU",
    "FAGUE",
    "FAIAI",
    "FAIAL",
    "FAIAM",
    "FAIAO",
    "FAIAR",
    "FAIAS",
    "FAIDA",
    "FAIDO",
    "FAIEI",
    "FAIEM",
    "FAIES",
    "FAINA",
    "FAINE",
    "FAINO",
    "FAINS",
    "FAIOU",
    "FAIRA",
    "FAIRE",
    "FAIRO",
    "FAISA",
    "FAITE",
    "FAIXA",
    "FAIXE",
    "FAIXO",
    "FAJAU",
    "FAJEA",
    "FAJOS",
    "FALAI",
    "FALAM",
    "FALAR",
    "FALAS",
    "FALAZ",
    "FALC.",
    "FALCA",
    "FALCI",
    "FALCO",
    "FALDA",
    "FALEG",
    "FALEI",
    "FALEM",
    "FALES",
    "FALGA",
    "FALGO",
    "FALHA",
    "FALHE",
    "FALHO",
    "FALIA",
    "FALIR",
    "FALIS",
    "FALIU",
    "FALOA",
    "FALOS",
    "FALOU",
    "FALSA",
    "FALSE",
    "FALSO",
    "FALTA",
    "FALTE",
    "FALTO",
    "FALUA",
    "FALUM",
    "FALUS",
    "FALUZ",
    "FAMAS",
    "FANAI",
    "FANAL",
    "FANAM",
    "FANAO",
    "FANAR",
    "FANAS",
    "FANCA",
    "FANDA",
    "FANDO",
    "FANEI",
    "FANEM",
    "FANES",
    "FANEU",
    "FANFA",
    "FANFE",
    "FANFO",
    "FANGA",
    "FANHA",
    "FANHO",
    "FANIA",
    "FANIO",
    "FANOA",
    "FANOU",
    "FANTA",
    "FANTE",
    "FANTI",
    "FAQUI",
    "FARAD",
    "FARAI",
    "FARAM",
    "FARAO",
    "FARAR",
    "FARAS",
    "FARAZ",
    "FARDA",
    "FARDE",
    "FARDO",
    "FAREI",
    "FAREL",
    "FAREM",
    "FARES",
    "FAREU",
    "FARFA",
    "FARIA",
    "FARIO",
    "FARIS",
    "FARM.",
    "FARME",
    "FARNO",
    "FAROE",
    "FAROL",
    "FAROS",
    "FAROU",
    "FARPA",
    "FARPE",
    "FARPO",
    "FARRA",
    "FARRE",
    "FARRO",
    "FARSA",
    "FARTA",
    "FARTE",
    "FARTO",
    "FARUM",
    "FASC.",
    "FASCA",
    "FASCO",
    "FASEA",
    "FASEO",
    "FASES",
    "FASIA",
    "FASMA",
    "FASOR",
    "FASTA",
    "FASTE",
    "FASTO",
    "FATAL",
    "FATAO",
    "FATAS",
    "FATES",
    "FATIA",
    "FATIE",
    "FATIO",
    "FATOR",
    "FATOS",
    "FATUA",
    "FATUO",
    "FAUCE",
    "FAULA",
    "FAULE",
    "FAULO",
    "FAUNA",
    "FAUNO",
    "FAUV.",
    "FAVAI",
    "FAVAL",
    "FAVAM",
    "FAVAO",
    "FAVAR",
    "FAVAS",
    "FAVEI",
    "FAVEM",
    "FAVES",
    "FAVIO",
    "FAVOR",
    "FAVOS",
    "FAVOU",
    "FAXEA",
    "FAXES",
    "FAZEI",
    "FAZEM",
    "FAZER",
    "FAZES",
    "FAZIA",
    "FEACE",
    "FEACO",
    "FEBEU",
    "FEBOS",
    "FEBRA",
    "FEBRE",
    "FECAL",
    "FECHA",
    "FECHE",
    "FECHO",
    "FEDAM",
    "FEDAS",
    "FEDEI",
    "FEDEM",
    "FEDER",
    "FEDES",
    "FEDEU",
    "FEDIA",
    "FEDOR",
    "FEDRA",
    "FEIAO",
    "FEIAS",
    "FEIJO",
    "FEILA",
    "FEIOS",
    "FEIRA",
    "FEIRE",
    "FEIRO",
    "FEIT.",
    "FEITA",
    "FEITE",
    "FEITO",
    "FEIXE",
    "FELAI",
    "FELAM",
    "FELAR",
    "FELAS",
    "FELEA",
    "FELEI",
    "FELEM",
    "FELEO",
    "FELES",
    "FELEU",
    "FELGA",
    "FELIX",
    "FELIZ",
    "FELOU",
    "FELPA",
    "FELPE",
    "FELPO",
    "FEMBA",
    "FEMBE",
    "FEMBO",
    "FEMEA",
    "FEMEO",
    "FEMTE",
    "FEMTO",
    "FEMUR",
    "FENAI",
    "FENAJ",
    "FENAL",
    "FENAM",
    "FENAR",
    "FENAS",
    "FENDA",
    "FENDE",
    "FENDI",
    "FENDO",
    "FENEI",
    "FENEM",
    "FENES",
    "FENIM",
    "FENIX",
    "FENOL",
    "FENOS",
    "FENOU",
    "FENTO",
    "FEOES",
    "FEOFO",
    "FEONA",
    "FEOSA",
    "FEOSO",
    "FEPES",
    "FERAL",
    "FERAS",
    "FERAZ",
    "FEREM",
    "FERES",
    "FEREU",
    "FERGA",
    "FERIA",
    "FERIE",
    "FERIO",
    "FERIR",
    "FERIS",
    "FERIU",
    "FERMI",
    "FERMO",
    "FEROS",
    "FEROZ",
    "FERPA",
    "FERRA",
    "FERRE",
    "FERRO",
    "FERTO",
    "FERVA",
    "FERVE",
    "FERVI",
    "FERVO",
    "FESTA",
    "FESTE",
    "FESTO",
    "FETAG",
    "FETAL",
    "FETAO",
    "FETIM",
    "FETOS",
    "FETUS",
    "FEUD.",
    "FEUDA",
    "FEUDO",
    "FEVRA",
    "FEZAL",
    "FEZES",
    "FIACO",
    "FIADA",
    "FIADO",
    "FIAIS",
    "FIALA",
    "FIAMO",
    "FIAPO",
    "FIARA",
    "FIAUS",
    "FIAVA",
    "FIBER",
    "FIBOS",
    "FIBRA",
    "FIBRO",
    "FICAI",
    "FICAM",
    "FICAO",
    "FICAR",
    "FICAS",
    "FICEA",
    "FICEO",
    "FICHA",
    "FICHE",
    "FICHO",
    "FICHU",
    "FICIS",
    "FICOU",
    "FICTA",
    "FICTO",
    "FICUS",
    "FIDAS",
    "FIDEL",
    "FIDEU",
    "FIDIA",
    "FIDJI",
    "FIDOS",
    "FIEIS",
    "FIEMO",
    "FIESP",
    "FIEZA",
    "FIFAS",
    "FIFES",
    "FIFIA",
    "FIFIS",
    "FIGAI",
    "FIGAM",
    "FIGAR",
    "FIGAS",
    "FIGD.",
    "FIGLE",
    "FIGOS",
    "FIGOU",
    "FIGUE",
    "FILAI",
    "FILAM",
    "FILAO",
    "FILAR",
    "FILAS",
    "FILEA",
    "FILEI",
    "FILEM",
    "FILEO",
    "FILER",
    "FILES",
    "FILHA",
    "FILHE",
    "FILHO",
    "FILIA",
    "FILIE",
    "FILIO",
    "FILMA",
    "FILME",
    "FILMO",
    "FILOS",
    "FILOU",
    "FIMAS",
    "FIMBA",
    "FIMBO",
    "FIMIA",
    "FIMPI",
    "FINAI",
    "FINAL",
    "FINAM",
    "FINAR",
    "FINAS",
    "FINCA",
    "FINCO",
    "FINDA",
    "FINDE",
    "FINDO",
    "FINEI",
    "FINEM",
    "FINES",
    "FINFA",
    "FINFE",
    "FINFO",
    "FINGE",
    "FINGI",
    "FINGO",
    "FINIS",
    "FINJA",
    "FINJO",
    "FINL.",
    "FINOS",
    "FINOU",
    "FINTA",
    "FINTE",
    "FINTO",
    "FIOFO",
    "FIOSE",
    "FIOTA",
    "FIOTE",
    "FIOTO",
    "FIQUE",
    "FIRAM",
    "FIRAS",
    "FIRMA",
    "FIRME",
    "FIRMO",
    "FIRPO",
    "FIRRA",
    "FIRRE",
    "FIRRO",
    "FISAL",
    "FISAS",
    "FISC.",
    "FISCO",
    "FISES",
    "FISGA",
    "FISGO",
    "FISIO",
    "FISL.",
    "FISMA",
    "FITAI",
    "FITAM",
    "FITAO",
    "FITAR",
    "FITAS",
    "FITEI",
    "FITEM",
    "FITES",
    "FITOL",
    "FITOS",
    "FITOU",
    "FIUSA",
    "FIUZA",
    "FIUZO",
    "FIXAI",
    "FIXAM",
    "FIXAR",
    "FIXAS",
    "FIXEI",
    "FIXEM",
    "FIXES",
    "FIXEZ",
    "FIXOS",
    "FIXOU",
    "FIZER",
    "FLACO",
    "FLAM.",
    "FLAMA",
    "FLAME",
    "FLAMO",
    "FLANA",
    "FLANE",
    "FLANO",
    "FLAPE",
    "FLARE",
    "FLASH",
    "FLATA",
    "FLATO",
    "FLAVA",
    "FLAVO",
    "FLEBE",
    "FLEBO",
    "FLEMA",
    "FLEME",
    "FLETE",
    "FLETI",
    "FLEXO",
    "FLIEU",
    "FLIMA",
    "FLIME",
    "FLIMO",
    "FLIPA",
    "FLIPE",
    "FLIPO",
    "FLITA",
    "FLITE",
    "FLITO",
    "FLOCA",
    "FLOCO",
    "FLOGO",
    "FLOME",
    "FLORA",
    "FLORE",
    "FLORI",
    "FLORO",
    "FLOSA",
    "FLOTA",
    "FLOTE",
    "FLOTO",
    "FLOXO",
    "FLOZO",
    "FLUAM",
    "FLUAS",
    "FLUEM",
    "FLUIA",
    "FLUIR",
    "FLUIS",
    "FLUIU",
    "FLUM.",
    "FLUME",
    "FLUOR",
    "FLUSH",
    "FLUTA",
    "FLUV.",
    "FLUXO",
    "FOBAI",
    "FOBAM",
    "FOBAR",
    "FOBAS",
    "FOBEI",
    "FOBEM",
    "FOBES",
    "FOBIA",
    "FOBOS",
    "FOBOU",
    "FOCAI",
    "FOCAL",
    "FOCAM",
    "FOCAO",
    "FOCAR",
    "FOCAS",
    "FOCEU",
    "FOCHO",
    "FOCIO",
    "FOCOS",
    "FOCOU",
    "FOCUS",
    "FODAM",
    "FODAS",
    "FODEI",
    "FODEM",
    "FODER",
    "FODES",
    "FODEU",
    "FODIA",
    "FOETA",
    "FOFAI",
    "FOFAM",
    "FOFAO",
    "FOFAR",
    "FOFAS",
    "FOFEI",
    "FOFEM",
    "FOFES",
    "FOFOS",
    "FOFOU",
    "FOGAL",
    "FOGAO",
    "FOGAR",
    "FOGEM",
    "FOGES",
    "FOGOS",
    "FOIAS",
    "FOICA",
    "FOICE",
    "FOICO",
    "FOIDE",
    "FOILA",
    "FOINA",
    "FOIOS",
    "FOITO",
    "FOJOS",
    "FOLAO",
    "FOLAR",
    "FOLAS",
    "FOLC.",
    "FOLEA",
    "FOLES",
    "FOLGA",
    "FOLGO",
    "FOLH.",
    "FOLHA",
    "FOLHE",
    "FOLHO",
    "FOLIA",
    "FOLIE",
    "FOLIO",
    "FOLIS",
    "FOLOE",
    "FOLS.",
    "FOMAS",
    "FOMES",
    "FOMOS",
    "FONAO",
    "FONAS",
    "FONES",
    "FONIA",
    "FONIX",
    "FONJO",
    "FONON",
    "FONOS",
    "FONTE",
    "FOPAS",
    "FOQUE",
    "FORAL",
    "FORAM",
    "FORAS",
    "FORCA",
    "FORCE",
    "FORCO",
    "FORDE",
    "FORDO",
    "FOREM",
    "FORES",
    "FORIA",
    "FORJA",
    "FORJE",
    "FORJO",
    "FORM.",
    "FORMA",
    "FORME",
    "FORMI",
    "FORMO",
    "FORNA",
    "FORNE",
    "FORNI",
    "FORNO",
    "FOROS",
    "FORRA",
    "FORRE",
    "FORRO",
    "FORT.",
    "FORTE",
    "FORUM",
    "FOSCA",
    "FOSCO",
    "FOSGA",
    "FOSIA",
    "FOSSA",
    "FOSSE",
    "FOSSO",
    "FOSTE",
    "FOTAO",
    "FOTAS",
    "FOTEA",
    "FOTON",
    "FOTOS",
    "FOUCA",
    "FOUCE",
    "FOUCO",
    "FOULA",
    "FOUPA",
    "FOVEA",
    "FOXIM",
    "FOXOS",
    "FOYER",
    "FRAC.",
    "FRACA",
    "FRACO",
    "FRADA",
    "FRADE",
    "FRADO",
    "FRAG.",
    "FRAGA",
    "FRAGO",
    "FRAMA",
    "FRAPE",
    "FRASE",
    "FREAI",
    "FREAR",
    "FREEI",
    "FREG.",
    "FREGA",
    "FREGE",
    "FREGO",
    "FREIA",
    "FREIE",
    "FREIO",
    "FREIS",
    "FREMA",
    "FREME",
    "FREMI",
    "FREMO",
    "FRENA",
    "FRENE",
    "FRENI",
    "FRENO",
    "FREON",
    "FREOU",
    "FREQ.",
    "FRESA",
    "FRESE",
    "FRESO",
    "FRETA",
    "FRETE",
    "FRETO",
    "FREUD",
    "FREVA",
    "FREVE",
    "FREVO",
    "FRIAL",
    "FRIAS",
    "FRIEZ",
    "FRIG.",
    "FRIGE",
    "FRIGI",
    "FRIGO",
    "FRIJA",
    "FRIJO",
    "FRILA",
    "FRIMA",
    "FRIMO",
    "FRINE",
    "FRINO",
    "FRIOS",
    "FRISA",
    "FRISE",
    "FRISO",
    "FRITA",
    "FRITE",
    "FRITO",
    "FRIUL",
    "FRIXO",
    "FROCA",
    "FROCO",
    "FROGE",
    "FROIS",
    "FROLA",
    "FROLE",
    "FROLO",
    "FROTA",
    "FROZO",
    "FRUAM",
    "FRUAS",
    "FRUEM",
    "FRUIA",
    "FRUIR",
    "FRUIS",
    "FRUIU",
    "FRURO",
    "FRUT.",
    "FRUTA",
    "FRUTE",
    "FRUTO",
    "FRUXU",
    "FT.P.",
    "FUBAS",
    "FUCAI",
    "FUCAL",
    "FUCAM",
    "FUCAR",
    "FUCAS",
    "FUCEA",
    "FUCEI",
    "FUCEM",
    "FUCES",
    "FUCIA",
    "FUCIM",
    "FUCOS",
    "FUCOU",
    "FUCUS",
    "FUETA",
    "FUFAS",
    "FUFIA",
    "FUFIO",
    "FUFUS",
    "FUGAI",
    "FUGAM",
    "FUGAO",
    "FUGAR",
    "FUGAS",
    "FUGAZ",
    "FUGEL",
    "FUGIA",
    "FUGIR",
    "FUGIS",
    "FUGIU",
    "FUGOU",
    "FUGUE",
    "FUINS",
    "FUJAM",
    "FUJAO",
    "FUJAS",
    "FUJES",
    "FUJIR",
    "FULAO",
    "FULAR",
    "FULAS",
    "FULBE",
    "FULDA",
    "FULER",
    "FULGE",
    "FULGI",
    "FULHA",
    "FULIX",
    "FULJA",
    "FULJO",
    "FULOA",
    "FULOE",
    "FULOO",
    "FULOS",
    "FULV.",
    "FULVA",
    "FULVO",
    "FUMAI",
    "FUMAL",
    "FUMAM",
    "FUMAO",
    "FUMAR",
    "FUMAS",
    "FUMBO",
    "FUMEA",
    "FUMEI",
    "FUMEM",
    "FUMEO",
    "FUMES",
    "FUMOS",
    "FUMOU",
    "FUNAI",
    "FUNAM",
    "FUNAR",
    "FUNAS",
    "FUNCA",
    "FUNCE",
    "FUNCO",
    "FUND.",
    "FUNDA",
    "FUNDE",
    "FUNDI",
    "FUNDO",
    "FUNEI",
    "FUNEL",
    "FUNEM",
    "FUNES",
    "FUNEU",
    "FUNFA",
    "FUNFE",
    "FUNFO",
    "FUNGA",
    "FUNGE",
    "FUNGO",
    "FUNHA",
    "FUNHE",
    "FUNHI",
    "FUNHO",
    "FUNIL",
    "FUNIS",
    "FUNJE",
    "FUNOU",
    "FUOES",
    "FUQUE",
    "FURAI",
    "FURAL",
    "FURAM",
    "FURAO",
    "FURAR",
    "FURAS",
    "FURCA",
    "FURCO",
    "FURDA",
    "FUREI",
    "FUREM",
    "FURES",
    "FURIA",
    "FURIL",
    "FURIS",
    "FURNA",
    "FUROA",
    "FUROE",
    "FUROO",
    "FUROR",
    "FUROS",
    "FUROU",
    "FURTA",
    "FURTE",
    "FURTO",
    "FUSAI",
    "FUSAM",
    "FUSAO",
    "FUSAR",
    "FUSAS",
    "FUSCA",
    "FUSCO",
    "FUSEI",
    "FUSEL",
    "FUSEM",
    "FUSES",
    "FUSGA",
    "FUSGO",
    "FUSIL",
    "FUSOR",
    "FUSOS",
    "FUSOU",
    "FUSTA",
    "FUSTE",
    "FUSTO",
    "FUTAS",
    "FUTB.",
    "FUTES",
    "FUTIL",
    "FUTRE",
    "FUTUM",
    "FUZIL",
    "FUZIS",
    "FUZOS",
    "FUZUE",
    "GABAI",
    "GABAM",
    "GABAO",
    "GABAR",
    "GABAS",
    "GABEI",
    "GABEM",
    "GABES",
    "GABEU",
    "GABIA",
    "GABIE",
    "GABIO",
    "GABOU",
    "GABRO",
    "GACHA",
    "GACHO",
    "GADAO",
    "GADAR",
    "GADES",
    "GADOS",
    "GAEIS",
    "GAEL.",
    "GAETA",
    "GAFAI",
    "GAFAM",
    "GAFAR",
    "GAFAS",
    "GAFEI",
    "GAFEM",
    "GAFES",
    "GAFIO",
    "GAFOU",
    "GAGAO",
    "GAGAS",
    "GAGAU",
    "GAGES",
    "GAGOS",
    "GAGUE",
    "GAIAI",
    "GAIAL",
    "GAIAM",
    "GAIAR",
    "GAIAS",
    "GAIEI",
    "GAIEM",
    "GAIES",
    "GAIOS",
    "GAIOU",
    "GAIPA",
    "GAIPO",
    "GAIRO",
    "GAITA",
    "GAITE",
    "GAITO",
    "GAIVA",
    "GAIVE",
    "GAIVO",
    "GAIZA",
    "GAJAI",
    "GAJAM",
    "GAJAO",
    "GAJAR",
    "GAJAS",
    "GAJEI",
    "GAJEM",
    "GAJES",
    "GAJIS",
    "GAJOS",
    "GAJOU",
    "GALAI",
    "GALAM",
    "GALAO",
    "GALAR",
    "GALAS",
    "GALAX",
    "GALBA",
    "GALBO",
    "GALDO",
    "GALEA",
    "GALEI",
    "GALEM",
    "GALEO",
    "GALES",
    "GALGA",
    "GALGO",
    "GALHA",
    "GALHO",
    "GALIA",
    "GALIO",
    "GALIS",
    "GALOA",
    "GALOE",
    "GALOO",
    "GALOS",
    "GALOU",
    "GALRA",
    "GALRE",
    "GALRO",
    "GALV.",
    "GAMAI",
    "GAMAM",
    "GAMAO",
    "GAMAR",
    "GAMAS",
    "GAMBA",
    "GAMBO",
    "GAMBU",
    "GAMEI",
    "GAMEM",
    "GAMES",
    "GAMIA",
    "GAMOS",
    "GAMOU",
    "GANAM",
    "GANAS",
    "GANAU",
    "GANCA",
    "GANCE",
    "GANCO",
    "GANDA",
    "GANDO",
    "GANDU",
    "GANEM",
    "GANES",
    "GANFA",
    "GANFE",
    "GANFO",
    "GANGA",
    "GANGO",
    "GANHA",
    "GANHE",
    "GANHO",
    "GANIA",
    "GANIM",
    "GANIR",
    "GANIS",
    "GANIU",
    "GANIZ",
    "GANJA",
    "GANSA",
    "GANSO",
    "GANTA",
    "GANTO",
    "GANZA",
    "GANZE",
    "GANZI",
    "GANZO",
    "GAPES",
    "GARAO",
    "GARAU",
    "GARBO",
    "GARCA",
    "GARCO",
    "GARDE",
    "GARES",
    "GARFA",
    "GARFE",
    "GARFO",
    "GARGA",
    "GARGO",
    "GARIS",
    "GARNA",
    "GARNE",
    "GARNI",
    "GARNO",
    "GAROA",
    "GAROE",
    "GAROO",
    "GAROS",
    "GARRA",
    "GARRE",
    "GARRI",
    "GARRO",
    "GARUA",
    "GARUE",
    "GARUO",
    "GARUS",
    "GARVO",
    "GASC.",
    "GASCA",
    "GASEA",
    "GASEO",
    "GASES",
    "GASMA",
    "GASME",
    "GASMO",
    "GASPA",
    "GASTA",
    "GASTE",
    "GASTO",
    "GATAI",
    "GATAL",
    "GATAM",
    "GATAO",
    "GATAR",
    "GATAS",
    "GATEA",
    "GATEI",
    "GATEM",
    "GATES",
    "GATEU",
    "GATIL",
    "GATIS",
    "GATOS",
    "GATOU",
    "GATUM",
    "GAUDA",
    "GAUDE",
    "GAUDO",
    "GAUL.",
    "GAULO",
    "GAURA",
    "GAURO",
    "GAUSS",
    "GAUZA",
    "GAVAI",
    "GAVAM",
    "GAVAO",
    "GAVAR",
    "GAVAS",
    "GAVEA",
    "GAVEI",
    "GAVEM",
    "GAVES",
    "GAVOU",
    "GAZAL",
    "GAZAO",
    "GAZAR",
    "GAZAS",
    "GAZEA",
    "GAZEL",
    "GAZEO",
    "GAZES",
    "GAZEU",
    "GAZIA",
    "GAZIL",
    "GAZIO",
    "GAZIS",
    "GAZOS",
    "GAZUA",
    "GAZUE",
    "GAZUO",
    "GEADA",
    "GEADO",
    "GEAIS",
    "GEAMO",
    "GEARA",
    "GEARO",
    "GEASE",
    "GEAVA",
    "GEBAI",
    "GEBAL",
    "GEBAM",
    "GEBAR",
    "GEBAS",
    "GEBEI",
    "GEBEM",
    "GEBES",
    "GEBIA",
    "GEBOU",
    "GEBRA",
    "GEBRE",
    "GEBRO",
    "GEBUS",
    "GECOS",
    "GEEIS",
    "GEEMO",
    "GEENA",
    "GEESA",
    "GEIAM",
    "GEIAS",
    "GEIEM",
    "GEIES",
    "GEINA",
    "GELAI",
    "GELAM",
    "GELAR",
    "GELAS",
    "GELBA",
    "GELDA",
    "GELEI",
    "GELEM",
    "GELES",
    "GELFA",
    "GELFO",
    "GELHA",
    "GELIO",
    "GELIS",
    "GELMA",
    "GELMO",
    "GELOS",
    "GELOU",
    "GELVA",
    "GEMAI",
    "GEMAM",
    "GEMAR",
    "GEMAS",
    "GEMEA",
    "GEMEI",
    "GEMEM",
    "GEMEO",
    "GEMER",
    "GEMES",
    "GEMEU",
    "GEMIA",
    "GEMIE",
    "GEMIO",
    "GEMOU",
    "GENAL",
    "GENAS",
    "GENES",
    "GENGE",
    "GENIA",
    "GENIO",
    "GENOA",
    "GENOL",
    "GENOS",
    "GENRO",
    "GENTE",
    "GEOD.",
    "GEODE",
    "GEODO",
    "GEOF.",
    "GEOG.",
    "GEOL.",
    "GEOM.",
    "GEON.",
    "GEOSA",
    "GEOSO",
    "GERAI",
    "GERAL",
    "GERAM",
    "GERAR",
    "GERAS",
    "GERBI",
    "GERBO",
    "GEREI",
    "GEREM",
    "GERES",
    "GERIA",
    "GERIR",
    "GERIS",
    "GERIU",
    "GERM.",
    "GERME",
    "GERMO",
    "GERNO",
    "GERON",
    "GEROU",
    "GERRA",
    "GERRE",
    "GESIO",
    "GESOS",
    "GESSA",
    "GESSE",
    "GESSO",
    "GESTA",
    "GESTE",
    "GESTO",
    "GETAS",
    "GETEU",
    "GIAUR",
    "GIBAO",
    "GIBAS",
    "GIBEA",
    "GIBIO",
    "GIBIS",
    "GIBLI",
    "GIBOS",
    "GICLE",
    "GICLO",
    "GIDEL",
    "GIDIO",
    "GIDOS",
    "GIGAS",
    "GIGIA",
    "GIGIS",
    "GIGLE",
    "GIGOS",
    "GILAS",
    "GILDA",
    "GILDO",
    "GILIA",
    "GIMAO",
    "GIMAS",
    "GIMBO",
    "GIMEL",
    "GIMOS",
    "GINCO",
    "GINDO",
    "GINES",
    "GINGA",
    "GINGE",
    "GINGO",
    "GINJA",
    "GIOTA",
    "GIPSO",
    "GIRAI",
    "GIRAM",
    "GIRAO",
    "GIRAR",
    "GIRAS",
    "GIRAU",
    "GIREI",
    "GIREM",
    "GIRES",
    "GIREU",
    "GIRIA",
    "GIRIO",
    "GIROS",
    "GIROU",
    "GIRUA",
    "GITAS",
    "GITEU",
    "GIZAI",
    "GIZAM",
    "GIZAR",
    "GIZAS",
    "GIZEI",
    "GIZEM",
    "GIZES",
    "GIZOU",
    "GLACA",
    "GLACE",
    "GLACO",
    "GLANS",
    "GLARO",
    "GLEBA",
    "GLEIA",
    "GLENA",
    "GLETE",
    "GLIAL",
    "GLIAS",
    "GLIDE",
    "GLIFO",
    "GLINO",
    "GLOBA",
    "GLOBO",
    "GLOES",
    "GLOMO",
    "GLONS",
    "GLOSA",
    "GLOSE",
    "GLOSO",
    "GLOT.",
    "GLOTA",
    "GLOTE",
    "GLUAO",
    "GLUMA",
    "GLUON",
    "GLUTE",
    "GNETO",
    "GNOM.",
    "GNOMA",
    "GNOMO",
    "GNOSE",
    "GOANA",
    "GOANE",
    "GOANO",
    "GOBIA",
    "GOBIO",
    "GOBOS",
    "GOCHA",
    "GOCHO",
    "GODAO",
    "GODAS",
    "GODEL",
    "GODES",
    "GODIA",
    "GODIE",
    "GODIO",
    "GODOI",
    "GODOS",
    "GODOY",
    "GOELA",
    "GOELE",
    "GOELO",
    "GOESA",
    "GOETE",
    "GOFER",
    "GOFOS",
    "GOFRA",
    "GOFRE",
    "GOFRO",
    "GOGAS",
    "GOGOS",
    "GOIAI",
    "GOIAM",
    "GOIAR",
    "GOIAS",
    "GOIEI",
    "GOIEM",
    "GOIES",
    "GOIIM",
    "GOIOS",
    "GOIOU",
    "GOITA",
    "GOITE",
    "GOITI",
    "GOITO",
    "GOIVA",
    "GOIVE",
    "GOIVO",
    "GOJAS",
    "GOJOS",
    "GOLAI",
    "GOLAM",
    "GOLAR",
    "GOLAS",
    "GOLDI",
    "GOLEA",
    "GOLEI",
    "GOLEM",
    "GOLES",
    "GOLFA",
    "GOLFE",
    "GOLFO",
    "GOLGA",
    "GOLIM",
    "GOLIS",
    "GOLOS",
    "GOLOU",
    "GOLPA",
    "GOLPE",
    "GOLPO",
    "GOMAI",
    "GOMAL",
    "GOMAM",
    "GOMAR",
    "GOMAS",
    "GOMBA",
    "GOMBE",
    "GOMBO",
    "GOMEI",
    "GOMEM",
    "GOMES",
    "GOMIA",
    "GOMIL",
    "GOMIS",
    "GOMOA",
    "GOMOE",
    "GOMOO",
    "GOMOR",
    "GOMOS",
    "GOMOU",
    "GONAI",
    "GONAM",
    "GONAR",
    "GONAS",
    "GONDA",
    "GONDE",
    "GONDI",
    "GONDO",
    "GONEI",
    "GONEL",
    "GONEM",
    "GONES",
    "GONGA",
    "GONGO",
    "GONIO",
    "GONIS",
    "GONOU",
    "GONUS",
    "GONZO",
    "GORAI",
    "GORAL",
    "GORAM",
    "GORAO",
    "GORAR",
    "GORAS",
    "GORAZ",
    "GORCA",
    "GORDA",
    "GORDO",
    "GOREI",
    "GOREM",
    "GORES",
    "GORGA",
    "GORJA",
    "GORNA",
    "GORNE",
    "GORNI",
    "GORNO",
    "GOROU",
    "GORRA",
    "GORRE",
    "GORRO",
    "GOSBA",
    "GOSMA",
    "GOSME",
    "GOSMO",
    "GOSPE",
    "GOSTA",
    "GOSTE",
    "GOSTO",
    "GOTAO",
    "GOTAS",
    "GOTEA",
    "GOTES",
    "GOTOS",
    "GOTRA",
    "GOTRI",
    "GOUDA",
    "GOUGA",
    "GOUGO",
    "GOULI",
    "GOULO",
    "GOURA",
    "GOURO",
    "GOUVE",
    "GOVAR",
    "GOVAS",
    "GOXOS",
    "GOZAI",
    "GOZAM",
    "GOZAO",
    "GOZAR",
    "GOZAS",
    "GOZEI",
    "GOZEM",
    "GOZES",
    "GOZIM",
    "GOZOS",
    "GOZOU",
    "GRAAL",
    "GRABE",
    "GRACA",
    "GRACO",
    "GRADA",
    "GRADE",
    "GRADO",
    "GRAF.",
    "GRAFA",
    "GRAFE",
    "GRAFO",
    "GRAIA",
    "GRAIE",
    "GRAIO",
    "GRAIS",
    "GRAJO",
    "GRAMA",
    "GRAME",
    "GRAMO",
    "GRANA",
    "GRAND",
    "GRANE",
    "GRANI",
    "GRANO",
    "GRANS",
    "GRAOS",
    "GRAPA",
    "GRATA",
    "GRATO",
    "GRAUS",
    "GRAV.",
    "GRAVA",
    "GRAVE",
    "GRAVO",
    "GRAXA",
    "GRAXO",
    "GREBE",
    "GREBO",
    "GRECO",
    "GREDA",
    "GREDE",
    "GREGA",
    "GREGE",
    "GREGO",
    "GREIA",
    "GREIS",
    "GRELA",
    "GRELE",
    "GRELO",
    "GREMO",
    "GRENA",
    "GREPO",
    "GRETA",
    "GRETE",
    "GRETO",
    "GREUS",
    "GREVA",
    "GREVE",
    "GRIAS",
    "GRIFA",
    "GRIFE",
    "GRIFO",
    "GRILA",
    "GRILE",
    "GRILI",
    "GRILO",
    "GRIMA",
    "GRIPA",
    "GRIPE",
    "GRIPO",
    "GRISA",
    "GRISE",
    "GRISO",
    "GRISU",
    "GRITA",
    "GRITE",
    "GRITO",
    "GRIVA",
    "GRIVE",
    "GRIVO",
    "GRODE",
    "GROES",
    "GROIR",
    "GROLA",
    "GROLE",
    "GROLO",
    "GROMA",
    "GROME",
    "GROMO",
    "GROOM",
    "GROSA",
    "GROSE",
    "GROSO",
    "GROTA",
    "GROUS",
    "GROXO",
    "GRUAL",
    "GRUAM",
    "GRUAS",
    "GRUAU",
    "GRUDA",
    "GRUDE",
    "GRUDO",
    "GRUEM",
    "GRUIA",
    "GRUIM",
    "GRUIR",
    "GRUIS",
    "GRUIU",
    "GRUMA",
    "GRUME",
    "GRUMO",
    "GRUNA",
    "GRUPA",
    "GRUPE",
    "GRUPO",
    "GRUTA",
    "GUACA",
    "GUACO",
    "GUACU",
    "GUADA",
    "GUADO",
    "GUAIA",
    "GUAIE",
    "GUAIO",
    "GUAIS",
    "GUAIU",
    "GUAJA",
    "GUAJE",
    "GUAJU",
    "GUAMA",
    "GUAME",
    "GUANA",
    "GUANO",
    "GUAPE",
    "GUAPO",
    "GUARA",
    "GUARE",
    "GUARI",
    "GUARO",
    "GUARU",
    "GUAT.",
    "GUATO",
    "GUAUS",
    "GUAXE",
    "GUAXI",
    "GUAXO",
    "GUAZA",
    "GUAZE",
    "GUAZO",
    "GUBRO",
    "GUDES",
    "GUDUS",
    "GUEBA",
    "GUEBO",
    "GUEDE",
    "GUEIS",
    "GUEJA",
    "GUELI",
    "GUERA",
    "GUERE",
    "GUERI",
    "GUETA",
    "GUETE",
    "GUETO",
    "GUEVE",
    "GUEXA",
    "GUGUS",
    "GUIAI",
    "GUIAM",
    "GUIAO",
    "GUIAR",
    "GUIAS",
    "GUIBA",
    "GUIBE",
    "GUIBO",
    "GUICA",
    "GUICO",
    "GUIDA",
    "GUIDE",
    "GUIDO",
    "GUIEI",
    "GUIEM",
    "GUIES",
    "GUIFE",
    "GUIGA",
    "GUIGO",
    "GUIN.",
    "GUINA",
    "GUINE",
    "GUINO",
    "GUIOU",
    "GUIPA",
    "GUIRA",
    "GUIRI",
    "GUISA",
    "GUISE",
    "GUISO",
    "GUITA",
    "GUITI",
    "GUITO",
    "GUIZA",
    "GUIZE",
    "GUIZO",
    "GULAR",
    "GULAS",
    "GULUA",
    "GULUE",
    "GUMBA",
    "GUMBE",
    "GUMBO",
    "GUMES",
    "GUNAS",
    "GUNDA",
    "GUNDE",
    "GUNDI",
    "GUNDU",
    "GUNES",
    "GUNFA",
    "GUNFE",
    "GUNFO",
    "GUNGA",
    "GUNGO",
    "GUNHO",
    "GUNJI",
    "GUNTO",
    "GURAL",
    "GURAS",
    "GURBI",
    "GURCA",
    "GURDA",
    "GURDE",
    "GURIA",
    "GURIS",
    "GURMA",
    "GURNA",
    "GURNE",
    "GURNI",
    "GURNO",
    "GUROS",
    "GURRA",
    "GURUS",
    "GUSAS",
    "GUSLA",
    "GUSOS",
    "GUSTA",
    "GUSTE",
    "GUSTO",
    "GUTAI",
    "GUTAM",
    "GUTAR",
    "GUTAS",
    "GUTEI",
    "GUTEM",
    "GUTES",
    "GUTOS",
    "GUTOU",
    "GUTT.",
    "GUVOS",
    "GUXES",
    "GUZOS",
    "HABES",
    "HABIA",
    "HABIL",
    "HABOR",
    "HABUR",
    "HACAS",
    "HACER",
    "HACOS",
    "HACUS",
    "HADAR",
    "HADEN",
    "HADJI",
    "HADOM",
    "HAGAR",
    "HAGRI",
    "HAIDE",
    "HAIFA",
    "HAITI",
    "HAJAM",
    "HAJAR",
    "HAJAS",
    "HAJES",
    "HAJIS",
    "HAJUL",
    "HALAS",
    "HALDE",
    "HALER",
    "HALEX",
    "HALFE",
    "HALIA",
    "HALIM",
    "HALIS",
    "HALMA",
    "HALOS",
    "HALUL",
    "HALUX",
    "HALVA",
    "HAMAL",
    "HAMAS",
    "HAMBA",
    "HAMIM",
    "HAMOM",
    "HAMSA",
    "HANDA",
    "HANES",
    "HANGO",
    "HANHA",
    "HANIA",
    "HANIO",
    "HANOA",
    "HANOI",
    "HANSA",
    "HAOMA",
    "HAPAX",
    "HAPLO",
    "HAPTO",
    "HARAO",
    "HARAS",
    "HARDA",
    "HAREM",
    "HARIM",
    "HARIS",
    "HARLO",
    "HAROE",
    "HARPA",
    "HARPE",
    "HARPO",
    "HARTO",
    "HARTZ",
    "HASEM",
    "HASOR",
    "HAST.",
    "HASTA",
    "HASTE",
    "HASUM",
    "HATIL",
    "HATIM",
    "HAUCA",
    "HAURA",
    "HAURE",
    "HAURI",
    "HAURO",
    "HAVAI",
    "HAVEI",
    "HAVER",
    "HAVIA",
    "HAZAR",
    "HAZEL",
    "HAZER",
    "HAZIM",
    "HAZOR",
    "HEARD",
    "HEBD.",
    "HEBER",
    "HEBR.",
    "HEBRO",
    "HECTO",
    "HEDIA",
    "HEDRA",
    "HEDUO",
    "HEDUS",
    "HEFER",
    "HEGAR",
    "HEGEL",
    "HEIDA",
    "HEIDE",
    "HEIDI",
    "HELAM",
    "HELAS",
    "HELBA",
    "HELEM",
    "HELEN",
    "HELEZ",
    "HELGA",
    "HELIA",
    "HELIO",
    "HELIX",
    "HELM.",
    "HELMA",
    "HELOM",
    "HEMAL",
    "HEMAM",
    "HEMES",
    "HEMIA",
    "HEMIS",
    "HEMOR",
    "HEMOS",
    "HENAS",
    "HENDA",
    "HENOC",
    "HENOS",
    "HENRI",
    "HENRY",
    "HEPAS",
    "HEPIA",
    "HEPTA",
    "HERAS",
    "HERB.",
    "HERDA",
    "HERDE",
    "HERDO",
    "HERES",
    "HEREU",
    "HERIL",
    "HERIS",
    "HERMA",
    "HEROI",
    "HEROO",
    "HEROS",
    "HERP.",
    "HERSE",
    "HERTA",
    "HERTZ",
    "HESLI",
    "HESSE",
    "HETAI",
    "HETAM",
    "HETAR",
    "HETAS",
    "HETEI",
    "HETEM",
    "HETES",
    "HETEU",
    "HETMA",
    "HETOL",
    "HETOU",
    "HEVEA",
    "HEVEU",
    "HEXAL",
    "HEXIL",
    "HEXIS",
    "HEXOL",
    "HEZAS",
    "HIAIS",
    "HIALA",
    "HIALO",
    "HIATO",
    "HIAVA",
    "HIBR.",
    "HICAS",
    "HICOS",
    "HICSA",
    "HICSO",
    "HIDAI",
    "HIDEO",
    "HIDNO",
    "HIDR.",
    "HIDRA",
    "HIDRO",
    "HIENA",
    "HIER.",
    "HIERA",
    "HIERO",
    "HIETO",
    "HIFAL",
    "HIFAS",
    "HIFEN",
    "HIGGS",
    "HIGR.",
    "HIGRA",
    "HIGRO",
    "HILAL",
    "HILAR",
    "HILAS",
    "HILDA",
    "HILDE",
    "HILDO",
    "HILEL",
    "HILEM",
    "HILEU",
    "HILIA",
    "HILMI",
    "HILOS",
    "HIMBA",
    "HIMEN",
    "HINAM",
    "HINAS",
    "HIND.",
    "HINDI",
    "HINDU",
    "HINEM",
    "HINES",
    "HINGA",
    "HINGO",
    "HINIA",
    "HINIR",
    "HINIS",
    "HINIU",
    "HINOM",
    "HINOS",
    "HIPER",
    "HIPNO",
    "HIPOS",
    "HIPTA",
    "HIPTE",
    "HIRAM",
    "HIRAO",
    "HIRAX",
    "HIRCO",
    "HIRIA",
    "HIROM",
    "HIRPA",
    "HIRPO",
    "HIRTA",
    "HIRTE",
    "HIRTO",
    "HISP.",
    "HISPA",
    "HISPE",
    "HISPO",
    "HIST.",
    "HISTO",
    "HOARE",
    "HOBBY",
    "HOBOS",
    "HODES",
    "HOFRA",
    "HOGLA",
    "HOIAS",
    "HOLAO",
    "HOLAS",
    "HOLCO",
    "HOLOM",
    "HOLOS",
    "HOMAO",
    "HOMBA",
    "HOMBO",
    "HOMEA",
    "HOMEM",
    "HOMEO",
    "HOMER",
    "HOMIA",
    "HOMOS",
    "HOMUM",
    "HONAS",
    "HOND.",
    "HONDA",
    "HONGA",
    "HONGO",
    "HONOR",
    "HONOS",
    "HONRA",
    "HONRE",
    "HONRO",
    "HOPEA",
    "HOPIA",
    "HOPIS",
    "HOPLO",
    "HOPOS",
    "HORAI",
    "HORAL",
    "HORAM",
    "HORAO",
    "HORAR",
    "HORAS",
    "HORDA",
    "HOREI",
    "HOREM",
    "HORES",
    "HOREU",
    "HORMA",
    "HOROM",
    "HOROU",
    "HORRA",
    "HORSA",
    "HORT.",
    "HORTA",
    "HORTE",
    "HORTO",
    "HOSCO",
    "HOSEA",
    "HOSIS",
    "HOSSI",
    "HOSTA",
    "HOSTE",
    "HOTAO",
    "HOTEL",
    "HOTES",
    "HOTIR",
    "HOURI",
    "HOUVE",
    "HOVAS",
    "HOVEA",
    "HOXAS",
    "HOYAS",
    "HTTPS",
    "HUALA",
    "HUAMA",
    "HUANE",
    "HUARI",
    "HUBIS",
    "HUBOS",
    "HUDES",
    "HUELA",
    "HUIDO",
    "HUILA",
    "HUINI",
    "HUIOS",
    "HULAS",
    "HULDA",
    "HULHA",
    "HUMAM",
    "HUMBA",
    "HUMBE",
    "HUMBI",
    "HUMBO",
    "HUMEA",
    "HUMIL",
    "HUMOR",
    "HUMOS",
    "HUMUS",
    "HUNG.",
    "HUNGO",
    "HUNOS",
    "HUNTA",
    "HUPDA",
    "HUPIM",
    "HURAI",
    "HURAO",
    "HURAS",
    "HURIS",
    "HURRA",
    "HUSAI",
    "HUSAM",
    "HUSAO",
    "HUSAS",
    "HUSIM",
    "HUSMA",
    "HUSNA",
    "HUTUS",
    "IACAS",
    "IACIO",
    "IACRI",
    "IACUS",
    "IAERA",
    "IAFIS",
    "IAGAS",
    "IAGOS",
    "IAGUA",
    "IAIAS",
    "IAMAS",
    "IAMBE",
    "IAMBO",
    "IAMEM",
    "IAMEU",
    "IAMOS",
    "IANAM",
    "IANDE",
    "IANDU",
    "IANGA",
    "IANSA",
    "IANVO",
    "IAPAS",
    "IAPUS",
    "IAQUE",
    "IARAS",
    "IASIO",
    "IASIS",
    "IASOS",
    "IATAI",
    "IATAL",
    "IATES",
    "IATIO",
    "IATRO",
    "IAUMA",
    "IAUPE",
    "IAUVA",
    "IAUVO",
    "IBALE",
    "IBAMA",
    "IBARO",
    "IBASE",
    "IBATE",
    "IBEJI",
    "IBEMA",
    "IBER.",
    "IBERA",
    "IBERE",
    "IBERO",
    "IBIAI",
    "IBIAM",
    "IBICE",
    "IBID.",
    "IBINS",
    "IBIRA",
    "IBIRI",
    "IBIRO",
    "IBLAS",
    "IBLEA",
    "IBOGA",
    "IBOPE",
    "IBOZA",
    "IBSAO",
    "IBSEN",
    "IBUNS",
    "ICACO",
    "ICACU",
    "ICADA",
    "ICADO",
    "ICAIS",
    "ICALA",
    "ICAMO",
    "ICANA",
    "ICARA",
    "ICARO",
    "ICATU",
    "ICAVA",
    "ICBAL",
    "ICEIS",
    "ICELO",
    "ICEMO",
    "ICENA",
    "ICENO",
    "ICHAO",
    "ICICA",
    "ICIOS",
    "ICIPO",
    "ICLES",
    "ICOLO",
    "ICOMO",
    "ICONE",
    "ICONO",
    "ICORE",
    "ICTAL",
    "ICTIO",
    "ICTIS",
    "ICTOS",
    "ICTUS",
    "ICUNS",
    "ICURE",
    "IDADE",
    "IDALA",
    "IDATE",
    "IDBAS",
    "IDEAI",
    "IDEAL",
    "IDEAR",
    "IDEEI",
    "IDEIA",
    "IDEIE",
    "IDEIO",
    "IDEOU",
    "IDIAS",
    "IDIOS",
    "IDOL.",
    "IDOLA",
    "IDOLO",
    "IDOSA",
    "IDOSE",
    "IDOSO",
    "IDRIA",
    "IDROL",
    "IDUNA",
    "IEIXA",
    "IELMO",
    "IEMEN",
    "IENES",
    "IENTO",
    "IERAS",
    "IETAS",
    "IETIM",
    "IETOS",
    "IFATA",
    "IFOIS",
    "IGABO",
    "IGACI",
    "IGALA",
    "IGAPE",
    "IGAPO",
    "IGARA",
    "IGBAS",
    "IGBIM",
    "IGBIS",
    "IGBOS",
    "IGDES",
    "IGEAL",
    "IGINO",
    "IGLUS",
    "IGNEA",
    "IGNEO",
    "IGOGA",
    "IGUAI",
    "IGUAL",
    "IGUAR",
    "IGUEI",
    "IGUEM",
    "IGUES",
    "IGUIS",
    "IGUOU",
    "IGUPA",
    "IIABA",
    "IICAI",
    "IINIS",
    "IINXE",
    "IIRSA",
    "IJACI",
    "IJEBU",
    "IJEXA",
    "IJOCO",
    "IJUIS",
    "ILADA",
    "ILADO",
    "ILAIS",
    "ILAMA",
    "ILAMO",
    "ILANA",
    "ILARA",
    "ILAVA",
    "ILDAS",
    "ILDOS",
    "ILEAL",
    "ILEIS",
    "ILELE",
    "ILEMO",
    "ILENA",
    "ILEON",
    "ILEOS",
    "ILESA",
    "ILESE",
    "ILESO",
    "ILEUS",
    "ILHAI",
    "ILHAL",
    "ILHAM",
    "ILHAR",
    "ILHAS",
    "ILHEI",
    "ILHEM",
    "ILHES",
    "ILHEU",
    "ILHOA",
    "ILHOS",
    "ILHOU",
    "ILIAS",
    "ILICA",
    "ILICE",
    "ILICO",
    "ILIDA",
    "ILIDE",
    "ILIDI",
    "ILIDO",
    "ILION",
    "ILIOS",
    "ILIPE",
    "ILITA",
    "ILITE",
    "ILIUM",
    "ILIZA",
    "ILMO.",
    "ILONA",
    "ILOTA",
    "ILSON",
    "ILTON",
    "ILTRA",
    "ILUCA",
    "ILUDA",
    "ILUDE",
    "ILUDI",
    "ILUDO",
    "ILUM.",
    "ILUSA",
    "ILUSE",
    "ILUSI",
    "ILUSO",
    "IMAGO",
    "IMALA",
    "IMAME",
    "IMAMO",
    "IMANA",
    "IMANE",
    "IMANO",
    "IMAZA",
    "IMAZE",
    "IMAZO",
    "IMBAS",
    "IMBAU",
    "IMBES",
    "IMBOA",
    "IMBUA",
    "IMBUI",
    "IMBUO",
    "IMBUS",
    "IMEME",
    "IMENE",
    "IMIDA",
    "IMIDO",
    "IMIGO",
    "IMINA",
    "IMIT.",
    "IMITA",
    "IMITE",
    "IMITI",
    "IMITO",
    "IMOLA",
    "IMOLE",
    "IMOLO",
    "IMOTO",
    "IMPAI",
    "IMPAM",
    "IMPAR",
    "IMPAS",
    "IMPEI",
    "IMPEM",
    "IMPES",
    "IMPIA",
    "IMPIO",
    "IMPIS",
    "IMPOE",
    "IMPOL",
    "IMPOR",
    "IMPOS",
    "IMPOU",
    "IMPR.",
    "IMPUS",
    "IMUNE",
    "IMUNO",
    "IMUTA",
    "IMUTE",
    "IMUTO",
    "INACO",
    "INAIA",
    "INAJA",
    "INAJE",
    "INALA",
    "INALE",
    "INALO",
    "INAMA",
    "INAME",
    "INAMU",
    "INANA",
    "INANE",
    "INANI",
    "INANO",
    "INARA",
    "INARE",
    "INARI",
    "INATA",
    "INATO",
    "INAYA",
    "INCAI",
    "INCAM",
    "INCAR",
    "INCAS",
    "INCEI",
    "INCEM",
    "INCES",
    "INCHA",
    "INCHE",
    "INCHO",
    "INCOA",
    "INCOE",
    "INCOO",
    "INCOU",
    "INCRA",
    "INCRO",
    "INCS.",
    "INDAS",
    "INDEX",
    "INDEZ",
    "INDIA",
    "INDIO",
    "INDIS",
    "INDJO",
    "INDOL",
    "INDRI",
    "INDUA",
    "INDUZ",
    "INERA",
    "INERE",
    "INERI",
    "INERO",
    "INEXA",
    "INEXO",
    "INFER",
    "INFL.",
    "INFLA",
    "INFLE",
    "INFLO",
    "INFRA",
    "INGAI",
    "INGAS",
    "INGEA",
    "INGES",
    "INGL.",
    "INGOS",
    "INGRE",
    "INGUA",
    "INGUE",
    "INHES",
    "INHUA",
    "INIAS",
    "INIBA",
    "INIBE",
    "INIBI",
    "INIBO",
    "INION",
    "INIOS",
    "INITA",
    "INITE",
    "INJOU",
    "INLAS",
    "INOMA",
    "INOPE",
    "INOVA",
    "INOVE",
    "INOVO",
    "INPUT",
    "INSC.",
    "INST.",
    "INSTA",
    "INSTE",
    "INSTO",
    "INSUA",
    "INTEL",
    "INTER",
    "INTJ.",
    "INTR.",
    "INTRA",
    "INTUA",
    "INTUI",
    "INTUO",
    "INUBA",
    "INUBE",
    "INUBO",
    "INULA",
    "INUMA",
    "INUME",
    "INUMO",
    "INUS.",
    "INVAR",
    "INVES",
    "INVIA",
    "INVIO",
    "INXUS",
    "IOBAI",
    "IOBAM",
    "IOBAR",
    "IOBAS",
    "IOBEI",
    "IOBEM",
    "IOBES",
    "IOBOU",
    "IOCAS",
    "IODAI",
    "IODAM",
    "IODAR",
    "IODAS",
    "IODEI",
    "IODEM",
    "IODES",
    "IODIS",
    "IODOL",
    "IODOS",
    "IODOU",
    "IOGAS",
    "IOGUE",
    "IOLAS",
    "IOLAU",
    "IOLCA",
    "IOLCO",
    "IOLES",
    "IOLIS",
    "IOLOS",
    "IONES",
    "IONIA",
    "IONIO",
    "IONIS",
    "IONTE",
    "IOPES",
    "IOTAS",
    "IOTIO",
    "IPABA",
    "IPACA",
    "IPACU",
    "IPADU",
    "IPCBR",
    "IPECA",
    "IPECU",
    "IPERO",
    "IPETE",
    "IPEUI",
    "IPIAU",
    "IPIDA",
    "IPIRA",
    "IPITI",
    "IPORA",
    "IPPAR",
    "IPPUR",
    "IPRES",
    "IPSIS",
    "IPUBI",
    "IPUCA",
    "IQUES",
    "IRACI",
    "IRACU",
    "IRADA",
    "IRADE",
    "IRADO",
    "IRAIS",
    "IRAJA",
    "IRAMO",
    "IRAN.",
    "IRANI",
    "IRAQ.",
    "IRARA",
    "IRARI",
    "IRATI",
    "IRAVA",
    "IRDES",
    "IRECE",
    "IREIS",
    "IREMO",
    "IRENA",
    "IRENE",
    "IRENO",
    "IRERE",
    "IRIAI",
    "IRIAL",
    "IRIAM",
    "IRIAR",
    "IRIAS",
    "IRIEI",
    "IRIEM",
    "IRIES",
    "IRIJU",
    "IRINA",
    "IRINO",
    "IRIOS",
    "IRIOU",
    "IRIRA",
    "IRISA",
    "IRISE",
    "IRISO",
    "IRITE",
    "IRIVA",
    "IRIZA",
    "IRIZE",
    "IRIZO",
    "IRMAO",
    "IRMAS",
    "IRMOS",
    "IROCO",
    "IROIS",
    "IRON.",
    "IRONA",
    "IRONE",
    "IRONO",
    "IROSA",
    "IROSO",
    "IRPAS",
    "IRPEX",
    "IRRAS",
    "IRRUA",
    "IRRUI",
    "IRRUO",
    "IRUCU",
    "IRUNS",
    "IRUPI",
    "IRURI",
    "ISAAC",
    "ISALA",
    "ISARA",
    "ISARO",
    "ISATE",
    "ISBAS",
    "ISCAI",
    "ISCAM",
    "ISCAR",
    "ISCAS",
    "ISCOU",
    "ISEIO",
    "ISEIS",
    "ISELE",
    "ISGAS",
    "ISGOS",
    "ISIOS",
    "ISLAO",
    "ISLAS",
    "ISMOS",
    "ISNAR",
    "ISOCA",
    "ISOLA",
    "ISOLE",
    "ISOLO",
    "ISOPS",
    "ISQUE",
    "ISSEI",
    "ISSEU",
    "ISTMO",
    "ISTRA",
    "ISTRO",
    "ISURO",
    "ISUSO",
    "ITABI",
    "ITACA",
    "ITACO",
    "ITAGI",
    "ITAIM",
    "ITAJA",
    "ITAJU",
    "ITAL.",
    "ITALA",
    "ITALO",
    "ITAME",
    "ITAMO",
    "ITANA",
    "ITANO",
    "ITAPE",
    "ITAPU",
    "ITATI",
    "ITAUA",
    "ITAUS",
    "ITEAS",
    "ITENS",
    "ITERA",
    "ITERE",
    "ITERO",
    "ITIEL",
    "ITOBI",
    "ITRAL",
    "ITRIA",
    "ITRIO",
    "ITROL",
    "ITUAS",
    "ITUIS",
    "ITUNA",
    "IUANE",
    "IUCAS",
    "IUCEA",
    "IULAS",
    "IULOS",
    "IUPAC",
    "IUPIS",
    "IUPUA",
    "IUQUE",
    "IURIS",
    "IUROS",
    "IURTA",
    "IURTE",
    "IVANA",
    "IVANI",
    "IVAOL",
    "IVATE",
    "IVECA",
    "IVESA",
    "IVETA",
    "IVETE",
    "IVIRA",
    "IVIRO",
    "IVONE",
    "IVORA",
    "IVOTI",
    "IXALO",
    "IXIAO",
    "IXIAS",
    "IXIEA",
    "IXODE",
    "IXODO",
    "IXORA",
    "IZAIS",
    "IZGAS",
    "IZGOS",
    "IZUZO",
    "JAACA",
    "JAALA",
    "JAARE",
    "JAATE",
    "JABAL",
    "JABAO",
    "JABAS",
    "JABEA",
    "JABES",
    "JABIM",
    "JABRE",
    "JABUS",
    "JACAI",
    "JACAS",
    "JACEA",
    "JACIA",
    "JACIO",
    "JACIR",
    "JACIS",
    "JACOB",
    "JACOS",
    "JACRA",
    "JACRE",
    "JACTA",
    "JACTE",
    "JACTO",
    "JACUA",
    "JACUI",
    "JACUS",
    "JADAI",
    "JADAO",
    "JADAS",
    "JADES",
    "JAERA",
    "JAEZA",
    "JAEZE",
    "JAEZO",
    "JAFAR",
    "JAFFE",
    "JAFIA",
    "JAGAS",
    "JAGAZ",
    "JAGRA",
    "JAGRE",
    "JAGUA",
    "JAGUE",
    "JAIBA",
    "JAICO",
    "JAIME",
    "JAINA",
    "JAIOS",
    "JAIRO",
    "JALAO",
    "JALAS",
    "JALDE",
    "JALDO",
    "JALEA",
    "JALEO",
    "JALES",
    "JALEU",
    "JALIA",
    "JALNE",
    "JAMAI",
    "JAMAL",
    "JAMBA",
    "JAMBE",
    "JAMBI",
    "JAMBO",
    "JAMBU",
    "JAMES",
    "JAMIE",
    "JAMIL",
    "JAMIM",
    "JAMIR",
    "JAMIS",
    "JANAI",
    "JANAL",
    "JANAM",
    "JANAR",
    "JANAS",
    "JANAU",
    "JANDA",
    "JANDE",
    "JANDU",
    "JANEI",
    "JANEM",
    "JANES",
    "JANFA",
    "JANGA",
    "JANGO",
    "JANIE",
    "JANIM",
    "JANIO",
    "JANIR",
    "JANJA",
    "JANOA",
    "JANOS",
    "JANOU",
    "JANTA",
    "JANTE",
    "JANTO",
    "JANUA",
    "JAPAO",
    "JAPAS",
    "JAPIM",
    "JAPIS",
    "JAPIU",
    "JAPIX",
    "JAPUE",
    "JAPUI",
    "JAPUS",
    "JAQUA",
    "JAQUE",
    "JARAS",
    "JARD.",
    "JARDA",
    "JARDE",
    "JARDO",
    "JARED",
    "JARES",
    "JARIA",
    "JARIO",
    "JARIR",
    "JAROA",
    "JAROS",
    "JARRA",
    "JARRO",
    "JARUS",
    "JASAO",
    "JASEN",
    "JASOM",
    "JASON",
    "JASPE",
    "JASSO",
    "JATAI",
    "JATAM",
    "JATAR",
    "JATAS",
    "JATEA",
    "JATEI",
    "JATEM",
    "JATER",
    "JATES",
    "JATIA",
    "JATIR",
    "JATIS",
    "JATOR",
    "JATOS",
    "JATOU",
    "JATUS",
    "JAULA",
    "JAUNA",
    "JAURU",
    "JAVAE",
    "JAVAS",
    "JAVRA",
    "JAVRE",
    "JAVRO",
    "JAYME",
    "JAZAM",
    "JAZAS",
    "JAZEI",
    "JAZEM",
    "JAZER",
    "JAZES",
    "JAZEU",
    "JAZIA",
    "JEANS",
    "JEBAS",
    "JEBUS",
    "JECAS",
    "JECOS",
    "JEGRA",
    "JEGRE",
    "JEGUE",
    "JEICO",
    "JEIRA",
    "JEITA",
    "JEITE",
    "JEITO",
    "JEJES",
    "JEJUA",
    "JEJUE",
    "JEJUM",
    "JEJUO",
    "JEJUS",
    "JEMBE",
    "JEMIA",
    "JENIA",
    "JENIS",
    "JEOVA",
    "JEPIA",
    "JEPIO",
    "JEQUE",
    "JEQUI",
    "JEROS",
    "JERRA",
    "JERUS",
    "JESSE",
    "JESUA",
    "JESUS",
    "JETAI",
    "JETAS",
    "JETER",
    "JETOM",
    "JETRO",
    "JEUBA",
    "JEUDE",
    "JEZER",
    "JIBAS",
    "JIBIS",
    "JICAO",
    "JICUI",
    "JIEFO",
    "JIEVO",
    "JIGAS",
    "JIHAD",
    "JIJUS",
    "JILAN",
    "JILES",
    "JIMBA",
    "JIMBE",
    "JIMBO",
    "JINAS",
    "JINGA",
    "JINGE",
    "JINGO",
    "JINJE",
    "JIPAO",
    "JIPES",
    "JIPIO",
    "JIPIS",
    "JIQUE",
    "JIQUI",
    "JIRAS",
    "JIRAU",
    "JIRES",
    "JIROE",
    "JISOS",
    "JITAI",
    "JITAS",
    "JITIS",
    "JITOS",
    "JITSU",
    "JIZAR",
    "JOABE",
    "JOACI",
    "JOADA",
    "JOANA",
    "JOANE",
    "JOBAI",
    "JOBAM",
    "JOBAR",
    "JOBAS",
    "JOBEI",
    "JOBEM",
    "JOBES",
    "JOBIM",
    "JOBOU",
    "JOCAL",
    "JOCAS",
    "JOCOS",
    "JOCSA",
    "JOCTA",
    "JODIE",
    "JOEDE",
    "JOELA",
    "JOETA",
    "JOFRE",
    "JOGAI",
    "JOGAM",
    "JOGAO",
    "JOGAR",
    "JOGAS",
    "JOGLA",
    "JOGOS",
    "JOGOU",
    "JOGUE",
    "JOGUI",
    "JOIAS",
    "JOIBA",
    "JOICA",
    "JOICE",
    "JOINA",
    "JOINT",
    "JOIOS",
    "JOLAI",
    "JOLAM",
    "JOLAR",
    "JOLAS",
    "JOLCA",
    "JOLCO",
    "JOLDA",
    "JOLEI",
    "JOLEM",
    "JOLES",
    "JOLGA",
    "JOLIS",
    "JOLIZ",
    "JOLOU",
    "JOMAR",
    "JOMOS",
    "JONAO",
    "JONAS",
    "JONCU",
    "JONES",
    "JONGA",
    "JONGO",
    "JONIA",
    "JONIO",
    "JONJA",
    "JONJE",
    "JONJO",
    "JONOS",
    "JOOES",
    "JOPAS",
    "JORAO",
    "JORGE",
    "JORIM",
    "JORN.",
    "JORNA",
    "JORNE",
    "JORRA",
    "JORRE",
    "JORRO",
    "JOSES",
    "JOSIA",
    "JOSIE",
    "JOSUE",
    "JOTAO",
    "JOTAS",
    "JOTBA",
    "JOTES",
    "JOUCA",
    "JOUJA",
    "JOULE",
    "JOVEM",
    "JUAMI",
    "JUANA",
    "JUARA",
    "JUARI",
    "JUATI",
    "JUBAI",
    "JUBAL",
    "JUBAS",
    "JUBEU",
    "JUCAL",
    "JUCAS",
    "JUCUS",
    "JUDAS",
    "JUDEO",
    "JUDEU",
    "JUDIA",
    "JUDIE",
    "JUDIO",
    "JUDOS",
    "JUDUM",
    "JUGAI",
    "JUGAL",
    "JUGAM",
    "JUGAR",
    "JUGAS",
    "JUGES",
    "JUGOS",
    "JUGOU",
    "JUGUE",
    "JUGUM",
    "JUINA",
    "JUIZA",
    "JUIZE",
    "JUIZO",
    "JUJUS",
    "JULAS",
    "JULES",
    "JULGA",
    "JULGO",
    "JULHO",
    "JULIA",
    "JULIO",
    "JULOS",
    "JUMAS",
    "JUMBO",
    "JUNAS",
    "JUNCA",
    "JUNCO",
    "JUNDO",
    "JUNDU",
    "JUNGE",
    "JUNGI",
    "JUNGO",
    "JUNHO",
    "JUNIA",
    "JUNJA",
    "JUNJO",
    "JUNTA",
    "JUNTE",
    "JUNTO",
    "JUPAO",
    "JUPIA",
    "JUPUA",
    "JUQUI",
    "JURAI",
    "JURAM",
    "JURAO",
    "JURAR",
    "JURAS",
    "JUREI",
    "JUREM",
    "JURES",
    "JURGA",
    "JURGO",
    "JURIS",
    "JUROS",
    "JUROU",
    "JURUA",
    "JURUS",
    "JUSAO",
    "JUSAS",
    "JUSOS",
    "JUST.",
    "JUSTA",
    "JUSTE",
    "JUSTO",
    "JUTAI",
    "JUTAS",
    "JUTOS",
    "JUTUS",
    "JUUNA",
    "JUVAS",
    "JUVIA",
    "KAFKA",
    "KARLA",
    "KARTS",
    "KATIA",
    "KAZUO",
    "KELDA",
    "KEOPS",
    "KEVIN",
    "KHMER",
    "KIOTO",
    "KLAUS",
    "KRILL",
    "KUALA",
    "KUITO",
    "KWAIT",
    "KYOTO",
    "KYRIE",
    "LABAO",
    "LABAS",
    "LABEA",
    "LABEO",
    "LABEU",
    "LABIA",
    "LABIL",
    "LABIO",
    "LABOR",
    "LABOS",
    "LABRO",
    "LACAI",
    "LACAM",
    "LACAO",
    "LACAR",
    "LACAS",
    "LACEA",
    "LACEI",
    "LACEM",
    "LACES",
    "LACHA",
    "LACIO",
    "LACMO",
    "LACNI",
    "LACNO",
    "LACOL",
    "LACON",
    "LACOS",
    "LACOU",
    "LACRA",
    "LACRE",
    "LACRO",
    "LACT.",
    "LACTA",
    "LACTE",
    "LACTO",
    "LADAO",
    "LADAS",
    "LADEA",
    "LADIM",
    "LADOS",
    "LADRA",
    "LADRE",
    "LADRO",
    "LADUS",
    "LAGAO",
    "LAGAR",
    "LAGAS",
    "LAGES",
    "LAGIS",
    "LAGOA",
    "LAGOS",
    "LAIAS",
    "LAICA",
    "LAICO",
    "LAIJA",
    "LAIKA",
    "LAILA",
    "LAILO",
    "LAIMA",
    "LAIME",
    "LAISA",
    "LAITU",
    "LAIVA",
    "LAIVE",
    "LAIVO",
    "LAJAI",
    "LAJAM",
    "LAJAO",
    "LAJAR",
    "LAJAS",
    "LAJEA",
    "LAJEI",
    "LAJEM",
    "LAJES",
    "LAJOU",
    "LALAI",
    "LALAM",
    "LALAR",
    "LALAS",
    "LALAU",
    "LALEI",
    "LALEM",
    "LALES",
    "LALIA",
    "LALNA",
    "LALOU",
    "LAMAR",
    "LAMAS",
    "LAMBA",
    "LAMBE",
    "LAMBI",
    "LAMBO",
    "LAMBU",
    "LAMEC",
    "LAMEL",
    "LAMIA",
    "LAMIM",
    "LAMIO",
    "LAMIS",
    "LAMNA",
    "LAMPA",
    "LAMPE",
    "LAMPO",
    "LANAI",
    "LANAM",
    "LANAR",
    "LANAS",
    "LANC.",
    "LANCA",
    "LANCE",
    "LANCO",
    "LANDA",
    "LANDE",
    "LANDI",
    "LANDO",
    "LANDU",
    "LANEA",
    "LANEI",
    "LANEM",
    "LANEO",
    "LANES",
    "LANGA",
    "LANGE",
    "LANGI",
    "LANGO",
    "LANHA",
    "LANHE",
    "LANHO",
    "LANIO",
    "LANKA",
    "LANOU",
    "LANTE",
    "LANTI",
    "LAPAI",
    "LAPAM",
    "LAPAO",
    "LAPAR",
    "LAPAS",
    "LAPEA",
    "LAPEI",
    "LAPEM",
    "LAPES",
    "LAPIA",
    "LAPIM",
    "LAPIS",
    "LAPOU",
    "LAPRE",
    "LAPSA",
    "LAPSO",
    "LAPUZ",
    "LAQUE",
    "LARAU",
    "LARDO",
    "LAREA",
    "LARES",
    "LAREU",
    "LARG.",
    "LARGA",
    "LARGO",
    "LARIA",
    "LARIM",
    "LARIS",
    "LARIX",
    "LAROS",
    "LAROZ",
    "LARPA",
    "LARPE",
    "LARPO",
    "LARRA",
    "LARRO",
    "LARVA",
    "LARVE",
    "LARVO",
    "LASCA",
    "LASCO",
    "LASER",
    "LASIA",
    "LASIO",
    "LASIS",
    "LASMO",
    "LASSA",
    "LASSE",
    "LASSO",
    "LATAM",
    "LATAO",
    "LATAS",
    "LATEA",
    "LATEM",
    "LATEO",
    "LATER",
    "LATES",
    "LATEX",
    "LATIA",
    "LATIM",
    "LATIR",
    "LATIS",
    "LATIU",
    "LAUD.",
    "LAUDA",
    "LAUDE",
    "LAUDO",
    "LAUIE",
    "LAULE",
    "LAURA",
    "LAURO",
    "LAUTA",
    "LAUTO",
    "LAUZA",
    "LAVAI",
    "LAVAM",
    "LAVAR",
    "LAVAS",
    "LAVEI",
    "LAVEM",
    "LAVES",
    "LAVOR",
    "LAVOU",
    "LAVRA",
    "LAVRE",
    "LAVRO",
    "LAXAI",
    "LAXAM",
    "LAXAR",
    "LAXAS",
    "LAXEI",
    "LAXEM",
    "LAXES",
    "LAXOU",
    "LAZAO",
    "LAZAS",
    "LAZER",
    "LAZOS",
    "LEAIS",
    "LEBAS",
    "LEBIA",
    "LEBON",
    "LEBRE",
    "LEBUS",
    "LECAL",
    "LECAS",
    "LECIA",
    "LECOS",
    "LECRE",
    "LEDAS",
    "LEDEA",
    "LEDES",
    "LEDOL",
    "LEDOR",
    "LEDOS",
    "LEDRA",
    "LEEIA",
    "LEGAI",
    "LEGAL",
    "LEGAM",
    "LEGAO",
    "LEGAR",
    "LEGAS",
    "LEGBA",
    "LEGIS",
    "LEGOU",
    "LEGRA",
    "LEGRE",
    "LEGRO",
    "LEGS.",
    "LEGUA",
    "LEGUE",
    "LEIAM",
    "LEIAS",
    "LEIDA",
    "LEIDE",
    "LEIGA",
    "LEIGO",
    "LEILA",
    "LEINO",
    "LEIRA",
    "LEIRE",
    "LEIRO",
    "LEIT.",
    "LEITA",
    "LEITE",
    "LEITO",
    "LEIVA",
    "LEIXA",
    "LEIXE",
    "LEIXO",
    "LELAS",
    "LELES",
    "LELIA",
    "LELIO",
    "LELIS",
    "LELOS",
    "LEMAS",
    "LEMBA",
    "LEMBE",
    "LEMBO",
    "LEMEA",
    "LEMES",
    "LEMNA",
    "LEMOS",
    "LEMPA",
    "LEMUR",
    "LENAM",
    "LENAO",
    "LENAS",
    "LENCA",
    "LENCO",
    "LENDA",
    "LENDE",
    "LENDO",
    "LENDU",
    "LENEA",
    "LENEM",
    "LENEO",
    "LENES",
    "LENEU",
    "LENGA",
    "LENGO",
    "LENHA",
    "LENHE",
    "LENHO",
    "LENIA",
    "LENIO",
    "LENIR",
    "LENIS",
    "LENIU",
    "LENOA",
    "LENTA",
    "LENTE",
    "LENTO",
    "LEOAS",
    "LEOBA",
    "LEOES",
    "LEONE",
    "LEONI",
    "LEOPS",
    "LEOTE",
    "LEPAS",
    "LEPES",
    "LEPIS",
    "LEPRA",
    "LEPTA",
    "LEPTO",
    "LEPUS",
    "LEQUE",
    "LERAM",
    "LERAO",
    "LERAS",
    "LERCA",
    "LERDA",
    "LERDO",
    "LEREI",
    "LEREM",
    "LERES",
    "LERIA",
    "LERIE",
    "LERIO",
    "LERMO",
    "LERNA",
    "LEROI",
    "LEROS",
    "LERPE",
    "LESAI",
    "LESAM",
    "LESAO",
    "LESAR",
    "LESAS",
    "LESCO",
    "LESEI",
    "LESEM",
    "LESES",
    "LESIM",
    "LESMA",
    "LESME",
    "LESMO",
    "LESOS",
    "LESOU",
    "LESSA",
    "LESSE",
    "LESTA",
    "LESTE",
    "LESTO",
    "LETAL",
    "LETAO",
    "LETAS",
    "LETEU",
    "LETOS",
    "LETRA",
    "LETRE",
    "LETRO",
    "LEUCA",
    "LEUCO",
    "LEUDE",
    "LEVAI",
    "LEVAM",
    "LEVAR",
    "LEVAS",
    "LEVEI",
    "LEVEM",
    "LEVES",
    "LEVEZ",
    "LEVIA",
    "LEVIE",
    "LEVIO",
    "LEVOU",
    "LEXIA",
    "LEXIS",
    "LHAMA",
    "LHANA",
    "LHANO",
    "LIABO",
    "LIACA",
    "LIADA",
    "LIADO",
    "LIAIS",
    "LIAME",
    "LIAMO",
    "LIANA",
    "LIANE",
    "LIARA",
    "LIASE",
    "LIAVA",
    "LIBAI",
    "LIBAM",
    "LIBAR",
    "LIBAS",
    "LIBAU",
    "LIBEI",
    "LIBEM",
    "LIBER",
    "LIBES",
    "LIBIA",
    "LIBIO",
    "LIBIS",
    "LIBOU",
    "LIBRA",
    "LIBRE",
    "LIBRO",
    "LIBUA",
    "LIBUO",
    "LICAO",
    "LICAS",
    "LICEA",
    "LICES",
    "LICEU",
    "LICHI",
    "LICIA",
    "LICIO",
    "LICNE",
    "LICOR",
    "LICOS",
    "LICRA",
    "LICTA",
    "LICTO",
    "LIDAI",
    "LIDAM",
    "LIDAR",
    "LIDAS",
    "LIDEI",
    "LIDEM",
    "LIDER",
    "LIDES",
    "LIDIA",
    "LIDIO",
    "LIDOS",
    "LIDOU",
    "LIEGE",
    "LIEIS",
    "LIEMO",
    "LIENE",
    "LIGAI",
    "LIGAL",
    "LIGAM",
    "LIGAR",
    "LIGAS",
    "LIGBI",
    "LIGEA",
    "LIGEO",
    "LIGEU",
    "LIGHT",
    "LIGIA",
    "LIGIO",
    "LIGOU",
    "LIGUE",
    "LILAS",
    "LILIA",
    "LILIO",
    "LILIS",
    "LIMAI",
    "LIMAM",
    "LIMAO",
    "LIMAR",
    "LIMAS",
    "LIMAX",
    "LIMBA",
    "LIMBO",
    "LIMEA",
    "LIMEI",
    "LIMEM",
    "LIMEO",
    "LIMES",
    "LIMIA",
    "LIMOS",
    "LIMOU",
    "LIMPA",
    "LIMPE",
    "LIMPO",
    "LINAS",
    "LINCA",
    "LINCE",
    "LINCO",
    "LINDA",
    "LINDB",
    "LINDE",
    "LINDO",
    "LINEA",
    "LINEO",
    "LINEU",
    "LINFA",
    "LINFE",
    "LINFO",
    "LING.",
    "LINGA",
    "LINGO",
    "LINHA",
    "LINHO",
    "LINKS",
    "LINOS",
    "LINUS",
    "LINUX",
    "LIODE",
    "LIOES",
    "LIOPO",
    "LIOTO",
    "LIPAS",
    "LIPES",
    "LIPIA",
    "LIPOA",
    "LIPOS",
    "LIPSE",
    "LIPUS",
    "LIRAL",
    "LIRAO",
    "LIRAS",
    "LIRIA",
    "LIRIO",
    "LIRIS",
    "LIROS",
    "LIRUS",
    "LISAS",
    "LISCA",
    "LISCO",
    "LISES",
    "LISGA",
    "LISIA",
    "LISIM",
    "LISIO",
    "LISMA",
    "LISME",
    "LISMO",
    "LISOL",
    "LISOS",
    "LISPA",
    "LISPE",
    "LISSA",
    "LISSO",
    "LISTA",
    "LISTE",
    "LISTO",
    "LITAI",
    "LITAM",
    "LITAO",
    "LITAR",
    "LITAS",
    "LITEI",
    "LITEM",
    "LITES",
    "LITIA",
    "LITIO",
    "LITOR",
    "LITOU",
    "LITRO",
    "LITUO",
    "LIVAS",
    "LIVEL",
    "LIVES",
    "LIVIA",
    "LIVIO",
    "LIVOR",
    "LIVOS",
    "LIVR.",
    "LIVRA",
    "LIVRE",
    "LIVRO",
    "LIXAI",
    "LIXAM",
    "LIXAO",
    "LIXAR",
    "LIXAS",
    "LIXEI",
    "LIXEM",
    "LIXES",
    "LIXOS",
    "LIXOU",
    "LIZAI",
    "LIZAM",
    "LIZAR",
    "LIZAS",
    "LIZEI",
    "LIZEM",
    "LIZES",
    "LIZIA",
    "LIZOU",
    "LOACO",
    "LOADA",
    "LOADO",
    "LOAIS",
    "LOAMO",
    "LOARA",
    "LOASA",
    "LOAVA",
    "LOBAL",
    "LOBAO",
    "LOBAR",
    "LOBAS",
    "LOBAZ",
    "LOBBY",
    "LOBOS",
    "LOCAI",
    "LOCAL",
    "LOCAM",
    "LOCAO",
    "LOCAR",
    "LOCAS",
    "LOCOU",
    "LOCUS",
    "LODAO",
    "LODOS",
    "LOEIS",
    "LOEMO",
    "LOENA",
    "LOFAS",
    "LOFIO",
    "LOFOS",
    "LOGAI",
    "LOGAM",
    "LOGAR",
    "LOGAS",
    "LOGBA",
    "LOGEA",
    "LOGIN",
    "LOGON",
    "LOGOS",
    "LOGOU",
    "LOGRA",
    "LOGRE",
    "LOGRO",
    "LOGUE",
    "LOIAS",
    "LOIBE",
    "LOICA",
    "LOIDE",
    "LOIOS",
    "LOIRA",
    "LOIRE",
    "LOIRO",
    "LOISA",
    "LOISE",
    "LOISO",
    "LOJAS",
    "LOJIA",
    "LOLIO",
    "LOLOS",
    "LOMAN",
    "LOMB.",
    "LOMBA",
    "LOMBE",
    "LOMBI",
    "LOMBO",
    "LOMIS",
    "LOMUE",
    "LONAS",
    "LONCA",
    "LONDO",
    "LONG.",
    "LONGA",
    "LONGE",
    "LONGO",
    "LONJA",
    "LOOES",
    "LOPAS",
    "LOPES",
    "LOPIS",
    "LOPOS",
    "LOPSA",
    "LOPSO",
    "LOQUE",
    "LOQUI",
    "LORAL",
    "LORAS",
    "LORCA",
    "LORCO",
    "LORDA",
    "LORDE",
    "LORDO",
    "LORFA",
    "LORFO",
    "LORGA",
    "LORGO",
    "LORIA",
    "LORIO",
    "LORIS",
    "LOROS",
    "LORPA",
    "LORTO",
    "LOSAS",
    "LOSIA",
    "LOSMA",
    "LOSNA",
    "LOSOS",
    "LOTAI",
    "LOTAM",
    "LOTAR",
    "LOTAS",
    "LOTEA",
    "LOTEI",
    "LOTEM",
    "LOTEO",
    "LOTES",
    "LOTIS",
    "LOTOS",
    "LOTOU",
    "LOTUR",
    "LOTUS",
    "LOUCA",
    "LOUCO",
    "LOUIS",
    "LOURA",
    "LOURE",
    "LOURO",
    "LOUSA",
    "LOUSE",
    "LOUSO",
    "LOUVA",
    "LOUVE",
    "LOUVO",
    "LOVIA",
    "LOXAS",
    "LOXIA",
    "LTDA.",
    "LUACO",
    "LUADA",
    "LUALE",
    "LUANA",
    "LUANE",
    "LUANO",
    "LUAUS",
    "LUBAS",
    "LUCAO",
    "LUCAS",
    "LUCHA",
    "LUCHE",
    "LUCHO",
    "LUCIA",
    "LUCIO",
    "LUCMO",
    "LUCOS",
    "LUCRA",
    "LUCRE",
    "LUCRO",
    "LUDAR",
    "LUDAS",
    "LUDIM",
    "LUDIO",
    "LUDOS",
    "LUDRA",
    "LUDRO",
    "LUEDA",
    "LUEEA",
    "LUENA",
    "LUETA",
    "LUFAI",
    "LUFAM",
    "LUFAR",
    "LUFAS",
    "LUFEI",
    "LUFEM",
    "LUFES",
    "LUFIA",
    "LUFOU",
    "LUFRE",
    "LUGAI",
    "LUGAM",
    "LUGAR",
    "LUGAS",
    "LUGES",
    "LUGOL",
    "LUGOU",
    "LUGRE",
    "LUGUE",
    "LUIAS",
    "LUICA",
    "LUICO",
    "LUIGI",
    "LUILA",
    "LUIME",
    "LUINA",
    "LUISA",
    "LUITA",
    "LUIZA",
    "LULAO",
    "LULAS",
    "LULOS",
    "LULUS",
    "LUMBO",
    "LUMEN",
    "LUMES",
    "LUMIA",
    "LUMIE",
    "LUMIO",
    "LUMPO",
    "LUNAR",
    "LUNAS",
    "LUND.",
    "LUNDA",
    "LUNDU",
    "LUNEL",
    "LUNF.",
    "LUNFA",
    "LUNGA",
    "LUNGO",
    "LUNIL",
    "LUNIS",
    "LUPAE",
    "LUPAI",
    "LUPAM",
    "LUPAR",
    "LUPAS",
    "LUPEI",
    "LUPEM",
    "LUPES",
    "LUPIA",
    "LUPOS",
    "LUPOU",
    "LUPUS",
    "LUQUE",
    "LURAI",
    "LURAM",
    "LURAR",
    "LURAS",
    "LURDA",
    "LUREI",
    "LUREM",
    "LURES",
    "LURGO",
    "LURIA",
    "LUROU",
    "LURTA",
    "LUSAS",
    "LUSCA",
    "LUSCO",
    "LUSIA",
    "LUSMA",
    "LUSME",
    "LUSMI",
    "LUSMO",
    "LUSOL",
    "LUSOS",
    "LUTAI",
    "LUTAM",
    "LUTAR",
    "LUTAS",
    "LUTEA",
    "LUTEI",
    "LUTEM",
    "LUTEO",
    "LUTES",
    "LUTOS",
    "LUTOU",
    "LUTRA",
    "LUTSE",
    "LUVAR",
    "LUVAS",
    "LUXAI",
    "LUXAM",
    "LUXAR",
    "LUXAS",
    "LUXAZ",
    "LUXEI",
    "LUXEM",
    "LUXES",
    "LUXOS",
    "LUXOU",
    "LUYSE",
    "LUZAM",
    "LUZAS",
    "LUZEM",
    "LUZES",
    "LUZIA",
    "LUZIE",
    "LUZIO",
    "LUZIR",
    "LUZIS",
    "LUZIU",
    "LVIII",
    "LXIII",
    "LXVII",
    "LXXII",
    "LXXIV",
    "LXXIX",
    "LXXVI",
    "LXXXI",
    "LXXXV",
    "LYCRA",
    "LYMES",
    "MAARI",
    "MABAS",
    "MABEA",
    "MABEL",
    "MABER",
    "MABIU",
    "MABRE",
    "MABUS",
    "MACAA",
    "MACAE",
    "MACAI",
    "MACAL",
    "MACAM",
    "MACAO",
    "MACAR",
    "MACAS",
    "MACAU",
    "MACAZ",
    "MACEA",
    "MACEI",
    "MACEM",
    "MACES",
    "MACHA",
    "MACHE",
    "MACHO",
    "MACIA",
    "MACIE",
    "MACIO",
    "MACIS",
    "MACLA",
    "MACOA",
    "MACOM",
    "MACOS",
    "MACOU",
    "MACRA",
    "MACRO",
    "MACUA",
    "MACUS",
    "MADAS",
    "MADES",
    "MADIA",
    "MADIM",
    "MADIS",
    "MADJE",
    "MADOM",
    "MADRE",
    "MADRI",
    "MAEIO",
    "MAETA",
    "MAFAU",
    "MAFES",
    "MAFIA",
    "MAFIE",
    "MAFIO",
    "MAFOR",
    "MAFRA",
    "MAFUA",
    "MAFUS",
    "MAG.ª",
    "MAGAI",
    "MAGAL",
    "MAGAS",
    "MAGBA",
    "MAGDA",
    "MAGIA",
    "MAGIE",
    "MAGIO",
    "MAGIS",
    "MAGMA",
    "MAGN.",
    "MAGNA",
    "MAGNE",
    "MAGNO",
    "MAGOA",
    "MAGOE",
    "MAGOO",
    "MAGOS",
    "MAGRA",
    "MAGRO",
    "MAHAL",
    "MAHDI",
    "MAIAI",
    "MAIAM",
    "MAIAO",
    "MAIAR",
    "MAIAS",
    "MAICA",
    "MAIDA",
    "MAIEI",
    "MAIEM",
    "MAIES",
    "MAILS",
    "MAINA",
    "MAINO",
    "MAIOR",
    "MAIOS",
    "MAIOU",
    "MAIRA",
    "MAIRE",
    "MAIRI",
    "MAIRU",
    "MAISA",
    "MAITA",
    "MAITE",
    "MAIUA",
    "MAJAS",
    "MAJIL",
    "MAJIS",
    "MAJOR",
    "MAJUS",
    "MALAI",
    "MALAM",
    "MALAR",
    "MALAS",
    "MALCA",
    "MALCO",
    "MALDA",
    "MALDE",
    "MALDO",
    "MALEA",
    "MALEI",
    "MALEM",
    "MALES",
    "MALG.",
    "MALGA",
    "MALGO",
    "MALH.",
    "MALHA",
    "MALHE",
    "MALHO",
    "MALIM",
    "MALIS",
    "MALOU",
    "MALSA",
    "MALTA",
    "MALTE",
    "MALTO",
    "MALUA",
    "MALUS",
    "MALVA",
    "MALVO",
    "MAMAE",
    "MAMAI",
    "MAMAL",
    "MAMAM",
    "MAMAO",
    "MAMAR",
    "MAMAS",
    "MAMBA",
    "MAMBE",
    "MAMBI",
    "MAMBO",
    "MAMEI",
    "MAMEM",
    "MAMES",
    "MAMOA",
    "MAMOU",
    "MAMPA",
    "MAMPE",
    "MAMPO",
    "MAMUA",
    "MAMUI",
    "MAMUM",
    "MANAI",
    "MANAL",
    "MANAM",
    "MANAR",
    "MANAS",
    "MANAU",
    "MANCA",
    "MANCO",
    "MANDA",
    "MANDE",
    "MANDI",
    "MANDO",
    "MANDU",
    "MANEA",
    "MANEI",
    "MANEL",
    "MANEM",
    "MANES",
    "MANGA",
    "MANGO",
    "MANGU",
    "MANHA",
    "MANHO",
    "MANIA",
    "MANIL",
    "MANIR",
    "MANIS",
    "MANIU",
    "MANJA",
    "MANJE",
    "MANJO",
    "MANJU",
    "MANOA",
    "MANOS",
    "MANOU",
    "MANSA",
    "MANSO",
    "MANTA",
    "MANTE",
    "MANTO",
    "MANUA",
    "MANUE",
    "MANUL",
    "MANUS",
    "MAOME",
    "MAONA",
    "MAORE",
    "MAORI",
    "MAPAO",
    "MAPAS",
    "MAPEA",
    "MAPIA",
    "MAPIE",
    "MAPIO",
    "MAPLE",
    "MAPUA",
    "MAQUE",
    "MAQUI",
    "MARAA",
    "MARAI",
    "MARAM",
    "MARAO",
    "MARAR",
    "MARAS",
    "MARAU",
    "MARC.",
    "MARCA",
    "MARCO",
    "MARDA",
    "MARDO",
    "MAREA",
    "MAREI",
    "MAREL",
    "MAREM",
    "MARES",
    "MAREU",
    "MARFA",
    "MARFE",
    "MARFI",
    "MARFO",
    "MARG.",
    "MARGA",
    "MARGE",
    "MARGI",
    "MARGO",
    "MARIA",
    "MARIE",
    "MARII",
    "MARIM",
    "MARIO",
    "MARIS",
    "MARIZ",
    "MARJA",
    "MARLA",
    "MARLI",
    "MARLY",
    "MARMA",
    "MARME",
    "MARMO",
    "MARN.",
    "MARNA",
    "MARNO",
    "MAROA",
    "MAROU",
    "MARR.",
    "MARRA",
    "MARRE",
    "MARRO",
    "MARSA",
    "MARSO",
    "MARTA",
    "MARTE",
    "MARTO",
    "MARUA",
    "MARUI",
    "MARX.",
    "MASAL",
    "MASAS",
    "MASC.",
    "MASCA",
    "MASCO",
    "MASEA",
    "MASEO",
    "MASER",
    "MASEU",
    "MASIO",
    "MASSA",
    "MASSE",
    "MASSO",
    "MASTO",
    "MASUE",
    "MATAI",
    "MATAL",
    "MATAM",
    "MATAO",
    "MATAR",
    "MATAS",
    "MATAT",
    "MATAU",
    "MATCH",
    "MATEA",
    "MATEI",
    "MATEM",
    "MATER",
    "MATES",
    "MATEU",
    "MATIA",
    "MATIS",
    "MATIZ",
    "MATOS",
    "MATOU",
    "MATRI",
    "MATUE",
    "MATUS",
    "MAUBA",
    "MAUCA",
    "MAUES",
    "MAUJO",
    "MAULA",
    "MAULO",
    "MAUNE",
    "MAURA",
    "MAURO",
    "MAUZA",
    "MAVIA",
    "MAVOS",
    "MAVUA",
    "MAXIM",
    "MAXIS",
    "MAYEN",
    "MAYER",
    "MAZAR",
    "MAZAS",
    "MAZES",
    "MAZEU",
    "MAZIA",
    "MCCCI",
    "MCCCL",
    "MCCCV",
    "MCCCX",
    "MCCII",
    "MCCIV",
    "MCCIX",
    "MCCLI",
    "MCCLV",
    "MCCLX",
    "MCCVI",
    "MCCXC",
    "MCCXI",
    "MCCXL",
    "MCCXV",
    "MCCXX",
    "MCDII",
    "MCDIV",
    "MCDIX",
    "MCDLI",
    "MCDLV",
    "MCDLX",
    "MCDVI",
    "MCDXC",
    "MCDXI",
    "MCDXL",
    "MCDXV",
    "MCDXX",
    "MCIII",
    "MCLII",
    "MCLIV",
    "MCLIX",
    "MCLVI",
    "MCLXI",
    "MCLXV",
    "MCLXX",
    "MCMII",
    "MCMIV",
    "MCMIX",
    "MCMLI",
    "MCMLV",
    "MCMLX",
    "MCMVI",
    "MCMXC",
    "MCMXI",
    "MCMXL",
    "MCMXV",
    "MCMXX",
    "MCVII",
    "MCXCI",
    "MCXCV",
    "MCXII",
    "MCXIV",
    "MCXIX",
    "MCXLI",
    "MCXLV",
    "MCXVI",
    "MCXXI",
    "MCXXV",
    "MCXXX",
    "MDCCC",
    "MDCCI",
    "MDCCL",
    "MDCCV",
    "MDCCX",
    "MDCII",
    "MDCIV",
    "MDCIX",
    "MDCLI",
    "MDCLV",
    "MDCLX",
    "MDCVI",
    "MDCXC",
    "MDCXI",
    "MDCXL",
    "MDCXV",
    "MDCXX",
    "MDIII",
    "MDLII",
    "MDLIV",
    "MDLIX",
    "MDLVI",
    "MDLXI",
    "MDLXV",
    "MDLXX",
    "MDVII",
    "MDXCI",
    "MDXCV",
    "MDXII",
    "MDXIV",
    "MDXIX",
    "MDXLI",
    "MDXLV",
    "MDXVI",
    "MDXXI",
    "MDXXV",
    "MDXXX",
    "MEACO",
    "MEADA",
    "MEADO",
    "MEAGO",
    "MEAIS",
    "MEANA",
    "MEANO",
    "MEAPO",
    "MEARA",
    "MEARI",
    "MEATO",
    "MEAVA",
    "MEBIA",
    "MEBOU",
    "MECAM",
    "MECAS",
    "MECHA",
    "MECHE",
    "MECHO",
    "MECIA",
    "MECOS",
    "MECRU",
    "MECUM",
    "MEDAO",
    "MEDAS",
    "MEDEA",
    "MEDEM",
    "MEDES",
    "MEDIA",
    "MEDIO",
    "MEDIR",
    "MEDIS",
    "MEDIU",
    "MEDOL",
    "MEDON",
    "MEDOS",
    "MEDRA",
    "MEDRE",
    "MEDRO",
    "MEDV.",
    "MEEIS",
    "MEGAN",
    "MEGAS",
    "MEIAM",
    "MEIAO",
    "MEIAS",
    "MEIEM",
    "MEIER",
    "MEIES",
    "MEIGA",
    "MEIGO",
    "MEIJU",
    "MEIOS",
    "MEIRA",
    "MEIRE",
    "MEIRI",
    "MEIRU",
    "MEJIS",
    "MELAI",
    "MELAM",
    "MELAO",
    "MELAR",
    "MELAS",
    "MELBA",
    "MELCA",
    "MELDA",
    "MELDO",
    "MELEA",
    "MELEI",
    "MELEM",
    "MELEO",
    "MELES",
    "MELEZ",
    "MELFA",
    "MELFO",
    "MELGA",
    "MELGO",
    "MELHA",
    "MELIA",
    "MELOA",
    "MELOE",
    "MELOU",
    "MELRA",
    "MELRO",
    "MEMBE",
    "MEMBI",
    "MEMES",
    "MEMIS",
    "MEMO.",
    "MENAI",
    "MENAM",
    "MENAR",
    "MENAS",
    "MENDA",
    "MENDE",
    "MENDI",
    "MENDO",
    "MENEA",
    "MENEI",
    "MENEM",
    "MENES",
    "MENEU",
    "MENGA",
    "MENGO",
    "MENHA",
    "MENIA",
    "MENIM",
    "MENIR",
    "MENIS",
    "MENOR",
    "MENOS",
    "MENOU",
    "MENS.",
    "MENSA",
    "MENSO",
    "MENTA",
    "MENTE",
    "MENTI",
    "MENTO",
    "MENUS",
    "MEOES",
    "MEOLA",
    "MEOLO",
    "MEOMA",
    "MEONA",
    "MEONE",
    "MEONO",
    "MEOTA",
    "MEOTE",
    "MERAS",
    "MERC.",
    "MERCA",
    "MERCE",
    "MERCO",
    "MERDA",
    "MEREM",
    "MERGA",
    "MERGO",
    "MERIM",
    "MERIO",
    "MERLA",
    "MERLO",
    "MERMA",
    "MERME",
    "MERMO",
    "MEROE",
    "MEROS",
    "MERRO",
    "MERUA",
    "MERUE",
    "MERUI",
    "MERUS",
    "MESAO",
    "MESAS",
    "MESCA",
    "MESCO",
    "MESES",
    "MESIA",
    "MESIO",
    "MESIS",
    "MESMA",
    "MESMO",
    "MESNA",
    "MESON",
    "MESOS",
    "MESSA",
    "MESSE",
    "MESSO",
    "MESSU",
    "MESTO",
    "MESUA",
    "METAL",
    "METAM",
    "METAS",
    "METEI",
    "METEM",
    "METER",
    "METES",
    "METEU",
    "METIA",
    "METIE",
    "METIL",
    "METIM",
    "METIS",
    "METOL",
    "METR.",
    "METRA",
    "METRE",
    "METRO",
    "MEUCO",
    "MEUDA",
    "MEUDO",
    "MEUIS",
    "MEULE",
    "MEUNS",
    "MEVIA",
    "MEVIO",
    "MEXAM",
    "MEXAO",
    "MEXAS",
    "MEXEI",
    "MEXEM",
    "MEXER",
    "MEXES",
    "MEXEU",
    "MEXIA",
    "MEXIR",
    "MEYER",
    "MEZEU",
    "MEZIO",
    "MEZZO",
    "MFECA",
    "MFITI",
    "MHARI",
    "MIAAS",
    "MIADA",
    "MIADO",
    "MIAIS",
    "MIAMA",
    "MIAMI",
    "MIAMO",
    "MIANA",
    "MIAOS",
    "MIARA",
    "MIARO",
    "MIAUS",
    "MIAVA",
    "MIBAS",
    "MIBUS",
    "MICAO",
    "MICAR",
    "MICAS",
    "MICHA",
    "MICHE",
    "MICHO",
    "MICIA",
    "MICIO",
    "MICOS",
    "MICRA",
    "MICRO",
    "MICTA",
    "MICTE",
    "MICTO",
    "MIDAS",
    "MIDAU",
    "MIDEU",
    "MIDIA",
    "MIDIM",
    "MIEIS",
    "MIELO",
    "MIEMO",
    "MIGAI",
    "MIGAM",
    "MIGAR",
    "MIGAS",
    "MIGDA",
    "MIGMA",
    "MIGOU",
    "MIGRA",
    "MIGRE",
    "MIGRO",
    "MIGUE",
    "MIINA",
    "MIITE",
    "MIJAI",
    "MIJAM",
    "MIJAO",
    "MIJAR",
    "MIJAS",
    "MIJEI",
    "MIJEM",
    "MIJES",
    "MIJOS",
    "MIJOU",
    "MIJUI",
    "MIJUS",
    "MILAO",
    "MILCA",
    "MILDE",
    "MILEA",
    "MILEO",
    "MILES",
    "MILEU",
    "MILHA",
    "MILHO",
    "MILIO",
    "MILOS",
    "MILPA",
    "MILUS",
    "MILVO",
    "MIMAI",
    "MIMAM",
    "MIMAR",
    "MIMAS",
    "MIMBI",
    "MIMBO",
    "MIMEI",
    "MIMEM",
    "MIMES",
    "MIMIS",
    "MIMON",
    "MIMOS",
    "MIMOU",
    "MINAI",
    "MINAM",
    "MINAR",
    "MINAS",
    "MINAZ",
    "MINDA",
    "MINEI",
    "MINEM",
    "MINES",
    "MINEU",
    "MING.",
    "MINGA",
    "MINGO",
    "MINGU",
    "MINH.",
    "MINHA",
    "MINHO",
    "MINIA",
    "MINIE",
    "MINIO",
    "MINIS",
    "MINJE",
    "MINKE",
    "MINOL",
    "MINOR",
    "MINOU",
    "MINSK",
    "MINTA",
    "MINTO",
    "MINUA",
    "MINUI",
    "MINUO",
    "MIOAS",
    "MIODE",
    "MIOGA",
    "MIOJO",
    "MIOLA",
    "MIOLO",
    "MIOMA",
    "MIOPA",
    "MIOPE",
    "MIOSE",
    "MIOTO",
    "MIOXO",
    "MIQUE",
    "MIQUI",
    "MIRAI",
    "MIRAM",
    "MIRAO",
    "MIRAR",
    "MIRAS",
    "MIREI",
    "MIREM",
    "MIRES",
    "MIRGA",
    "MIRIA",
    "MIRIM",
    "MIRIO",
    "MIRIS",
    "MIRMA",
    "MIRMO",
    "MIRNA",
    "MIROE",
    "MIROU",
    "MIRRA",
    "MIRRE",
    "MIRRO",
    "MIRTA",
    "MIRTO",
    "MIRUI",
    "MIRZA",
    "MISAL",
    "MISAS",
    "MISCA",
    "MISCO",
    "MISES",
    "MISGA",
    "MISGO",
    "MISIA",
    "MISIO",
    "MISIS",
    "MISMA",
    "MISNA",
    "MISOS",
    "MISPA",
    "MISSA",
    "MISSE",
    "MISSO",
    "MIST.",
    "MISTA",
    "MISTE",
    "MISTO",
    "MITAS",
    "MITES",
    "MITIM",
    "MITIS",
    "MITOS",
    "MITRA",
    "MITRE",
    "MITRO",
    "MITUA",
    "MITUS",
    "MIUCA",
    "MIUDA",
    "MIUDE",
    "MIUDO",
    "MIUFA",
    "MIUIS",
    "MIULO",
    "MIUNS",
    "MIURA",
    "MIURO",
    "MIUVA",
    "MIVAS",
    "MIXAI",
    "MIXAM",
    "MIXAR",
    "MIXAS",
    "MIXEI",
    "MIXEM",
    "MIXES",
    "MIXIA",
    "MIXNA",
    "MIXOS",
    "MIXOU",
    "MIXTO",
    "MIZAS",
    "MIZEU",
    "MIZOS",
    "MLIII",
    "MLVII",
    "MLXII",
    "MLXIV",
    "MLXIX",
    "MLXVI",
    "MLXXI",
    "MLXXV",
    "MLXXX",
    "MMIII",
    "MMIN.",
    "MMLII",
    "MMLIV",
    "MMLIX",
    "MMLVI",
    "MMLXI",
    "MMLXV",
    "MMLXX",
    "MMVII",
    "MMXCI",
    "MMXCV",
    "MMXII",
    "MMXIV",
    "MMXIX",
    "MMXLI",
    "MMXLV",
    "MMXVI",
    "MMXXI",
    "MMXXV",
    "MMXXX",
    "MNESE",
    "MNIOS",
    "MOABE",
    "MOABI",
    "MOACA",
    "MOADA",
    "MOADI",
    "MOADO",
    "MOAFA",
    "MOAG.",
    "MOAIS",
    "MOALI",
    "MOAMO",
    "MOANA",
    "MOBIL",
    "MOCAI",
    "MOCAM",
    "MOCAO",
    "MOCAR",
    "MOCAS",
    "MOCEA",
    "MOCEI",
    "MOCEM",
    "MOCES",
    "MOCHA",
    "MOCHE",
    "MOCHI",
    "MOCHO",
    "MOCIM",
    "MOCIS",
    "MOCOA",
    "MOCOS",
    "MOCOU",
    "MOCSA",
    "MOCUI",
    "MODAL",
    "MODAS",
    "MODEM",
    "MODIO",
    "MODOS",
    "MODUS",
    "MOECA",
    "MOED.",
    "MOEDA",
    "MOEGA",
    "MOEGO",
    "MOEIS",
    "MOELA",
    "MOEMA",
    "MOEMO",
    "MOERA",
    "MOERE",
    "MOESO",
    "MOESU",
    "MOETA",
    "MOFAI",
    "MOFAM",
    "MOFAR",
    "MOFAS",
    "MOFEI",
    "MOFEM",
    "MOFES",
    "MOFOS",
    "MOFOU",
    "MOGAI",
    "MOGAO",
    "MOGAS",
    "MOGEM",
    "MOGES",
    "MOGNO",
    "MOGOL",
    "MOGOR",
    "MOGOS",
    "MOGUE",
    "MOHOS",
    "MOIAM",
    "MOIAO",
    "MOIAS",
    "MOICA",
    "MOICO",
    "MOIDA",
    "MOIDO",
    "MOINA",
    "MOINE",
    "MOINO",
    "MOINS",
    "MOIOS",
    "MOIRA",
    "MOIRE",
    "MOIRO",
    "MOITA",
    "MOITE",
    "MOITO",
    "MOIXE",
    "MOJAI",
    "MOJAM",
    "MOJAR",
    "MOJAS",
    "MOJEI",
    "MOJEM",
    "MOJES",
    "MOJOS",
    "MOJOU",
    "MOJUI",
    "MOLAL",
    "MOLAM",
    "MOLAR",
    "MOLAS",
    "MOLDA",
    "MOLDE",
    "MOLDO",
    "MOLEA",
    "MOLEI",
    "MOLEM",
    "MOLES",
    "MOLGA",
    "MOLGE",
    "MOLGO",
    "MOLHA",
    "MOLHE",
    "MOLHO",
    "MOLIA",
    "MOLIM",
    "MOLIO",
    "MOLIR",
    "MOLIS",
    "MOLIU",
    "MOLOS",
    "MOLUA",
    "MOMAI",
    "MOMAM",
    "MOMAR",
    "MOMAS",
    "MOMBE",
    "MOMEA",
    "MOMEI",
    "MOMEM",
    "MOMES",
    "MOMOS",
    "MOMOU",
    "MOMPE",
    "MONAM",
    "MONAS",
    "MONCA",
    "MONCO",
    "MONDA",
    "MONDE",
    "MONDO",
    "MONEL",
    "MONEM",
    "MONES",
    "MONFA",
    "MONFI",
    "MONGE",
    "MONGI",
    "MONGO",
    "MONGU",
    "MONHA",
    "MONHE",
    "MONHO",
    "MONIA",
    "MONIR",
    "MONIS",
    "MONIU",
    "MONIZ",
    "MONJA",
    "MONJO",
    "MONOS",
    "MONOX",
    "MONSA",
    "MONSO",
    "MONT.",
    "MONTA",
    "MONTE",
    "MONTO",
    "MONVA",
    "MONVO",
    "MONZA",
    "MOOCA",
    "MOOGO",
    "MOPIS",
    "MOPLA",
    "MOQUA",
    "MOQUE",
    "MOQUI",
    "MOQUO",
    "MORAI",
    "MORAL",
    "MORAM",
    "MORAO",
    "MORAR",
    "MORAS",
    "MORBO",
    "MORCA",
    "MORCO",
    "MORDA",
    "MORDE",
    "MORDI",
    "MORDO",
    "MOREA",
    "MOREI",
    "MOREL",
    "MOREM",
    "MORES",
    "MORF.",
    "MORFA",
    "MORFE",
    "MORFO",
    "MORGA",
    "MORGO",
    "MORIA",
    "MORIL",
    "MORIM",
    "MORIO",
    "MORIS",
    "MORMA",
    "MORMO",
    "MORNA",
    "MORNE",
    "MORNO",
    "MOROS",
    "MOROU",
    "MORRA",
    "MORRE",
    "MORRI",
    "MORRO",
    "MORSA",
    "MORSE",
    "MORSO",
    "MORTA",
    "MORTE",
    "MORTO",
    "MORUS",
    "MORXI",
    "MOSAS",
    "MOSBA",
    "MOSCA",
    "MOSCO",
    "MOSES",
    "MOSSA",
    "MOSSE",
    "MOSSI",
    "MOSSO",
    "MOSTO",
    "MOTAR",
    "MOTAS",
    "MOTEL",
    "MOTES",
    "MOTIM",
    "MOTIS",
    "MOTOR",
    "MOTOS",
    "MOTUM",
    "MOUCA",
    "MOUCO",
    "MOUFA",
    "MOUFE",
    "MOUFO",
    "MOULE",
    "MOUMA",
    "MOUME",
    "MOUMO",
    "MOUOS",
    "MOURA",
    "MOURE",
    "MOURO",
    "MOUSE",
    "MOUTA",
    "MOUXE",
    "MOVAM",
    "MOVAS",
    "MOVEI",
    "MOVEL",
    "MOVEM",
    "MOVER",
    "MOVES",
    "MOVEU",
    "MOVIA",
    "MOXAI",
    "MOXAM",
    "MOXAR",
    "MOXAS",
    "MOXEI",
    "MOXEM",
    "MOXES",
    "MOXOU",
    "MOZAR",
    "MSCOL",
    "MUACA",
    "MUACO",
    "MUADI",
    "MUAFA",
    "MUAFO",
    "MUAJE",
    "MUALO",
    "MUAMA",
    "MUAME",
    "MUANA",
    "MUANE",
    "MUANI",
    "MUARI",
    "MUATA",
    "MUAVE",
    "MUBES",
    "MUBIS",
    "MUBUS",
    "MUCAO",
    "MUCAS",
    "MUCHA",
    "MUCHE",
    "MUCHI",
    "MUCIA",
    "MUCIO",
    "MUCOL",
    "MUCOR",
    "MUCOS",
    "MUCRO",
    "MUCUA",
    "MUCUE",
    "MUCUI",
    "MUCUM",
    "MUDAI",
    "MUDAM",
    "MUDAR",
    "MUDAS",
    "MUDEI",
    "MUDEM",
    "MUDES",
    "MUDEZ",
    "MUDIR",
    "MUDOS",
    "MUDOU",
    "MUDRA",
    "MUEBA",
    "MUELA",
    "MUELE",
    "MUEMA",
    "MUEME",
    "MUEMO",
    "MUENA",
    "MUENE",
    "MUENO",
    "MUERE",
    "MUEZO",
    "MUFAS",
    "MUFLA",
    "MUFLO",
    "MUFTI",
    "MUFUI",
    "MUGEM",
    "MUGES",
    "MUGIA",
    "MUGIL",
    "MUGIR",
    "MUGIS",
    "MUGIU",
    "MUGOS",
    "MUGRE",
    "MUGUE",
    "MUIEU",
    "MUILA",
    "MUINS",
    "MUIOS",
    "MUIRA",
    "MUITA",
    "MUITO",
    "MUJAM",
    "MUJAS",
    "MUJAU",
    "MUJEM",
    "MUJES",
    "MUJIA",
    "MUJIL",
    "MUJIO",
    "MUJIS",
    "MULAI",
    "MULAS",
    "MULEA",
    "MULEI",
    "MULEO",
    "MULEU",
    "MULGU",
    "MULIM",
    "MULIO",
    "MULME",
    "MULOI",
    "MULOS",
    "MULSA",
    "MULSO",
    "MULT.",
    "MULTA",
    "MULTE",
    "MULTI",
    "MULTO",
    "MULUA",
    "MULUM",
    "MUMBA",
    "MUMBE",
    "MUMBO",
    "MUMIA",
    "MUMOS",
    "MUMUA",
    "MUMUS",
    "MUNAM",
    "MUNAS",
    "MUNDA",
    "MUNDE",
    "MUNDI",
    "MUNDO",
    "MUNEM",
    "MUNES",
    "MUNGA",
    "MUNGE",
    "MUNGI",
    "MUNGO",
    "MUNGU",
    "MUNHA",
    "MUNHO",
    "MUNIA",
    "MUNIR",
    "MUNIS",
    "MUNIU",
    "MUNIZ",
    "MUNJA",
    "MUNJE",
    "MUNJO",
    "MUNSI",
    "MUNTO",
    "MUNUS",
    "MUOCO",
    "MUOLO",
    "MUONS",
    "MUPAS",
    "MUPIM",
    "MUQUE",
    "MUQUI",
    "MURAI",
    "MURAL",
    "MURAM",
    "MURAR",
    "MURAS",
    "MURCA",
    "MURCO",
    "MUREA",
    "MUREI",
    "MUREM",
    "MURES",
    "MUREX",
    "MURIA",
    "MURIM",
    "MURIS",
    "MURLE",
    "MUROS",
    "MUROU",
    "MURRA",
    "MURRE",
    "MURRO",
    "MURSO",
    "MURTA",
    "MURTO",
    "MURUA",
    "MURUI",
    "MURUM",
    "MURUS",
    "MURZA",
    "MUSAL",
    "MUSAS",
    "MUSCA",
    "MUSCO",
    "MUSEU",
    "MUSGA",
    "MUSGO",
    "MUSGU",
    "MUSME",
    "MUSSA",
    "MUSSE",
    "MUSSI",
    "MUSTA",
    "MUSTO",
    "MUTEL",
    "MUTIA",
    "MUTIS",
    "MUTOA",
    "MUTOE",
    "MUTOM",
    "MUTOS",
    "MUTRA",
    "MUTRE",
    "MUTRO",
    "MUTUA",
    "MUTUE",
    "MUTUM",
    "MUTUO",
    "MUUBA",
    "MUVIU",
    "MUXAS",
    "MUXEM",
    "MUXIS",
    "MUXOS",
    "MUZUA",
    "MVDOL",
    "MVIII",
    "MXCII",
    "MXCIV",
    "MXCIX",
    "MXCVI",
    "MXIII",
    "MXLII",
    "MXLIV",
    "MXLIX",
    "MXLVI",
    "MXVII",
    "MXXII",
    "MXXIV",
    "MXXIX",
    "MXXVI",
    "MXXXI",
    "MXXXV",
    "N.PR.",
    "NABAL",
    "NABAO",
    "NABAS",
    "NABIS",
    "NABLA",
    "NABLO",
    "NABOR",
    "NABOS",
    "NACAO",
    "NACAR",
    "NACAS",
    "NACHE",
    "NACIB",
    "NACIP",
    "NACOR",
    "NACOS",
    "NACRE",
    "NACUA",
    "NACUS",
    "NADAI",
    "NADAL",
    "NADAM",
    "NADAR",
    "NADAS",
    "NADEI",
    "NADEM",
    "NADER",
    "NADES",
    "NADIA",
    "NADIM",
    "NADIR",
    "NADJA",
    "NADOS",
    "NADOU",
    "NAFIL",
    "NAFIR",
    "NAFIS",
    "NAFOS",
    "NAFTA",
    "NAGAI",
    "NAGAM",
    "NAGAO",
    "NAGAR",
    "NAGAS",
    "NAGER",
    "NAGIA",
    "NAGIS",
    "NAGOA",
    "NAGOR",
    "NAGOS",
    "NAGOU",
    "NAGUA",
    "NAGUE",
    "NAGUI",
    "NAGUL",
    "NAIAS",
    "NAIBE",
    "NAIDA",
    "NAIDE",
    "NAIFA",
    "NAIFE",
    "NAIFO",
    "NAIPA",
    "NAIPE",
    "NAIPO",
    "NAIRA",
    "NAIRE",
    "NAIRO",
    "NAIVE",
    "NAIXO",
    "NAJAS",
    "NAJIR",
    "NAJIS",
    "NALAS",
    "NALDO",
    "NALES",
    "NALGA",
    "NALGO",
    "NALUS",
    "NAMAR",
    "NAMAS",
    "NAMAU",
    "NAMAZ",
    "NAMBE",
    "NAMBI",
    "NAMBU",
    "NAMIA",
    "NAMIR",
    "NANAI",
    "NANAL",
    "NANAM",
    "NANAR",
    "NANAS",
    "NANCI",
    "NANCY",
    "NANDA",
    "NANDI",
    "NANDO",
    "NANDU",
    "NANEI",
    "NANEM",
    "NANES",
    "NANGA",
    "NANIA",
    "NANIS",
    "NANJA",
    "NANOU",
    "NAOTO",
    "NAPAS",
    "NAPEU",
    "NAQUE",
    "NARAS",
    "NARAZ",
    "NARCA",
    "NARCO",
    "NARDA",
    "NARDO",
    "NAREA",
    "NARGO",
    "NARIO",
    "NARIZ",
    "NARLO",
    "NARRA",
    "NARRE",
    "NARRO",
    "NARSA",
    "NARTA",
    "NARTE",
    "NARUS",
    "NARVA",
    "NASAL",
    "NASCA",
    "NASCE",
    "NASCI",
    "NASCO",
    "NASEA",
    "NASEO",
    "NASES",
    "NASIA",
    "NASIM",
    "NASIO",
    "NASIR",
    "NASSA",
    "NASSE",
    "NASSI",
    "NASSO",
    "NASUA",
    "NATAL",
    "NATAN",
    "NATAS",
    "NATEL",
    "NATIA",
    "NATIL",
    "NATIO",
    "NATIS",
    "NATOS",
    "NATRO",
    "NAUA.",
    "NAUAI",
    "NAUAS",
    "NAUES",
    "NAUGA",
    "NAULO",
    "NAURO",
    "NAURU",
    "NAUT.",
    "NAUTA",
    "NAVAL",
    "NAVAO",
    "NAVAS",
    "NAVEA",
    "NAVEM",
    "NAVES",
    "NAVIA",
    "NAVIM",
    "NAVIO",
    "NAXAS",
    "NAXIA",
    "NAXIO",
    "NAZES",
    "NAZIA",
    "NAZIR",
    "NAZIS",
    "NDALA",
    "NDAUS",
    "NDUAS",
    "NDULU",
    "NEALA",
    "NEBAI",
    "NEBEL",
    "NEBOS",
    "NEBRI",
    "NECAS",
    "NECEA",
    "NECRA",
    "NECRO",
    "NECTO",
    "NEDAI",
    "NEDAS",
    "NEDIA",
    "NEDIO",
    "NEDJI",
    "NEDUM",
    "NEEAS",
    "NEERA",
    "NEFAS",
    "NEFRO",
    "NEGAI",
    "NEGAM",
    "NEGAO",
    "NEGAR",
    "NEGAS",
    "NEGOU",
    "NEGRA",
    "NEGRO",
    "NEGUE",
    "NEGUS",
    "NEIDA",
    "NEIDE",
    "NEIDO",
    "NEIVA",
    "NEJAS",
    "NELAS",
    "NELDO",
    "NELES",
    "NELIA",
    "NELIO",
    "NELMA",
    "NELOS",
    "NEMAS",
    "NEMBA",
    "NEMBO",
    "NEMEU",
    "NENAS",
    "NENAX",
    "NENDE",
    "NENDI",
    "NENEM",
    "NENES",
    "NENGO",
    "NENHA",
    "NENHO",
    "NENIA",
    "NENIO",
    "NENOS",
    "NENTE",
    "NEOES",
    "NEOIS",
    "NEONE",
    "NEONS",
    "NEPAL",
    "NEPAS",
    "NEPER",
    "NEPIA",
    "NEPOS",
    "NEQUE",
    "NERAL",
    "NERAS",
    "NERDS",
    "NEREA",
    "NEREO",
    "NERES",
    "NEREU",
    "NERIA",
    "NERIO",
    "NEROL",
    "NEROS",
    "NERTO",
    "NERVO",
    "NERVU",
    "NESEA",
    "NESEO",
    "NESEU",
    "NESGA",
    "NESGO",
    "NESSA",
    "NESSE",
    "NESTA",
    "NESTE",
    "NESTO",
    "NETAS",
    "NETOS",
    "NEUMA",
    "NEUR.",
    "NEURA",
    "NEURI",
    "NEURO",
    "NEUSA",
    "NEUZA",
    "NEVAI",
    "NEVAM",
    "NEVAO",
    "NEVAR",
    "NEVAS",
    "NEVEI",
    "NEVEM",
    "NEVES",
    "NEVIO",
    "NEVIS",
    "NEVOA",
    "NEVOE",
    "NEVOO",
    "NEVOU",
    "NEVRI",
    "NEXOS",
    "NGANA",
    "NGOLA",
    "NGUES",
    "NGUTA",
    "NHACA",
    "NHAMA",
    "NHATA",
    "NHATI",
    "NHATO",
    "NHELE",
    "NHIMO",
    "NHOCA",
    "NHOLE",
    "NHONS",
    "NHUNS",
    "NIAIA",
    "NIAIS",
    "NIALO",
    "NIAMA",
    "NIBOE",
    "NIBUS",
    "NICAI",
    "NICAM",
    "NICAR",
    "NICAS",
    "NICES",
    "NICHA",
    "NICHE",
    "NICHO",
    "NICIA",
    "NICOL",
    "NICOU",
    "NICTA",
    "NICTE",
    "NICTI",
    "NICTO",
    "NIDAI",
    "NIDAM",
    "NIDAR",
    "NIDAS",
    "NIDEI",
    "NIDEM",
    "NIDES",
    "NIDIA",
    "NIDOR",
    "NIDOU",
    "NIELO",
    "NIFAL",
    "NIFAO",
    "NIFES",
    "NIGEL",
    "NIGER",
    "NIGRA",
    "NIGUA",
    "NILAS",
    "NILGO",
    "NILIA",
    "NILIE",
    "NILIO",
    "NILIS",
    "NILMA",
    "NILOS",
    "NILVA",
    "NIMAS",
    "NIMBA",
    "NIMBE",
    "NIMBO",
    "NIMIA",
    "NIMIO",
    "NIMPA",
    "NINAI",
    "NINAM",
    "NINAR",
    "NINAS",
    "NINEI",
    "NINEM",
    "NINES",
    "NINFA",
    "NINGA",
    "NINHA",
    "NINHE",
    "NINHO",
    "NINIS",
    "NINJA",
    "NINJI",
    "NINOU",
    "NIOPO",
    "NIPAO",
    "NIPAS",
    "NIPLE",
    "NIPOA",
    "NIPOS",
    "NIPTO",
    "NIQUE",
    "NIREX",
    "NISAO",
    "NISAS",
    "NISCA",
    "NISCO",
    "NISEI",
    "NISEU",
    "NISIA",
    "NISIO",
    "NISPO",
    "NISSO",
    "NISTO",
    "NITAL",
    "NITAS",
    "NITON",
    "NITOS",
    "NITRA",
    "NITRE",
    "NITRI",
    "NITRO",
    "NIVAL",
    "NIVEA",
    "NIVEL",
    "NIVEO",
    "NIVES",
    "NIVIA",
    "NIZAS",
    "NOBEL",
    "NOBIL",
    "NOBIS",
    "NOBLE",
    "NOBRE",
    "NOCAI",
    "NOCAM",
    "NOCAO",
    "NOCAR",
    "NOCAS",
    "NOCHA",
    "NOCIA",
    "NOCIO",
    "NOCIR",
    "NOCOU",
    "NODAI",
    "NODAL",
    "NODAM",
    "NODAR",
    "NODAS",
    "NODEI",
    "NODEM",
    "NODES",
    "NODIO",
    "NODOA",
    "NODOE",
    "NODOO",
    "NODOS",
    "NODOU",
    "NOEIS",
    "NOEMA",
    "NOEMI",
    "NOENA",
    "NOENO",
    "NOESE",
    "NOETE",
    "NOGAI",
    "NOGAL",
    "NOGAO",
    "NOIAS",
    "NOIRA",
    "NOISE",
    "NOITE",
    "NOIVA",
    "NOIVE",
    "NOIVO",
    "NOJAF",
    "NOJAI",
    "NOJAM",
    "NOJAR",
    "NOJAS",
    "NOJEI",
    "NOJEM",
    "NOJES",
    "NOJOS",
    "NOJOU",
    "NOLAN",
    "NOLAS",
    "NOLHA",
    "NOMAS",
    "NOMEA",
    "NOMEO",
    "NOMES",
    "NOMIA",
    "NOMOS",
    "NONAL",
    "NONAS",
    "NONDE",
    "NONDO",
    "NONEA",
    "NONES",
    "NONEX",
    "NONGA",
    "NONIO",
    "NONOS",
    "NOOCA",
    "NOPAL",
    "NOPAS",
    "NOPES",
    "NOPRA",
    "NOQUE",
    "NORAS",
    "NORBU",
    "NORCA",
    "NORD.",
    "NORIA",
    "NORIO",
    "NORM.",
    "NORMA",
    "NORNA",
    "NORSA",
    "NORSO",
    "NORTE",
    "NOSSA",
    "NOSSO",
    "NOTAI",
    "NOTAL",
    "NOTAM",
    "NOTAR",
    "NOTAS",
    "NOTEA",
    "NOTEI",
    "NOTEM",
    "NOTEO",
    "NOTES",
    "NOTOU",
    "NOTRA",
    "NOTRE",
    "NOTRO",
    "NOUTE",
    "NOVAI",
    "NOVAL",
    "NOVAM",
    "NOVAR",
    "NOVAS",
    "NOVEA",
    "NOVEI",
    "NOVEL",
    "NOVEM",
    "NOVES",
    "NOVIO",
    "NOVOA",
    "NOVOS",
    "NOVOU",
    "NOXAL",
    "NOXAS",
    "NOXIO",
    "NOZES",
    "NOZUL",
    "NUBAS",
    "NUBEU",
    "NUBIA",
    "NUBIL",
    "NUBIO",
    "NUBLA",
    "NUBLE",
    "NUBLO",
    "NUBOS",
    "NUCAL",
    "NUCAO",
    "NUCAS",
    "NUCES",
    "NUCIO",
    "NUCL.",
    "NUDAS",
    "NUDEZ",
    "NUDOS",
    "NUEIS",
    "NUELA",
    "NUELO",
    "NUEZA",
    "NUFAR",
    "NUFES",
    "NUGAS",
    "NUIMA",
    "NUITA",
    "NUJOL",
    "NULAS",
    "NULOS",
    "NUMAS",
    "NUMBE",
    "NUMBU",
    "NUMEN",
    "NUMES",
    "NUMOS",
    "NUNCA",
    "NUNCE",
    "NUNDO",
    "NUNES",
    "NUNGO",
    "NUNOS",
    "NUPER",
    "NURIA",
    "NURUZ",
    "NUTAI",
    "NUTAM",
    "NUTAR",
    "NUTAS",
    "NUTEI",
    "NUTEM",
    "NUTES",
    "NUTOU",
    "NUTR.",
    "NUTRA",
    "NUTRE",
    "NUTRI",
    "NUTRO",
    "NUTUM",
    "NUVEA",
    "NUVEM",
    "NUVEO",
    "NUVOL",
    "NUXIA",
    "NYHAN",
    "NYLON",
    "NZILA",
    "OALAS",
    "OANDA",
    "OASIS",
    "OBANE",
    "OBANS",
    "OBEBA",
    "OBED.",
    "OBELO",
    "OBERA",
    "OBERE",
    "OBERO",
    "OBESA",
    "OBESO",
    "OBICE",
    "OBITO",
    "OBMEP",
    "OBOAZ",
    "OBOLO",
    "OBORI",
    "OBRAI",
    "OBRAM",
    "OBRAR",
    "OBRAS",
    "OBREI",
    "OBREM",
    "OBRES",
    "OBROU",
    "OBSIA",
    "OBST.",
    "OBSTA",
    "OBSTE",
    "OBSTO",
    "OBTEM",
    "OBTER",
    "OBULO",
    "OBUTU",
    "OBVEM",
    "OBVIA",
    "OBVIE",
    "OBVIM",
    "OBVIO",
    "OBVIR",
    "OCADA",
    "OCADO",
    "OCAIA",
    "OCAIS",
    "OCAMO",
    "OCANA",
    "OCANO",
    "OCAPI",
    "OCARA",
    "OCASO",
    "OCAVA",
    "OCELO",
    "OCHES",
    "OCID.",
    "OCIDA",
    "OCIMO",
    "OCIOS",
    "OCLUA",
    "OCLUI",
    "OCLUO",
    "OCNAS",
    "OCNOS",
    "OCOTA",
    "OCOTO",
    "OCRAS",
    "OCREA",
    "OCRES",
    "OCROS",
    "OCTAL",
    "OCTIL",
    "OCTIS",
    "OCUAS",
    "OCUL.",
    "OCULO",
    "OCUME",
    "OCUPA",
    "OCUPE",
    "OCUPO",
    "ODAIR",
    "ODARA",
    "ODEAO",
    "ODEDE",
    "ODEIA",
    "ODEIE",
    "ODEIO",
    "ODELA",
    "ODELE",
    "ODEON",
    "ODETE",
    "ODIAI",
    "ODIAR",
    "ODICA",
    "ODICO",
    "ODIEI",
    "ODILA",
    "ODILO",
    "ODINA",
    "ODINO",
    "ODIOS",
    "ODIOU",
    "ODITE",
    "ODITO",
    "ODJAS",
    "ODONE",
    "ODORA",
    "ODORE",
    "ODORO",
    "ODRES",
    "ODULE",
    "ODUNS",
    "OECIO",
    "OENAS",
    "OEONE",
    "OESTE",
    "OETAS",
    "OFAIE",
    "OFATO",
    "OFEGA",
    "OFEGO",
    "OFESO",
    "OFID.",
    "OFIRA",
    "OFITA",
    "OFITE",
    "OFITO",
    "OFRES",
    "OFRIO",
    "OFRIS",
    "OFURO",
    "OGADA",
    "OGADO",
    "OGAIS",
    "OGAMO",
    "OGANA",
    "OGANO",
    "OGARA",
    "OGAVA",
    "OGENS",
    "OGIVA",
    "OGIVE",
    "OGIVO",
    "OGODO",
    "OGOES",
    "OGONI",
    "OGRAS",
    "OGRES",
    "OGROS",
    "OGUDE",
    "OGUEI",
    "OGUEM",
    "OGUES",
    "OGUIM",
    "OGUXO",
    "OIACA",
    "OIANA",
    "OIAPI",
    "OIARA",
    "OICAM",
    "OICAS",
    "OICOS",
    "OIDES",
    "OIDIO",
    "OIGAI",
    "OIGAM",
    "OIGAR",
    "OIGAS",
    "OIGOU",
    "OIGUE",
    "OILAS",
    "OINCA",
    "OIRAI",
    "OIRAM",
    "OIRAR",
    "OIRAS",
    "OIREI",
    "OIREM",
    "OIRES",
    "OIROS",
    "OIROU",
    "OITAO",
    "OITIS",
    "OIXOS",
    "OLABA",
    "OLABO",
    "OLADA",
    "OLAIA",
    "OLALA",
    "OLANS",
    "OLARE",
    "OLAVO",
    "OLEAI",
    "OLEAL",
    "OLEAR",
    "OLEDE",
    "OLEEI",
    "OLEIA",
    "OLEIE",
    "OLEIO",
    "OLELE",
    "OLENO",
    "OLEOL",
    "OLEOS",
    "OLEOU",
    "OLERE",
    "OLGAS",
    "OLHAI",
    "OLHAL",
    "OLHAM",
    "OLHAO",
    "OLHAR",
    "OLHAS",
    "OLHEI",
    "OLHEM",
    "OLHES",
    "OLHOS",
    "OLHOU",
    "OLIAS",
    "OLIG.",
    "OLIGO",
    "OLINA",
    "OLIRA",
    "OLIVA",
    "OLIVE",
    "OLIVO",
    "OLMOS",
    "OLOBO",
    "OLOCO",
    "OLODE",
    "OLOMA",
    "OLUAS",
    "OLUBO",
    "OMADA",
    "OMADO",
    "OMAEL",
    "OMAHA",
    "OMAIA",
    "OMALA",
    "OMALO",
    "OMANA",
    "OMANI",
    "OMANO",
    "OMASO",
    "OMATA",
    "OMBIA",
    "OMBIS",
    "OMBOS",
    "OMBRA",
    "OMBRO",
    "OMBUS",
    "OMEGA",
    "OMELE",
    "OMEME",
    "OMETE",
    "OMINA",
    "OMINE",
    "OMINO",
    "OMIRI",
    "OMITA",
    "OMITE",
    "OMITI",
    "OMITO",
    "OMNES",
    "OMOLU",
    "OMOMA",
    "OMUIS",
    "OMULU",
    "ONAGA",
    "ONCAS",
    "ONCOS",
    "ONDAI",
    "ONDAM",
    "ONDAR",
    "ONDAS",
    "ONDEA",
    "ONDEI",
    "ONDEM",
    "ONDES",
    "ONDIM",
    "ONDOA",
    "ONDOE",
    "ONDOO",
    "ONDOU",
    "ONERA",
    "ONERE",
    "ONERO",
    "ONETA",
    "ONFRA",
    "ONFUA",
    "ONG'S",
    "ONGOS",
    "ONGUE",
    "ONHIS",
    "ONIAS",
    "ONICA",
    "ONICO",
    "ONINS",
    "ONIRO",
    "ONIXE",
    "ONJOS",
    "ONOBA",
    "ONOM.",
    "ONONE",
    "ONTEM",
    "OONIM",
    "OPABA",
    "OPACA",
    "OPACO",
    "OPADA",
    "OPADO",
    "OPAIE",
    "OPAIS",
    "OPALA",
    "OPALO",
    "OPAMO",
    "OPARA",
    "OPAVA",
    "OPCAO",
    "OPEIS",
    "OPELE",
    "OPEMO",
    "OPERA",
    "OPERE",
    "OPERO",
    "OPIAI",
    "OPIAM",
    "OPIAR",
    "OPIAS",
    "OPIDO",
    "OPIEI",
    "OPIEM",
    "OPIES",
    "OPILA",
    "OPILE",
    "OPILO",
    "OPIMA",
    "OPIMO",
    "OPINA",
    "OPINE",
    "OPINO",
    "OPIOS",
    "OPIOU",
    "OPLOS",
    "OPOEM",
    "OPOES",
    "OPOIS",
    "OPOMO",
    "OPORA",
    "OPOS.",
    "OPSIA",
    "OPSIS",
    "OPTAI",
    "OPTAM",
    "OPTAR",
    "OPTAS",
    "OPTEI",
    "OPTEM",
    "OPTES",
    "OPTOU",
    "OQUEA",
    "OQUEI",
    "OQUEM",
    "OQUES",
    "OQUIE",
    "OQUIM",
    "ORACA",
    "ORADA",
    "ORADO",
    "ORAGO",
    "ORAIS",
    "ORAMO",
    "ORANA",
    "ORANO",
    "ORARA",
    "ORAT.",
    "ORATE",
    "ORAVA",
    "ORBAS",
    "ORBOS",
    "ORCAI",
    "ORCAM",
    "ORCAR",
    "ORCAS",
    "ORCAZ",
    "ORCEI",
    "ORCEM",
    "ORCES",
    "ORCOS",
    "ORCOU",
    "ORDEM",
    "ORDOS",
    "OREAI",
    "OREAR",
    "OREBE",
    "OREEI",
    "OREGA",
    "OREIA",
    "OREIE",
    "OREIO",
    "OREIS",
    "ORELA",
    "OREMO",
    "OREOU",
    "ORETE",
    "OREUS",
    "ORFAO",
    "ORFAS",
    "ORFEU",
    "ORFIA",
    "ORFNO",
    "ORGAO",
    "ORGIA",
    "ORGIE",
    "ORGIO",
    "ORIBI",
    "ORICA",
    "ORICO",
    "ORIEL",
    "ORIG.",
    "ORION",
    "ORITA",
    "ORITE",
    "ORIXA",
    "ORIZA",
    "ORJAL",
    "ORKUT",
    "ORLAI",
    "ORLAM",
    "ORLAR",
    "ORLAS",
    "ORLEA",
    "ORLEI",
    "ORLEM",
    "ORLES",
    "ORLON",
    "ORLOU",
    "ORMUZ",
    "ORNAI",
    "ORNAM",
    "ORNAR",
    "ORNAS",
    "ORNEA",
    "ORNEI",
    "ORNEM",
    "ORNES",
    "ORNEU",
    "ORNIS",
    "ORNOU",
    "OROBO",
    "OROCO",
    "OROG.",
    "OROMO",
    "ORON.",
    "OROPA",
    "ORQUE",
    "ORQUI",
    "ORSEU",
    "ORSON",
    "ORTAS",
    "ORTIZ",
    "ORTOL",
    "ORTOS",
    "ORUCO",
    "ORUPA",
    "ORUZU",
    "OSACA",
    "OSANA",
    "OSCAR",
    "OSCAS",
    "OSCES",
    "OSCOS",
    "OSEAS",
    "OSELA",
    "OSGAS",
    "OSIAS",
    "OSIDO",
    "OSLER",
    "OSMAR",
    "OSMAS",
    "OSMIA",
    "OSMIO",
    "OSMOL",
    "OSMOS",
    "OSONA",
    "OSRAM",
    "OSSAE",
    "OSSAS",
    "OSSEA",
    "OSSEO",
    "OSSOS",
    "OSTEA",
    "OSTEO",
    "OSTES",
    "OSTIA",
    "OSTIO",
    "OSTR.",
    "OSTRA",
    "OSTRO",
    "OTAVA",
    "OTECA",
    "OTELO",
    "OTICA",
    "OTICO",
    "OTIMA",
    "OTIMO",
    "OTINA",
    "OTINS",
    "OTITA",
    "OTITE",
    "OTOBA",
    "OTOES",
    "OTOJO",
    "OTOMA",
    "OTOMI",
    "OTONA",
    "OTONI",
    "OTORO",
    "OTOSE",
    "OTOTO",
    "OUARI",
    "OUARU",
    "OUCAM",
    "OUCAO",
    "OUCAS",
    "OUCOS",
    "OUDRE",
    "OUGAI",
    "OUGAM",
    "OUGAR",
    "OUGAS",
    "OUGOU",
    "OUGUE",
    "OURAI",
    "OURAM",
    "OURAR",
    "OURAS",
    "OUREI",
    "OUREM",
    "OURES",
    "OUROS",
    "OUROU",
    "OUSAI",
    "OUSAM",
    "OUSAR",
    "OUSAS",
    "OUSEI",
    "OUSEM",
    "OUSES",
    "OUSIA",
    "OUSIE",
    "OUSIO",
    "OUSOU",
    "OUTAI",
    "OUTAM",
    "OUTAO",
    "OUTAR",
    "OUTAS",
    "OUTEI",
    "OUTEM",
    "OUTES",
    "OUTOU",
    "OUTRA",
    "OUTRO",
    "OUTUS",
    "OUVEM",
    "OUVES",
    "OUVIA",
    "OUVIR",
    "OUVIS",
    "OUVIU",
    "OUXES",
    "OUZES",
    "OUZOS",
    "OVADA",
    "OVADO",
    "OVAIS",
    "OVALA",
    "OVALE",
    "OVALO",
    "OVAMO",
    "OVANA",
    "OVANO",
    "OVARA",
    "OVATE",
    "OVAVA",
    "OVEAS",
    "OVEIS",
    "OVEMO",
    "OVENS",
    "OVEOS",
    "OVEVA",
    "OVINA",
    "OVINO",
    "OVIUA",
    "OVNIS",
    "OVULA",
    "OVULE",
    "OVULO",
    "OXALA",
    "OXALE",
    "OXEBA",
    "OXEOL",
    "OXERA",
    "OXEUS",
    "OXIAO",
    "OXIDA",
    "OXIDE",
    "OXIDO",
    "OXILO",
    "OXIMA",
    "OXINA",
    "OXONO",
    "OXURA",
    "OZEAI",
    "OZEAR",
    "OZEEI",
    "OZEIA",
    "OZEIE",
    "OZEIO",
    "OZENA",
    "OZEOU",
    "OZIAS",
    "OZIEL",
    "OZOLA",
    "OZOLO",
    "OZONA",
    "OZONE",
    "OZONO",
    "OZUEU",
    "P.AE.",
    "P.AL.",
    "PA.G.",
    "PAARI",
    "PABAS",
    "PABLO",
    "PACAA",
    "PACAI",
    "PACAL",
    "PACAO",
    "PACAS",
    "PACAU",
    "PACEM",
    "PACHA",
    "PACHO",
    "PACOL",
    "PACOS",
    "PACTA",
    "PACTE",
    "PACTO",
    "PACUA",
    "PACUI",
    "PACUS",
    "PADAS",
    "PADER",
    "PADES",
    "PADEU",
    "PADMA",
    "PADOA",
    "PADOS",
    "PADRA",
    "PADRE",
    "PADRO",
    "PADUA",
    "PADUS",
    "PAETE",
    "PAFIA",
    "PAFIO",
    "PAFOS",
    "PAFUA",
    "PAGAI",
    "PAGAM",
    "PAGAO",
    "PAGAR",
    "PAGAS",
    "PAGEL",
    "PAGEM",
    "PAGER",
    "PAGEU",
    "PAGG.",
    "PAGOS",
    "PAGOU",
    "PAGRO",
    "PAGS.",
    "PAGUA",
    "PAGUE",
    "PAIAL",
    "PAIAO",
    "PAIAS",
    "PAIBA",
    "PAICA",
    "PAIGC",
    "PAILO",
    "PAINA",
    "PAINS",
    "PAIOL",
    "PAIOS",
    "PAIRA",
    "PAIRE",
    "PAIRO",
    "PAISM",
    "PAITA",
    "PAIVA",
    "PAIVE",
    "PAIVO",
    "PAIXA",
    "PAJAO",
    "PAJAU",
    "PAJEA",
    "PAJEM",
    "PAJES",
    "PAJEU",
    "PALAL",
    "PALAO",
    "PALAR",
    "PALAS",
    "PALAU",
    "PALCO",
    "PALEA",
    "PALEO",
    "PALES",
    "PALHA",
    "PALHE",
    "PALHO",
    "PALIA",
    "PALIE",
    "PALIO",
    "PALIS",
    "PALMA",
    "PALME",
    "PALMO",
    "PALOR",
    "PALOS",
    "PALPA",
    "PALPE",
    "PALPO",
    "PALRA",
    "PALRE",
    "PALRO",
    "PALS.",
    "PALTA",
    "PAMAS",
    "PAMBO",
    "PAMIS",
    "PAMOA",
    "PAMPA",
    "PAMPO",
    "PANAI",
    "PANAL",
    "PANAM",
    "PANAO",
    "PANAR",
    "PANAS",
    "PANAX",
    "PANCA",
    "PANCO",
    "PANDA",
    "PANDE",
    "PANDO",
    "PANEI",
    "PANEM",
    "PANES",
    "PANGA",
    "PANGO",
    "PANHA",
    "PANHE",
    "PANHI",
    "PANIS",
    "PANJA",
    "PANJE",
    "PANOS",
    "PANOU",
    "PANRI",
    "PANRO",
    "PANTA",
    "PANTO",
    "PANUS",
    "PAOLA",
    "PAOLO",
    "PAOLU",
    "PAPAI",
    "PAPAL",
    "PAPAM",
    "PAPAO",
    "PAPAR",
    "PAPAS",
    "PAPEA",
    "PAPEI",
    "PAPEL",
    "PAPEM",
    "PAPES",
    "PAPIA",
    "PAPIE",
    "PAPIO",
    "PAPOS",
    "PAPOU",
    "PAPUA",
    "PAPUO",
    "PAPUS",
    "PAQUA",
    "PAQUE",
    "PAQUI",
    "PARAI",
    "PARAL",
    "PARAM",
    "PARAO",
    "PARAR",
    "PARAS",
    "PARAU",
    "PARBA",
    "PARCA",
    "PARCO",
    "PARDA",
    "PARDE",
    "PARDO",
    "PAREA",
    "PAREI",
    "PAREM",
    "PAREO",
    "PARES",
    "PARGA",
    "PARGO",
    "PARIA",
    "PARIO",
    "PARIR",
    "PARIS",
    "PARIU",
    "PARL.",
    "PARLA",
    "PARLE",
    "PARLO",
    "PARMA",
    "PARN.",
    "PARNA",
    "PARNE",
    "PARNO",
    "PAROL",
    "PAROU",
    "PARRA",
    "PARRE",
    "PARRO",
    "PARSE",
    "PARSI",
    "PART.",
    "PARTA",
    "PARTE",
    "PARTI",
    "PARTO",
    "PARUA",
    "PARUI",
    "PARUM",
    "PARUS",
    "PARVA",
    "PARVI",
    "PARVO",
    "PARVU",
    "PASCA",
    "PASCE",
    "PASCI",
    "PASCO",
    "PASEA",
    "PASEP",
    "PASMA",
    "PASME",
    "PASMO",
    "PASS.",
    "PASSA",
    "PASSE",
    "PASSO",
    "PAST.",
    "PASTA",
    "PASTE",
    "PASTO",
    "PATAI",
    "PATAO",
    "PATAS",
    "PATAU",
    "PATAZ",
    "PATEA",
    "PATEL",
    "PATEM",
    "PATEO",
    "PATER",
    "PATES",
    "PATIA",
    "PATIE",
    "PATIM",
    "PATIO",
    "PATIS",
    "PATOA",
    "PATOS",
    "PATR.",
    "PATUA",
    "PATUM",
    "PAUAL",
    "PAUDA",
    "PAUIS",
    "PAULA",
    "PAULE",
    "PAULO",
    "PAULS",
    "PAUNA",
    "PAURA",
    "PAUSA",
    "PAUSE",
    "PAUSO",
    "PAUTA",
    "PAUTE",
    "PAUTO",
    "PAUXI",
    "PAVAL",
    "PAVAO",
    "PAVAS",
    "PAVES",
    "PAVIA",
    "PAVIO",
    "PAVOA",
    "PAVOL",
    "PAVOR",
    "PAVOS",
    "PAXAU",
    "PAXIA",
    "PAZAO",
    "PAZAS",
    "PAZEA",
    "PAZES",
    "PCDOB",
    "PDVSA",
    "PEACA",
    "PEADA",
    "PEADO",
    "PEAIS",
    "PEALA",
    "PEALE",
    "PEALO",
    "PEANA",
    "PEANE",
    "PEANS",
    "PEARA",
    "PEAVA",
    "PEBAS",
    "PECAI",
    "PECAM",
    "PECAR",
    "PECAS",
    "PECEM",
    "PECHA",
    "PECHE",
    "PECHO",
    "PECOI",
    "PECOU",
    "PECTE",
    "PEDAL",
    "PEDEM",
    "PEDES",
    "PEDIA",
    "PEDIR",
    "PEDIS",
    "PEDIU",
    "PEDOS",
    "PEDR.",
    "PEDRA",
    "PEDRE",
    "PEDRO",
    "PEEIS",
    "PEGAI",
    "PEGAM",
    "PEGAO",
    "PEGAR",
    "PEGAS",
    "PEGMA",
    "PEGOL",
    "PEGOS",
    "PEGOU",
    "PEGUE",
    "PEGUS",
    "PEIAM",
    "PEIAS",
    "PEIDA",
    "PEIDE",
    "PEIDO",
    "PEIEM",
    "PEIES",
    "PEINA",
    "PEINE",
    "PEINO",
    "PEIPA",
    "PEITA",
    "PEITE",
    "PEITO",
    "PEIXA",
    "PEIXE",
    "PEIXO",
    "PEJAI",
    "PEJAM",
    "PEJAR",
    "PEJAS",
    "PEJEI",
    "PEJEM",
    "PEJES",
    "PEJIS",
    "PEJOS",
    "PEJOU",
    "PELAI",
    "PELAM",
    "PELAO",
    "PELAR",
    "PELAS",
    "PELEA",
    "PELEI",
    "PELEM",
    "PELES",
    "PELEU",
    "PELGA",
    "PELIA",
    "PELIO",
    "PELMA",
    "PELOR",
    "PELOS",
    "PELOU",
    "PELTA",
    "PELVE",
    "PEMAS",
    "PEMBA",
    "PEMBE",
    "PEMIS",
    "PEMOM",
    "PEMPA",
    "PENAI",
    "PENAL",
    "PENAM",
    "PENAO",
    "PENAR",
    "PENAS",
    "PENCA",
    "PENCE",
    "PENCO",
    "PENDA",
    "PENDE",
    "PENDI",
    "PENDO",
    "PENEA",
    "PENEI",
    "PENEM",
    "PENES",
    "PENHA",
    "PENIA",
    "PENIO",
    "PENIS",
    "PENOL",
    "PENOU",
    "PENSA",
    "PENSE",
    "PENSO",
    "PENT.",
    "PENTA",
    "PENTE",
    "PEOAS",
    "PEOES",
    "PEONA",
    "PEONE",
    "PEONS",
    "PEOTA",
    "PEOTE",
    "PEPAS",
    "PEPEL",
    "PEPES",
    "PEPIA",
    "PEPLO",
    "PEPOS",
    "PEPSE",
    "PEQUE",
    "PEQUI",
    "PERAL",
    "PERAS",
    "PERAU",
    "PERCA",
    "PERCE",
    "PERCO",
    "PERDA",
    "PERDE",
    "PERDI",
    "PEREA",
    "PERES",
    "PEREZ",
    "PERF.",
    "PERIS",
    "PERLA",
    "PERLE",
    "PERLO",
    "PERMA",
    "PERN.",
    "PERNA",
    "PERNE",
    "PERNO",
    "PERNS",
    "PEROA",
    "PEROS",
    "PERRA",
    "PERRE",
    "PERRO",
    "PERS.",
    "PERSA",
    "PERTO",
    "PERUA",
    "PERUE",
    "PERUM",
    "PERUO",
    "PERUS",
    "PERVA",
    "PERVE",
    "PERVI",
    "PERVO",
    "PESAI",
    "PESAM",
    "PESAO",
    "PESAR",
    "PESAS",
    "PESC.",
    "PESCA",
    "PESCO",
    "PESEI",
    "PESEM",
    "PESES",
    "PESGA",
    "PESGO",
    "PESME",
    "PESOS",
    "PESOU",
    "PESPE",
    "PESQ.",
    "PESS.",
    "PESSE",
    "PESTE",
    "PETAI",
    "PETAL",
    "PETAM",
    "PETAO",
    "PETAR",
    "PETAS",
    "PETEA",
    "PETEI",
    "PETEM",
    "PETER",
    "PETES",
    "PETIA",
    "PETIM",
    "PETIT",
    "PETIU",
    "PETIZ",
    "PETOS",
    "PETOU",
    "PETR.",
    "PETRA",
    "PETRI",
    "PETRO",
    "PETUM",
    "PEUAS",
    "PEUCO",
    "PEUGA",
    "PEUIS",
    "PEULE",
    "PEUMO",
    "PEUVA",
    "PEVAS",
    "PEXAO",
    "PEXAS",
    "PEXEM",
    "PEXES",
    "PEXIA",
    "PEXIM",
    "PEXIS",
    "PEZAO",
    "PEZES",
    "PFUCA",
    "PFUCO",
    "PGTO.",
    "PH.B.",
    "PH.D.",
    "PHNOM",
    "PIABA",
    "PIABE",
    "PIABO",
    "PIACA",
    "PIACO",
    "PIADA",
    "PIADE",
    "PIADO",
    "PIAFA",
    "PIAFE",
    "PIAFO",
    "PIAGA",
    "PIAIA",
    "PIAIS",
    "PIALA",
    "PIALE",
    "PIALO",
    "PIAMO",
    "PIANA",
    "PIANO",
    "PIAPE",
    "PIARA",
    "PIATA",
    "PIAUI",
    "PIAUS",
    "PIAVA",
    "PICAI",
    "PICAL",
    "PICAM",
    "PICAO",
    "PICAR",
    "PICAS",
    "PICAU",
    "PICEA",
    "PICEO",
    "PICHA",
    "PICHE",
    "PICHI",
    "PICHO",
    "PICLE",
    "PICOA",
    "PICOS",
    "PICOU",
    "PICRE",
    "PICRO",
    "PICTA",
    "PICTO",
    "PICUA",
    "PICUE",
    "PICUI",
    "PICUL",
    "PICUM",
    "PICUO",
    "PICUS",
    "PIDAO",
    "PIDAS",
    "PIEGA",
    "PIEGO",
    "PIEIS",
    "PIELA",
    "PIELO",
    "PIEMO",
    "PIERA",
    "PIERE",
    "PIERO",
    "PIESE",
    "PIETA",
    "PIEZE",
    "PIEZO",
    "PIFAI",
    "PIFAM",
    "PIFAO",
    "PIFAR",
    "PIFAS",
    "PIFEI",
    "PIFEM",
    "PIFES",
    "PIFIA",
    "PIFIO",
    "PIFOU",
    "PIGAI",
    "PIGAL",
    "PIGAM",
    "PIGAR",
    "PIGAS",
    "PIGEA",
    "PIGEO",
    "PIGEU",
    "PIGIA",
    "PIGMA",
    "PIGOU",
    "PIGRA",
    "PIGRO",
    "PIGUA",
    "PIGUE",
    "PIINA",
    "PIJIN",
    "PILAI",
    "PILAM",
    "PILAO",
    "PILAR",
    "PILAS",
    "PILAU",
    "PILDA",
    "PILDE",
    "PILDO",
    "PILEA",
    "PILEI",
    "PILEM",
    "PILEO",
    "PILES",
    "PILEU",
    "PILHA",
    "PILHE",
    "PILHO",
    "PILIA",
    "PILIM",
    "PILIO",
    "PILIS",
    "PILMA",
    "PILOU",
    "PILRA",
    "PIMAS",
    "PIMBA",
    "PIMBE",
    "PIMBO",
    "PIMPA",
    "PIMPE",
    "PIMPO",
    "PINAI",
    "PINAM",
    "PINAR",
    "PINAS",
    "PINAZ",
    "PINCA",
    "PINCE",
    "PINCO",
    "PINDA",
    "PINDI",
    "PINDO",
    "PINDU",
    "PINEA",
    "PINEI",
    "PINEL",
    "PINEM",
    "PINEO",
    "PINES",
    "PINEU",
    "PINGA",
    "PINGO",
    "PINHA",
    "PINHE",
    "PINHO",
    "PINOL",
    "PINOS",
    "PINOU",
    "PINT.",
    "PINTA",
    "PINTE",
    "PINTO",
    "PINX.",
    "PIOCA",
    "PIOES",
    "PIOIA",
    "PIOIE",
    "PIOLA",
    "PIOLI",
    "PIONA",
    "PIONO",
    "PIONS",
    "PIORA",
    "PIORE",
    "PIORO",
    "PIOSE",
    "PIPAI",
    "PIPAL",
    "PIPAM",
    "PIPAO",
    "PIPAR",
    "PIPAS",
    "PIPEI",
    "PIPEM",
    "PIPER",
    "PIPES",
    "PIPIA",
    "PIPIE",
    "PIPIO",
    "PIPOU",
    "PIPRA",
    "PIQUA",
    "PIQUE",
    "PIQUI",
    "PIRAI",
    "PIRAL",
    "PIRAM",
    "PIRAO",
    "PIRAR",
    "PIRAS",
    "PIRAU",
    "PIRCA",
    "PIREA",
    "PIREI",
    "PIREM",
    "PIRES",
    "PIREU",
    "PIREX",
    "PIRGO",
    "PIRIA",
    "PIRIO",
    "PIRIS",
    "PIRIZ",
    "PIROA",
    "PIROL",
    "PIROU",
    "PIRRO",
    "PIRUA",
    "PISAI",
    "PISAM",
    "PISAO",
    "PISAR",
    "PISAS",
    "PISC.",
    "PISCA",
    "PISCO",
    "PISEA",
    "PISEI",
    "PISEM",
    "PISEO",
    "PISES",
    "PISEU",
    "PISGA",
    "PISGO",
    "PISOA",
    "PISOE",
    "PISOO",
    "PISOS",
    "PISOU",
    "PISSA",
    "PISTA",
    "PISTE",
    "PISTO",
    "PITAI",
    "PITAM",
    "PITAO",
    "PITAR",
    "PITAS",
    "PITEI",
    "PITEM",
    "PITES",
    "PITEU",
    "PITIA",
    "PITIO",
    "PITIS",
    "PITIU",
    "PITOM",
    "PITON",
    "PITOS",
    "PITOT",
    "PITOU",
    "PITUA",
    "PITUI",
    "PITUS",
    "PIUBA",
    "PIUCA",
    "PIUCO",
    "PIUGO",
    "PIUMA",
    "PIUNA",
    "PIUNS",
    "PIUVA",
    "PIVAS",
    "PIVOS",
    "PIXAS",
    "PIXEA",
    "PIXEL",
    "PIXEU",
    "PIXIS",
    "PIXUA",
    "PIZAS",
    "PIZZ.",
    "PIZZA",
    "PLACA",
    "PLACE",
    "PLAGA",
    "PLANA",
    "PLANE",
    "PLANI",
    "PLANO",
    "PLAPO",
    "PLAT.",
    "PLATO",
    "PLEAS",
    "PLEB.",
    "PLEBE",
    "PLENA",
    "PLENO",
    "PLEON",
    "PLEOS",
    "PLEXO",
    "PLIAS",
    "PLICA",
    "PLICO",
    "PLIDA",
    "PLIDE",
    "PLIDO",
    "PLOCE",
    "PLOME",
    "PLOMO",
    "PLOTA",
    "PLOTE",
    "PLOTO",
    "PLUGA",
    "PLUGE",
    "PLUGO",
    "PLUMA",
    "PLUME",
    "PLUMO",
    "PLURI",
    "PLUSH",
    "PLUTO",
    "PMSPA",
    "PNEUS",
    "PNHIS",
    "PNIGO",
    "PNONS",
    "POACU",
    "POAIA",
    "POARE",
    "POBES",
    "POBRE",
    "POCAI",
    "POCAL",
    "POCAM",
    "POCAO",
    "POCAR",
    "POCAS",
    "POCHA",
    "POCHE",
    "POCHO",
    "POCOS",
    "POCOU",
    "PODAI",
    "PODAL",
    "PODAM",
    "PODAO",
    "PODAR",
    "PODAS",
    "PODEI",
    "PODEM",
    "PODER",
    "PODES",
    "PODEX",
    "PODIA",
    "PODIO",
    "PODOA",
    "PODOI",
    "PODON",
    "PODOU",
    "PODRA",
    "PODRE",
    "POEJO",
    "POEMA",
    "POET.",
    "POETA",
    "POETE",
    "POETO",
    "POFIA",
    "POFOS",
    "POGAS",
    "POGEA",
    "POIAI",
    "POIAL",
    "POIAM",
    "POIAR",
    "POIAS",
    "POICA",
    "POIEI",
    "POIEL",
    "POIEM",
    "POIES",
    "POIOS",
    "POIOU",
    "POISA",
    "POISE",
    "POISO",
    "POITA",
    "POITE",
    "POITO",
    "POJAI",
    "POJAM",
    "POJAR",
    "POJAS",
    "POJEI",
    "POJEM",
    "POJES",
    "POJOU",
    "POLAO",
    "POLAR",
    "POLAS",
    "POLAU",
    "POLCA",
    "POLCO",
    "POLDA",
    "POLDO",
    "POLEA",
    "POLEM",
    "POLEN",
    "POLES",
    "POLEX",
    "POLHA",
    "POLHO",
    "POLIA",
    "POLIM",
    "POLIO",
    "POLIR",
    "POLIS",
    "POLIU",
    "POLJA",
    "POLJE",
    "POLME",
    "POLMO",
    "POLOM",
    "POLOS",
    "POLPA",
    "POLPE",
    "POLPO",
    "POLUA",
    "POLUI",
    "POLUO",
    "POLUX",
    "POLVA",
    "POLVO",
    "POMAL",
    "POMAR",
    "POMAS",
    "POMBA",
    "POMBE",
    "POMBO",
    "POMEA",
    "POMES",
    "POMOS",
    "POMPA",
    "POMPO",
    "PONAS",
    "PONCA",
    "PONCE",
    "PONCO",
    "PONDA",
    "PONDE",
    "PONDO",
    "PONEI",
    "PONES",
    "PONFO",
    "PONGA",
    "PONGO",
    "PONHA",
    "PONHE",
    "PONHO",
    "PONIS",
    "PONJE",
    "PONOM",
    "PONTA",
    "PONTE",
    "PONTO",
    "POOIS",
    "POONE",
    "POPAO",
    "POPAS",
    "POPES",
    "POPIA",
    "POPIE",
    "POPIO",
    "POPOS",
    "POQUE",
    "PORAO",
    "PORAS",
    "PORCA",
    "PORCO",
    "POREI",
    "POREM",
    "PORES",
    "PORIA",
    "PORIO",
    "PORMA",
    "PORME",
    "PORNO",
    "POROS",
    "PORRA",
    "PORRE",
    "PORRO",
    "PORT.",
    "PORTA",
    "PORTE",
    "PORTO",
    "POSAI",
    "POSAM",
    "POSAR",
    "POSAS",
    "POSCA",
    "POSEI",
    "POSEM",
    "POSES",
    "POSOU",
    "POSPO",
    "POSS.",
    "POSSA",
    "POSSE",
    "POSSO",
    "POSTA",
    "POSTE",
    "POSTO",
    "POTAO",
    "POTAS",
    "POTEA",
    "POTES",
    "POTIA",
    "POTIM",
    "POTIS",
    "POTOS",
    "POTRA",
    "POTRO",
    "POTUM",
    "POTUS",
    "POUCA",
    "POUCO",
    "POULA",
    "POULE",
    "POULO",
    "POUPA",
    "POUPE",
    "POUPO",
    "POUSA",
    "POUSE",
    "POUSO",
    "POUTA",
    "POUTE",
    "POUTO",
    "POVAO",
    "POVOA",
    "POVOE",
    "POVOO",
    "POVOS",
    "POXAS",
    "POXIM",
    "PRAC.",
    "PRACA",
    "PRADO",
    "PRAGA",
    "PRAGO",
    "PRAIA",
    "PRAIS",
    "PRAJA",
    "PRAMA",
    "PRANA",
    "PRANS",
    "PRASE",
    "PRAT.",
    "PRATA",
    "PRATO",
    "PRAVA",
    "PRAVO",
    "PRAXA",
    "PRAXE",
    "PRAXO",
    "PRAZA",
    "PRAZE",
    "PRAZO",
    "PREAI",
    "PREAR",
    "PREAS",
    "PREC.",
    "PRECE",
    "PRECO",
    "PRED.",
    "PREEI",
    "PREF.",
    "PREGA",
    "PREGO",
    "PREIA",
    "PREIE",
    "PREIO",
    "PRELO",
    "PREMA",
    "PREME",
    "PREMI",
    "PREMO",
    "PREON",
    "PREOU",
    "PREP.",
    "PREPO",
    "PRES.",
    "PRESA",
    "PRESE",
    "PRESO",
    "PRET.",
    "PRETA",
    "PRETO",
    "PREV.",
    "PREVE",
    "PREVI",
    "PREZA",
    "PREZE",
    "PREZO",
    "PRIAO",
    "PRIAS",
    "PRIM.",
    "PRIMA",
    "PRIME",
    "PRIMI",
    "PRIMO",
    "PRINO",
    "PRION",
    "PRIOR",
    "PRITA",
    "PRIUS",
    "PRIV.",
    "PRIVA",
    "PRIVE",
    "PRIVO",
    "PROAI",
    "PROAL",
    "PROAM",
    "PROAR",
    "PROAS",
    "PROBA",
    "PROBO",
    "PROC.",
    "PROCA",
    "PROCO",
    "PROD.",
    "PROEI",
    "PROEM",
    "PROES",
    "PROF.",
    "PROIZ",
    "PROLE",
    "PROM.",
    "PRON.",
    "PRONO",
    "PROOU",
    "PROP.",
    "PROPO",
    "PROSA",
    "PROSE",
    "PROSO",
    "PROT.",
    "PROTO",
    "PROV.",
    "PROVA",
    "PROVE",
    "PROVI",
    "PROVO",
    "PROX.",
    "PROZA",
    "PROZE",
    "PROZO",
    "PRUAM",
    "PRUAS",
    "PRUCA",
    "PRUEM",
    "PRUGA",
    "PRUIA",
    "PRUIR",
    "PRUIS",
    "PRUIU",
    "PRUMA",
    "PRUME",
    "PRUMO",
    "PRUNO",
    "PRUNS",
    "PRURA",
    "PRURE",
    "PRURI",
    "PRURO",
    "PSALO",
    "PSAMA",
    "PSARA",
    "PSARO",
    "PSECA",
    "PSESA",
    "PSESO",
    "PSETA",
    "PSETO",
    "PSIC.",
    "PSICA",
    "PSICO",
    "PSILA",
    "PSILO",
    "PSIQ.",
    "PSOAS",
    "PSOCO",
    "PSOFO",
    "PSOLO",
    "PSORA",
    "PTDOB",
    "PTENA",
    "PTERE",
    "PTERO",
    "PTISE",
    "PTOSE",
    "PUACO",
    "PUACU",
    "PUADA",
    "PUADO",
    "PUAIA",
    "PUAIS",
    "PUAMO",
    "PUARA",
    "PUAVA",
    "PUBAI",
    "PUBAM",
    "PUBAR",
    "PUBAS",
    "PUBEI",
    "PUBEM",
    "PUBES",
    "PUBIS",
    "PUBL.",
    "PUBOU",
    "PUCAS",
    "PUCHA",
    "PUCHO",
    "PUCOS",
    "PUCTO",
    "PUCTU",
    "PUCUS",
    "PUDER",
    "PUDIM",
    "PUDLA",
    "PUDLE",
    "PUDLO",
    "PUDOR",
    "PUDUS",
    "PUEIS",
    "PUELA",
    "PUEMO",
    "PUERA",
    "PUERI",
    "PUFES",
    "PUFOS",
    "PUGIL",
    "PUGNA",
    "PUGNE",
    "PUGNO",
    "PUIAM",
    "PUIAS",
    "PUIDA",
    "PUIDO",
    "PUIRA",
    "PUITA",
    "PUITE",
    "PUITO",
    "PUJAI",
    "PUJAM",
    "PUJAR",
    "PUJAS",
    "PUJEI",
    "PUJEM",
    "PUJES",
    "PUJOU",
    "PULAI",
    "PULAM",
    "PULAO",
    "PULAR",
    "PULAS",
    "PULEA",
    "PULEI",
    "PULEM",
    "PULES",
    "PULEX",
    "PULGA",
    "PULGO",
    "PULHA",
    "PULHE",
    "PULHO",
    "PULIM",
    "PULIZ",
    "PULOS",
    "PULOU",
    "PULSA",
    "PULSE",
    "PULSO",
    "PULTA",
    "PULTE",
    "PULTO",
    "PULV.",
    "PUMAS",
    "PUMBA",
    "PUMBO",
    "PUNAM",
    "PUNAS",
    "PUNCA",
    "PUNCE",
    "PUNCO",
    "PUNDE",
    "PUNEM",
    "PUNES",
    "PUNGA",
    "PUNGE",
    "PUNGI",
    "PUNHA",
    "PUNHO",
    "PUNIA",
    "PUNIR",
    "PUNIS",
    "PUNIU",
    "PUNJA",
    "PUNJO",
    "PUNKS",
    "PUNRA",
    "PUNTA",
    "PUNTE",
    "PUNTO",
    "PUNUS",
    "PUPAI",
    "PUPAL",
    "PUPAM",
    "PUPAR",
    "PUPAS",
    "PUPEI",
    "PUPEM",
    "PUPES",
    "PUPIA",
    "PUPOU",
    "PUPUS",
    "PURAL",
    "PURAS",
    "PUREU",
    "PURGA",
    "PURGO",
    "PURIM",
    "PURIS",
    "PUROS",
    "PURUI",
    "PURUS",
    "PUSER",
    "PUTAL",
    "PUTAS",
    "PUTEA",
    "PUTIS",
    "PUTOS",
    "PUVAS",
    "PUVIA",
    "PUVIS",
    "PUXAI",
    "PUXAM",
    "PUXAO",
    "PUXAR",
    "PUXAS",
    "PUXEI",
    "PUXEM",
    "PUXES",
    "PUXOS",
    "PUXOU",
    "PUZOS",
    "QAEDA",
    "QATAR",
    "QQ.V.",
    "QUACO",
    "QUADA",
    "QUADO",
    "QUAGA",
    "QUAIS",
    "QUALE",
    "QUALI",
    "QUARA",
    "QUARE",
    "QUARI",
    "QUARK",
    "QUARO",
    "QUASA",
    "QUASE",
    "QUATA",
    "QUATI",
    "QUBIT",
    "QUEBA",
    "QUEBE",
    "QUECA",
    "QUECE",
    "QUECI",
    "QUEDA",
    "QUEDE",
    "QUEDO",
    "QUEIA",
    "QUELA",
    "QUELE",
    "QUELO",
    "QUEMI",
    "QUEMO",
    "QUENA",
    "QUENO",
    "QUEPE",
    "QUEPI",
    "QUERA",
    "QUERE",
    "QUERI",
    "QUERO",
    "QUETE",
    "QUETO",
    "QUEZA",
    "QUIAS",
    "QUIBA",
    "QUIBE",
    "QUICA",
    "QUICE",
    "QUICO",
    "QUIDO",
    "QUIJA",
    "QUILE",
    "QUILI",
    "QUILO",
    "QUIM.",
    "QUIME",
    "QUIMI",
    "QUIMO",
    "QUINA",
    "QUINE",
    "QUINO",
    "QUIOS",
    "QUIPA",
    "QUIPE",
    "QUIPO",
    "QUIRA",
    "QUIRE",
    "QUIRI",
    "QUIRO",
    "QUITA",
    "QUITE",
    "QUITI",
    "QUITO",
    "QUIUI",
    "QUIVI",
    "QUIXA",
    "QUIXO",
    "QUIZA",
    "QUOTA",
    "QUOTE",
    "QUOTO",
    "RABAL",
    "RABAO",
    "RABAT",
    "RABAZ",
    "RABDO",
    "RABEA",
    "RABEL",
    "RABIA",
    "RABIE",
    "RABIL",
    "RABIO",
    "RABIS",
    "RABOS",
    "RACAO",
    "RACAS",
    "RACAU",
    "RACEA",
    "RACHA",
    "RACHE",
    "RACHO",
    "RACIO",
    "RACOA",
    "RACOE",
    "RACOO",
    "RACOR",
    "RACUM",
    "RADAO",
    "RADAR",
    "RADAS",
    "RADIA",
    "RADIE",
    "RADIO",
    "RADOM",
    "RADON",
    "RAFAI",
    "RAFAM",
    "RAFAR",
    "RAFAS",
    "RAFEI",
    "RAFEM",
    "RAFES",
    "RAFFA",
    "RAFFE",
    "RAFFO",
    "RAFIA",
    "RAFIE",
    "RAFIO",
    "RAFIS",
    "RAFOU",
    "RAGAS",
    "RAGAU",
    "RAGIA",
    "RAGIO",
    "RAGLA",
    "RAGUE",
    "RAGUS",
    "RAIAI",
    "RAIAL",
    "RAIAM",
    "RAIAO",
    "RAIAR",
    "RAIAS",
    "RAIDE",
    "RAIEI",
    "RAIEM",
    "RAIES",
    "RAILE",
    "RAIOM",
    "RAIOS",
    "RAIOU",
    "RAISA",
    "RAITA",
    "RAIVA",
    "RAIVE",
    "RAIVO",
    "RAJAI",
    "RAJAM",
    "RAJAO",
    "RAJAR",
    "RAJAS",
    "RAJEI",
    "RAJEM",
    "RAJES",
    "RAJOU",
    "RALAI",
    "RALAM",
    "RALAO",
    "RALAR",
    "RALAS",
    "RALEA",
    "RALEI",
    "RALEM",
    "RALES",
    "RALHA",
    "RALHE",
    "RALHO",
    "RALIS",
    "RALL.",
    "RALOS",
    "RALOU",
    "RAMAI",
    "RAMAL",
    "RAMAM",
    "RAMAO",
    "RAMAR",
    "RAMAS",
    "RAMBO",
    "RAMEA",
    "RAMEI",
    "RAMEM",
    "RAMEO",
    "RAMES",
    "RAMEU",
    "RAMIE",
    "RAMIS",
    "RAMNO",
    "RAMON",
    "RAMOS",
    "RAMOU",
    "RAMPA",
    "RANAL",
    "RANAS",
    "RANCA",
    "RANCE",
    "RANCO",
    "RANES",
    "RANFA",
    "RANFE",
    "RANFO",
    "RANGA",
    "RANGE",
    "RANGI",
    "RANGO",
    "RANHA",
    "RANHE",
    "RANHO",
    "RANIS",
    "RANJA",
    "RANJO",
    "RANUS",
    "RAPAI",
    "RAPAM",
    "RAPAO",
    "RAPAR",
    "RAPAS",
    "RAPAZ",
    "RAPEI",
    "RAPEL",
    "RAPEM",
    "RAPES",
    "RAPIA",
    "RAPIE",
    "RAPIO",
    "RAPOU",
    "RAPTA",
    "RAPTE",
    "RAPTO",
    "RAQUE",
    "RAQUI",
    "RARAI",
    "RARAM",
    "RARAR",
    "RARAS",
    "RAREA",
    "RAREI",
    "RAREM",
    "RARES",
    "RAROS",
    "RAROU",
    "RASAI",
    "RASAM",
    "RASAO",
    "RASAR",
    "RASAS",
    "RASCA",
    "RASCO",
    "RASEI",
    "RASEM",
    "RASES",
    "RASGA",
    "RASGO",
    "RASOS",
    "RASOU",
    "RASPA",
    "RASPE",
    "RASPO",
    "RASTA",
    "RASTE",
    "RASTO",
    "RATAI",
    "RATAM",
    "RATAO",
    "RATAR",
    "RATAS",
    "RATEA",
    "RATEI",
    "RATEL",
    "RATEM",
    "RATES",
    "RATIM",
    "RATIO",
    "RATIS",
    "RATOS",
    "RATOU",
    "RATUS",
    "RAUDA",
    "RAULI",
    "RAUSA",
    "RAUSE",
    "RAUSO",
    "RAVAS",
    "RAVES",
    "RAVOS",
    "RAWLS",
    "RAXAS",
    "RAZAO",
    "RAZIA",
    "RAZIE",
    "RAZIO",
    "RAZOA",
    "RAZOE",
    "RAZOO",
    "REAGE",
    "REAGI",
    "REAIS",
    "REAJA",
    "REAJO",
    "REALE",
    "REAME",
    "REATA",
    "REATE",
    "REATO",
    "REAVE",
    "REBAI",
    "REBAM",
    "REBAR",
    "REBAS",
    "REBEI",
    "REBEM",
    "REBES",
    "REBOA",
    "REBOE",
    "REBOO",
    "REBOU",
    "REBUS",
    "RECAI",
    "RECAM",
    "RECAO",
    "RECAR",
    "RECAS",
    "RECEA",
    "RECEM",
    "RECHA",
    "RECHO",
    "RECIA",
    "RECIO",
    "RECOS",
    "RECOU",
    "RECRU",
    "RECTA",
    "RECTO",
    "RECUA",
    "RECUE",
    "RECUO",
    "REDAR",
    "REDEA",
    "REDEM",
    "REDES",
    "REDIA",
    "REDIL",
    "REDIS",
    "REDOR",
    "REDOX",
    "REDRA",
    "REDRE",
    "REDRO",
    "REDUZ",
    "REFAZ",
    "REFEM",
    "REFEZ",
    "REFIA",
    "REFIL",
    "REFIS",
    "REFIZ",
    "REFL.",
    "REFLA",
    "REFLE",
    "REFLO",
    "REGAI",
    "REGAL",
    "REGAM",
    "REGAO",
    "REGAR",
    "REGAS",
    "REGEI",
    "REGEM",
    "REGER",
    "REGES",
    "REGEU",
    "REGIA",
    "REGIE",
    "REGIO",
    "REGIS",
    "REGMA",
    "REGOA",
    "REGOE",
    "REGOO",
    "REGOS",
    "REGOU",
    "REGRA",
    "REGRE",
    "REGRO",
    "REGUA",
    "REGUE",
    "REICH",
    "REIDA",
    "REIDE",
    "REIMA",
    "REINA",
    "REINE",
    "REINO",
    "REIRA",
    "REIVO",
    "REIXA",
    "REIXE",
    "REIXO",
    "REJAM",
    "REJAO",
    "REJAS",
    "RELAI",
    "RELAM",
    "RELAR",
    "RELAS",
    "RELAX",
    "RELEI",
    "RELEM",
    "RELER",
    "RELES",
    "RELEU",
    "RELFA",
    "RELHA",
    "RELHE",
    "RELHO",
    "RELIA",
    "RELOU",
    "RELUZ",
    "RELVA",
    "RELVE",
    "RELVO",
    "REMAI",
    "REMAL",
    "REMAM",
    "REMAR",
    "REMAS",
    "REMEI",
    "REMEL",
    "REMEM",
    "REMES",
    "REMIA",
    "REMIR",
    "REMIS",
    "REMIU",
    "REMIX",
    "REMOA",
    "REMOE",
    "REMOI",
    "REMOO",
    "REMOS",
    "REMOU",
    "RENAL",
    "RENAN",
    "RENAO",
    "RENAS",
    "RENDA",
    "RENDE",
    "RENDI",
    "RENDO",
    "RENGA",
    "RENGE",
    "RENGO",
    "RENHA",
    "RENHE",
    "RENHI",
    "RENHO",
    "RENIO",
    "RENOS",
    "RENTA",
    "RENTE",
    "RENTO",
    "RENUA",
    "RENUI",
    "RENUO",
    "RENZO",
    "REOGE",
    "REPAS",
    "REPES",
    "REPIA",
    "REPLO",
    "REPOE",
    "REPOR",
    "REPOS",
    "REPTA",
    "REPTE",
    "REPTO",
    "REPUS",
    "REQUE",
    "RERIU",
    "RESES",
    "RESGA",
    "RESGO",
    "RESMA",
    "RESME",
    "RESMO",
    "RESOS",
    "RESP.",
    "RESPE",
    "RESPO",
    "RESSA",
    "REST.",
    "RESTA",
    "RESTE",
    "RESTO",
    "RETAI",
    "RETAL",
    "RETAM",
    "RETAR",
    "RETAS",
    "RETEA",
    "RETEI",
    "RETEM",
    "RETER",
    "RETES",
    "RETOR",
    "RETOS",
    "RETOU",
    "RETRE",
    "RETRO",
    "REUMA",
    "REUNA",
    "REUNE",
    "REUNI",
    "REUNO",
    "REUSO",
    "REVEL",
    "REVEM",
    "REVER",
    "REVES",
    "REVEZ",
    "REVIA",
    "REVIR",
    "REVIU",
    "REVOA",
    "REVOO",
    "REXES",
    "REXIA",
    "REXIO",
    "REYES",
    "REZAI",
    "REZAM",
    "REZAO",
    "REZAR",
    "REZAS",
    "REZEI",
    "REZEM",
    "REZES",
    "REZOU",
    "RIADE",
    "RIAIS",
    "RIAMO",
    "RIANA",
    "RIANE",
    "RIANO",
    "RIATA",
    "RIBAI",
    "RIBAM",
    "RIBAR",
    "RIBAS",
    "RIBEI",
    "RIBEM",
    "RIBES",
    "RIBLA",
    "RIBOU",
    "RIBUS",
    "RICAI",
    "RICAM",
    "RICAO",
    "RICAR",
    "RICAS",
    "RICEI",
    "RICEM",
    "RICES",
    "RICIA",
    "RICIO",
    "RICOL",
    "RICOS",
    "RICOU",
    "RICTO",
    "RIDAS",
    "RIDES",
    "RIDOR",
    "RIDOS",
    "RIEIS",
    "RIELA",
    "RIETE",
    "RIFAI",
    "RIFAM",
    "RIFAO",
    "RIFAR",
    "RIFAS",
    "RIFEI",
    "RIFEM",
    "RIFES",
    "RIFEU",
    "RIFLA",
    "RIFLE",
    "RIFLO",
    "RIFOU",
    "RIFTE",
    "RIGAI",
    "RIGAM",
    "RIGAR",
    "RIGAS",
    "RIGEL",
    "RIGIA",
    "RIGIO",
    "RIGOL",
    "RIGOR",
    "RIGOS",
    "RIGOU",
    "RIGUE",
    "RIJAI",
    "RIJAL",
    "RIJAM",
    "RIJAO",
    "RIJAR",
    "RIJAS",
    "RIJEI",
    "RIJEM",
    "RIJES",
    "RIJOS",
    "RIJOU",
    "RILAI",
    "RILAM",
    "RILAR",
    "RILAS",
    "RILDO",
    "RILEI",
    "RILEM",
    "RILES",
    "RILHA",
    "RILHE",
    "RILHO",
    "RILOS",
    "RILOU",
    "RIMAI",
    "RIMAM",
    "RIMAR",
    "RIMAS",
    "RIMEI",
    "RIMEL",
    "RIMEM",
    "RIMES",
    "RIMOS",
    "RIMOU",
    "RINAL",
    "RINAS",
    "RINCA",
    "RINCE",
    "RINCO",
    "RINDO",
    "RINFA",
    "RINFE",
    "RINFO",
    "RINGE",
    "RINGI",
    "RINGO",
    "RINHA",
    "RINHE",
    "RINHO",
    "RINIA",
    "RINIO",
    "RINJA",
    "RINJO",
    "RINOS",
    "RINTO",
    "RIOJA",
    "RIOLA",
    "RIPAI",
    "RIPAL",
    "RIPAM",
    "RIPAO",
    "RIPAR",
    "RIPAS",
    "RIPEI",
    "RIPEM",
    "RIPES",
    "RIPEU",
    "RIPIA",
    "RIPIO",
    "RIPOU",
    "RIQUE",
    "RIRAM",
    "RIRAO",
    "RIRAS",
    "RIREI",
    "RIREM",
    "RIRES",
    "RIRIA",
    "RIRMO",
    "RISAO",
    "RISAS",
    "RISCA",
    "RISCO",
    "RISOR",
    "RISOS",
    "RISSA",
    "RISSE",
    "RISSO",
    "RISTE",
    "RITAO",
    "RITAS",
    "RITIO",
    "RITMA",
    "RITME",
    "RITMO",
    "RITON",
    "RITOS",
    "RIUTA",
    "RIVAL",
    "RIVEA",
    "RIXAI",
    "RIXAM",
    "RIXAR",
    "RIXAS",
    "RIXEI",
    "RIXEM",
    "RIXES",
    "RIXOU",
    "RIZAI",
    "RIZAM",
    "RIZAR",
    "RIZAS",
    "RIZEI",
    "RIZEM",
    "RIZES",
    "RIZOU",
    "ROAIS",
    "ROALA",
    "ROALO",
    "ROAMO",
    "ROANA",
    "ROBAO",
    "ROBAZ",
    "ROBER",
    "ROBES",
    "ROBIA",
    "ROBIN",
    "ROBLE",
    "ROBOR",
    "ROBOS",
    "ROBOT",
    "ROBUR",
    "ROCAI",
    "ROCAL",
    "ROCAM",
    "ROCAO",
    "ROCAR",
    "ROCAS",
    "ROCAZ",
    "ROCEI",
    "ROCEM",
    "ROCES",
    "ROCHA",
    "ROCIA",
    "ROCIE",
    "ROCIM",
    "ROCIO",
    "ROCLA",
    "ROCLE",
    "ROCLO",
    "ROCOU",
    "RODAI",
    "RODAL",
    "RODAM",
    "RODAO",
    "RODAR",
    "RODAS",
    "RODEA",
    "RODEI",
    "RODEL",
    "RODEM",
    "RODEO",
    "RODES",
    "RODIM",
    "RODIO",
    "RODOS",
    "RODOU",
    "ROEIS",
    "ROEMO",
    "ROERA",
    "ROFOS",
    "ROGAI",
    "ROGAL",
    "ROGAM",
    "ROGAR",
    "ROGAS",
    "ROGEM",
    "ROGER",
    "ROGES",
    "ROGOS",
    "ROGOU",
    "ROGUE",
    "ROIAM",
    "ROIAS",
    "ROIDA",
    "ROIDO",
    "ROILA",
    "ROJAI",
    "ROJAM",
    "ROJAO",
    "ROJAR",
    "ROJAS",
    "ROJEI",
    "ROJEM",
    "ROJES",
    "ROJOU",
    "ROLAI",
    "ROLAM",
    "ROLAO",
    "ROLAR",
    "ROLAS",
    "ROLAZ",
    "ROLDA",
    "ROLDE",
    "ROLDO",
    "ROLEI",
    "ROLEM",
    "ROLES",
    "ROLHA",
    "ROLHE",
    "ROLHO",
    "ROLIM",
    "ROLIS",
    "ROLOS",
    "ROLOU",
    "ROMAO",
    "ROMAR",
    "ROMAS",
    "ROMBO",
    "ROMEU",
    "ROMN.",
    "ROMPA",
    "ROMPE",
    "ROMPI",
    "ROMPO",
    "RONAN",
    "RONCA",
    "RONCO",
    "RONDA",
    "RONDE",
    "RONDO",
    "RONEI",
    "RONES",
    "RONGA",
    "RONGE",
    "RONGO",
    "RONHA",
    "RONIM",
    "RONOS",
    "RONTO",
    "ROPIA",
    "ROQUE",
    "RORAI",
    "RORAM",
    "RORAR",
    "RORAS",
    "ROREI",
    "ROREM",
    "RORES",
    "ROROU",
    "ROSAI",
    "ROSAL",
    "ROSAM",
    "ROSAO",
    "ROSAR",
    "ROSAS",
    "ROSCA",
    "ROSCO",
    "ROSEA",
    "ROSEI",
    "ROSEM",
    "ROSEO",
    "ROSES",
    "ROSIA",
    "ROSIO",
    "ROSNA",
    "ROSNE",
    "ROSNO",
    "ROSOU",
    "ROSTA",
    "ROSTE",
    "ROSTI",
    "ROSTO",
    "ROTAI",
    "ROTAM",
    "ROTAR",
    "ROTAS",
    "ROTEA",
    "ROTEI",
    "ROTEM",
    "ROTES",
    "ROTIA",
    "ROTIM",
    "ROTOR",
    "ROTOS",
    "ROTOU",
    "ROUBA",
    "ROUBE",
    "ROUBO",
    "ROUCA",
    "ROUCE",
    "ROUCO",
    "ROUEN",
    "ROUGE",
    "ROUND",
    "ROUPA",
    "ROUPE",
    "ROUPO",
    "ROXAS",
    "ROXEA",
    "ROXOS",
    "RUACA",
    "RUADA",
    "RUADO",
    "RUAIS",
    "RUAMO",
    "RUANA",
    "RUANO",
    "RUARA",
    "RUAVA",
    "RUBAI",
    "RUBAO",
    "RUBEA",
    "RUBEN",
    "RUBEO",
    "RUBIA",
    "RUBIM",
    "RUBIO",
    "RUBIS",
    "RUBLO",
    "RUBOR",
    "RUBOS",
    "RUBRA",
    "RUBRO",
    "RUCAI",
    "RUCAM",
    "RUCAR",
    "RUCAS",
    "RUCEI",
    "RUCEM",
    "RUCES",
    "RUCHE",
    "RUCOS",
    "RUCOU",
    "RUDAO",
    "RUDAS",
    "RUDES",
    "RUDEZ",
    "RUDOS",
    "RUEIS",
    "RUELA",
    "RUEMO",
    "RUFAI",
    "RUFAM",
    "RUFAO",
    "RUFAR",
    "RUFAS",
    "RUFEI",
    "RUFEM",
    "RUFES",
    "RUFIA",
    "RUFIE",
    "RUFIO",
    "RUFLA",
    "RUFLE",
    "RUFLO",
    "RUFOL",
    "RUFOS",
    "RUFOU",
    "RUGAI",
    "RUGAM",
    "RUGAR",
    "RUGAS",
    "RUGBI",
    "RUGBY",
    "RUGEM",
    "RUGES",
    "RUGIA",
    "RUGIO",
    "RUGIR",
    "RUGIS",
    "RUGIU",
    "RUGOU",
    "RUGUE",
    "RUIAM",
    "RUIAS",
    "RUIDA",
    "RUIDE",
    "RUIDO",
    "RUIMO",
    "RUINA",
    "RUINS",
    "RUIRA",
    "RUIVA",
    "RUIVO",
    "RUJAM",
    "RUJAS",
    "RULAI",
    "RULAM",
    "RULAR",
    "RULAS",
    "RULEI",
    "RULEM",
    "RULES",
    "RULHA",
    "RULHE",
    "RULHO",
    "RULOU",
    "RULUL",
    "RUMAI",
    "RUMAM",
    "RUMAR",
    "RUMAS",
    "RUMBA",
    "RUMBE",
    "RUMBO",
    "RUMEI",
    "RUMEM",
    "RUMEN",
    "RUMES",
    "RUMEX",
    "RUMIA",
    "RUMIE",
    "RUMIO",
    "RUMIS",
    "RUMOR",
    "RUMOS",
    "RUMOU",
    "RUMPI",
    "RUNAS",
    "RUNCO",
    "RUNDO",
    "RUNFA",
    "RUNFE",
    "RUNFO",
    "RUNGA",
    "RUNHA",
    "RUNHE",
    "RUNHO",
    "RUNIM",
    "RUNLE",
    "RUNOS",
    "RUNTO",
    "RUOES",
    "RUOLZ",
    "RUPAI",
    "RUPAM",
    "RUPAR",
    "RUPAS",
    "RUPEI",
    "RUPEM",
    "RUPES",
    "RUPIA",
    "RUPOU",
    "RUPSA",
    "RUPTA",
    "RUPTO",
    "RUPUL",
    "RURAL",
    "RUSAS",
    "RUSCO",
    "RUSGA",
    "RUSGO",
    "RUSMA",
    "RUSS.",
    "RUSSA",
    "RUSSE",
    "RUSSO",
    "RUST.",
    "RUSTA",
    "RUSTE",
    "RUSTI",
    "RUSTO",
    "RUTAI",
    "RUTAL",
    "RUTAM",
    "RUTAR",
    "RUTAS",
    "RUTEA",
    "RUTEI",
    "RUTEM",
    "RUTES",
    "RUTIM",
    "RUTOU",
    "RUTUL",
    "S.VV.",
    "SAARA",
    "SABAL",
    "SABAO",
    "SABEI",
    "SABEM",
    "SABER",
    "SABES",
    "SABEU",
    "SABIA",
    "SABIN",
    "SABIO",
    "SABIR",
    "SABIU",
    "SABLE",
    "SABOI",
    "SABOR",
    "SABRA",
    "SABRE",
    "SACAI",
    "SACAL",
    "SACAM",
    "SACAO",
    "SACAR",
    "SACAS",
    "SACES",
    "SACHA",
    "SACHE",
    "SACHO",
    "SACIA",
    "SACIE",
    "SACIO",
    "SACIS",
    "SACOS",
    "SACOU",
    "SACRA",
    "SACRE",
    "SACRO",
    "SACTA",
    "SACTI",
    "SACUE",
    "SADAL",
    "SADAO",
    "SADAS",
    "SADES",
    "SADIA",
    "SADIM",
    "SADIO",
    "SADIS",
    "SADOC",
    "SADOS",
    "SADRA",
    "SADUS",
    "SAEIS",
    "SAETA",
    "SAFAI",
    "SAFAM",
    "SAFAO",
    "SAFAR",
    "SAFAS",
    "SAFEA",
    "SAFEI",
    "SAFEM",
    "SAFEO",
    "SAFER",
    "SAFES",
    "SAFIA",
    "SAFIO",
    "SAFOS",
    "SAFOU",
    "SAFRA",
    "SAFRE",
    "SAFUS",
    "SAGAI",
    "SAGAS",
    "SAGAZ",
    "SAGES",
    "SAGEZ",
    "SAGIA",
    "SAGIO",
    "SAGMA",
    "SAGOS",
    "SAGR.",
    "SAGRA",
    "SAGRE",
    "SAGRO",
    "SAGUA",
    "SAGUI",
    "SAGUM",
    "SAGUS",
    "SAIAL",
    "SAIAM",
    "SAIAO",
    "SAIAS",
    "SAIBA",
    "SAIBO",
    "SAICA",
    "SAICO",
    "SAIDA",
    "SAIDE",
    "SAIDO",
    "SAIGA",
    "SAIJE",
    "SAIMO",
    "SAINA",
    "SAINE",
    "SAINO",
    "SAINS",
    "SAINT",
    "SAIPE",
    "SAIRA",
    "SAIRE",
    "SAIRI",
    "SAITA",
    "SAIUM",
    "SAIVA",
    "SAIXE",
    "SAJUM",
    "SAJUS",
    "SALAM",
    "SALAO",
    "SALAS",
    "SALAZ",
    "SALCA",
    "SALDA",
    "SALDE",
    "SALDO",
    "SALEM",
    "SALES",
    "SALGA",
    "SALGO",
    "SALHA",
    "SALHE",
    "SALHO",
    "SALIA",
    "SALIO",
    "SALIX",
    "SALMA",
    "SALMI",
    "SALMO",
    "SALOA",
    "SALOL",
    "SALOS",
    "SALPA",
    "SALS.",
    "SALSA",
    "SALSE",
    "SALSO",
    "SALTA",
    "SALTE",
    "SALTO",
    "SALUS",
    "SALVA",
    "SALVE",
    "SALVO",
    "SAMAO",
    "SAMAS",
    "SAMBA",
    "SAMBE",
    "SAMBO",
    "SAMEA",
    "SAMEU",
    "SAMIA",
    "SAMIO",
    "SAMOA",
    "SAMOS",
    "SAMPA",
    "SAMPE",
    "SAMPO",
    "SAMUR",
    "SANAI",
    "SANAM",
    "SANAR",
    "SANAS",
    "SANCA",
    "SANCO",
    "SANDA",
    "SANDE",
    "SANDI",
    "SANDO",
    "SANEA",
    "SANEI",
    "SANEM",
    "SANES",
    "SANGA",
    "SANGO",
    "SANHA",
    "SANIE",
    "SANIO",
    "SANJA",
    "SANJE",
    "SANJO",
    "SANOU",
    "SANS.",
    "SANSA",
    "SANTA",
    "SANTE",
    "SANTO",
    "SAPAI",
    "SAPAL",
    "SAPAM",
    "SAPAO",
    "SAPAR",
    "SAPAS",
    "SAPEA",
    "SAPEI",
    "SAPEM",
    "SAPES",
    "SAPEU",
    "SAPIA",
    "SAPIM",
    "SAPIO",
    "SAPOS",
    "SAPOU",
    "SAPOV",
    "SAPRO",
    "SAPUA",
    "SAPUS",
    "SAQUE",
    "SAQUI",
    "SARAH",
    "SARAI",
    "SARAM",
    "SARAN",
    "SARAO",
    "SARAR",
    "SARAS",
    "SARAU",
    "SARCA",
    "SARCO",
    "SARDA",
    "SARDE",
    "SARDO",
    "SAREI",
    "SAREM",
    "SARES",
    "SARG.",
    "SARGA",
    "SARGO",
    "SARIA",
    "SARIS",
    "SARJA",
    "SARJE",
    "SARJO",
    "SARNA",
    "SARNE",
    "SARNO",
    "SAROE",
    "SAROS",
    "SAROU",
    "SARPA",
    "SARPE",
    "SARPO",
    "SARRA",
    "SARRE",
    "SARRO",
    "SARTA",
    "SARTE",
    "SARTO",
    "SARUA",
    "SARUE",
    "SARUG",
    "SARUS",
    "SASSA",
    "SASSE",
    "SASSO",
    "SATES",
    "SATIS",
    "SAUAS",
    "SAUBA",
    "SAUCO",
    "SAUDA",
    "SAUDE",
    "SAUDI",
    "SAUDO",
    "SAUIA",
    "SAUIM",
    "SAUIS",
    "SAULO",
    "SAUNA",
    "SAUNI",
    "SAUPE",
    "SAURA",
    "SAURO",
    "SAUVA",
    "SAVAL",
    "SAVAS",
    "SAVEL",
    "SAVIA",
    "SAVIO",
    "SAXAO",
    "SAXEA",
    "SAXEO",
    "SAXES",
    "SAXOS",
    "SAYAO",
    "SAZAO",
    "SAZOA",
    "SAZOE",
    "SAZOO",
    "SAZOS",
    "SAZUS",
    "SCENE",
    "SCONE",
    "SCOPE",
    "SCORE",
    "SCUL.",
    "SEADA",
    "SEARA",
    "SEBAS",
    "SEBEL",
    "SEBES",
    "SEBOS",
    "SECAD",
    "SECAI",
    "SECAL",
    "SECAM",
    "SECAO",
    "SECAR",
    "SECAS",
    "SECIA",
    "SECIE",
    "SECIO",
    "SECOS",
    "SECOU",
    "SECR.",
    "SECT.",
    "SECTA",
    "SECTO",
    "SECUA",
    "SEDAI",
    "SEDAL",
    "SEDAM",
    "SEDAN",
    "SEDAO",
    "SEDAR",
    "SEDAS",
    "SEDEA",
    "SEDEI",
    "SEDEM",
    "SEDES",
    "SEDEX",
    "SEDIA",
    "SEDIE",
    "SEDIO",
    "SEDOU",
    "SEDUZ",
    "SEFAZ",
    "SEFEL",
    "SEFIA",
    "SEGAI",
    "SEGAM",
    "SEGAO",
    "SEGAR",
    "SEGAS",
    "SEGES",
    "SEGG.",
    "SEGNA",
    "SEGNE",
    "SEGNO",
    "SEGOU",
    "SEGS.",
    "SEGUA",
    "SEGUE",
    "SEGUI",
    "SEGUO",
    "SEIBO",
    "SEICA",
    "SEICE",
    "SEIMA",
    "SEIOS",
    "SEIRA",
    "SEISA",
    "SEITA",
    "SEITE",
    "SEITO",
    "SEIVA",
    "SEIVE",
    "SEIVO",
    "SEIXA",
    "SEIXO",
    "SEJAM",
    "SEJAS",
    "SEJOS",
    "SELAI",
    "SELAM",
    "SELAO",
    "SELAR",
    "SELAS",
    "SELEI",
    "SELEM",
    "SELES",
    "SELHA",
    "SELIA",
    "SELIC",
    "SELIM",
    "SELIO",
    "SELMA",
    "SELOA",
    "SELOS",
    "SELOU",
    "SELVA",
    "SEMAS",
    "SEMBA",
    "SEMBE",
    "SEMBO",
    "SEMEA",
    "SEMEI",
    "SEMEN",
    "SEMIO",
    "SEMIS",
    "SENAC",
    "SENAI",
    "SENAL",
    "SENAO",
    "SENAS",
    "SENDA",
    "SENDO",
    "SENES",
    "SENGA",
    "SENGO",
    "SENHA",
    "SENHO",
    "SENIL",
    "SENIO",
    "SENIR",
    "SENIS",
    "SENNA",
    "SENOS",
    "SENRA",
    "SENSO",
    "SENSU",
    "SENT.",
    "SENTA",
    "SENTE",
    "SENTI",
    "SENTO",
    "SEPIA",
    "SEPIO",
    "SEPOS",
    "SEPSE",
    "SEPTA",
    "SEPTE",
    "SEPTO",
    "SEQQ.",
    "SEQUE",
    "SEQUI",
    "SERAL",
    "SERAO",
    "SERAS",
    "SERBO",
    "SEREI",
    "SEREM",
    "SERES",
    "SERFO",
    "SERG.",
    "SERIA",
    "SERIE",
    "SERIO",
    "SERIS",
    "SERIU",
    "SERJA",
    "SERNA",
    "SERPA",
    "SERPE",
    "SERR.",
    "SERRA",
    "SERRE",
    "SERRO",
    "SERTA",
    "SERUM",
    "SERV.",
    "SERVA",
    "SERVE",
    "SERVI",
    "SERVO",
    "SESAI",
    "SESEA",
    "SESEG",
    "SESGO",
    "SESIA",
    "SESMA",
    "SESME",
    "SESMO",
    "SESSA",
    "SESSE",
    "SESSO",
    "SESTA",
    "SESTO",
    "SETAI",
    "SETAM",
    "SETAR",
    "SETAS",
    "SETEA",
    "SETEI",
    "SETEM",
    "SETER",
    "SETES",
    "SETIA",
    "SETOR",
    "SETOS",
    "SETOU",
    "SETRA",
    "SEVAI",
    "SEVAL",
    "SEVAM",
    "SEVAR",
    "SEVAS",
    "SEVEI",
    "SEVEM",
    "SEVES",
    "SEVOU",
    "SEXAI",
    "SEXAM",
    "SEXAR",
    "SEXAS",
    "SEXEI",
    "SEXEM",
    "SEXES",
    "SEXOS",
    "SEXOU",
    "SEXTA",
    "SEXTO",
    "SEXUA",
    "SEXUE",
    "SEXUO",
    "SEZAO",
    "SEZOA",
    "SEZOE",
    "SEZOO",
    "SHORT",
    "SHOWS",
    "SHOYU",
    "SHUNT",
    "SIADA",
    "SIADO",
    "SIAIS",
    "SIALO",
    "SIAME",
    "SIAMO",
    "SIARA",
    "SIAUS",
    "SIAVA",
    "SIBAL",
    "SIBAR",
    "SIBAS",
    "SIBES",
    "SICAF",
    "SICAS",
    "SICIO",
    "SICLO",
    "SICON",
    "SICOS",
    "SICUS",
    "SIDAS",
    "SIDEA",
    "SIDEO",
    "SIDIS",
    "SIDOM",
    "SIDOS",
    "SIDRA",
    "SIEIS",
    "SIEMO",
    "SIENA",
    "SIFAO",
    "SIFIA",
    "SIFLA",
    "SIFLE",
    "SIFLO",
    "SIGAM",
    "SIGAS",
    "SIGEU",
    "SIGLA",
    "SIGLE",
    "SIGLO",
    "SIGMA",
    "SIGMO",
    "SIGN.",
    "SIGNA",
    "SIGNE",
    "SIGNO",
    "SILAS",
    "SILER",
    "SILES",
    "SILEX",
    "SILFO",
    "SILHA",
    "SILHO",
    "SILIS",
    "SILOE",
    "SILOS",
    "SILTE",
    "SILV.",
    "SILVA",
    "SILVE",
    "SILVO",
    "SIMAO",
    "SIMAS",
    "SIMB.",
    "SIMBA",
    "SIMBI",
    "SIMBO",
    "SIMEI",
    "SIMEL",
    "SIMIA",
    "SIMIL",
    "SIMIO",
    "SIMON",
    "SIMUM",
    "SINAI",
    "SINAL",
    "SINAM",
    "SINAO",
    "SINAR",
    "SINAS",
    "SINDA",
    "SINDI",
    "SINDO",
    "SINEI",
    "SINEM",
    "SINES",
    "SINEU",
    "SING.",
    "SINGA",
    "SINHA",
    "SINHO",
    "SINIS",
    "SINJE",
    "SINOP",
    "SINOS",
    "SINOU",
    "SINT.",
    "SINTA",
    "SINTO",
    "SINUA",
    "SINUE",
    "SINUO",
    "SINUS",
    "SINXO",
    "SIOBA",
    "SIODE",
    "SIOES",
    "SIOTE",
    "SIPAI",
    "SIPES",
    "SIPIA",
    "SIQUE",
    "SIRAS",
    "SIRES",
    "SIREX",
    "SIRFA",
    "SIRFO",
    "SIRGA",
    "SIRGO",
    "SIRIA",
    "SIRIO",
    "SIRIS",
    "SIRIU",
    "SIRLO",
    "SIRMA",
    "SIROS",
    "SIRTE",
    "SIRVA",
    "SIRVO",
    "SIRZO",
    "SISAI",
    "SISAL",
    "SISAM",
    "SISAO",
    "SISAR",
    "SISAS",
    "SISEI",
    "SISEM",
    "SISES",
    "SISGO",
    "SISMA",
    "SISME",
    "SISMO",
    "SISOR",
    "SISOS",
    "SISOU",
    "SISSO",
    "SIST.",
    "SISTO",
    "SITAR",
    "SITAS",
    "SITES",
    "SITIA",
    "SITIE",
    "SITIO",
    "SITOS",
    "SITRA",
    "SITUA",
    "SITUE",
    "SITUO",
    "SIUSI",
    "SIVAO",
    "SIVAS",
    "SIVIA",
    "SIVOM",
    "SIZAU",
    "SKATE",
    "SLIDE",
    "SNIFA",
    "SNIFE",
    "SNIFO",
    "SNOBE",
    "SOADA",
    "SOADO",
    "SOAIS",
    "SOAJE",
    "SOAJO",
    "SOALA",
    "SOAMO",
    "SOARA",
    "SOAVA",
    "SOBAS",
    "SOBEM",
    "SOBES",
    "SOBEU",
    "SOBIA",
    "SOBIE",
    "SOBIO",
    "SOBPE",
    "SOBPO",
    "SOBRA",
    "SOBRE",
    "SOBRO",
    "SOCAI",
    "SOCAM",
    "SOCAO",
    "SOCAR",
    "SOCAS",
    "SOCIA",
    "SOCIO",
    "SOCLO",
    "SOCOI",
    "SOCOL",
    "SOCOS",
    "SOCOU",
    "SODAI",
    "SODAM",
    "SODAR",
    "SODAS",
    "SODEI",
    "SODEM",
    "SODES",
    "SODIO",
    "SODOU",
    "SODRA",
    "SODRE",
    "SOEIS",
    "SOEMO",
    "SOERA",
    "SOFAS",
    "SOFIA",
    "SOFRA",
    "SOFRE",
    "SOFRI",
    "SOFRO",
    "SOGAI",
    "SOGAM",
    "SOGAR",
    "SOGAS",
    "SOGOU",
    "SOGRA",
    "SOGRE",
    "SOGRO",
    "SOGUA",
    "SOGUE",
    "SOIAM",
    "SOIAS",
    "SOIDA",
    "SOIDO",
    "SOILA",
    "SOIMA",
    "SOINS",
    "SOITO",
    "SOJAS",
    "SOLAI",
    "SOLAM",
    "SOLAO",
    "SOLAR",
    "SOLAS",
    "SOLAU",
    "SOLAZ",
    "SOLDA",
    "SOLDE",
    "SOLDO",
    "SOLEA",
    "SOLEI",
    "SOLEM",
    "SOLEO",
    "SOLES",
    "SOLFA",
    "SOLFE",
    "SOLFO",
    "SOLHA",
    "SOLHE",
    "SOLHO",
    "SOLIA",
    "SOLIO",
    "SOLOS",
    "SOLOU",
    "SOLTA",
    "SOLTE",
    "SOLTO",
    "SOLVA",
    "SOLVE",
    "SOLVI",
    "SOLVO",
    "SOMAI",
    "SOMAM",
    "SOMAR",
    "SOMAS",
    "SOMEI",
    "SOMEM",
    "SOMES",
    "SOMOS",
    "SOMOU",
    "SOMPA",
    "SOMPE",
    "SOMPO",
    "SONAI",
    "SONAM",
    "SONAR",
    "SONAS",
    "SONDA",
    "SONDE",
    "SONDO",
    "SONEI",
    "SONEM",
    "SONES",
    "SONGA",
    "SONGO",
    "SONHA",
    "SONHE",
    "SONHO",
    "SONIA",
    "SONOS",
    "SONOU",
    "SONSA",
    "SONSO",
    "SOOES",
    "SOPAO",
    "SOPAS",
    "SOPEA",
    "SOPES",
    "SOPIA",
    "SOPIE",
    "SOPIO",
    "SOPLO",
    "SOPOR",
    "SOPOS",
    "SOPRA",
    "SOPRE",
    "SOPRO",
    "SOQUA",
    "SOQUE",
    "SOQUI",
    "SOQUO",
    "SORAI",
    "SORAL",
    "SORAM",
    "SORAR",
    "SORAS",
    "SORBO",
    "SORCA",
    "SORDA",
    "SOREA",
    "SOREI",
    "SOREM",
    "SORES",
    "SOREX",
    "SORGA",
    "SORGO",
    "SORIA",
    "SORNA",
    "SORNE",
    "SORNI",
    "SORNO",
    "SOROR",
    "SOROS",
    "SOROU",
    "SORRI",
    "SORTA",
    "SORTE",
    "SORTI",
    "SORTO",
    "SORVA",
    "SORVE",
    "SORVI",
    "SORVO",
    "SOSAS",
    "SOSIA",
    "SOSSO",
    "SOST.",
    "SOTAA",
    "SOTAI",
    "SOTAM",
    "SOTAO",
    "SOTAR",
    "SOTAS",
    "SOTEI",
    "SOTEM",
    "SOTER",
    "SOTES",
    "SOTIA",
    "SOTOS",
    "SOTOU",
    "SOUBE",
    "SOUIS",
    "SOURE",
    "SOUSA",
    "SOUTA",
    "SOUTE",
    "SOUTO",
    "SOUZA",
    "SOVAI",
    "SOVAM",
    "SOVAR",
    "SOVAS",
    "SOVEI",
    "SOVEM",
    "SOVES",
    "SOVEU",
    "SOVIS",
    "SOVOU",
    "SOVRO",
    "SOZAL",
    "SPAIN",
    "SPANS",
    "SPINS",
    "SPLIT",
    "SPRAY",
    "SQ.M.",
    "SRAS.",
    "SRES.",
    "SRTA.",
    "STAC.",
    "STAFE",
    "STAFF",
    "STAND",
    "STATO",
    "STELA",
    "STENO",
    "STILB",
    "STOMA",
    "STOPS",
    "STOUT",
    "STRIP",
    "SUACO",
    "SUACU",
    "SUADA",
    "SUADE",
    "SUADI",
    "SUADO",
    "SUAIS",
    "SUAJA",
    "SUAJO",
    "SUAMO",
    "SUANA",
    "SUANO",
    "SUARA",
    "SUARE",
    "SUARO",
    "SUAVA",
    "SUAVE",
    "SUAZI",
    "SUBAM",
    "SUBAS",
    "SUBER",
    "SUBIA",
    "SUBIR",
    "SUBIS",
    "SUBIU",
    "SUBJ.",
    "SUBUS",
    "SUCAI",
    "SUCAM",
    "SUCAO",
    "SUCAR",
    "SUCAS",
    "SUCHO",
    "SUCIA",
    "SUCIE",
    "SUCIO",
    "SUCOS",
    "SUCOU",
    "SUCRE",
    "SUCTO",
    "SUDAI",
    "SUDAM",
    "SUDAO",
    "SUDAR",
    "SUDAS",
    "SUDEI",
    "SUDEM",
    "SUDES",
    "SUDOU",
    "SUDRA",
    "SUDRO",
    "SUEC.",
    "SUECA",
    "SUECO",
    "SUEDA",
    "SUEDE",
    "SUEIS",
    "SUELI",
    "SUELY",
    "SUEMO",
    "SUETA",
    "SUETO",
    "SUEVA",
    "SUEVO",
    "SUFF.",
    "SUFIS",
    "SUFLA",
    "SUFLE",
    "SUFLO",
    "SUGAI",
    "SUGAM",
    "SUGAR",
    "SUGAS",
    "SUGOU",
    "SUGUE",
    "SUIAS",
    "SUICA",
    "SUICO",
    "SUIDA",
    "SUINA",
    "SUINO",
    "SUITA",
    "SUITE",
    "SUJAI",
    "SUJAM",
    "SUJAO",
    "SUJAR",
    "SUJAS",
    "SUJEI",
    "SUJEM",
    "SUJES",
    "SUJOS",
    "SUJOU",
    "SULAI",
    "SULAM",
    "SULAO",
    "SULAR",
    "SULAS",
    "SULCA",
    "SULCO",
    "SULEI",
    "SULEM",
    "SULES",
    "SULFA",
    "SULFO",
    "SULIA",
    "SULOU",
    "SUMAM",
    "SUMAS",
    "SUMBA",
    "SUMBE",
    "SUMBO",
    "SUMEA",
    "SUMIA",
    "SUMIR",
    "SUMIS",
    "SUMIU",
    "SUMOS",
    "SUNAS",
    "SUNDO",
    "SUNES",
    "SUNFA",
    "SUNGA",
    "SUNGO",
    "SUNTO",
    "SUOES",
    "SUOME",
    "SUOMI",
    "SUPER",
    "SUPL.",
    "SUPOE",
    "SUPOR",
    "SUPOS",
    "SUPRA",
    "SUPRE",
    "SUPRI",
    "SUPRO",
    "SUPUS",
    "SUQUA",
    "SUQUE",
    "SUQUI",
    "SUQUO",
    "SURAL",
    "SURAS",
    "SURCA",
    "SURCO",
    "SURDA",
    "SURDE",
    "SURDI",
    "SURDO",
    "SURFA",
    "SURFE",
    "SURFO",
    "SURGE",
    "SURGI",
    "SURGO",
    "SURIA",
    "SURIM",
    "SURIO",
    "SURIS",
    "SURJA",
    "SURJO",
    "SURNO",
    "SURNU",
    "SUROS",
    "SURR.",
    "SURRA",
    "SURRE",
    "SURRO",
    "SURTA",
    "SURTE",
    "SURTI",
    "SURTO",
    "SURUI",
    "SURUS",
    "SUSAN",
    "SUSAO",
    "SUSAS",
    "SUSEP",
    "SUSHI",
    "SUSIA",
    "SUSIO",
    "SUSOS",
    "SUSSU",
    "SUSTA",
    "SUSTE",
    "SUSTI",
    "SUSTO",
    "SUTAI",
    "SUTAM",
    "SUTAO",
    "SUTAR",
    "SUTAS",
    "SUTEI",
    "SUTEM",
    "SUTES",
    "SUTIA",
    "SUTIL",
    "SUTIS",
    "SUTOU",
    "SUTRA",
    "SUVAO",
    "SUXAI",
    "SUXAM",
    "SUXAR",
    "SUXAS",
    "SUXEI",
    "SUXEM",
    "SUXES",
    "SUXOU",
    "SVAVA",
    "SWING",
    "TAACA",
    "TABAI",
    "TABAO",
    "TABAS",
    "TABAZ",
    "TABES",
    "TABLA",
    "TABOA",
    "TABOR",
    "TABUA",
    "TABUS",
    "TACAI",
    "TACAM",
    "TACAO",
    "TACAR",
    "TACAS",
    "TACAU",
    "TACHA",
    "TACHE",
    "TACHO",
    "TACIA",
    "TACIO",
    "TACIS",
    "TACLE",
    "TACOS",
    "TACOU",
    "TACTA",
    "TACTO",
    "TACUA",
    "TACUS",
    "TADEA",
    "TADEO",
    "TADEU",
    "TAEIS",
    "TAFAL",
    "TAFIA",
    "TAFIO",
    "TAFUL",
    "TAGAL",
    "TAGAR",
    "TAGAZ",
    "TAGBA",
    "TAGMA",
    "TAGNA",
    "TAGRA",
    "TAGUA",
    "TAHIR",
    "TAIAS",
    "TAIBU",
    "TAICA",
    "TAICO",
    "TAIFA",
    "TAIFO",
    "TAIGA",
    "TAIL.",
    "TAIMA",
    "TAINA",
    "TAINE",
    "TAINO",
    "TAIPA",
    "TAIPE",
    "TAIPO",
    "TAIPU",
    "TAIRA",
    "TAISA",
    "TAITA",
    "TAITI",
    "TAIXI",
    "TAIXO",
    "TAJAL",
    "TAJAS",
    "TAJAZ",
    "TALAI",
    "TALAM",
    "TALAO",
    "TALAR",
    "TALAS",
    "TALCO",
    "TALEI",
    "TALEM",
    "TALER",
    "TALES",
    "TALHA",
    "TALHE",
    "TALHO",
    "TALIA",
    "TALIM",
    "TALIN",
    "TALIO",
    "TALIS",
    "TALMA",
    "TALMI",
    "TALOL",
    "TALOR",
    "TALOS",
    "TALOU",
    "TALPA",
    "TALUS",
    "TAMAM",
    "TAMAO",
    "TAMAR",
    "TAMAS",
    "TAMBA",
    "TAMBI",
    "TAMBO",
    "TAMBU",
    "TAMIA",
    "TAMIL",
    "TAMIM",
    "TAMIR",
    "TAMIS",
    "TAMOS",
    "TAMPA",
    "TAMPE",
    "TAMPO",
    "TAMUL",
    "TAMUZ",
    "TANAI",
    "TANAL",
    "TANAM",
    "TANAR",
    "TANAS",
    "TANCA",
    "TANCO",
    "TANDO",
    "TANEI",
    "TANEM",
    "TANES",
    "TANG.",
    "TANGA",
    "TANGE",
    "TANGI",
    "TANGO",
    "TANHO",
    "TANIA",
    "TANIO",
    "TANIS",
    "TANJA",
    "TANJO",
    "TANOA",
    "TANOE",
    "TANOO",
    "TANOU",
    "TANSA",
    "TANSO",
    "TANTA",
    "TANTO",
    "TANUS",
    "TAOCA",
    "TAPAI",
    "TAPAM",
    "TAPAO",
    "TAPAR",
    "TAPAS",
    "TAPEA",
    "TAPEI",
    "TAPEM",
    "TAPES",
    "TAPIA",
    "TAPII",
    "TAPIR",
    "TAPIU",
    "TAPIZ",
    "TAPOA",
    "TAPOU",
    "TAPTO",
    "TAPUA",
    "TAQUE",
    "TAQUI",
    "TARAI",
    "TARAM",
    "TARAO",
    "TARAR",
    "TARAS",
    "TARAU",
    "TARCA",
    "TARD.",
    "TARDA",
    "TARDE",
    "TARDO",
    "TAREA",
    "TAREI",
    "TAREM",
    "TARES",
    "TARIA",
    "TARIM",
    "TARIS",
    "TARJA",
    "TARJE",
    "TARJO",
    "TAROL",
    "TAROU",
    "TARPA",
    "TARRO",
    "TARSA",
    "TARSO",
    "TARTA",
    "TARTE",
    "TASCA",
    "TASCO",
    "TASGA",
    "TASGO",
    "TASIA",
    "TASIO",
    "TASNA",
    "TASSO",
    "TASTO",
    "TATAI",
    "TATAS",
    "TATAU",
    "TATEA",
    "TATES",
    "TATIL",
    "TATOS",
    "TATRA",
    "TATSU",
    "TATUA",
    "TATUE",
    "TATUI",
    "TATUO",
    "TATUS",
    "TATZE",
    "TAUNA",
    "TAUR.",
    "TAURA",
    "TAURO",
    "TAUVA",
    "TAVAO",
    "TAVAS",
    "TAVEL",
    "TAVIS",
    "TAVOA",
    "TAXAI",
    "TAXAL",
    "TAXAM",
    "TAXAR",
    "TAXAS",
    "TAXEI",
    "TAXEM",
    "TAXES",
    "TAXIA",
    "TAXIE",
    "TAXIO",
    "TAXIS",
    "TAXON",
    "TAXOU",
    "TCHAD",
    "TCHAU",
    "TEACA",
    "TEACU",
    "TEADA",
    "TEAME",
    "TEANA",
    "TEANO",
    "TEARA",
    "TEARO",
    "TEASE",
    "TEAT.",
    "TEATE",
    "TEBAS",
    "TEBES",
    "TEBEU",
    "TECAI",
    "TECAL",
    "TECAM",
    "TECAR",
    "TECAS",
    "TECEI",
    "TECEM",
    "TECER",
    "TECES",
    "TECEU",
    "TECIA",
    "TECLA",
    "TECLE",
    "TECLO",
    "TECN.",
    "TECNO",
    "TECOU",
    "TECT.",
    "TECTO",
    "TECUM",
    "TEDAS",
    "TEDEU",
    "TEDIO",
    "TEERA",
    "TEFES",
    "TEFLA",
    "TEGAO",
    "TEGBA",
    "TEGBO",
    "TEGME",
    "TEGUI",
    "TEIAS",
    "TEIGA",
    "TEIGO",
    "TEIMA",
    "TEIME",
    "TEIMO",
    "TEINA",
    "TEIOS",
    "TEIPE",
    "TEIRA",
    "TEIRO",
    "TEIRU",
    "TEITE",
    "TEIUS",
    "TEIXE",
    "TEIXO",
    "TEJAS",
    "TEJOS",
    "TEJUS",
    "TELAI",
    "TELAM",
    "TELAO",
    "TELAR",
    "TELAS",
    "TELEI",
    "TELEM",
    "TELES",
    "TELEX",
    "TELHA",
    "TELHE",
    "TELHO",
    "TELIA",
    "TELIM",
    "TELIO",
    "TELIZ",
    "TELMA",
    "TELMO",
    "TELOU",
    "TELSO",
    "TEMAM",
    "TEMAO",
    "TEMAS",
    "TEMBA",
    "TEMBE",
    "TEMBI",
    "TEMEI",
    "TEMEM",
    "TEMER",
    "TEMES",
    "TEMEU",
    "TEMIA",
    "TEMIO",
    "TEMIS",
    "TEMNE",
    "TEMOR",
    "TEMOS",
    "TEMP.",
    "TEMPE",
    "TEMPO",
    "TENAR",
    "TENAZ",
    "TENCA",
    "TENDA",
    "TENDE",
    "TENDI",
    "TENDO",
    "TENEA",
    "TENES",
    "TENGO",
    "TENHA",
    "TENHO",
    "TENIA",
    "TENIO",
    "TENIS",
    "TENOR",
    "TENOS",
    "TENRA",
    "TENRO",
    "TENSA",
    "TENSE",
    "TENSO",
    "TENTA",
    "TENTE",
    "TENTO",
    "TENUE",
    "TEOL.",
    "TEON.",
    "TEORO",
    "TEOS.",
    "TEOSE",
    "TEPES",
    "TEPOR",
    "TEQUE",
    "TERAI",
    "TERAO",
    "TERAS",
    "TERBO",
    "TERCA",
    "TERCE",
    "TERCO",
    "TEREI",
    "TEREM",
    "TERES",
    "TEREU",
    "TERGO",
    "TERIA",
    "TERIO",
    "TERLO",
    "TERM.",
    "TERMA",
    "TERMO",
    "TERNA",
    "TERNE",
    "TERNO",
    "TEROL",
    "TEROS",
    "TERR.",
    "TERRA",
    "TERRE",
    "TERRO",
    "TERSA",
    "TERSO",
    "TESAI",
    "TESAM",
    "TESAO",
    "TESAR",
    "TESAS",
    "TESBI",
    "TESCA",
    "TESEI",
    "TESEM",
    "TESES",
    "TESIO",
    "TESIS",
    "TESLA",
    "TESNO",
    "TESOS",
    "TESOU",
    "TESSE",
    "TEST.",
    "TESTA",
    "TESTE",
    "TESTO",
    "TESUM",
    "TETAI",
    "TETAM",
    "TETAR",
    "TETAS",
    "TETEI",
    "TETEM",
    "TETES",
    "TETEU",
    "TETIA",
    "TETIM",
    "TETIO",
    "TETIS",
    "TETOS",
    "TETOU",
    "TETRA",
    "TETRO",
    "TETUM",
    "TEUBA",
    "TEUDA",
    "TEUDO",
    "TEUTA",
    "TEUTO",
    "TEXAS",
    "TEXT.",
    "TEXTO",
    "THOME",
    "TIACE",
    "TIADE",
    "TIAGO",
    "TIAIS",
    "TIANA",
    "TIANO",
    "TIAPI",
    "TIARA",
    "TIASO",
    "TIATA",
    "TIAUS",
    "TIBAI",
    "TIBAM",
    "TIBAR",
    "TIBAS",
    "TIBAU",
    "TIBEI",
    "TIBEL",
    "TIBEM",
    "TIBER",
    "TIBES",
    "TIBIA",
    "TIBIO",
    "TIBIS",
    "TIBOU",
    "TIBRE",
    "TIBUS",
    "TICAI",
    "TICAL",
    "TICAM",
    "TICAO",
    "TICAR",
    "TICAS",
    "TICIA",
    "TICIO",
    "TICOA",
    "TICOS",
    "TICOU",
    "TICTO",
    "TICUM",
    "TICUS",
    "TIDAL",
    "TIDAS",
    "TIDIA",
    "TIDIO",
    "TIDOR",
    "TIDOS",
    "TIELA",
    "TIENA",
    "TIENS",
    "TIETA",
    "TIETE",
    "TIETO",
    "TIFAO",
    "TIFAS",
    "TIFES",
    "TIFEU",
    "TIFIA",
    "TIFIS",
    "TIFLO",
    "TIFOS",
    "TIGAO",
    "TIGAS",
    "TIGRE",
    "TIITO",
    "TIJUS",
    "TILAI",
    "TILAM",
    "TILAR",
    "TILAS",
    "TILDA",
    "TILDE",
    "TILDO",
    "TILEA",
    "TILEI",
    "TILEM",
    "TILES",
    "TILHA",
    "TILHO",
    "TILIA",
    "TILIM",
    "TILIO",
    "TILOA",
    "TILOS",
    "TILOU",
    "TIMAO",
    "TIMAR",
    "TIMAS",
    "TIMBA",
    "TIMBE",
    "TIMBO",
    "TIMBU",
    "TIMER",
    "TIMES",
    "TIMEU",
    "TIMIA",
    "TIMIO",
    "TIMOL",
    "TIMON",
    "TIMOR",
    "TIMOS",
    "TINAM",
    "TINAO",
    "TINAS",
    "TINCA",
    "TINEA",
    "TINEM",
    "TINER",
    "TINES",
    "TINGA",
    "TINGE",
    "TINGI",
    "TINGO",
    "TINHA",
    "TINHO",
    "TINIA",
    "TINIR",
    "TINIS",
    "TINIU",
    "TINJA",
    "TINJO",
    "TINOR",
    "TINT.",
    "TINTA",
    "TINTE",
    "TINTO",
    "TIOCA",
    "TIOCO",
    "TIOGE",
    "TIOIS",
    "TIONA",
    "TIONE",
    "TIOTE",
    "TIPAO",
    "TIPAS",
    "TIPES",
    "TIPIO",
    "TIPIS",
    "TIPLE",
    "TIPOI",
    "TIPOS",
    "TIPUS",
    "TIQUE",
    "TIQUI",
    "TIRAI",
    "TIRAM",
    "TIRAO",
    "TIRAR",
    "TIRAS",
    "TIRAZ",
    "TIREA",
    "TIREI",
    "TIREM",
    "TIREO",
    "TIRES",
    "TIRIA",
    "TIRIO",
    "TIRIS",
    "TIROL",
    "TIROS",
    "TIROU",
    "TIRSO",
    "TIRTA",
    "TIRZA",
    "TISCA",
    "TISCO",
    "TISNA",
    "TISNE",
    "TISNO",
    "TISSO",
    "TISSU",
    "TITAO",
    "TITAS",
    "TITIA",
    "TITIM",
    "TITIO",
    "TITIS",
    "TITOS",
    "TIUBA",
    "TIUPA",
    "TIUVA",
    "TIVER",
    "TIZAS",
    "TIZIO",
    "TIZIS",
    "TIZIU",
    "TJDFT",
    "TLACO",
    "TLINS",
    "TMEMA",
    "TMESE",
    "TOACA",
    "TOADA",
    "TOADO",
    "TOAIS",
    "TOAMO",
    "TOANA",
    "TOANO",
    "TOARA",
    "TOAVA",
    "TOBAS",
    "TOBIN",
    "TOBIU",
    "TOCAI",
    "TOCAM",
    "TOCAR",
    "TOCAS",
    "TOCEA",
    "TOCHA",
    "TOCHO",
    "TOCIA",
    "TOCIS",
    "TOCLO",
    "TOCOS",
    "TOCOU",
    "TODAS",
    "TODEA",
    "TODIS",
    "TODOS",
    "TOEIS",
    "TOEMO",
    "TOESA",
    "TOESE",
    "TOESO",
    "TOEZA",
    "TOFEL",
    "TOFOS",
    "TOFUS",
    "TOGAI",
    "TOGAM",
    "TOGAR",
    "TOGAS",
    "TOGOI",
    "TOGOU",
    "TOGUA",
    "TOGUE",
    "TOICA",
    "TOICE",
    "TOICO",
    "TOIRA",
    "TOIRE",
    "TOIRO",
    "TOITA",
    "TOJAL",
    "TOJOS",
    "TOLAM",
    "TOLAO",
    "TOLAR",
    "TOLAS",
    "TOLAZ",
    "TOLDA",
    "TOLDE",
    "TOLDO",
    "TOLEI",
    "TOLEM",
    "TOLER",
    "TOLES",
    "TOLEU",
    "TOLHA",
    "TOLHE",
    "TOLHI",
    "TOLHO",
    "TOLIA",
    "TOLIL",
    "TOLIS",
    "TOLOS",
    "TOLPE",
    "TOLUS",
    "TOLVA",
    "TOMAI",
    "TOMAM",
    "TOMAO",
    "TOMAR",
    "TOMAS",
    "TOMBA",
    "TOMBE",
    "TOMBO",
    "TOMEI",
    "TOMEM",
    "TOMES",
    "TOMIA",
    "TOMIM",
    "TOMIO",
    "TOMIX",
    "TOMOS",
    "TOMOU",
    "TONAI",
    "TONAL",
    "TONAM",
    "TONAR",
    "TONAS",
    "TONCA",
    "TONDO",
    "TONEI",
    "TONEL",
    "TONEM",
    "TONER",
    "TONES",
    "TONFE",
    "TONGA",
    "TONGO",
    "TONHA",
    "TONHO",
    "TONIA",
    "TONIS",
    "TONOA",
    "TONOU",
    "TONSA",
    "TONSE",
    "TONSO",
    "TONTA",
    "TONTO",
    "TONUS",
    "TOONA",
    "TOPAI",
    "TOPAM",
    "TOPAR",
    "TOPAS",
    "TOPAZ",
    "TOPEA",
    "TOPEI",
    "TOPEM",
    "TOPES",
    "TOPIA",
    "TOPIE",
    "TOPIO",
    "TOPOS",
    "TOPOU",
    "TOQUE",
    "TORAI",
    "TORAL",
    "TORAM",
    "TORAO",
    "TORAR",
    "TORAS",
    "TORAX",
    "TORBA",
    "TORCA",
    "TORCE",
    "TORCI",
    "TORCO",
    "TORDA",
    "TORDO",
    "TOREI",
    "TOREM",
    "TORES",
    "TORGA",
    "TORGO",
    "TORIA",
    "TORIO",
    "TORIS",
    "TORNA",
    "TORNE",
    "TORNO",
    "TOROM",
    "TORON",
    "TOROS",
    "TOROU",
    "TORP.",
    "TORPE",
    "TORRA",
    "TORRE",
    "TORRO",
    "TORSA",
    "TORSO",
    "TORTA",
    "TORTO",
    "TORUS",
    "TORVA",
    "TORVE",
    "TORVO",
    "TOSAI",
    "TOSAM",
    "TOSAO",
    "TOSAR",
    "TOSAS",
    "TOSC.",
    "TOSCA",
    "TOSCO",
    "TOSEI",
    "TOSEM",
    "TOSES",
    "TOSGA",
    "TOSOU",
    "TOSSE",
    "TOSSI",
    "TOSSO",
    "TOSTA",
    "TOSTE",
    "TOSTO",
    "TOTAL",
    "TOTEM",
    "TOUCA",
    "TOUCE",
    "TOUCO",
    "TOUPA",
    "TOURA",
    "TOURE",
    "TOURO",
    "TOUTA",
    "TOVAS",
    "TOXIA",
    "TOXIS",
    "TOZIA",
    "TRAB.",
    "TRACA",
    "TRACE",
    "TRACO",
    "TRAD.",
    "TRADA",
    "TRADE",
    "TRADO",
    "TRAEM",
    "TRAF.",
    "TRAGA",
    "TRAGO",
    "TRAIA",
    "TRAIO",
    "TRAIR",
    "TRAIS",
    "TRAIU",
    "TRAJA",
    "TRAJE",
    "TRAJO",
    "TRALA",
    "TRALE",
    "TRALO",
    "TRAMA",
    "TRAME",
    "TRAMO",
    "TRANA",
    "TRANE",
    "TRANO",
    "TRANS",
    "TRAPA",
    "TRAPE",
    "TRAPO",
    "TRARA",
    "TRAT.",
    "TRATA",
    "TRATE",
    "TRATO",
    "TRAV.",
    "TRAVA",
    "TRAVE",
    "TRAVO",
    "TRAZE",
    "TRECO",
    "TREDA",
    "TREDO",
    "TREFE",
    "TREFO",
    "TRELA",
    "TRELE",
    "TRELI",
    "TRELO",
    "TREMA",
    "TREME",
    "TREMI",
    "TREMO",
    "TRENA",
    "TRENE",
    "TRENO",
    "TRENS",
    "TREPA",
    "TREPE",
    "TREPO",
    "TRERA",
    "TRERE",
    "TRERO",
    "TRESO",
    "TRETA",
    "TREUS",
    "TREVA",
    "TREVO",
    "TREZE",
    "TRIAI",
    "TRIAL",
    "TRIAM",
    "TRIAO",
    "TRIAR",
    "TRIAS",
    "TRIB.",
    "TRIBO",
    "TRICA",
    "TRICO",
    "TRIDI",
    "TRIEI",
    "TRIEM",
    "TRIES",
    "TRIG.",
    "TRIGA",
    "TRIGO",
    "TRILA",
    "TRILE",
    "TRILO",
    "TRIM.",
    "TRINA",
    "TRINE",
    "TRINI",
    "TRINO",
    "TRIOL",
    "TRIOS",
    "TRIOU",
    "TRIPA",
    "TRIPE",
    "TRIPO",
    "TRIPS",
    "TRISA",
    "TRISE",
    "TRISO",
    "TRIT.",
    "TRITA",
    "TRITE",
    "TRITO",
    "TRIV.",
    "TROAI",
    "TROAM",
    "TROAR",
    "TROAS",
    "TROBO",
    "TROCA",
    "TROCE",
    "TROCO",
    "TROEI",
    "TROEM",
    "TROES",
    "TROFA",
    "TROFO",
    "TROGE",
    "TROIA",
    "TROIE",
    "TROIO",
    "TROIS",
    "TROLE",
    "TRONA",
    "TRONE",
    "TRONO",
    "TRONS",
    "TROOU",
    "TROP.",
    "TROPA",
    "TROPE",
    "TROPO",
    "TROTA",
    "TROTE",
    "TROTO",
    "TROVA",
    "TROVE",
    "TROVO",
    "TRUAO",
    "TRUAS",
    "TRUCA",
    "TRUCO",
    "TRUDA",
    "TRUDE",
    "TRUDO",
    "TRUFA",
    "TRUFE",
    "TRUFO",
    "TRUPA",
    "TRUPE",
    "TRUPI",
    "TRUPO",
    "TRUTA",
    "TRUXU",
    "TSELA",
    "TSUAS",
    "TUACA",
    "TUAIA",
    "TUBAI",
    "TUBAL",
    "TUBAM",
    "TUBAR",
    "TUBAS",
    "TUBEI",
    "TUBEL",
    "TUBEM",
    "TUBER",
    "TUBES",
    "TUBIM",
    "TUBIS",
    "TUBOS",
    "TUBOU",
    "TUCAO",
    "TUCAS",
    "TUCHO",
    "TUCOS",
    "TUCUI",
    "TUCUM",
    "TUCUS",
    "TUDEL",
    "TUDOS",
    "TUDRA",
    "TUDRO",
    "TUDUM",
    "TUFAI",
    "TUFAM",
    "TUFAO",
    "TUFAR",
    "TUFAS",
    "TUFEI",
    "TUFEM",
    "TUFES",
    "TUFOS",
    "TUFOU",
    "TUGAS",
    "TUGEM",
    "TUGES",
    "TUGIA",
    "TUGIR",
    "TUGIS",
    "TUGIU",
    "TUGRA",
    "TUGUE",
    "TUIAS",
    "TUINS",
    "TUIRA",
    "TUITA",
    "TUITE",
    "TUITO",
    "TUIUE",
    "TUIUS",
    "TUJAM",
    "TUJAS",
    "TUJIS",
    "TUJOL",
    "TUJUS",
    "TULAS",
    "TULES",
    "TULHA",
    "TULIA",
    "TULIO",
    "TULOS",
    "TUMBA",
    "TUMBE",
    "TUMBO",
    "TUMOR",
    "TUNAI",
    "TUNAL",
    "TUNAM",
    "TUNAR",
    "TUNAS",
    "TUNCO",
    "TUNDA",
    "TUNDE",
    "TUNDO",
    "TUNEI",
    "TUNEL",
    "TUNEM",
    "TUNES",
    "TUNGA",
    "TUNGO",
    "TUNGU",
    "TUNIA",
    "TUNIS",
    "TUNOU",
    "TUPAM",
    "TUPAS",
    "TUPIA",
    "TUPIM",
    "TUPIR",
    "TUPIS",
    "TUPIU",
    "TUPLA",
    "TUPLO",
    "TUQUE",
    "TURAI",
    "TURAM",
    "TURAR",
    "TURAS",
    "TURBA",
    "TURBE",
    "TURBO",
    "TURC.",
    "TURCA",
    "TURCO",
    "TURDO",
    "TUREI",
    "TUREM",
    "TURES",
    "TURFA",
    "TURFE",
    "TURGE",
    "TURGI",
    "TURIA",
    "TURIM",
    "TURIS",
    "TURJA",
    "TURJO",
    "TURKS",
    "TURMA",
    "TURME",
    "TURMO",
    "TURNA",
    "TURNE",
    "TURNO",
    "TUROU",
    "TURRA",
    "TURRE",
    "TURRO",
    "TURUS",
    "TURVA",
    "TURVE",
    "TURVO",
    "TUSCA",
    "TUSCO",
    "TUSSA",
    "TUSSI",
    "TUSSO",
    "TUSTA",
    "TUSTO",
    "TUTAI",
    "TUTAM",
    "TUTAO",
    "TUTAR",
    "TUTAS",
    "TUTEA",
    "TUTEI",
    "TUTEM",
    "TUTES",
    "TUTIA",
    "TUTIE",
    "TUTIO",
    "TUTOR",
    "TUTOU",
    "TUTSI",
    "TUTTI",
    "TUTUM",
    "TUTUS",
    "TUVIS",
    "TUXIS",
    "TUZAS",
    "TWEED",
    "TWIST",
    "UABUI",
    "UACAI",
    "UACOS",
    "UACUS",
    "UADAS",
    "UADES",
    "UADOS",
    "UAGUA",
    "UAIBA",
    "UAICA",
    "UAIMA",
    "UAINA",
    "UAIPI",
    "UAITA",
    "UAIUA",
    "UALES",
    "UALUA",
    "UAMBE",
    "UAMOI",
    "UANDA",
    "UANGA",
    "UANHA",
    "UANHI",
    "UANIA",
    "UAPES",
    "UAQUI",
    "UARIA",
    "UARIS",
    "UARUS",
    "UASCA",
    "UASSU",
    "UAUCU",
    "UAUPE",
    "UAURA",
    "UAURI",
    "UAXUA",
    "UBAIA",
    "UBARI",
    "UBATA",
    "UBEBA",
    "UBELE",
    "UBERA",
    "UBERE",
    "UBERO",
    "UBIAS",
    "UBINS",
    "UBIOS",
    "UBIRA",
    "UBRES",
    "UBUCU",
    "UCENA",
    "UCENO",
    "UCHAO",
    "UCHAS",
    "UCHOA",
    "UCILA",
    "UCIMA",
    "UCOLA",
    "UCUBU",
    "UDANA",
    "UDASI",
    "UDINA",
    "UDINO",
    "UDONS",
    "UDORA",
    "UEDIS",
    "UENAS",
    "UEUAS",
    "UFABC",
    "UFANA",
    "UFANE",
    "UFANO",
    "UFFRJ",
    "UFOBA",
    "UFOPA",
    "UFPEL",
    "UFRGS",
    "UFRPE",
    "UFRRJ",
    "UFSSP",
    "UFVJM",
    "UGABE",
    "UGADA",
    "UGADO",
    "UGAIS",
    "UGARA",
    "UGAVA",
    "UGNIS",
    "UGROS",
    "UGUEI",
    "UGUEM",
    "UGUES",
    "UIAIS",
    "UIAPE",
    "UIARA",
    "UIARI",
    "UIBAI",
    "UIGUR",
    "UIQUE",
    "UIRAS",
    "UIRUU",
    "UISTE",
    "UIVAI",
    "UIVAM",
    "UIVAR",
    "UIVAS",
    "UIVEI",
    "UIVEM",
    "UIVES",
    "UIVOS",
    "UIVOU",
    "UJARA",
    "UJICA",
    "ULADI",
    "ULANA",
    "ULANO",
    "ULAUS",
    "ULCAS",
    "ULEDA",
    "ULEIA",
    "ULEMA",
    "ULHOA",
    "ULIIA",
    "ULITE",
    "ULMER",
    "ULMOS",
    "ULNAL",
    "ULNAR",
    "ULNAS",
    "ULOMA",
    "ULONS",
    "ULOPA",
    "ULOSE",
    "ULOTA",
    "ULREI",
    "ULTAS",
    "ULTOR",
    "ULTOS",
    "ULTRA",
    "ULUAS",
    "ULUBA",
    "ULUCO",
    "ULUFE",
    "ULULA",
    "ULULE",
    "ULULO",
    "ULVAS",
    "UMARI",
    "UMAUA",
    "UMBLA",
    "UMBLO",
    "UMBOA",
    "UMBOS",
    "UMBRA",
    "UMBRO",
    "UMBUS",
    "UMERA",
    "UMERI",
    "UMERO",
    "UMIDA",
    "UMIDO",
    "UMIRI",
    "UMOES",
    "UNADA",
    "UNADE",
    "UNADO",
    "UNAIS",
    "UNAMO",
    "UNANI",
    "UNARA",
    "UNAUS",
    "UNAVA",
    "UNCAO",
    "UNCAS",
    "UNCIA",
    "UNCIS",
    "UNCOS",
    "UNCUS",
    "UNDAI",
    "UNDAM",
    "UNDAR",
    "UNDAS",
    "UNDEI",
    "UNDEM",
    "UNDES",
    "UNDOS",
    "UNDOU",
    "UNEDO",
    "UNEIS",
    "UNELA",
    "UNELO",
    "UNEMO",
    "UNESA",
    "UNESP",
    "UNGEM",
    "UNGES",
    "UNGIA",
    "UNGIR",
    "UNGIS",
    "UNGIU",
    "UNGUE",
    "UNGUI",
    "UNHAI",
    "UNHAM",
    "UNHAO",
    "UNHAR",
    "UNHAS",
    "UNHEI",
    "UNHEM",
    "UNHES",
    "UNHOU",
    "UNIAM",
    "UNIAO",
    "UNIAS",
    "UNICA",
    "UNICO",
    "UNID.",
    "UNIDA",
    "UNIDO",
    "UNIF.",
    "UNILA",
    "UNIMO",
    "UNIOA",
    "UNIOS",
    "UNIRA",
    "UNITA",
    "UNIV.",
    "UNJAM",
    "UNJAS",
    "UNONA",
    "UNSIA",
    "UNTAI",
    "UNTAM",
    "UNTAR",
    "UNTAS",
    "UNTEI",
    "UNTEM",
    "UNTES",
    "UNTOR",
    "UNTOU",
    "UNTUE",
    "UNUES",
    "UOGEM",
    "UOGES",
    "UONGO",
    "UPADA",
    "UPADO",
    "UPAIS",
    "UPAMO",
    "UPARA",
    "UPAVA",
    "UPEIS",
    "UPEMA",
    "UPEMO",
    "UPERU",
    "UPIAO",
    "UPUPA",
    "UQUIS",
    "URACA",
    "URACO",
    "URACU",
    "URAGO",
    "URAIS",
    "URALI",
    "URALO",
    "URANA",
    "URANE",
    "URANO",
    "URARE",
    "URARI",
    "URASE",
    "URATO",
    "URBAN",
    "URBES",
    "URCAS",
    "URCEA",
    "URCEO",
    "URCOS",
    "URDAM",
    "URDAS",
    "URDEM",
    "URDES",
    "URDIA",
    "URDIR",
    "URDIS",
    "URDIU",
    "URDUS",
    "UREDO",
    "UREIA",
    "URGEM",
    "URGES",
    "URGIA",
    "URGIR",
    "URGIS",
    "URGIU",
    "URIAS",
    "URICA",
    "URICO",
    "URIEL",
    "URIEN",
    "URINA",
    "URINE",
    "URINO",
    "URINS",
    "URJAM",
    "URJAS",
    "URNAS",
    "UROL.",
    "UROPO",
    "URRAI",
    "URRAM",
    "URRAR",
    "URRAS",
    "URREI",
    "URREM",
    "URRES",
    "URROS",
    "URROU",
    "URSAS",
    "URSEL",
    "URSOS",
    "URUBA",
    "URUBU",
    "URUCU",
    "URUG.",
    "URULU",
    "URUPA",
    "URUPE",
    "URUTU",
    "URUXI",
    "URZAL",
    "URZES",
    "USADA",
    "USADO",
    "USAIS",
    "USAMO",
    "USARA",
    "USAVA",
    "USEIS",
    "USEMO",
    "USGAS",
    "USIAS",
    "USINA",
    "USINE",
    "USINO",
    "USITA",
    "USITE",
    "USITO",
    "USMAI",
    "USMAM",
    "USMAR",
    "USMAS",
    "USMEI",
    "USMEM",
    "USMES",
    "USMOU",
    "USNEA",
    "USSAS",
    "USSIA",
    "USSUA",
    "USTAM",
    "USTAO",
    "USTAS",
    "USTEM",
    "USTES",
    "USTIA",
    "USTIR",
    "USTIS",
    "USTIU",
    "USTOS",
    "USUAL",
    "USURA",
    "USURE",
    "USURO",
    "UTADA",
    "UTADO",
    "UTAIS",
    "UTAMO",
    "UTANA",
    "UTARA",
    "UTATA",
    "UTAVA",
    "UTEIS",
    "UTEMO",
    "UTENA",
    "UTERO",
    "UTFPR",
    "UTIAS",
    "UTOCA",
    "UTOP.",
    "UTOTA",
    "UTRAS",
    "UVACA",
    "UVACU",
    "UVADA",
    "UVAIA",
    "UVALA",
    "UVATO",
    "UVEAL",
    "UVEAS",
    "UVICA",
    "UVICO",
    "UVIDA",
    "UVIDO",
    "UVITA",
    "UVITE",
    "UVULA",
    "UXINS",
    "UXIVA",
    "UXTES",
    "UZIAS",
    "UZIEL",
    "V.SA.",
    "VAALI",
    "VACAI",
    "VACAL",
    "VACAM",
    "VACAO",
    "VACAR",
    "VACAS",
    "VACEA",
    "VACEO",
    "VACEU",
    "VACOU",
    "VACUA",
    "VACUM",
    "VACUO",
    "VACUS",
    "VADEA",
    "VADES",
    "VADEU",
    "VADIA",
    "VADIE",
    "VADIO",
    "VADM.",
    "VADUZ",
    "VAEAS",
    "VAFRO",
    "VAGAI",
    "VAGAL",
    "VAGAM",
    "VAGAO",
    "VAGAR",
    "VAGAS",
    "VAGEA",
    "VAGEM",
    "VAGES",
    "VAGIA",
    "VAGIL",
    "VAGIR",
    "VAGIS",
    "VAGIU",
    "VAGOS",
    "VAGOU",
    "VAGUE",
    "VAIAI",
    "VAIAM",
    "VAIAR",
    "VAIAS",
    "VAIEI",
    "VAIEM",
    "VAIES",
    "VAILA",
    "VAIOU",
    "VAIPE",
    "VAIRA",
    "VAITA",
    "VAIXA",
    "VAJAM",
    "VAJAS",
    "VALAI",
    "VALAM",
    "VALAO",
    "VALAR",
    "VALAS",
    "VALDA",
    "VALDO",
    "VALEA",
    "VALEI",
    "VALEM",
    "VALER",
    "VALES",
    "VALEU",
    "VALGA",
    "VALGO",
    "VALHA",
    "VALHO",
    "VALIA",
    "VALIE",
    "VALIM",
    "VALIO",
    "VALIS",
    "VALOR",
    "VALOS",
    "VALOU",
    "VALSA",
    "VALSE",
    "VALSO",
    "VALVA",
    "VALVU",
    "VAMOS",
    "VAMPA",
    "VAMPE",
    "VAMPI",
    "VANDA",
    "VANDE",
    "VANDO",
    "VANGA",
    "VANIA",
    "VANTE",
    "VAPAS",
    "VAPIS",
    "VAPOR",
    "VAPUA",
    "VAQUE",
    "VARAI",
    "VARAL",
    "VARAM",
    "VARAO",
    "VARAR",
    "VARAS",
    "VAREA",
    "VAREI",
    "VAREM",
    "VARES",
    "VARGA",
    "VARGE",
    "VARIA",
    "VARIE",
    "VARIO",
    "VARIS",
    "VARIZ",
    "VARJA",
    "VARLI",
    "VARNA",
    "VARNO",
    "VAROA",
    "VAROU",
    "VARRA",
    "VARRE",
    "VARRI",
    "VARRO",
    "VARVE",
    "VARVO",
    "VASAL",
    "VASAS",
    "VASC.",
    "VASCA",
    "VASCO",
    "VASOL",
    "VASOS",
    "VASSA",
    "VASTA",
    "VASTE",
    "VASTI",
    "VASTO",
    "VATAS",
    "VATEL",
    "VATES",
    "VATIO",
    "VATUA",
    "VATUS",
    "VAURA",
    "VAVAS",
    "VAZAI",
    "VAZAM",
    "VAZAO",
    "VAZAR",
    "VAZAS",
    "VAZEI",
    "VAZEM",
    "VAZES",
    "VAZIA",
    "VAZIE",
    "VAZIO",
    "VAZOU",
    "VEADA",
    "VEADO",
    "VEBAS",
    "VECT.",
    "VECTA",
    "VEDAI",
    "VEDAL",
    "VEDAM",
    "VEDAR",
    "VEDAS",
    "VEDEI",
    "VEDEM",
    "VEDES",
    "VEDOR",
    "VEDOU",
    "VEDRA",
    "VEDRO",
    "VEEME",
    "VEGAS",
    "VEIAI",
    "VEIAM",
    "VEIAR",
    "VEIAS",
    "VEIEI",
    "VEIEM",
    "VEIES",
    "VEIGA",
    "VEIOS",
    "VEIOU",
    "VEIRA",
    "VEIRO",
    "VEJAM",
    "VEJAS",
    "VELAI",
    "VELAM",
    "VELAR",
    "VELAS",
    "VELEA",
    "VELEI",
    "VELEM",
    "VELES",
    "VELHA",
    "VELHO",
    "VELIA",
    "VELON",
    "VELOS",
    "VELOU",
    "VELOZ",
    "VELTA",
    "VELUM",
    "VEMOS",
    "VENAL",
    "VENCA",
    "VENCE",
    "VENCI",
    "VENCO",
    "VEND.",
    "VENDA",
    "VENDE",
    "VENDI",
    "VENDO",
    "VENHA",
    "VENHO",
    "VENIA",
    "VENTA",
    "VENTE",
    "VENTO",
    "VENUS",
    "VEPRE",
    "VEPSA",
    "VERAO",
    "VERAS",
    "VERAZ",
    "VERB.",
    "VERBA",
    "VERBI",
    "VERBO",
    "VERCA",
    "VERDE",
    "VERDI",
    "VEREA",
    "VEREI",
    "VEREM",
    "VERES",
    "VERGA",
    "VERGE",
    "VERGO",
    "VERIA",
    "VERIL",
    "VERIO",
    "VERIS",
    "VERME",
    "VERN.",
    "VERNA",
    "VERNE",
    "VERNO",
    "VERPA",
    "VERS.",
    "VERSA",
    "VERSE",
    "VERSO",
    "VERTA",
    "VERTE",
    "VERTI",
    "VERTO",
    "VERVE",
    "VESCO",
    "VESGA",
    "VESGO",
    "VESPA",
    "VESSA",
    "VESSE",
    "VESSO",
    "VESTA",
    "VESTE",
    "VESTI",
    "VESTO",
    "VETAI",
    "VETAM",
    "VETAO",
    "VETAR",
    "VETAS",
    "VETEI",
    "VETEM",
    "VETES",
    "VETOR",
    "VETOS",
    "VETOU",
    "VEXAI",
    "VEXAM",
    "VEXAR",
    "VEXAS",
    "VEXEI",
    "VEXEM",
    "VEXES",
    "VEXOU",
    "VEZAI",
    "VEZAM",
    "VEZAR",
    "VEZAS",
    "VEZEI",
    "VEZEM",
    "VEZES",
    "VEZOU",
    "VIADA",
    "VIADO",
    "VIAIS",
    "VIAJA",
    "VIAJE",
    "VIAJO",
    "VIANA",
    "VIARA",
    "VIBAL",
    "VIBAS",
    "VIBRA",
    "VIBRE",
    "VIBRO",
    "VICAI",
    "VICAM",
    "VICAR",
    "VICAS",
    "VICEI",
    "VICEM",
    "VICES",
    "VICHI",
    "VICIA",
    "VICIE",
    "VICIO",
    "VICOA",
    "VICOS",
    "VICOU",
    "VICTA",
    "VICTO",
    "VIDAI",
    "VIDAL",
    "VIDAM",
    "VIDAR",
    "VIDAS",
    "VIDEA",
    "VIDEI",
    "VIDEM",
    "VIDEO",
    "VIDES",
    "VIDIA",
    "VIDMA",
    "VIDOU",
    "VIDR.",
    "VIDRA",
    "VIDRE",
    "VIDRO",
    "VIDUA",
    "VIEIS",
    "VIELA",
    "VIELO",
    "VIEMO",
    "VIENA",
    "VIERA",
    "VIEZA",
    "VIGAI",
    "VIGAM",
    "VIGAR",
    "VIGAS",
    "VIGEI",
    "VIGEM",
    "VIGER",
    "VIGES",
    "VIGEU",
    "VIGIA",
    "VIGIE",
    "VIGIL",
    "VIGIO",
    "VIGNA",
    "VIGOR",
    "VIGOU",
    "VIGUE",
    "VIIAS",
    "VIIIA",
    "VIJAM",
    "VIJAS",
    "VILAO",
    "VILAR",
    "VILAS",
    "VILEU",
    "VILIA",
    "VILIE",
    "VILIO",
    "VILIS",
    "VILLA",
    "VILMA",
    "VILOA",
    "VILRO",
    "VILTA",
    "VIMAI",
    "VIMAM",
    "VIMAR",
    "VIMAS",
    "VIMBA",
    "VIMEA",
    "VIMEI",
    "VIMEM",
    "VIMES",
    "VIMOS",
    "VIMOU",
    "VINAL",
    "VINAS",
    "VINCA",
    "VINCI",
    "VINCO",
    "VINDA",
    "VINDE",
    "VINDO",
    "VINEA",
    "VINEO",
    "VINGA",
    "VINGO",
    "VINHA",
    "VINHE",
    "VINHO",
    "VINIL",
    "VINIS",
    "VINOA",
    "VINTA",
    "VINTE",
    "VIOCO",
    "VIOL.",
    "VIOLA",
    "VIOLE",
    "VIOLO",
    "VIRAI",
    "VIRAL",
    "VIRAM",
    "VIRAO",
    "VIRAR",
    "VIRAS",
    "VIREA",
    "VIREI",
    "VIREM",
    "VIREO",
    "VIRES",
    "VIRGA",
    "VIRGO",
    "VIRIA",
    "VIRIL",
    "VIRIS",
    "VIRMO",
    "VIROL",
    "VIROS",
    "VIROU",
    "VIRTE",
    "VIRUS",
    "VISAI",
    "VISAM",
    "VISAO",
    "VISAR",
    "VISAS",
    "VISCA",
    "VISCO",
    "VISEI",
    "VISEM",
    "VISES",
    "VISEU",
    "VISGA",
    "VISGO",
    "VISMA",
    "VISOM",
    "VISOR",
    "VISOS",
    "VISOU",
    "VISPA",
    "VISPE",
    "VISPO",
    "VISSE",
    "VISTA",
    "VISTE",
    "VISTO",
    "VITAE",
    "VITAL",
    "VITAS",
    "VITES",
    "VITEX",
    "VITIS",
    "VITOR",
    "VITR.",
    "VITRE",
    "VITRO",
    "VITUS",
    "VIUVA",
    "VIUVE",
    "VIUVO",
    "VIVAI",
    "VIVAM",
    "VIVAR",
    "VIVAS",
    "VIVAZ",
    "VIVEI",
    "VIVEM",
    "VIVER",
    "VIVES",
    "VIVEU",
    "VIVIA",
    "VIVIU",
    "VIVOS",
    "VIVOU",
    "VIVRE",
    "VIZIR",
    "VLDIS",
    "VLEME",
    "VOACO",
    "VOADA",
    "VOADO",
    "VOAIS",
    "VOAMO",
    "VOARA",
    "VOATA",
    "VOAVA",
    "VOBIS",
    "VOCAL",
    "VOCES",
    "VODAS",
    "VODCA",
    "VODOS",
    "VODUM",
    "VODUS",
    "VOEIS",
    "VOEJA",
    "VOEJE",
    "VOEJO",
    "VOEMO",
    "VOGAI",
    "VOGAL",
    "VOGAM",
    "VOGAR",
    "VOGAS",
    "VOGOU",
    "VOGUE",
    "VOGUL",
    "VOILE",
    "VOIXA",
    "VOIXO",
    "VOLAR",
    "VOLAS",
    "VOLCA",
    "VOLCO",
    "VOLEI",
    "VOLL.",
    "VOLOS",
    "VOLS.",
    "VOLTA",
    "VOLTE",
    "VOLTO",
    "VOLTS",
    "VOLVA",
    "VOLVE",
    "VOLVI",
    "VOLVO",
    "VOMER",
    "VONDA",
    "VORAS",
    "VORAZ",
    "VOSEA",
    "VOSSA",
    "VOSSO",
    "VOTAI",
    "VOTAM",
    "VOTAR",
    "VOTAS",
    "VOTEI",
    "VOTEM",
    "VOTES",
    "VOTOS",
    "VOTOU",
    "VOUGA",
    "VOUVE",
    "VOVOS",
    "VOZAO",
    "VOZEA",
    "VOZES",
    "VREIA",
    "VRIDI",
    "VRIJA",
    "VRITI",
    "VUAPA",
    "VUBAS",
    "VUDUS",
    "VULC.",
    "VULCA",
    "VULCO",
    "VULG.",
    "VULGO",
    "VULSO",
    "VULTA",
    "VULTE",
    "VULTO",
    "VULVA",
    "VUMBE",
    "VUMBI",
    "VUMOS",
    "VUNAI",
    "VUNAM",
    "VUNAR",
    "VUNAS",
    "VUNDA",
    "VUNEI",
    "VUNEM",
    "VUNES",
    "VUNGA",
    "VUNGO",
    "VUNJE",
    "VUNOU",
    "VUNZA",
    "VUNZE",
    "VUNZO",
    "VURMA",
    "VURME",
    "VURMO",
    "VUVUS",
    "WATTS",
    "WEBER",
    "WELIA",
    "XABAO",
    "XABEU",
    "XACAS",
    "XACOS",
    "XACRA",
    "XADAI",
    "XADOR",
    "XAIAS",
    "XAILE",
    "XALAS",
    "XALES",
    "XALMA",
    "XAMAS",
    "XAMBA",
    "XAMPU",
    "XANDU",
    "XANGO",
    "XANTA",
    "XANTE",
    "XANTO",
    "XAORO",
    "XAQUE",
    "XARAS",
    "XARAU",
    "XARDA",
    "XAREL",
    "XAREM",
    "XAREU",
    "XARIA",
    "XARUS",
    "XATES",
    "XAUAL",
    "XAUIM",
    "XAXIM",
    "XCIII",
    "XCVII",
    "XEICA",
    "XELIM",
    "XELMA",
    "XENAI",
    "XENAM",
    "XENAR",
    "XENAS",
    "XENEI",
    "XENEM",
    "XENES",
    "XENIA",
    "XENIO",
    "XENON",
    "XENOS",
    "XENOU",
    "XEQUE",
    "XERAS",
    "XEREM",
    "XEREU",
    "XEREZ",
    "XERGA",
    "XEROS",
    "XEROX",
    "XERRE",
    "XERVA",
    "XETAS",
    "XETRA",
    "XETRE",
    "XETRO",
    "XEURA",
    "XEXEU",
    "XEXOS",
    "XIBAS",
    "XIBIO",
    "XIBIU",
    "XICAS",
    "XICOS",
    "XICUS",
    "XIFIO",
    "XIFOS",
    "XIITA",
    "XILAS",
    "XILOL",
    "XILOS",
    "XIMAO",
    "XIMBE",
    "XIMBO",
    "XINAI",
    "XINAM",
    "XINAR",
    "XINAS",
    "XINEI",
    "XINEM",
    "XINES",
    "XINGA",
    "XINGO",
    "XINGU",
    "XINJE",
    "XINOU",
    "XINTO",
    "XIPOS",
    "XIQUE",
    "XIRAS",
    "XIRIS",
    "XIRUA",
    "XIRUS",
    "XISTO",
    "XITAU",
    "XIXIS",
    "XLIII",
    "XLVII",
    "XOCAI",
    "XOCAM",
    "XOCAR",
    "XOCAS",
    "XOCOU",
    "XOGUM",
    "XONAI",
    "XONAM",
    "XONAR",
    "XONAS",
    "XONEI",
    "XONEM",
    "XONES",
    "XONOU",
    "XOQUE",
    "XORCA",
    "XORDA",
    "XORDO",
    "XOSAS",
    "XOSSA",
    "XOTAI",
    "XOTAM",
    "XOTAR",
    "XOTAS",
    "XOTEI",
    "XOTEM",
    "XOTES",
    "XOTOU",
    "XOXOS",
    "XOXUS",
    "XUATE",
    "XUCRA",
    "XUCRO",
    "XURDA",
    "XURDE",
    "XURDI",
    "XURDO",
    "XURIS",
    "XURUS",
    "XUXOS",
    "XVIII",
    "XXIII",
    "XXVII",
    "XXXII",
    "XXXIV",
    "XXXIX",
    "XXXVI",
    "YOCTO",
    "YOGAS",
    "YOGOS",
    "YOTTA",
    "ZABAI",
    "ZABES",
    "ZABRA",
    "ZABRO",
    "ZACAI",
    "ZACAS",
    "ZACOS",
    "ZACUM",
    "ZAGAL",
    "ZAGAO",
    "ZAGAR",
    "ZAGAS",
    "ZAGOR",
    "ZAGRE",
    "ZAGUE",
    "ZAGUS",
    "ZAIBO",
    "ZAIDA",
    "ZAIDE",
    "ZAIDI",
    "ZAIMO",
    "ZAINA",
    "ZAINE",
    "ZAINO",
    "ZAIRA",
    "ZAIRE",
    "ZALDO",
    "ZAMAS",
    "ZAMBA",
    "ZAMBE",
    "ZAMBI",
    "ZAMBO",
    "ZAMIA",
    "ZAMPA",
    "ZAMPE",
    "ZAMPO",
    "ZANAL",
    "ZANAS",
    "ZANDE",
    "ZANGA",
    "ZANGO",
    "ZANIA",
    "ZANOA",
    "ZANTE",
    "ZANZA",
    "ZANZE",
    "ZANZO",
    "ZAPEA",
    "ZAPES",
    "ZAQUE",
    "ZARAO",
    "ZARCA",
    "ZARCO",
    "ZARGA",
    "ZARGO",
    "ZARPA",
    "ZARPE",
    "ZARPO",
    "ZARRA",
    "ZARRO",
    "ZARZA",
    "ZAVAI",
    "ZAVAM",
    "ZAVAR",
    "ZAVAS",
    "ZAVEI",
    "ZAVEM",
    "ZAVES",
    "ZAVOU",
    "ZAVRA",
    "ZAZOS",
    "ZEBOS",
    "ZEBRA",
    "ZEBRE",
    "ZEBRO",
    "ZEBUL",
    "ZEBUS",
    "ZECAS",
    "ZEFIR",
    "ZELAI",
    "ZELAM",
    "ZELAR",
    "ZELAS",
    "ZELDA",
    "ZELEI",
    "ZELEM",
    "ZELES",
    "ZELHA",
    "ZELIA",
    "ZELIO",
    "ZELOS",
    "ZELOU",
    "ZENAM",
    "ZENAS",
    "ZEND.",
    "ZENDA",
    "ZENDE",
    "ZENEM",
    "ZENES",
    "ZENIA",
    "ZENIR",
    "ZENIS",
    "ZENIU",
    "ZEPTO",
    "ZERAI",
    "ZERAM",
    "ZERAR",
    "ZERAS",
    "ZERBO",
    "ZEREI",
    "ZEREM",
    "ZERES",
    "ZEROS",
    "ZEROU",
    "ZESTO",
    "ZETAS",
    "ZETTA",
    "ZEUGO",
    "ZEZAO",
    "ZIADA",
    "ZIADO",
    "ZIAIS",
    "ZIAMO",
    "ZIARA",
    "ZIAVA",
    "ZIBIA",
    "ZICHA",
    "ZICHE",
    "ZICHO",
    "ZIDIM",
    "ZIEIS",
    "ZIEMO",
    "ZIGUE",
    "ZILDA",
    "ZILMA",
    "ZILRO",
    "ZIMBO",
    "ZIMOS",
    "ZIMRA",
    "ZIMRI",
    "ZINAM",
    "ZINAS",
    "ZINCA",
    "ZINCO",
    "ZINEM",
    "ZINES",
    "ZINGA",
    "ZINGO",
    "ZINHO",
    "ZINIA",
    "ZINIR",
    "ZINIS",
    "ZINIU",
    "ZIPAI",
    "ZIPAM",
    "ZIPAR",
    "ZIPAS",
    "ZIPEI",
    "ZIPEM",
    "ZIPER",
    "ZIPES",
    "ZIPOR",
    "ZIPOU",
    "ZIRBO",
    "ZIRRA",
    "ZIRRE",
    "ZIRRO",
    "ZITOS",
    "ZIZIA",
    "ZIZIE",
    "ZIZIO",
    "ZOADA",
    "ZOADO",
    "ZOAIS",
    "ZOAMO",
    "ZOARA",
    "ZOAVA",
    "ZOEIA",
    "ZOEIS",
    "ZOEMO",
    "ZOGAS",
    "ZOICA",
    "ZOICO",
    "ZOIDE",
    "ZOILO",
    "ZOINA",
    "ZOLAS",
    "ZOLIS",
    "ZOMBA",
    "ZOMBE",
    "ZOMBO",
    "ZONAI",
    "ZONAL",
    "ZONAM",
    "ZONAR",
    "ZONAS",
    "ZONEA",
    "ZONEI",
    "ZONEM",
    "ZONES",
    "ZONOU",
    "ZONZA",
    "ZONZE",
    "ZONZO",
    "ZOOL.",
    "ZOONS",
    "ZOOSE",
    "ZOOT.",
    "ZOPAS",
    "ZOPOS",
    "ZORNA",
    "ZORNE",
    "ZORNO",
    "ZORRA",
    "ZORRO",
    "ZORTA",
    "ZORTE",
    "ZORTO",
    "ZOTES",
    "ZOUPA",
    "ZOUPO",
    "ZOURA",
    "ZOVOS",
    "ZUAIS",
    "ZUATE",
    "ZUAVO",
    "ZUCAI",
    "ZUCAM",
    "ZUCAR",
    "ZUCAS",
    "ZUCOS",
    "ZUCOU",
    "ZUELA",
    "ZUELE",
    "ZUELO",
    "ZUIAM",
    "ZUIAS",
    "ZUIDA",
    "ZUIDO",
    "ZUIRA",
    "ZULAI",
    "ZULAM",
    "ZULAR",
    "ZULAS",
    "ZULEI",
    "ZULEM",
    "ZULES",
    "ZULOS",
    "ZULOU",
    "ZULUS",
    "ZUMBA",
    "ZUMBE",
    "ZUMBI",
    "ZUMBO",
    "ZUNAI",
    "ZUNAM",
    "ZUNAR",
    "ZUNAS",
    "ZUNDA",
    "ZUNEI",
    "ZUNEM",
    "ZUNES",
    "ZUNGA",
    "ZUNGO",
    "ZUNGU",
    "ZUNIA",
    "ZUNIR",
    "ZUNIS",
    "ZUNIU",
    "ZUNOU",
    "ZUPAI",
    "ZUPAM",
    "ZUPAR",
    "ZUPAS",
    "ZUPEI",
    "ZUPEM",
    "ZUPES",
    "ZUPOU",
    "ZUQUE",
    "ZURAS",
    "ZURCA",
    "ZURPA",
    "ZURRA",
    "ZURRE",
    "ZURRO",
    "ZURZA",
    "ZURZE",
    "ZURZI",
    "ZURZO"
   ];