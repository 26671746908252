import { createContext, ReactNode, useContext, useState } from "react";
import { WORD_SIZE } from "../shared/GameConstants";

type UnderlinePositionProps = {
  children: ReactNode;
}

export type UnderlinePositionData = {
  underlinePosition : number,
  updateUnderlinePosition : (increaseCount : boolean) => void,
  setSpecificUnderlinePosition : (position : number) => void
};

export const UnderlinePosition = createContext({} as UnderlinePositionData);

export function UnderlinePositionProvider({ children }: UnderlinePositionProps) : JSX.Element {
  const [underlinePosition, setUnderlinePosition] = useState(0);

  const updateUnderlinePosition = (increaseCount : boolean) : void => {
    // garant the underline position not assume -1 or WORD_SIZE
    if (underlinePosition === WORD_SIZE - 1 && increaseCount) return;
    if (underlinePosition === 0 && !increaseCount) return;
  
    const value = increaseCount ? 1 : -1;
    setUnderlinePosition((prevPosition) => prevPosition + value);
  }

  const setSpecificUnderlinePosition = (position : number) : void => {
    if (position > WORD_SIZE) return;
    if (position < 0) return;

    setUnderlinePosition(position);
  }

  return (
    <UnderlinePosition.Provider
      value={{
        underlinePosition,
        updateUnderlinePosition,
        setSpecificUnderlinePosition
      }}
    >
      {children}
    </UnderlinePosition.Provider>
  );
}

export const useUnderlinePosition = () : UnderlinePositionData => {
  return useContext(UnderlinePosition);
};