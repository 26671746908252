import { OverlayScreenProps } from "../../models";
import Overlay from "./Overlay";
import './styles/SettingsScreen.css';
import { StatisticsView } from "./StatisticsView";
import Button from "../keyboard/Button";

export function StatisticsScreen({
  handleCloseScreen,
}: OverlayScreenProps) {

  return <Overlay content={
    <>
      <div className="container">
        <StatisticsView />
      </div>

      <div className="d-flex align-items-center justify-content-center">
        <Button
          onClick={handleCloseScreen}
          label='FECHAR'
        />
      </div>
    </>
  } />
};