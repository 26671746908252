import { useMemo, useState } from 'react';
import { EndGameScreenProps } from '../../models';
import Button from '../keyboard/Button';
import Overlay from './Overlay';
import './styles/EndGameScreen.css';
import { getNormalEndGameMessage, getWhatsAppEndGameMessage, getTwitterEndGameMessage, getFacebookEndGameMessage } from '../../utils';
import { StatisticsView } from './StatisticsView';
import { BsWhatsapp, BsTwitter, BsFacebook, BsXCircleFill } from "react-icons/bs";
import ReactGA from "react-ga";

function EndGameScreen(props: EndGameScreenProps) {
  ReactGA.pageview('/endGame');
  const [isResultCopied, setIsResultCopied] = useState<boolean>(false);
  const message = useMemo<string>(
    () => getNormalEndGameMessage(props.dailyWord.edition, props.guesses, props.isGameWon),
    [props.dailyWord.edition, props.guesses, props.isGameWon],
  );

  const whatsAppMessage = useMemo<string>(
    () => getWhatsAppEndGameMessage(props.dailyWord.edition, props.guesses, props.isGameWon),
    [props.dailyWord.edition, props.guesses, props.isGameWon],
  );

  const twitterMessage = useMemo<string>(
    () => getTwitterEndGameMessage(props.dailyWord.edition, props.guesses, props.isGameWon),
    [props.dailyWord.edition, props.guesses, props.isGameWon],
  );

  const facebookMessage = useMemo<string>(
    () => getFacebookEndGameMessage(props.dailyWord.edition, props.guesses, props.isGameWon),
    [props.dailyWord.edition, props.guesses, props.isGameWon],
  );

  const handleCopyButton = () => {
    navigator.clipboard.writeText(message);
    setIsResultCopied(true);
  }

  const facebookShareButton = () => {
    navigator.clipboard.writeText(facebookMessage);
    setIsResultCopied(true);
  }

  return (
    <Overlay content={
      <>
        <div className="container-fluid">
          <div className="row">
            <div className='col-md-6'>
              <button
                className='btn btn-sq-responsive close-button'
                onClick={props.handleCloseScreen}
              >
                <BsXCircleFill size={30} />
              </button>
              <h1
                className={
                  'text-center mb-3 '
                  + (props.isGameWon ? 'win-text' : 'lose-text')
                }
              >{props.isGameWon ? 'Você acertou! 🎉' : 'Não foi desta vez...😢'}</h1>
              <p className='text-center mb-3'>Você usou <b>{props.guesses.length} de 6</b> tentativas.</p>
              <p className='text-center mb-1'>O LetriCANdo do dia era: <b>{props.dailyWord.word}</b></p>
              <p className='fw-lighter'>{props.dailyWord.meaning}</p>
              <hr />

              <div className="d-flex flex-column justify-content-center align-items-center">
                <h3>Compartilhe seu resultado:</h3>
                <Button
                  className='mb-2'
                  onClick={() => handleCopyButton()}
                  label={isResultCopied ? 'TEXTO DE COMPARTILHAMENTO COPIADO' : 'COPIAR para o ctrl+v'}
                />
                <div className='row'>
                  <div className="col-12 col-md-12 align-self-center text-center">
                    <button
                      className={'styled-button rounded px-2 py-1 mb-2 win-text'}
                      onClick={() => window.open(whatsAppMessage, '_blank')}
                    ><BsWhatsapp /> WhatsApp
                    </button>
                  </div>
                  <div className="col-12 col-md-12 align-self-center text-center">
                    <button
                      className={'styled-button rounded px-2 py-1 mb-2'}
                      onClick={() => window.open(twitterMessage, '_blank')}
                    ><BsTwitter /> Twitter
                    </button>
                  </div>
                  <div className="col-12 col-md-12 align-self-center text-center">
                    <button
                      className={'styled-button rounded px-2 py-1 mb-2 d-inline'}
                      onClick={() => window.open('https://www.facebook.com/sharer/sharer.php?u=https://letricando.cidadeazulnoticias.com.br&quote=', '_blank', "popup") && facebookShareButton()}
                    ><BsFacebook /> Facebook
                    </button>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <div className=' col-md-6'>
              <StatisticsView />
            </div>
          </div>
        </div>

      </>
    } />
  );
}

export default EndGameScreen;