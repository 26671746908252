export const WORD_SIZE = 5;
export const GUESS_LIST_SIZE = 6;
export const GAME_END_DELAY = 0.8 * 1000;
export const SAVED_GAME_KEY = 'savedGameLetricando';

export const KEY_LEFT = 'ArrowLeft';
export const KEY_RIGHT = 'ArrowRight';
export const KEY_TAB = 'Tab';
export const KEY_DELETE = 'Delete';
export const KEY_BACKSPACE = 'Backspace';
export const KEY_ENTER = 'Enter';
export const KEY_LETTERS = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';