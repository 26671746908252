import GuessLetterView from "../GuessLetterView";
import Overlay from "./Overlay";
import './styles/HowToPlayScreen.css';
import Button from "../keyboard/Button";
import { getRandomInt } from "../../utils";
import { GuessLetterState, OverlayScreenProps } from "../../models";
import { useContext } from "react";
import { GlobalSettingsContext } from "../../hooks/useGlobalSettings";
import { BsXCircleFill } from "react-icons/bs";
import ReactGA from "react-ga";

function GuessExample(props: { word: string, exampleState: GuessLetterState }) {
  const randomExample = getRandomInt(0, 5);

  return <div className="d-flex">
    {
      props.word.split('').map((letter, index) => (
        <GuessLetterView
          key={letter + '-' + props.exampleState + index.toString()}
          letter={letter}
          state={index === randomExample ? props.exampleState : 'wrong'}
          marginRight={index !== 4}
        ></GuessLetterView>
      ))
    }
  </div>
}

function GuessError(props: { word: string, exampleState: GuessLetterState }) {

  return <div className="d-flex">
    {
      props.word.split('').map((letter, index) => (
        <GuessLetterView
          key={letter + '-' + props.exampleState + index.toString()}
          letter={letter}
          state={'wordlistError'}
          marginRight={index !== 4}
        ></GuessLetterView>
      ))
    }
  </div>
}

function HowToPlayScreen(props: OverlayScreenProps) {
  const [{ isColorblindModeActive: colorblind }] = useContext(GlobalSettingsContext);

  ReactGA.pageview('/howToPlay');

  return (
    <Overlay content={
      <>
        <button
          className='btn btn-sq-responsive close-button'
          onClick={props.handleCloseScreen}
        >
          <BsXCircleFill size={30} />
        </button>
        <h1 className="text-center">COMO JOGAR</h1>

        <p className="text-left">
          Todos os dias, uma nova palavra aparecerá no letriCANdo para você adivinhar.
          Você terá 6 tentativas para adivinhar a palavra do dia, e em cada uma delas, deve ser uma palavra que exista.
          Após chutar uma palavra, as letras mudarão de cor para indicar o quão perto você está da palavra do dia.
        </p>

        <div className="d-flex align-items-center justify-content-center mb-4">
          <iframe src="https://ads.cidadeazulnoticias.com.br?_dnid=7" width="100%" height="250" title="Anuncie aqui"></iframe>
        </div>

        <p className="text-left">Se uma letra ficar {colorblind ? 'neste formato' : 'verde'}, ela faz parte da palavra procurada e está na posição correta.</p>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <GuessExample word='CERTO' exampleState='right' />
        </div>

        <p className="text-left">Se uma letra ficar {colorblind ? 'redonda' : 'amarela'}, ela faz parte da palavra procurada, mas deve estar em outra posição.</p>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <GuessExample word='QUASE' exampleState='displaced' />
        </div>

        <p className="text-left">Se uma palavra ou letra ficar {colorblind ? 'desta cor' : 'vermelha'}, nenhuma das letras digitadas na tentativa faz parte da palavra procurada.</p>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <GuessExample word='FALHA' exampleState='wrong' />
        </div>

        <p className="text-left">Atenção: você deve digitar uma palavra válida antes de confirmar. Preencher com apenas uma letra ou letras aleatórias, não irá validar a tentativa.</p>
        <div className="d-flex justify-content-center align-items-center mb-4">
          <GuessError word='AEIOU' exampleState='wordlistError' />
        </div>

        <p className="text-muted">Para mais dicas, acesse este link
          <a href="https://cidadeazulnoticias.com.br/letricando-um-jogo-de-palavras-do-cidade-azul-noticias" target="_blank" rel="noopener noreferrer"> https://cidadeazulnoticias.com.br/letricando-um-jogo-de-palavras-do-cidade-azul-noticias</a>
        </p>
        <p className="text-muted">*Atualmente acentos, cedilha e letras repetidas não são consideradas neste jogo.<br></br></p>
        <p className="text-muted">**Mande um print screen de erros ou incoerências  para o nosso
          <a href="https://api.whatsapp.com/send?phone=5519998687434&amp;text=Ol%C3%A1%2C%20estou%20entrando%20em%20contato%20pelo%20site%20Cidade%20Azul%20Not%C3%ADcias.%20Meu%20nome%20%C3%A9%20" className="whatsapp" target="_blank" rel="noopener noreferrer"><b> WhatsApp </b></a>
          🛠️
        </p>

        <div className="d-flex align-items-center justify-content-center mb-4">
          <iframe src="https://ads.cidadeazulnoticias.com.br?_dnid=6" width="100%" height="600" title="Anuncie aqui"></iframe>
        </div>

        <div className="d-flex align-items-center justify-content-center">
          <Button
            onClick={props.handleCloseScreen}
            label='FECHAR'
          />
        </div>
      </>
    } />
  )
}

export default HowToPlayScreen;