import '../styles/Header.css';
import { BsFillBarChartLineFill, BsFillGearFill, BsQuestionLg} from "react-icons/bs"; import { IoNewspaperOutline } from "react-icons/io5";
import HowToPlayScreen from './screens/HowToPlayScreen';
import { useState } from 'react';
import SettingsScreen from './screens/SettingsScreen';
import { StatisticsScreen } from './screens/StatisticsScreen';
//import ReactLogo from '../img/letriCANdo.svg';

const SITE_URL = 'https://cidadeazulnoticias.com.br';

function Header() {
  const [isHowToPlayOpen, setIsHowToPlayOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isStatisticsOpen, setIsStatisticsOpen] = useState(false);

  return (
    <>
      {isHowToPlayOpen &&
        <HowToPlayScreen
          handleCloseScreen={() => setIsHowToPlayOpen(false)}
        />
      }

      {isSettingsOpen &&
        <SettingsScreen
          handleCloseScreen={() => setIsSettingsOpen(false)}
        />
      }

      {isStatisticsOpen &&
        <StatisticsScreen
          handleCloseScreen={() => setIsStatisticsOpen(false)}
        />
      }

      <nav className='navbar py-0'>
        <ul className="nav col-3">
          <li className="nav-item col-6 text-center">
            <button
              className='btn btn-sq-responsive header-button rounded'
              onClick={() => setIsHowToPlayOpen(true)}
            >
              <BsQuestionLg />
            </button>
          </li>
          <li className="nav-item col-6 text-center">
            <button
                className='btn btn-sq-responsive header-button rounded'
                onClick={() => window.open(SITE_URL, '_blank')}
              >
                <IoNewspaperOutline />
              </button>
          </li>
        </ul>

        <ul className="nav col-6">
          <li className="nav-item p-2">
            <a href='https://letricando.cidadeazulnoticias.com.br/' target='_blank' rel='noreferrer' >
              <img src='icons/letricando.svg'  className='col-12' alt="letriCANdo" />
            </a>
          </li>
        </ul>
        
        <ul className="nav col-3">
          <li className="nav-item col-6 text-center">
            <button
              className='btn btn-sq-responsive header-button rounded'
              onClick={() => setIsStatisticsOpen(true)}
            >
              <BsFillBarChartLineFill />
            </button>
          </li>
          <li className="nav-item col-6 text-center">
            <button
              className='btn btn-sq-responsive header-button rounded '
              onClick={() => setIsSettingsOpen(true)}
            >
              <BsFillGearFill />
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Header;