import { GuessLetter } from './../models/game.model';

const NORMAL_MODE_EMOJIS = {
  'right': '🟩',
  'displaced': '🟨',
  'wrong': '🟥',
  'typing': '',
  'disabled': '⬛',
  'wordlistError': '',
}
// general function for creating a message
export function getMessage(
  prefix: string,
  edition: string,
  guesses: GuessLetter[][],
  isGameWon: boolean,
  suffix: string,
  enterMode: string,
): string {
  let message = `${prefix}`;
  
  message += `Joguei o desafio nº ${edition} e ${isGameWon ? 'acertei na tentativa ' + guesses.length + '/6' : 'não acertei a palavra do dia 😢'}${enterMode}${enterMode}`;

  message += guesses.map(guess => {
    return guess.map(letter => NORMAL_MODE_EMOJIS[letter.state]).join('') + enterMode;
  }).join('');

  message += `${suffix}`;
  
  return message;
}

export function getNormalEndGameMessage(
  edition: string,
  guesses: GuessLetter[][],
  isGameWon: boolean,
): string {
  let message = `Joguei o desafio nº ${edition} e ${isGameWon ? 'acertei na tentativa ' + guesses.length + '/6' : 'não acertei a palavra do dia 😢'}\n\n`;

  message += guesses.map(guess => {
    return guess.map(letter => NORMAL_MODE_EMOJIS[letter.state]).join('') + '\n';
  }).join('');

  message += '\njogue você também 👇🏻👇🏻👇🏻\n';
  message += 'https://letricando.cidadeazulnoticias.com.br';

  return message;
}

export function getWhatsAppEndGameMessage(
  edition: string,
  guesses: GuessLetter[][],
  isGameWon: boolean,
): string {
  let message = 'https://api.whatsapp.com/send?text=';
  
  message += `Joguei o desafio nº ${edition} e ${isGameWon ? 'acertei na tentativa ' + guesses.length + '/6' : 'não acertei a palavra do dia 😢'}%0A%0A`;

  message += guesses.map(guess => {
    return guess.map(letter => NORMAL_MODE_EMOJIS[letter.state]).join('') + '%0A';
  }).join('');

  message += '%0Ajogue você também 👇🏻👇🏻👇🏻%0A';
  message += 'https://letricando.cidadeazulnoticias.com.br';

  return message;
}

export function getTwitterEndGameMessage(
  edition: string,
  guesses: GuessLetter[][],
  isGameWon: boolean,
): string {
  let message = 'https://twitter.com/intent/tweet?text=';
  
  message += `Joguei o desafio nº ${edition} e ${isGameWon ? 'acertei na tentativa ' + guesses.length + '/6' : 'não acertei a palavra do dia 😢'}%0A%0A`;

  message += guesses.map(guess => {
    return guess.map(letter => NORMAL_MODE_EMOJIS[letter.state]).join('') + '%0A';
  }).join('');

  message += '%0Ajogue você também em';
  message += '&hashtags=letriCANdo';
  message += '&via=CidAzulNoticias';
  message += '&url=letricando.cidadeazulnoticias.com.br';

  return message;
}

export function getFacebookEndGameMessage(
  edition: string,
  guesses: GuessLetter[][],
  isGameWon: boolean,
): string {
  let message = `Joguei o desafio nº ${edition} e ${isGameWon ? 'acertei na tentativa ' + guesses.length + '/6 😅' : 'não acertei a palavra do dia 😢'}\n\n`;

  message += guesses.map(guess => {
    return guess.map(letter => NORMAL_MODE_EMOJIS[letter.state]).join('') + '\n';
  }).join('');

  message += '\njogue você também 👇🏻👇🏻👇🏻';

  return message;
}