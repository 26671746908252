import Game from "./Game";
import Header from "./Header";
import '../styles/App.css';
import { GlobalSettingsContext, useGlobalSettings } from "../hooks/useGlobalSettings";
import { StatisticsContext, useStatistics } from "../hooks/useStatistics";
import ReactGA from "react-ga";
// import ShuffleApp from "./ShuffleApp";
import { UnderlinePositionProvider } from "../hooks/useUnderlinePosition";

function App() {
  const globalSettings = useGlobalSettings();
  const statistics = useStatistics();
  ReactGA.initialize('UA-119478958-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <StatisticsContext.Provider value={statistics}>
      <GlobalSettingsContext.Provider value={globalSettings}>
        <UnderlinePositionProvider>
          <div className="app-container">
            <div className="row top-25 start-50 translate-middle">
              <div id="liveToast" className="toast align-items-center text-white bg-danger bg-gradient border-0" role="alert" aria-live="assertive" aria-atomic="true">
                <div className='d-flex'>
                  <div className='toast-body'>
                    Sigua esta dica.
                  </div>
                  <button type="button" className='btn-close btn-close-white me-2 m-auto' data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
              </div>
            </div>
            <Header />
            <Game />
            {/* <ShuffleApp /> */}
          </div>
        </UnderlinePositionProvider>
      </GlobalSettingsContext.Provider>
    </StatisticsContext.Provider>
  );
}

export default App;